import { BaseModel } from './base';

export class Approval extends BaseModel {
  MailSent: string;

  LevelPriority: number;

  AssessmentId: string;

  DamageRefNo: string;

  SenderId: string;

  SenderName: string;

  SenderRemarks: string;

  ReceiverId: string;

  ReceiverName: string;

  ReceiverRemarks: string;

  SenderApprovalStatus: string;

  ReceiverApprovalStatus: string;

  ReceiverResponseDate: Date;

  SenderDateSent: Date;

  Descriptions: string;

  AssessmentDate: Date;

  ContractorId: string;

  ContractorName: string;

  Location: string;

  LineTypeId: string;

  LineTypeName: string;

  LineNumber: string;

  SwathId: string;

  SwathNo: string;

  ProgrammeId: string;

  ProgrammeName: string;

  AccessorName: string;

  AccessorId: string;

  OMLId: string;

  OMLName: string;

  TotalAmount: number;

  CouponNo: string;

  IsFlagged: boolean;

  IsFlaggedDate: Date;

  LastFlaggedById: string;

  LastFlaggedByName: string;

  LastFlaggedStatus: string;

  AllFlags: string;

  ApprovalTrackingNo: string;

  CheckerIsFlagged: boolean;

  CheckerIsFlaggedDate: Date;

  CheckerLastFlaggedById: string;

  CheckerLastFlaggedByName: string;

  CheckerLastFlaggedStatus: string;

  CheckersAllFlags: string;

  ApproveById: string;

  ApproveByName: string;

  ApproveType: string;

  DelegateIsFlagged: boolean;

  DelegateRemarks: string;

  DelegateApproveStatus: string;

  DelegateReviewDate: Date;

  DelegateId: string;

  DelegateName: string;

  CheckersRemarks: string;

  LastFlaggedByRemarks: string;

  SenderEmail: string;

  ReceiverEmail: string;

  CheckerEmail: string;

  selected: boolean;

  SubscriberId: string;

  SubscriptionUserId: string;

  SiteId: string;

  ModifiedById: string;

  CreatedById: string;

  dateUpdated: Date;

  dateCreated: Date;

  Version: string;

  description: string;
  approvalTypeId: string;
  approvalTypeName: string;
  detailsId: string;
  approvalSubTypeId: string;
  approvalSubTypeName: string;
  employeeId: string;
  CanEdit: string;
  IsReSubmitted: boolean;
  IsQueried: boolean;
  queryById: string;
  queryByName: string;

  isQueriedRespone: boolean;
  queryDateResponse: Date;
  queryReasonsResponse: string;
  queryByIdResponse: string;
  queryByNameResponse: string;
  copiedComment: string;
  copiedCommentDate: Date;
}
