/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@mrsoftebs/material';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { SigninComponent } from './components/signin/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardNavModule } from '@mrsoftebs/dashboard-nav';
import { HomepageComponent } from 'libs/account/src/lib/components/homepage/homepage.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuard } from './auth.guard';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ErrorComponent } from './components/error/error.component';
import { AuthGuardService } from './shared/auth.load.guard';
import { JwtInterceptor } from './shared/jwt.interceptor';
import { SimplenavComponent } from './simplenav/simplenav.component';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { AppsComponent } from './components/apps/apps.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers';
import { AuthFacade, AuthModule } from '@mrsoftebs/auth';
import { GatewayFacade } from '@mrsoftebs/gateway';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { dbConfig } from './indexdbconfig';
import { GatewayModule } from '@mrsoftebs/gateway';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { LogService } from './services/log.service';
import { NotificationviewerComponent } from './components/notificationviewer/notificationviewer.component';
import { HtmlModule } from 'libs/staff/src/lib/html/html.module';
import { NotifierComponent } from './components/notifier/notifier.component';
import { TableComponent } from './components/table/table.component';
import { SubMenuComponent } from './components/top-nav/sub-menu/sub-menu.component';
import { UnAuthorizeComponent } from './components/un-authorize/un-authorize.component';

const config: SocketIoConfig = {
  url: environment.socketUrl,
  // options: { withCredentials: true },
};

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    DashboardComponent,
    HomepageComponent,
    SubscribeComponent,
    SigninComponent,
    SimplenavComponent,
    CarouselComponent,
    HomeComponent,
    LoginComponent,
    TopNavComponent,
    AppsComponent,
    NotificationviewerComponent,
    NotifierComponent,
    TableComponent,
    SubMenuComponent,
    UnAuthorizeComponent,

    // LeaveschedulereportComponent,
  ],
  imports: [
    AngularMaterialModule,
    HtmlModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgxSpinnerModule,
    // ReferencesModule,
    // LeaveModule,
    // PerformanceModule,
    // StaffModule,
    SocketIoModule.forRoot(config),
    RouterModule,
    AppRoutingModule,
    DashboardNavModule,
    GatewayModule,
    LayoutModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: true,
      },
    }),
    EffectsModule.forRoot([]),

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    LogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AuthGuard,
    // AuthGuardService,

    AuthFacade,
    GatewayFacade,
  ],
  bootstrap: [AppComponent],
  entryComponents: [SubscribeComponent, SigninComponent, AppsComponent],
  // schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
