import { BaseModel } from './base';
import { QualificationType } from './qualificationtype';

export class StaffEducationalHistory extends BaseModel {
  qualificationTypeId: QualificationType;
  institutionName: string;
  yearOfAdmission: string;
  yearOfGraduation: string;
  dateCreated: Date;
  dateModified: Date;
}
