import { StaffInfo } from './staffinfo';

import { BaseModel } from './base';

export class InventoryDto extends BaseModel {
  _id: string;
  qtyOfOldTools: number;
  qtyOfNewPurchasedTools: number;
  qtyOfToolsTaken: number;
  sizeOfItem: string;
  description: string;
  measure: string;
  measureName: string;
  codeNumber: string;
  location: string;
  ownership: string;
  whereStored: string;
  manufacturer: string;
  supplier: string;

  qtySupplied: string;
  expiredDate: Date;
  dateSupplied: Date;

  datePurchased: Date;
  dateCreated: Date;
  dateUpdated: Date;
  model: string;
  labelNumber: string;
  associatedHazard: string;
  controlMeasure: string;
  replacedDate: Date;

  takenBy: string;
  returnBy: string;
  dateCollected: Date;
  dateReturned: Date;
  serialNoOfItems: string;
  namesOfTool: string;
  category: string;
  Status: string;
  amount: string;
  // createdBy: StaffInfo;
  modifyBy: StaffInfo;
  departmentId: string;
  unitId: string;
  subscriberId: string;
  siteId: string;
}

export class OrderToolDto {
  toolId?: InventoryDto;
  qtyOfToolsTaken: number;
  takenBy: StaffInfo;
  returnBy: StaffInfo;
  createdBy: StaffInfo;
  modifyBy: StaffInfo;
  dateCollected: Date;
  dateReturned: Date;
  dateCreated: Date;
  dateUpdated: Date;
  departmentId: string;
  Status: string;
  subscriberId: string;
  siteId: string;
}

export class OldStockDto {
  _id?: string;
  client: string;
  description: string;
  dateStarted: Date;
  dateDelivered: Date;
  duration: string;
  projectTeam: string[];
  category: string;
  Status: string;
  subscriberId: string;
  siteId: string;
}

export class MovementDto {
  _id?: string;
  client: string;
  fromLocation: string;
  toLocation: string;
  driver: string;
  vehicleNumber: string;
  receiptNumber: string;
  dateStarted: Date;
  takenBy: StaffInfo;
  returnBy: StaffInfo;
  dateCollected: Date;
  dateReturned: Date;
  dateDelivered: Date;
  commentBeforeDispatch: string;
  commentOnReturn: string;
  beforeDispatchWitness: string;
  onReturnWitness: string;
  driverName: string;
  beforeDispatchWitnessName: string;
  onReturnWitnessName: string;
  receivedBy: string
  receivedByName: string
  dateReceived: string
  items: Item[];
  description: string;
  qtyOfToolsTaken: number;
  qtyOfToolsReturn: number;

  orderBy: string;
  wellName: string;
  company: string;
  location: string
  departmentId: string;
  approvalStatus: string;

  preparedBy: string;
  preparedByName: string;
  preparedByPhoneNo: string;
  datePrepared: Date;
  approvedBy: string;
  approvedByName: string;
  approvedByPhoneNo: string;
  dateApproved: Date;
  transportedBy: string;
  transportedByName: string;
  purpose: string;

  dateTransported: Date;
  receivedPhoneNo: string;

  status: string;
  createdBy: StaffInfo;
  modifyBy: StaffInfo;
  subscriberId: string;
  siteId: string;
}

class Item {
  description: string;
  qtyOfToolsTaken: number;
  qtyOfToolsReturn: number;
  status: string;

  lease: string;
  pressureTest: string;
  sit: string;
  remarks: string;
  serialNo: string;

  qtyOfOldTools: number;
  toolId?: InventoryDto;
}
