import { BaseModel } from './base';

export class   hazardObservationHistory extends BaseModel{
   
    HazardObservationId:string;
    EventtypeId:string;
    EventtypeName:string;
    EventId:string;
    EventName: string;
    ObservationDate: Date;
    ObservationTime: string;

    subscriberId: string;
  
    siteId:   string;
    
}