import { BaseModel } from './base';

export class QMSRespondents extends BaseModel {
  respondentId: string;
  staffId: string;
  staffName: string;
  staffEmail: string;
  resType: string;
  noOfRespondent: number;
  respondentPosition: number;
  respondentDesc: string;
  subscriberId: string;
  siteId: string;
  departmentId: string;
  departmentName: string;
  accessLevel: string;
}
