import { BaseModel } from "./base";

export class RequestBreakDowns extends BaseModel{
    
    requisitionId?: string;    
    requestDate: Date;   
    amount: number;   
    requesttypeId: string;    
    requestStatus: string;
    collectionDate: Date;
    itemDescription: string;
    quantity: number;
    unitPrice: number;
    totalPrice: number;
    remarks: string;
    subscriberId: string;   
    siteId: string;   
    dateCreated: Date;   
    dateModified: Date;
    id: string;
  }
  