import { BaseModel } from './base';

export class EventScheduler extends BaseModel {
  subject: string;
  startDate: Date;
  endDate: Date;
  departments: [];
  startTime: string;
  endTime: string;
  secetary: [];
  description: string;
  remarks: string;
  reminderindays: string;
  expectedattendee: string;
  staffsattended: [];
  comments: string;
  eventvenue: string;
  eventlink: string;
  staffs: [];
  status: string;
  host: [];
  filePath: string;
  subscriberId: string;
  siteId: string;
  createdDate: Date;
  modifiedDate: Date;
  createdById: string;
}
