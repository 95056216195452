import { EventType } from "./eventtype";
import { HazardIncidentCategory } from "./hazardincidentcategory";

import { BaseModel } from './base';

export class HazardObservation   extends BaseModel {


    ObservationDate: Date;
    ObservationTime: string;
    ObservedBy: string;
    Location: string;
    Client:string;
    Intervention: string;
    IncidentcategoryId: HazardIncidentCategory;

    
    EventtypeId:EventType;

    // EventtypeName:string;
    // EventId:string;
    // EventName: string;
    CommentsOnIncidentObserved: string;
    Intervention_Sugesstion:string;
    FollowUpRequired:string;
    subscriberId: string;
  
    siteId:   string;

}