import { BaseModel } from './base';

export class WorkExperience extends BaseModel {
  workExperienceId?: string;

  jobTitleId: string;

  jobDescription: string;

  companyName: string;

  // staffId: StaffInfo;

  startYear: string;

  endYear: string;

  reasons: string;

  subscriberid: string;

  siteId: string;

  dateCreated: string;

  dateModified: string;
  _id: string;
}
