<div *ngIf="loading" class="loading-container flex-content-center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="site-content">

  <div class="example-container" autosize>
    <p>
      <mat-toolbar class="example-toolbar  mat-elevation-z10 " style="background-color: white;">
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon (click)="toggle()">menu</mat-icon>
        </button>
        <span>
          <h5 *ngIf="loggedInUser && loggedInUser?.subscriber?.subscriberName?.length > 25">
            {{loggedInUser.subscriber.subscriberShortName}}</h5>
          <h5 *ngIf="loggedInUser && loggedInUser?.subscriber?.subscriberName?.length <= 25">
            {{loggedInUser?.subscriber?.subscriberName}}</h5>
        </span>
        <span class="example-spacer"></span>
        <!-- Right floated items -->
        <img src="assets/images/top_nav/user.jpg" class="nav_img-icon" alt="" height="30px"
          style="border-radius: 30px; width:30px;">
        <p class="greetings">

          <span class="name" *ngIf="loggedInUser">

            <button mat-button [matMenuTriggerFor]="menu">{{loggedInUser.email}}
              <span><img src="assets/images/top_nav/more-alt.svg" height="15px" style="margin-left: 5px;" alt=""></span>
            </button>
          </span>
        </p>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="profile()">
            <mat-icon>perm_identity</mat-icon> Profile
          </button>
          <button mat-menu-item (click)="setting()">
            <mat-icon aria-hidden="false" aria-label="{2:label} icon">settings</mat-icon> Setting
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon aria-hidden="false" aria-label="{2:label} icon">logout</mat-icon> Log out
          </button>

        </mat-menu>
      </mat-toolbar>
    </p>
    <mat-sidenav-container [hasBackdrop]="false" class="example-sidenav-container ">
      <!-- SIDENAV -->

      <mat-sidenav #sidenav [(mode)]="over" [(opened)]="opened" class="side-nav" style="width: 240px; border: 0px; ">

        <!-- SIDENAV-HEADER-TOOLBAR -->


        <!--SIDENAV-BODY-CONTENT -->

        <div class="mt-3">
          <mat-list-item class="nav-item active">

            <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
              data-target="#collapseheadingOne" aria-expanded="false" aria-controls="collapseheadingOne">
              <i class="Navigation__search-icon side_icon icon-basic-accelerator">
                <!-- <span>Dashboard</span> -->
              </i>

            </button>

          </mat-list-item>
          <mat-list-item class="nav-item side_nav">
            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/User.svg" height="60%" alt="">

                    <span class="nav__list ml-3">Admin</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a class="collapse-item">Sign up</a>
                  </mat-list-item>

                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/employee.svg" height="60%" alt="">

                    <span class="nav__list ml-3">Employees</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/staff/staffinfo">Enrolment</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/staff/">Department Transfer</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/staff/">Staff Query</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/staff/">Staff Termination </a>
                  </mat-list-item>
                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/Calendar.svg" height="90%" alt="">

                    <span class="nav__list ml-3">Leave Management</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/leavetype/">Leave Type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/leave/leavelevel/">Leave Level</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/leave/leaveapprovalperson/">Leave Approval Persons</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/leave/leaveplan/">Leave Plan</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/leave/leaverequest/">Leave Request</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/leave/leaveapproval/">Leave Approval</a>
                  </mat-list-item>


                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/Calendar.svg" height="90%" alt="">

                    <span class="nav__list ml-3">Staff Training</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/stafftraining/trainingtype">Training Type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/stafftraining/trainingcategory">Training Category</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/stafftraining/stafftrainingplan/">Staff Training plan</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/stafftraining/mytraining/">My Training</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/stafftraining/closeouttraining/">Approve/Close-out
                      Training</a>
                  </mat-list-item>



                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/Import.svg" alt="">

                    <span class="nav__list ml-3">Payroll</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/payroll/">Staff Payroll</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/payroll/staffpaycategory">Staff Pay Category</a>
                  </mat-list-item>
                  <!-- <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/payroll/create-payslip">Generate Payslip</a>
                  </mat-list-item> -->
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/payroll/payslipschedule">Payslip Schedule</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/payroll/payrollsetting">Payroll Setting</a>
                  </mat-list-item>
                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>
            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/icons8-data-sheet-50.svg" height="53%" alt="">

                    <span class="nav__list ml-3">Performance</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/performance/">Performance</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/performance/apprasal">Appriasal</a>
                  </mat-list-item>

                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>
            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/Import.svg" alt="">

                    <span class="nav__list ml-3">Recruitment</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/recruitment/joblist">Jobs</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link">
                    <a class="collapse-item" routerLink="/recruitment/jobopennings">Create New Jobs</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link">
                    <a class="collapse-item" routerLink="/recruitment/jobapplicants">Jobs
                      Applicants</a>

                  </mat-list-item>
                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>
            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/icons8-alarm-clock-50.svg" height="53%" alt="">

                    <span class="nav__list ml-3">Time Manegement</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a class="collapse-item">Attendance</a>
                  </mat-list-item>

                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/icons8-airplane-take-off-50.svg" height="53%" alt="">

                    <span class="nav__list ml-3">Travel Management</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a routerLink="/travel/" class="collapse-item">Travel Itinerary</a>
                  </mat-list-item>

                </mat-nav-list>

              </mat-expansion-panel>

            </mat-accordion>




            <mat-accordion multi="true" [displayMode]="displayMode">
              <mat-expansion-panel class="no-shadow mat-elevation-z0 mat-expansion-panel-nav "
                style="background: none;">
                <mat-expansion-panel-header class="specific-class p-0" fxLayout="row" [expandedHeight]="expandHeight"
                  [collapsedHeight]="collapseHeight">
                  <mat-panel-title class="panel-title nav-link">

                    <img src="assets/images/side_nav/icons8-company-50.svg" height="53%" alt="">

                    <span class="nav__list ml-3">References</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list dense>

                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/country">Country</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/state">State</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/lga">Lga</a>
                  </mat-list-item>
                  <!-- <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/jobtitle">Job title</a>
                  </mat-list-item> -->
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/relationshiptype">Relationship type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/documenttype">Document type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/qualificationtype">Qualification type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/gender">Gender</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/bloodgroup">Blood group</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/department">Department</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/maritalstatus">Marital status</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/title">Title</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/projecttype">Project type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/criterial">Criterial</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/documenttype">Document type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/paycategorytype">Payment category type</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/paycategory">Payment category</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/step">Step</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/grade">Grade</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/level">Level</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/position">Position</a>
                  </mat-list-item>
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/leavetype">Leave type</a>
                  </mat-list-item>
                  <!-- <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/references/leavesetting">Leave setting</a>
                  </mat-list-item> -->
                  <mat-list-item class="nav-link ">
                    <a class="collapse-item" routerLink="/payroll/payrollsetting">Payroll setting</a>
                  </mat-list-item>
                </mat-nav-list>
              </mat-expansion-panel>
            </mat-accordion>

            <mat-panel-title class="panel-title nav-link">
              <!-- <button mat-button class="nav__list ml-3 " style="cursor: pointer;" (click)="logout()">
                <mat-icon aria-hidden="false" aria-label="{2:label} icon">logout</mat-icon>
                Log out
              </button> -->
            </mat-panel-title>
          </mat-list-item>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>

        <!-- <ebs-error></ebs-error> -->
        <ng-content></ng-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
      <!-- CONTENT -->

    </mat-sidenav-container>
  </div>

</div>