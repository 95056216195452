import { BaseModel } from './base';

export class Overtime extends BaseModel {
  OvertimeId: string;
  Month: string;
  JobDone: string;
  FromDate: string;
  ToDate: string;
  Rate: number;
  approvalStatus: string;
  paidStatus: string;
  TotalHrs: string;
  Comment: string;
  userId: string;
  subscriberId: string;
  siteId: string;
  id: string;
  _id?: string;
}
