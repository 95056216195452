<div class="the_top" *ngIf="isClient != 'true'">
  <h4 class="title" style="display: block">{{ module?.itemName }}</h4>

  <div class="other_links">
    <div style="display: inline-block" *ngFor="let item of module">
      <button
        class="links"
        (click)="navigateAction(item)"
        *ngIf="item.actionName && item.canAccess"
      >
        {{ item?.actionName }}
      </button>
    </div>
  </div>
</div>
<!-- <h1>This is a sub menu component to display sub menu item</h1> -->
