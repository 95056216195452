import { Activity } from './activity';
import { BaseModel } from './base';
import { Owner } from './owner';
export class Task extends BaseModel {
  createdBy: string;

  title: string;

  taskDescription: string;

  taskOwners: Owner[];

  startTime: string;

  endTime: string;

  projectId: string;

  activities: Activity[];

  subscriberId: string;

  priority: string;

  siteId: string;
  id: string;
}
