import { BaseModel } from './base';

export class   Gender extends BaseModel{
  id: string;
  SubscriberId:string;
  SiteId:string;
  genderId:string;
  genderName: string;
  _id: string;
}
