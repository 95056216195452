/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationDto, RoleDto } from '@mrsoftebs/data';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GatewayFacade } from 'libs/gateway/src/lib/states/+gateway/gateway.facades';
import { Observable } from 'rxjs';
import { MenuService } from 'apps/ebs/src/app/services/menu.service';
import { finalize, tap } from 'rxjs/operators';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cradua-application-container',
  templateUrl: './application-container.component.html',
  styleUrls: ['./application-container.component.scss'],
})
export class ApplicationContainerComponent implements OnInit {
  applications$: Observable<ApplicationDto[]>;
  roles$: Observable<RoleDto[]>;
  staffRole$: Observable<any[]>;

  isLoading$: Observable<boolean>;
  isLoadingRole$: Observable<boolean>;
  isLoadingStaffRole$: Observable<boolean>;

  constructor(
    private gatewayFacade: GatewayFacade,
    private router: Router,
    public menuService: MenuService
  ) // private menuService: MenuService
  {}

  ngOnInit(): void {
    this.loadMenus();
    this.isLoading$ = this.gatewayFacade.isLoadingGateway('Application');
    this.isLoadingStaffRole$ = this.gatewayFacade.isLoadingGateway(
      'StaffRoleNew'
    );
    // this.isLoadingRole$ = this.gatewayFacade.isLoadingGateway('Role');
    this.applications$ = this.gatewayFacade.loadGateway('Application');
    // this.roles$ = this.gatewayFacade.loadGateway('Role');
    // this.roles$ = this.menuService.getMenu();
    this.staffRole$ = this.gatewayFacade.loadGateway('StaffRoleNew');
  }

  loadMenus() {

    this.roles$ = this.menuService.getMenu()
  //  this.roles$ = this.menuService.showLoaderUntilComplement(roles$);
  }

  onEmitAssignedRole(value) {
    const addAssignRole = [];
    const updateAssignRole = [];
    for (const assign of value) {
      if (assign?.id) {
        updateAssignRole.push(assign);
      } else {
        addAssignRole.push(assign);
      }
    }
    if (updateAssignRole?.length) {
      this.gatewayFacade.update('StaffRoleNew', updateAssignRole, ['id']);
    }
    if (addAssignRole?.length) {
      this.gatewayFacade.add('StaffRoleNew', value);
    }
  }

  onEmitDeleteRole(value) {
    if (value?.length) {
      this.gatewayFacade.remove('StaffRoleNew', value);
    }
  }

  onSubmit(data) {
    const roles = data.Roles.filter((bc) => bc.isChecked === true);
    const updatedData = [];
    const savedData = [];
    for (const role of roles) {
      if (role.id) {
        updatedData.push(role);
      } else {
        delete role.id;
        savedData.push(role);
      }
    }

    if (savedData.length > 0) {
      this.gatewayFacade.add('Application', savedData);
    }

    if (updatedData.length > 0) {
      this.gatewayFacade.update('Application', updatedData, ['id']);
    }
  }

  onDeleteapplication(ids) {
    // console.log('Ids: ', ids);
    this.gatewayFacade.remove('Application', ids);
  }
}
