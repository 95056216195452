import { BaseModel } from './base';
import { Country } from './country';
import { LGA } from './lga';
import { State } from './state';

export class StoreCategory extends BaseModel {
  categoryName: string;
  subscriberId: string;
  siteId: string;
}

export class StoreSubCategory extends BaseModel {
  subCategoryName: string;
  categoryId: StoreCategory[];
  subscriberId: string;
  siteId: string;
}

export class StoreSubSubCategory extends BaseModel {
  subSubCategoryName: string;
  subCategoryId: StoreSubCategory[];
  subscriberId: string;
  siteId: string;
}

export class StoreSubSubSubCategory extends BaseModel {
  subSubSubCategoryName: string;
  subSubCategoryId: StoreSubSubCategory[];
  subscriberId: string;
  siteId: string;
}

export class UnitOfMeasureDto extends BaseModel {
  id: string;
  name: string;
  rate: number;
  subscriberId: string;
  siteId: string;
}

export class Currency extends BaseModel {
  name: string;
  code: string;
  subscriberId: string;
  siteId: string;
}

export class Warehouse extends BaseModel {
  name: string;
  address: string;
  country: Country;
  state: State;
  city: string;
  lga: LGA;
  subscriberId: string;
  siteId: string;
}

export class ManufacturerDto extends BaseModel {
  id: string;
  name: string;
  phoneNo: string;
  manufactureDate: Date;
  expirationDate: Date;
  email: string;
  address: string;
  subscriberId: string;
  siteId: string;
}

export class SupplierDto extends BaseModel {
  id: string;
  name: string;
  phoneNo: string;
  email: string;
  subscriberId: string;
  siteId: string;
}
