<div class="box green_box"></div>
<div class="box pink_box"></div>
<div class="blur_ball">
  <img src="assets/images/small.svg" class="top1" alt="" />
  <img src="assets/images/small.svg" class="bottom1" alt="" />
</div>

<main class="main_signin">
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="form">
    <div class="sign_in">
      <h2 class="title">Update Profile</h2>

      <div class="contain">
        <div class="the_input">
          <input #file type="file" class="form-control" multiple (change)="uploadFile($event)" />
        </div>

        <div class="the_input">
          <label for="">Organization name</label>
          <input type="text" placeholder="Enter organization name" formControlName="SubscriberName"
            class="default_input" [ngClass]="{ inactive_input: (authService.isLoading$ | async) }"
            [readonly]="authService.isLoading$ | async" />
        </div>
        <div class="the_input">
          <label for="">Email</label>
          <input type="email" placeholder="Enter email" formControlName="UserName" class="default_input"
            [ngClass]="{ inactive_input: (authService.isLoading$ | async) }"
            [readonly]="authService.isLoading$ | async" />
        </div>
      </div>
      <!-- <p class="alternative">
        Already have an account?
        <a [routerLink]="['/auth/login']" style="cursor: pointer">Click here to Sign in</a>
      </p> -->
      <div class="sign_in-submit">
        <button type="submit" class="default_primary-button"
          [ngClass]="{ inactive_btn: (authService.isLoading$ | async) }" [disabled]="authService.isLoading$ | async">
          Update
        </button>
        <a class="m-4" mat-button routerLink="/">Return to Dashboard</a>
        <div *ngIf="authService.isLoading$ | async">
          <mat-progress-spinner mode="indeterminate" diameter="30" strokeWidth="1"></mat-progress-spinner>
        </div>
      </div>
    </div>
  </form>
</main>