import { BaseModel } from './base';

export class summaryOfImprestExpensesDetails extends BaseModel {
  id: string;
  description: string;
  openingBal: number;
  additions: number;
  expensesTransfers: number;
  closingBal: number;
}
