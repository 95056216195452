import { BaseModel } from './base';

export class   LGA extends BaseModel{
  lgaId: string;
  lgaName: string;
  stateId: string;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  lgaCode: string;
  id: string;
  _id: string;
}
