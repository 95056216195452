import { BaseModel } from "./base";

export class ApplicationDepartment extends BaseModel{


  departmentId: string;

  departmentName: string;

  facultyId: string;

  facultyName: string;
  
  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  _id: string;
}
