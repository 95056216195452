import { BaseModel } from './base';

export class Title extends BaseModel {
  TitleId: string;

  TitleName: string;

  DateCreated: string;

  DateModified: string;

  SubscriberId: string;

  SiteId: string;
  id: string;
  _id: string;
}
