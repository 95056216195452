import { BaseModel } from './base';

export class  JobTitle  extends BaseModel{
    jobTitleName: string;
    unitId: string;
    DateCreated: string;

    DateModified: string;
  
    subscriberId: string;
  
    siteId: string;
    id: string;
    _id: string;
  }
  