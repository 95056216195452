import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  showProgress = false;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private tokenService: TokenService,
    private dialogRef: MatDialogRef<SigninComponent>
  ) {}

  ngOnInit() {
    this.signinForm = this.fb.group({
      email: [''],
      password: [''],
      rememberMe: [''],
    });
  }
  onSubmit() {
    this.showProgress = true;
    console.log('Form value: ', this.signinForm.value);
    this.accountService.dialogSignin(this.signinForm.value).subscribe(
      (data) => {
        // console.log('signin data: ', data);
        this.tokenService.setLoggedIn(true);
        this.accountService.saveUser(data.access_token);
        this.accountService.setToken(data.access_token);
        this.dialogRef.close('Closing dialog. ...');
        this.router.navigateByUrl(`/hr`);
      },
      (err) => {
        this.showProgress = false;
      }
    );
    // this.dialogRef.close();
  }
}
