import {
  trigger,
  transition,
  style,
  animate,
  useAnimation
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeOut } from './carousel.animations';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        useAnimation(fadeIn, { params: { time: '1300ms' } })
      ]),
      transition('* => void', [
        useAnimation(fadeOut, { params: { time: '1300ms' } })
      ])
    ])
  ]
})
export class CarouselComponent implements OnInit {
  @Input() slides: Array<any> = [];
  currentSlide: number = 0;
  constructor() {}

  ngOnInit() {
    // console.log('Slides: ', this.slides);
    //   setTimeout(() => {
    //     setInterval(function() {
    //       if (this.currentSlide < this.slides && this.slides.length) {
    //         const next = this.currentSlide + 1;
    //         this.currentSlide = next === this.slides.length ? 0 : next;
    //       } else {
    //         const previous = this.currentSlide - 1;
    //         this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    //         console.log('Current slide: ', this.currentSlide);
    //       }
    //     }, 130);
    //   }, 5000);
  }
  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    // console.log('previous clicked, new current slide is: ', this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    // console.log('next clicked, new current slide is: ', this.currentSlide);
  }
}
