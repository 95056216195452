import { LeaveType } from "./leavetype";
import { StaffInfo } from "./staffinfo";

import { BaseModel } from './base';

export class  ExtraLeaveHistory  extends BaseModel{

staffId: StaffInfo;

leavetypeId: LeaveType;

Year: string;

noOfExtraDays: string;

previousnoOfDays: string;

subscriberId: string;

siteId: string;
}
