import { BaseModel } from './base';

export class StaffRole extends BaseModel {
  id: string;
  name: string;
  link: string;
  menuId: string;
  parentId: string;

  createdAt: Date;

  updatedAt: Date;

  StaffId: string;

  selected: boolean;

  SubscriberId: string;
  SubscriptionUserId: string;

  SiteId: string;

  ModifiedById: string;

  CreatedById: string;

  dateUpdated: Date;

  dateCreated: Date;

  Version: string;

  isMajor: boolean;

  isDefault: boolean;

  MenuDesc: string;

  activities: string;
  iconPath: string;
}
