export class Appraisal {
  staffId: string;
  positionId: string;
  reviewFrom: string;
  reviewTo: string;
  appriaseDate: number;
  unitId: string;
  idCardNo: string;
  siteId: string;
  subscriberId: string;
  rating: number;
  agreement: string;
  employeeComment: string;
  categoryId: string;
  _id: string;
  Criterials: [{ criterialId: string; average: number; remarks: string }];
  additionalCriterials: [
    { criterialId: string; average: number; remarks: string }
  ];
  appraisee: [
    { questionId: string; answer: string; remarks: string; average: number }
  ];
  finalComment: [{ position: string; comment: string; date: Date }];
}
