import { BaseModel } from "./base";

export class SafetyObservationDto extends BaseModel {
  safetyobservationId: string;
  toolName: [];
  conditionName: [];
  subtoolName: [];
  othersubTool:string
  observerName:string;
date: string;
location: string;
companyName: string;
departmentName: string;
time: string;
observationDetails:[];
  subscriberId: string;
mediaFile:[];
  siteId: string;


}
