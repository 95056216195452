import { BaseModel } from './base';

export class InvoicePreparedDetails extends BaseModel {
  id: string;
  client: string;
  jobDescription: string;
  invoiceNo: string;
  NGN: number;
  USD: number;
  totalNGN: number;
  totalUSD: number;
}
