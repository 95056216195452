import { BaseModel } from './base';

export class EnvSampleRegistration extends BaseModel {
  constructor() {
    super();
  }
  samples: Array<{
    dateReceived: Date;
    client: string;
    labSampleId: string;
    sampleMatrix: string;
    sampleSource: string;
    clientId: string;
    remStage: string;
    depth: string;
    sampler: string;
    contractorIncidentId: string;
    coordinate: string;
    preservation: string;
    dateSampled: Date;
    receivedBy: string;
    timeReceived: string;
    timeLoggedIn: string;
    jobScope: string;
    containerType: string;
    containerCompatibility: string;
    sampleDescription: string;
    comment: string;
    sampleNo: number;
    sampleidName: string;
    sampleRetention: string;
    disposalstatus: string;
    disposalsdate: Date;
  }>;

  client: string;
  clientId: string;
  jobId: string;
  jobNo: number;
  loggedinBy: string;
}
