import { BaseModel } from "./base";

export class HrReport extends BaseModel {
  FromDate: string;
  ToDate: string;
  Hr: Hr[];
  id: string;
}

export class Hr {
  Item: string;
  Description: string;
  Remark: string;
}
