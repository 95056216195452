import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LogService {
    log(message?, data?, ...options) {
        if (data && options.length < 1) {
            console.log(message, data);
        } else if (data && options.length > 0) {
            console.log(message, data, options.join(' '));
        } else {
            console.log(message);
        }
    }
}