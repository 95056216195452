import { BaseModel } from './base';

export class QMSObjective extends BaseModel {
  objectiveId: string;

  activityId: string;

  activityName: string;

  planTargetExpected: string;

  indicator: string;

  actualPercentage: number;

  supervisorPercentage: number;

  managementPercentage: number;

  remarks: string;

  dateCreated: Date;

  revDate: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  objectiveTypeId: string;
  objectiveTypeName: string;

  departmentId: string;

  departmentName: string;
}
