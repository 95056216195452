<main class="reference_main">
  <div class="pink_box"></div>
  <div class="green_box"></div>

  <div class="grid_container" [ngClass]="showForm ? '':'whole_span'">
    <div class="form_holder" [ngClass]="showForm ? '':'hide_element'" >
      <h2 class="title" >Site - <span>New Entry</span></h2>
      <form [formGroup]="siteForm">
      <div class="hold_input"  >
        <div class="info_input">
          <label for=""style="color:black; ">Site Name</label>
          <input type="text" formControlName="siteName" class="email" />
        </div>
        <div class="info_input">
          <label for=""style="color:black; ">Site Email</label>
          <input type="text" formControlName="Email" class="email" />
        </div>
        <div class="info_input">
          <label for=""style="color:black; ">First Name</label>
          <input type="text" formControlName="FirstName" class="email" />
        </div>
        <div class="info_input">
          <label for=""style="color:black; ">Last Name</label>
          <input type="text" formControlName="LastName" class="email" />
        </div>
        <div class="checker">
          <label for=""style="color:black; ">Can Add Site?</label>
          <input type="checkbox" formControlName="canAddSub" class="check" />
        </div>
        <div class="btn_hold">
          <button class="submit" [ngClass]="siteForm.invalid ? 'grey_out':''" (click)="onSubmit(siteForm.value)">Submit</button>
        </div>
      </div>
    </form>

    </div>

   <app-site-table
   [sites]="sites"
   (emitAddEvent)="onEmitAddEvent()"
   (emitSiteUpdate)="onEmitSiteUpdate($event)"
   (emitDeleteEvent)="onEmitDeleteEvent($event)"
   ></app-site-table>
  </div>
</main>
