<table>
  <caption>
    Life Expectancy By Current Age
  </caption>
  <tr>
    <th colspan="2">65</th>
    <th colspan="2">40</th>
    <th colspan="2">20</th>
  </tr>
  <tr>
    <th>Men</th>
    <th>Women</th>
    <th>Men</th>
    <th>Women</th>
    <th>Men</th>
    <th>Women</th>
  </tr>
  <tr>
    <td>82</td>
    <td>85</td>
    <td>78</td>
    <td>82</td>
    <td>77</td>
    <td>81</td>
  </tr>
</table>
