import * as internal from 'events';
import { BaseModel } from './base';

export class ApplicationCertificateType extends BaseModel{

 certificateTypeId: string;

  certificateTypeName: string;
  piority: number;

  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  _id: string;



}
