import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { GatewayEffects } from './states/+gateway/gateway.effects';
import { GatewayFacade } from './states/+gateway/gateway.facades';
import { StoreModule } from '@ngrx/store';

import * as gatewayreducer from './states/+gateway/gateway.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY,
      gatewayreducer.reducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Group,
      gatewayreducer.groupreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AccountableFunction,
      gatewayreducer.accountablefunctionreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Function,
      gatewayreducer.functionreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SubGroup,
      gatewayreducer.subgroupreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SubSubGroup,
      gatewayreducer.subsubgroupreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SubSubGroupType,
      gatewayreducer.subsubgrouptypereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_OwnershipType,
      gatewayreducer.ownershiptypereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ClaimType,
      gatewayreducer.claimtypereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Terrain,
      gatewayreducer.terrainreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PaymentStatus,
      gatewayreducer.paymentstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CompanyFunction,
      gatewayreducer.companyfunctionreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DivestmentStatus,
      gatewayreducer.divestmentstatusreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_EncroachmentType,
      gatewayreducer.encroachmenttypereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_EncroachmentStatus,
      gatewayreducer.encroachmentstatusreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Title,
      gatewayreducer.titlereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DocumentType,
      gatewayreducer.documenttypereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Bank,
      gatewayreducer.bankreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DivisionTbl,
      gatewayreducer.divisiontblreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Continent,
      gatewayreducer.continentreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_FiscalFileLocation,
      gatewayreducer.fiscalfilelocatonreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_GeoDatum,
      gatewayreducer.geodatumreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Country,
      gatewayreducer.countryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_State,
      gatewayreducer.statereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Lga,
      gatewayreducer.lgareducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Community,
      gatewayreducer.communityreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Measure,
      gatewayreducer.measurereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Purpose,
      gatewayreducer.purposereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AcquisitionType,
      gatewayreducer.acquisitiontypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Currency,
      gatewayreducer.currencyreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_BankingStatus,
      gatewayreducer.bankingstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LandUseStatus,
      gatewayreducer.landusestatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RightHeld,
      gatewayreducer.rightheldreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Category,
      gatewayreducer.categoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_MeasuringFactor,
      gatewayreducer.measuringfactorreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AppConfiguration,
      gatewayreducer.appconfigurationreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CabinetShelfRef,
      gatewayreducer.cabinetshelfrefreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DocumentTypeCode,
      gatewayreducer.documenttypecodereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RackTrayRef,
      gatewayreducer.racktrayrefreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SecurityClassification,
      gatewayreducer.securityclassificationreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StakeholderResponse,
      gatewayreducer.stakeholderResponserreducer
    ),
    // StoreModule.forFeature(
    //   gatewayreducer.Gateway_FEATURE_KEY_PipelineSection,
    //   gatewayreducer.pipelinesectionreducer,
    // ),

    // StoreModule.forFeature(
    //   gatewayreducer.Gateway_FEATURE_KEY_AccountableFunctionLink2,
    //   gatewayreducer.accountablefunctionlink2reducer,
    // ),

    // StoreModule.forFeature(
    //   gatewayreducer.Gateway_FEATURE_KEY_PipelineAssetLink2,
    //   gatewayreducer.pipelineassetlink2reducer,
    // ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_VerificationStatus,
      gatewayreducer.verificationstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IPStatus,
      gatewayreducer.uomreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineAssetLocation,
      gatewayreducer.pipelineassetlocationreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Pigibility,
      gatewayreducer.pigibilityreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AssetStatus,
      gatewayreducer.assetstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DeliveryType,
      gatewayreducer.deliverytypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LicenceStatus,
      gatewayreducer.licencestatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Network,
      gatewayreducer.networkreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IPStatus,
      gatewayreducer.ipstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineGrade,
      gatewayreducer.pipelinegradereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLStageActivity,
      gatewayreducer.plstageactivityreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLStakeholderMatrix,
      gatewayreducer.plstakeholdermatrixreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLStakeholderMatrixRaci,
      gatewayreducer.plstakeholdermatrixracireducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineLicenseCategory,
      gatewayreducer.pipelinelicensecategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineLicenseStage,
      gatewayreducer.pipelinelicensestagereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineLicenseStakeholder,
      gatewayreducer.pipelinelicensestakeholderreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StakeholderRep,
      gatewayreducer.stakeholderrepreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Hierarchy,
      gatewayreducer.hierarchyreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IdentityUser,
      gatewayreducer.identityuserreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ActivityActionsPerson,
      gatewayreducer.activityactionspersonreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineLicenceRequest,
      gatewayreducer.pipelinelicenserequestreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineLicenceRequestStage,
      gatewayreducer.pipelinelicenserequeststagereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLRStageActivity,
      gatewayreducer.plrstageactivityreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CounselType,
      gatewayreducer.counseltypereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SuitCounsel,
      gatewayreducer.suitcounselreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AdrPersonnel,
      gatewayreducer.adrpersonnelreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AdrAssets,
      gatewayreducer.adrassetsreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLRActivityActionHistory,
      gatewayreducer.plractivityactionhistoryreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLRStakeholderMatrix,
      gatewayreducer.plrstakeholdermatrixreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLRStakeholderMatrixRaci,
      gatewayreducer.plrstakeholdermatrixracireducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CorrespondenceTeam,
      gatewayreducer.correspondenceteamreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CorrespondenceActionParty,
      gatewayreducer.correspondenceactionpartyreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_UserStaffDetail,
      gatewayreducer.userstaffdetailreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PLActivityActionPerson,
      gatewayreducer.plactivityactionpersonreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineEncroachment,
      gatewayreducer.pipelineEncroachmentreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_EncroachmentAsset,
      gatewayreducer.encroachmentAssetreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_EncroachmentAction,
      gatewayreducer.encroachmentActionreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Encroacher,
      gatewayreducer.encroacherreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LandPrefix,
      gatewayreducer.landprefixreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RequestType,
      gatewayreducer.requesttypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CorrespondenceCompany,
      gatewayreducer.correspondencecompanyreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Sex,
      gatewayreducer.sexreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_TotalSumTable,
      gatewayreducer.totalsumtablereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LossOfUseItems,
      gatewayreducer.lossofuseitemsreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_UserStaffDetail,
      gatewayreducer.userstaffdetailreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AuditTray,
      gatewayreducer.audittrayreducer
    ),

    //eGG
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DocumentCategory,
      gatewayreducer.documentcategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_YearOfSuit,
      gatewayreducer.yearofsuitreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LessorRepType,
      gatewayreducer.lessorreptypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DamageStatus,
      gatewayreducer.damagestatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Pole,
      gatewayreducer.polereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AccessRight,
      gatewayreducer.accessrightreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CofOStatus,
      gatewayreducer.cofostatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SubSubGroupTypeCategory,
      gatewayreducer.subsubgrouptypecategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IncidentQuantum,
      gatewayreducer.incidentquantumreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Quantum,
      gatewayreducer.quantumreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IncidentSubCategory,
      gatewayreducer.incidentsubcategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IncidentCategory,
      gatewayreducer.incidentcategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CounselType,
      gatewayreducer.counseltypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RateStatus,
      gatewayreducer.ratestatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RequestCategory,
      gatewayreducer.requestcategoryreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RequestSubCategory,
      gatewayreducer.requestsubcategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ObligationPeriod,
      gatewayreducer.obligationperiodreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CleanUpStatus,
      gatewayreducer.cleanupstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DamageList,
      gatewayreducer.damagelistreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AddressType,
      gatewayreducer.addresstypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ActivityStatus,
      gatewayreducer.activitystatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Batch,
      gatewayreducer.batchreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Adr,
      gatewayreducer.adrreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Lorp,
      gatewayreducer.lorpreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ExpirationStatus,
      gatewayreducer.expirationstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_FacilityAsset,
      gatewayreducer.facilityassetreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_IssuedCategory,
      gatewayreducer.issuedcategoryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ItemType,
      gatewayreducer.itemtypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LeaseExpiration,
      gatewayreducer.leaseexpirationreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineTemplate,
      gatewayreducer.pipelinetemplatereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Uom,
      gatewayreducer.uomreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DamageAssessment,
      gatewayreducer.damageassessmentreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DamageAssessmentBreakDown,
      gatewayreducer.damageassessmentbreakdownreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DamageAssessmentDraft,
      gatewayreducer.damageassessmentdraftreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_DamageAssessmentBreakDownDraft,
      gatewayreducer.damageassessmentbreakdowndraftreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicClaimantRef,
      gatewayreducer.seismicclaimantrefreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_PipelineDetails,
      gatewayreducer.pipelinedetailsreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Role,
      gatewayreducer.rolereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Staffrole,
      gatewayreducer.staffrolereducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_BatchData,
      gatewayreducer.batchdatareducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Lessors,
      gatewayreducer.lessorsreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicClaimType,
      gatewayreducer.seismicclaimtypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicCurrency,
      gatewayreducer.seismiccurrencyreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicDivision,
      gatewayreducer.seismicdivisionreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicLineType,
      gatewayreducer.seismiclinetypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicOML,
      gatewayreducer.seismicomlreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicProgram,
      gatewayreducer.seismicprogramreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicParty,
      gatewayreducer.seismicpartyreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicSwathNo,
      gatewayreducer.seismicswathnoreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicCrew,
      gatewayreducer.seismiccrewreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicCommunity,
      gatewayreducer.seismiccommunityreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicDamageList,
      gatewayreducer.seismicdamagelistreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicSwathCalc,
      gatewayreducer.seismicswathcalcreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AddressBook,
      gatewayreducer.addressbookreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ApprovalLevel,
      gatewayreducer.approvallevelreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Supervisor,
      gatewayreducer.supervisorreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicApproval,
      gatewayreducer.seismicapprovalreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_NumberOfApproval,
      gatewayreducer.numberofapprovalreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeisMicSwath,
      gatewayreducer.seismicswathreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Program,
      gatewayreducer.programreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_OilMineralLease,
      gatewayreducer.oilmineralleasereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ProgramConfig,
      gatewayreducer.programconfigreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Party,
      gatewayreducer.partyreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_AreaOfImpact,
      gatewayreducer.areaofimpactreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ImpactedAsset,
      gatewayreducer.impactedassetreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Vendor,
      gatewayreducer.vendorreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Application,
      gatewayreducer.applicationreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_SeismicContractor,
      gatewayreducer.seismiccontractorreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_OpllPtsHistory,
      gatewayreducer.opllptshistoryreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StaffInfos,
      gatewayreducer.staffinfosreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Department,
      gatewayreducer.departmentreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Unit,
      gatewayreducer.unitreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Position,
      gatewayreducer.positionreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Gender,
      gatewayreducer.genderreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_JobTitle,
      gatewayreducer.jobtitlereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_MaritalStatus,
      gatewayreducer.maritalstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_EmploymentStatus,
      gatewayreducer.employmentstatusreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Memo,
      gatewayreducer.memoreducer
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_MemoType,
      gatewayreducer.memotypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CertPermitRenewal,
      gatewayreducer.certpermitrenewalreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Certnotificaionmapping,
      gatewayreducer.certnotificaionmappingreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StaffQuery,
      gatewayreducer.staffqueryreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StaffTransfer,
      gatewayreducer.stafftransferreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StaffTermination,
      gatewayreducer.staffterminationreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ApprovalType,
      gatewayreducer.approvaltypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ApprovalMapping,
      gatewayreducer.approvalmappingreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_GenericApprovalLevel,
      gatewayreducer.genericapprovallevelreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ApprovalSetting,
      gatewayreducer.approvalsettingreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Approval,
      gatewayreducer.approvalreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_StaffInfo,
      gatewayreducer.staffinforeducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_RequestBreakDowns,
      gatewayreducer.requestbreakdownsreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_LeaveRequest,
      gatewayreducer.leaverequestreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Stafftrainingplan,
      gatewayreducer.stafftrainingplanreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_Requisition,
      gatewayreducer.requisitionreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_qmsObjectiveType,
      gatewayreducer.qmsobjectivetypereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_qmsObjectiveactivity,
      gatewayreducer.qmsobjectiveactivityreducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_qmsObjective,
      gatewayreducer.qmsobjectivereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_qmsObjectivePerformance,
      gatewayreducer.qmsobjectiveperformancereducer
    ),

    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_ApprovalDelegate,
      gatewayreducer.approvalDelegatereducer
    ),

    EffectsModule.forFeature([GatewayEffects]),
  ],
  providers: [GatewayFacade],
})
export class GatewayModule {}
