import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserSelectors from '../selectors/auth.selector';
import * as UserActions from '../actions/index';

import * as AuthReducers from '../reducers/index';
import * as UserReducers from '../reducers/user.reducer';

//import { StudentRecordStoreActions, StudentRecordStoreSelectors, StudentRecordStoreReducer } from './index';
import { UserDto, UserRO } from '@mrsoftebs/data'



@Injectable()
export class AuthFacade {
	// studentRecordError$ = this.store.select(StudentRecordStoreSelectors.getStudentRecordError);

	constructor(
		private store: Store<UserReducers.UserPartialState>
		
	) {}

	

	addNewUser(user: UserDto[]) {

		//console.log("user In Facade ", user);
		this.store.dispatch(UserActions.LoginPageActions.addUser({ user }));
	}


	get getLoginUser() {

		 // console.log("Refreshing log in");
		if(AuthReducers.getUser === null)
		{
			//Check if USer Exist in Local Storage
            if (localStorage.getItem('loggedInUser')) {

				var usermain2 = JSON.parse(localStorage.getItem('loggedInUser'));
				let user: UserRO = usermain2;
				this.store.dispatch(UserActions.AuthApiActions.loginSuccess({ user }));
				return AuthReducers.getUser;
			}else{
				// Redirect to Login
			}

		}

		return AuthReducers.getUser;
		
		//return this.store.select(UserActions.LoginPageActions.);
	}


	logOut(){

		this.store.dispatch(UserActions.AuthActions.logout());
	}

	// updateStudentRecord(updateRecord: StudentDto[]) {
	// 	this.store.dispatch(StudentRecordStoreActions.updateStudentRecord({ updateRecord }));
	// }

	// removeStudentRecord(ids: string[]) {
	// 	this.store.dispatch(StudentRecordStoreActions.removeStudentRecord({ ids }));
	// }
}