import { Grade } from './grade';

import { BaseModel } from './base';

export class Level extends BaseModel {
  levelName: string;

  description: string;

  gradeId?: Grade;
}
