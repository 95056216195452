

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlViewComponent } from './html-view/html-view.component';
import { RenderHtmlPipe } from '../render-html';

@NgModule({
  declarations: [RenderHtmlPipe, HtmlViewComponent],
  imports: [
    CommonModule
  ],
  exports: [RenderHtmlPipe, HtmlViewComponent]
})
export class HtmlModule { }
