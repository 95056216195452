import { BaseModel } from "./base";

export class HseConditionsDto extends BaseModel {
  conditionId: string;
  conditionName: string;

  subscriberId: string;

  siteId: string;


}
