import { BaseModel } from './base';

export class PerformEvaluateTest extends BaseModel {
  evaluationQxnsId: string;
  evaluateOptions: string;
  subscriberId: string;

  siteId: string;

  DateCreated: string;

  DateModified: string;

  id: string;
  _id: string;
}
