import { BaseModel } from './base';

export class MemoType extends BaseModel {
  id: string;
  SubscriberId: string;
  memoTypeName: string;
  memoTypeId: string;
  SiteId: string;
  _id: string;
}
