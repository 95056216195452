import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, concatMap, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { LoginPageActions, AuthActions, AuthApiActions } from '../actions';
import { Credentials, UserDto } from '@mrsoftebs/data';
import { AuthService } from '../services/auth.service';
import { LogoutConfirmationDialogComponent } from '../components';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { TokenService } from 'apps/ebs/src/app/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginPageActions.login),
      map((action) => action.credentials),
      exhaustMap((auth: Credentials) =>
        this.authService.login(auth).pipe(
          map((user) => {
            console.log('Reaching User Logged In  ', user);

            // if (localStorage.getItem('loggedInUser')) {
            // 	localStorage.removeItem('loggedInUser');
            // 	localStorage.setItem('loggedInUser', JSON.stringify(user));

            // 	this.authService.doLoginUser(user.username, { jwt: user.token, refreshToken: '' });
            // } else {
            // 	localStorage.setItem('loggedInUser', JSON.stringify(user));
            // 	this.authService.doLoginUser(user.username, { jwt: user.token, refreshToken: '' });
            // }

            this.tokenService.setLoggedIn(true);
            console.log('User: ', user);
            this.accountService.saveUser(user['access_token']);
            this.accountService.setToken(user['access_token']);
            // if (returnUrl) {
            // this.router.navigate([`${returnUrl}`]);
            // } else {
            // this.tokenService.setLoggedIn(false);
            // this.router.navigateByUrl(`/`);
            // }

            this.authService.isLoading$.next(false);
            this.authService.isLoggedIn$.next(true);

            //console.log("Set Is Logged In to true");

            return AuthApiActions.loginSuccess({ user });
          }),
          catchError((error) => {
            console.log('Login error ', error);
            this.authService.isLoading$.next(false);
            this.msb.open('Invalid username or password', 'Close', {
              duration: 3000,
            });
            //this.notification.error("Invalid username or password");

            return of(
              AuthApiActions.loginFailure({ error: error.error.message })
            );
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.loginSuccess),
        tap(() => {
          console.log('login and redirect to dashboard');

          const user = this.accountService.getLoggedInUser();
          if (
            user.userType.toLocaleLowerCase() === 'customer' ||
            user.userType.toLocaleLowerCase() === 'rep'
          ) {
            localStorage.setItem('isClient', 'true');
            return this.router.navigate(['/client/reports']);
          }
          return this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  // registerSuccess$ = createEffect(

  // 	() => this.actions$.pipe(ofType(AuthApiActions.addUserSuccess), tap(() => this.router.navigate([ '/dashboard' ]))),
  // 	{ dispatch: false }
  // );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.loginRedirect, AuthActions.logout),
        tap((authed) => {
          localStorage.removeItem('loggedInUser');
          localStorage.removeItem('Application');
          localStorage.removeItem('AssignedRoles');
          localStorage.removeItem('REFRESH_TOKEN');
          localStorage.removeItem('JWT_TOKEN');
          localStorage.removeItem('Link');
          this.authService.isLoggedIn$.next(false);
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  logoutConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutConfirmation),
      exhaustMap(() => {
        const dialogRef = this.dialog.open<
          LogoutConfirmationDialogComponent,
          undefined,
          boolean
        >(LogoutConfirmationDialogComponent);

        return dialogRef.afterClosed();
      }),
      map((result) =>
        result ? AuthActions.logout() : AuthActions.logoutConfirmationDismiss()
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginPageActions.addUser),
      map((action) => action.user),
      exhaustMap((user: UserDto[]) =>
        this.authService.creatUser(user).pipe(
          map((user) => {
            // Dispatch Login Action

            console.log('User Password Creation', user);

            const credentials: Credentials = new Credentials();

            let userTypeMain = '';

            credentials.email = user[0]['UserName'];
            credentials.password = user[0]['password'];
            credentials.UIN = user[0]['UIN'];
            userTypeMain = user[0]['UserType'];

            console.log(
              'User Password Creation after',
              user,
              credentials,
              userTypeMain
            );
            // console.log("User Password Creation", credentials.username, credentials.password);

            //this.authMainService.isLoggedIn$.next(true);
            this.authService.isLoading$.next(false);

            if (userTypeMain === 'Subscriber') {
              console.log('Going into Logged In ', credentials);
              return LoginPageActions.login({ credentials });
            } else {
              return AuthApiActions.addUserSuccess({ user });
            }

            //return	AuthApiActions.addUserSuccess({ user })
          }),
          catchError((error) => {
            console.log('User Creation Error ', error);
            return of(
              AuthApiActions.loadUserFailure({ error: error.error.message })
            );
          })
        )
      )
    )
  );

  loadUsersBySubscriber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginPageActions.LoadUsersBySubscriber),
      exhaustMap((_) =>
        this.authService.getAllUsersBySubscriberId().pipe(
          map((user) => {
            return LoginPageActions.LoadUsersBySubscriberSuccess({ user });
          }),
          catchError((error) => {
            ////console.log('error ', error.error.message)
            return of(
              AuthApiActions.loadUserFailure({ error: error.error.message })
            );
          })
        )
      )
    )
  );

  // 	addUser$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LoginPageActions.addUser),
  //     concatMap(({ user }) =>
  //     this.authService.creatUser(user)
  //         .pipe(
  //           map((data) => AuthApiActions.addUserSuccess({ user: data })),
  //           catchError((error) =>
  //             of(AuthApiActions.addUserFailure({ error: error.error.message }))
  //           )
  //         )
  //     )
  //   )
  // );

  //   logoutIdleUser$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(UserActions.idleTimeout),
  //       map(() => AuthActions.logout())
  //     )
  //   );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private accountService: AccountService,
    private tokenService: TokenService,
    private msb: MatSnackBar
  ) {}
}
