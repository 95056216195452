import { BaseModel } from './base';

export class LeaveType extends BaseModel {
  id: string;
  leaveTypeName: string;
  maxDays: number;
  approvalPersons: [{ level: number; position: string }];
  daysPerMonth: number;
  daysPerYear: number;
}
