import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '../../shared/error.service';

@Component({
  selector: 'ebs-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(
    // private mbsRef: MatBottomSheetRef<ErrorComponent>,
    // @Inject(MAT_BOTTOM_SHEET_DATA) public error: any,
    private errorService: ErrorService,
    private location: Location,
    private router: Router
  ) {}
  ngOnInit() {
    this.errorService.error$.subscribe((error) => {
      console.log('Error: ', error);
    });
    // console.log('Error object: ', error);
  }
  close() {
    // this.mbsRef.dismiss();
    console.log('Dismissed button clicked...');
  }
  back() {
    // this.location.back();
    this.router.navigate(['/']);
  }
}
