import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/ebs/src/environments/environment';

const apiUrl = environment.apiUrl;
const route = 'user/';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  getAllUsers() {
    return this.http.get<any[]>(apiUrl + route + 'listusers');
  }
  findByEmail(userEmail: string) {
    console.log('Email sent: ', userEmail);
    return this.http.post<any>(environment.apiUrl + 'user/findbyemail/', {
      email: userEmail,
    });
  }
  changePassword(data) {
    return this.http.post<any>(
      environment.apiUrl + 'user/changepassword/',
      data
    );
  }

  changeUserData(data) {
    return this.http.post<any>(
      environment.apiUrl + 'user/changeuserdata/',
      data
    );
  }
}
