import { BaseModel } from './base';

export class StaffQuery extends BaseModel {
  staffId: string;
  staffs: any[];
  queryDate: string;
  queryLetter: string;
  copiedStaff: any[];
  subscriberId: string;
  siteId: string;
  createdbyId: string;
  createdbyName: string;
  modifiedBy: string;
  dateCreated: string;
  dateModified: string;
  memoNumber: string;
}
