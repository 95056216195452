import { BaseModel } from './base';
import { TaskComment } from '@mrsoftebs/data';

// export class EventTaskDto extends BaseModel {
//   createdBy: string;
//   title: string;
//   taskDescription: string;
//   dueDate: string;
//   eventId: string;
//   subscriberId: string;
//   priority: string;
//   status: string;
//   siteId: string;
//   id: string;
//   teammembers: [];
//   taskOwners: [];
//   comments: TaskComment[];
// }
