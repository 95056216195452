import { ApprovalType } from './approvaltype';
import { GenericApprovalLevel } from './genericapprovallevel';
import { StaffInfo } from './staffinfo';

import { BaseModel } from './base';

export class ApprovalMapping extends BaseModel {
  StaffInfoId: string;
  StaffName: string;
  StaffEmail: string;
  GenericApprovalLevelId: string;
  genapprovallevelName: string;
  LevelPriorityId: string;
  LevelPriority: number;
  approvalLimit: number;
  ApprovalTypeId: string;
  approvaltypeName: string;
  SubscriberId: string;
  SiteId: string;
  dateCreated: string;
  dateModified: string;

}
