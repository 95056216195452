<div class="box green_box"></div>
<div class="box pink_box"></div>

<div class="site-container">
  <div class="site-header" autosize>
    <mat-toolbar>
      <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button> -->

      <span (click)="jobHome()" style="cursor: pointer;">
        <img src="assets/newlogo.png" class="mb-2" />Jobs</span>

      <span class="example-spacer"></span>
      <!-- <form> -->
      <mat-form-field style="background:transparent; width: 45vw;">
        <input #search matInput type="text" id="s" placeholder="Search available jobs" [formControl]="searchControl"
          [matAutocomplete]="auto">
      </mat-form-field>
      <!-- </form> -->
      <mat-autocomplete role="combobox" #auto="matAutocomplete">
        <mat-option *ngFor="let job of jobs " [value]="job.title" (click)="openJob(job)">
          <span>{{job.title}}</span>
        </mat-option>
        <mat-divider></mat-divider>
        <mat-option value="{{search.value}}" (click)="goToSearch(search.value)">
          <mat-icon>search</mat-icon> Search for {{search.value}}
        </mat-option>
      </mat-autocomplete>
      &nbsp;
      <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
      </button>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>share</mat-icon>
      </button> -->
      <div *ngIf="!user">
        <button mat-button (click)="signin()">Sign in</button>
        <button mat-button (click)="signup()">Sign up</button>
      </div>
      <button class="icon-person" mat-button [matMenuTriggerFor]="menu" *ngIf="user">
        {{user.email}}
      </button>
      <!-- <mat-icon [matMenuTriggerFor]="menu" *ngIf="user">account_circle</mat-icon> -->
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="myApplications()">My Applications</button>
        <button mat-menu-item (click)="signout()">Sign out</button>
      </mat-menu>
    </mat-toolbar>
  </div>

  <div class="site-content">

    <router-outlet></router-outlet>
  </div>
</div>