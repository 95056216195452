import { BaseModel } from './base';

export class WireLog extends BaseModel {
  equipmentName: string;
  date: string;
  location: string;
  wellNo: string;
  wireInDrum: string;
  spoolInDate: string;
  maxDepth: string;
  jobType: string;
  wireCutOff: string;
  balanceOfWire: string;
  dateCreated: string;
  dateModified: string;
  subscriberId: string;
  siteId: string;
  id: string;
}
