import { BaseModel } from './base';

export class Owner extends BaseModel {
  ownerId: string;

  weight: number;

  isLead: boolean;

  status: boolean;
}
