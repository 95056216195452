import { BaseModel } from './base';

export class SlicklineItem extends BaseModel {
  SlicklineItemName: string;
  SlicklineItemCategoryId: string;
  SlicklineItemCategoryName: string;
  SlicklineTypeId: string;
  SlicklineTypeName: string;
  SubscriberId: string;
  siteId: string;
  id: string;
  Status: string;
  Remarks: string;
}
