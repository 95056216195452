import { BaseModel } from './base';

export class ImprestTypeDetails extends BaseModel {
  id: string;
  imprestTypeName: string;
  descriptions: string;
  openingBal: number;
  additions: number;
  expensesTransfer: number;
  closingBal: number;
  dateCreated: Date;
  dateModified: Date;
}
