import { BaseModel } from './base';
import { Unit } from './unit';

export class PostJobReview extends BaseModel {
  date: Date;
  location: string;
  jobStatus: boolean;
  problemEncountered: string;
  deptReached: string;
  unitDamage: string;
  lostTime: string;
  otherComment: string;
  crewMembers: string[];
  thp: string;
  chp: string;
  fhp: string;
  _id: string;
  approvalStatus: string;
  approvedBy: string;
  postedBy: string;
  unit: Unit | string;
}
