import { BaseModel } from './base';

export class  ContractStaff  extends BaseModel{
    contractStaffId: string;
    fullname: string;   
    email: string;
    phoneNo: string;
    dateCreated: Date;
    dateModified: Date;
    subscriberId: string;
    siteId: string;
    id: string;
    _id: string;
  }