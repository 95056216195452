export class AppraiseQuestionaire {
  hasMark: boolean;
  categoryId: string;
  categoryName: string;
  forSupervisor: boolean;
  description: string;
  dateCreated: string;
  dateModified: string;
  siteId: string;
  subscriberId: string;
  id: string;
  _id: string;
}
