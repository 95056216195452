import { BaseModel } from "./base";

export class ApprovalDelegate extends BaseModel {
  StaffInfoId: string;
  StaffName: string;
  StaffEmail: string;
  userId: string;
  departmentId: string;
  unitId: string;
  DelegateId: string;
  DelegateName: string;
  DelegateEmail: string;

  SubscriberId: string;
  SiteId: string;
  dateCreated: string;
  dateModified: string;


}
