import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  GatewayActions,
  GatewayStoreSelectors,
  GatewayStoreReducer,
} from './index';

@Injectable()
export class GatewayFacade {
  constructor(private store: Store<GatewayStoreReducer.GatewayPartialState>) {}

  gatewayRecord$ = this.store.select(GatewayStoreSelectors.getSelected);

  getselected() {
    return this.store.select(GatewayStoreSelectors.getSelected);
  }

  isLoadingGateway(modeltype: string) {
    // let invokeAction = AnchorEPIMSActions.loadEpimsgate("Club");
    //let newInvoke = invokeAction('Club');

    // this.store.dispatch( GatewayActions.loadGateway(modeltype)({modeltype}));
    // // console.log('reaching facades: ', this.store.select(GatewayStoreSelectors.loadingGateway));
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype));
  }

  genericFilterGateway(
    modeltype: string,
    entryType: string,
    searchField: string
  ) {
    console.log(
      'Type Passed in FilterEPIMSGate ',
      modeltype,
      entryType,
      searchField
    );

    this.store.dispatch(
      GatewayActions.genericFilterGateway(modeltype)({
        modeltype,
        entryType,
        searchField,
      })
    );
    // this.store.select(AnchorEPIMSStoreSelectors.getAllEPIMS(modeltype)).subscribe((data) => {
    // 	// console.log("Data from the Store Up ", data );
    // 	});
    return this.store.select(
      GatewayStoreSelectors.genericFilterGateway(modeltype)
    );
  }

  loadGateway(modeltype: string) {
    //let invokeAction = AnchorEPIMSActions.loadEpimsgate("Club");
    //// console.log("Reaching Load Facade ", AnchorEPIMSActions.loadEpimsgate("Club"));

    // console.log('Type Passed in LoadEPIMSGate ', modeltype );

    this.store.dispatch(GatewayActions.loadGateway(modeltype)({ modeltype }));
    // this.store.select(AnchorEPIMSStoreSelectors.getAllEPIMS(modeltype)).subscribe((data) => {
    // 	// console.log("Data from the Store Up ", data );
    // 	});
    return this.store.select(GatewayStoreSelectors.getAllGateway(modeltype));
  }

  loadGatewayClear(modelType: string) {
    this.store.dispatch(
      GatewayActions.loadGatewayClearState(modelType)({ modelType })
    );
    return this.store.select(GatewayStoreSelectors.getAllGateway(modelType));
  }

  add(modelType: string, dataToAdd: any[]) {
    this.store.dispatch(
      GatewayActions.addGateway(modelType)({ dataToAdd, modelType })
    );
  }

  update(modelType: string, dataToAdd: any[], deletedData: any[]) {
    // console.log('Reaching Update Facade ', dataToAdd);
    this.store.dispatch(
      GatewayActions.updateGateway(modelType)({
        dataToAdd,
        deletedData,
        modelType,
      })
    );
  }

  remove(modelType: string, ids: string[]) {
    this.store.dispatch(
      GatewayActions.removeGateway(modelType)({ ids, modelType })
    );
  }
}
