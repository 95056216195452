<div class="container down">
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="item">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '100%',
            'border-radius': '10px',
            height: '40px',
            'background-color': 'rgb(226, 225, 225)',
            'margin-bottom': '10px',
            'text-align': 'center'
          }"
        >
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
