import { BaseModel } from './base';

export class Criterial extends BaseModel {
  average: number;
  criterialId: string;
  categoryId: string;
  categoryName: string;
  expectations: string;
  criterialName: string;
  assessmentType: string;
  dateCreated: string;
  dateModified: string;
  siteId: string;
  subscriberId: string;
  id: string;
  _id: string;
}
