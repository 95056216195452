import { BaseModel } from './base';

export class ApplicationCategory extends BaseModel{

  categoryId: string;
  categoryName: string;
  endDate: Date;
  startDate: Date;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
  _id: string;
}
