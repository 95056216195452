import { BaseModel } from './base';

export class  FinalReport  extends BaseModel{

    clientName: string;

    fieldLocationWell: string;

    jobIdReportNo: string;

    typeOfAnalysis: string;

    jobCurrentStatus: string;


    DataAnalyst: string;


    initiatorId: string;


    initiatorName: string;


    finalReportAutoNo: string;


    currentDocPath: string;


    allDocPaths: string;


    dateReported: string;


    allVettingComments: string[];




    dateCreated: string;

    dateModified: string;

    subscriberId: string;

    siteId: string;


    userId: string;
    id: string;

    FileName: string;
    originalname: string;
    dataAnalystId: string;
}
