import { Injectable } from '@angular/core';
import { environment } from 'apps/ebs/src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JobOpenning } from '@mrsoftebs/data';
import { Observable } from 'rxjs';
import { LogService } from 'apps/ebs/src/app/services/log.service';

const apiUrl = environment.apiUrl;
const route = 'jobopening/';

@Injectable({
  providedIn: 'root',
})
export class JobopeningService {
  constructor(private http: HttpClient, private logService: LogService) {}

  saveOpening(data) {
    // this.logService.log('getting data', data);
    return this.http.post<JobOpenning>(apiUrl + route, data);
  }

  findAll(value?): Observable<any> {
    // this.logService.log('params: ', value);
    return this.http.post(apiUrl + route + 'all', value);
  }
  findOne(id: String) {
    return this.http.get<JobOpenning>(apiUrl + route + id);
  }
  deleteOne(id: string) {
    return this.http.delete(apiUrl + route + id);
  }
  search(value: any) {
    this.logService.log('Query: ', value);
    // const params = new HttpParams();
    // params.append('page', value.page);
    // params.append('limit', value.limit);
    return this.http.post(apiUrl + route + 'search/' + value.query, value);
  }
}
