import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'libs/account/src/lib/services/account.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  signupForm: FormGroup;
  showProgress = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<SubscribeComponent>
  ) {}

  ngOnInit() {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      phone: [''],
      userType: [''],
      rememberMe: [''],
      subscriberShortName: [''],
      subscriberName: [''],
    });
  }

  onSignUp() {
    this.showProgress = true;
    // console.log("Form value: ", this.signinForm.value);
    // if (this.type.includes('subscriber')) {
    this.accountService.signUp(this.signupForm.value).subscribe(
      (data) => {
        if (data) {
          this.accountService.signin(this.signupForm.value);
          this.dialogRef.close();
        }
      },
      (err) => {
        this.showProgress = false;
      }
    );
    // }
  }
  cancel() {
    this.dialogRef.close();
  }
}
