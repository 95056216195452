<div class="content_right">
  <div class="top">
    <h2 class="title">Site management</h2>
    <button class="secondary-btn" (click)="emitAddEvent.emit()">Add New Site</button>
  </div>
  <div class="site_table">
    <div class="the_row">
      <span class="item">S/n</span>
      <span class="item">Site name</span>
      <span class="item">Site email</span>
      <span class="item">First name</span>
      <span class="item">Last name</span>
      <span class="item">Can add site</span>
      <span class="item"></span>
    </div>

    <div class="the_row" *ngFor="let site of sites; index as i"
    (click)="emitSiteUpdate.emit(site)" style="cursor: pointer;">
      <span class="item">{{i + 1}}</span>
      <span class="item">{{site.siteName}}</span>
      <span class="item">{{site.Email}}</span>
      <span class="item">{{site.FirstName}}</span>
      <span class="item">{{site.LastName}}</span>
      <span class="item" *ngIf="site.canAddSub; else notAddSite">Can Add Site</span>
      <ng-template #notAddSite>
        <span class="item" >Can't Add Site</span>
      </ng-template>


      <!-- <span class="item delete" (click)="$event.stopImmediatePropagation()"><i class="ti-trash" (click)="emitDeleteEvent.emit(site?._id)"></i></span> -->
    </div>

  </div>
</div>
