import { BaseModel } from './base';

export class ContractType extends BaseModel {
  contractTypeId: string;
  contractName: string;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
  _id: string;
}
