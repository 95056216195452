import {
    createSelector,
    createFeatureSelector,
    Action,
    combineReducers,
  } from '@ngrx/store';
  import * as fromRoot from '../reducers';
  import * as fromAuth from '../reducers/auth.reducer';
  import * as fromLoginPage from '../reducers/login-page.reducer';
  
  export const authFeatureKey = 'auth';
  
  export interface AuthState {
    [fromAuth.statusFeatureKey]: fromAuth.State;
    [fromLoginPage.loginPageFeatureKey]: fromLoginPage.State;
  }
  
  export interface State extends fromAuth.State {
    [authFeatureKey]: AuthState;
  }
  
  export function reducers(state: AuthState | undefined, action: Action) {
    return combineReducers({
      [fromAuth.statusFeatureKey]: fromAuth.reducer,
      [fromLoginPage.loginPageFeatureKey]: fromLoginPage.reducer,
    })(state, action);
  }
  
  export const selectAuthState = createFeatureSelector<State, AuthState>(
    authFeatureKey
  );
  
  export const selectAuthStatusState = createSelector(
    selectAuthState,
    (state: AuthState) => state.status
  );
  export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
  export const getLoggedIn = createSelector(getUser, user => !!user);
  
  export const selectLoginPageState = createSelector(
    selectAuthState,
    (state: AuthState) => state.loginPage
  );
  export const getLoginPageError = createSelector(
    selectLoginPageState,
    fromLoginPage.getError
  );
  export const getLoginPagePending = createSelector(
    selectLoginPageState,
    fromLoginPage.getPending
  );
  