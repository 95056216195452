import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, Effect } from '@ngrx/effects';
import {
  map,
  catchError,
  concatMap,
  exhaustMap,
  switchMap,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import * as GatewayActions from './gateway.actions';
import { GatewayService } from '../../services/gateway.service';
import { Action } from '@ngrx/store';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import { Router } from '@angular/router';
import { updateGatewaySuccess } from './gateway.actions';

@Injectable()
export class GatewayEffects {
  mainActionType: string;

  public SetActionType(type: string) {
    this.mainActionType = type;
  }

  protected loadClearState(actions, type) {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.loadGatewayClearState(type)),
      mergeMap((action) =>
        of(actions.loadGatewayClearStateSuccess(type)({ returnedData: [] }))
      )
    );
    return fn;
  }

  protected loadAllData = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.loadGateway(type)),
      mergeMap((action) =>
        this.gatewayService.getAll(type).pipe(
          map((data) => {
            // //console.log("Generic Data ",type, data);
            return actions.loadGatewaySuccess(type)({ returnedData: data });
          }),
          catchError((error) => of(actions.loadGatewayFailure(type)({ error })))
        )
      )
    );
    return fn;
  };

  protected genericFilter = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.genericFilterGateway(type)),
      mergeMap((action) =>
        this.gatewayService
          .genericFilter(type, action.entryType, action.searchField)
          .pipe(
            map((data) => {
              // console.log("Generic Data ",type, data);
              return actions.genericFilterGatewaySuccess(type)({
                returnedData: data,
              });
            }),
            catchError((error) =>
              of(actions.genericFilterGatewayFailure(type)({ error }))
            )
          )
      )
    );
    return fn;
  };

  protected add = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.addGateway(type)),
      concatMap(({ dataToAdd }) => {
        const user: any = JSON.parse(localStorage.getItem('loggedInUser'));
        let newDataToAdd;
        if (Array.isArray(dataToAdd) && user) {
          newDataToAdd = dataToAdd.map((item) => {
            return {
              ...item,
              SiteId: user.user['SiteId'],
              SubscriberId: user.user['SubscriberId'],
            };
          });
        } else if (
          Array.isArray(dataToAdd) &&
          user &&
          type === 'IdentityUser'
        ) {
          newDataToAdd = dataToAdd.map((item) => {
            return {
              ...item,
              SiteId: user.user['SiteId'],
              SubscriberId: user.user['SubscriberId'],
            };
          });
        } else {
          newDataToAdd = dataToAdd;
        }
        return this.gatewayService.createData(type, newDataToAdd).pipe(
          map((data) => {
            //this.notification.success("Saved Successfully");
            console.log('Data Back ', data);

            let newData = [];

            if (typeof data === 'object') {
              newData.push(...data);
            } else {
              newData = data;
            }
            this.gatewayService.isLoading$.next(false);
            return actions.addGatewaySuccess(type)({
              returnedData: newData,
            });
          }),

          catchError((error) =>
            of(actions.addGatewayFailure(type)({ error: error.error.message }))
          )
        );
      })
    );
    return fn;
  };

  protected addBatch = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.addGateway(type)),
      concatMap(({ dataToAdd }) =>
        this.gatewayService.createDataBatch(dataToAdd).pipe(
          map((data) => {
            //this.notification.success("Saved Successfully");
            //console.log('fwf ', data);

            this.gatewayService.isLoading$.next(false);
            return actions.addGatewaySuccess(type)({
              returnedData: data,
            });
          }),

          catchError((error) =>
            of(actions.addGatewayFailure(type)({ error: error.error.message }))
          )
        )
      )
    );
    return fn;
  };

  protected update = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.updateGateway(type)),
      concatMap(({ dataToAdd, deletedData }) =>
        this.gatewayService.updateData(type, dataToAdd, deletedData).pipe(
          map((data) => {
            if (type === 'IdentityUser') {
              this.router.navigate(['/login']);
              return actions.updateGatewaySuccess(type)({ returnedData: data });
            } else {
              this.gatewayService.isLoading$.next(false);
              return actions.updateGatewaySuccess(type)({ returnedData: data });
            }
          }),
          catchError((error) =>
            of(
              actions.updateGatewayFailure(type)({ error: error.error.message })
            )
          )
        )
      )
    );
    return fn;
  };

  protected updateBatch = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.updateGateway(type)),
      concatMap(({ dataToAdd, deletedData }) =>
        this.gatewayService.updateDataBatch(dataToAdd, deletedData).pipe(
          map((data) => {
            this.gatewayService.isLoading$.next(false);
            return actions.updateGatewaySuccess(type)({ returnedData: data });
          }),
          catchError((error) =>
            of(
              actions.updateGatewayFailure(type)({ error: error.error.message })
            )
          )
        )
      )
    );
    return fn;
  };

  protected remove = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.removeGateway(type)),
      concatMap(({ ids }) =>
        this.gatewayService.deleteData(type, ids).pipe(
          map((data) => {
            this.gatewayService.isLoading$.next(false);
            return actions.removeGatewaySuccess(type)({ ids });
          }),
          catchError((error) =>
            of(
              actions.updateGatewayFailure(type)({ error: error.error.message })
            )
          )
        )
      )
    );
    return fn;
  };

  loadGatewayGroup$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Group')
  );
  addGatewayGroup$ = createEffect(() => this.add(GatewayActions, 'Group'));
  updateGatewayGroup$ = createEffect(() =>
    this.update(GatewayActions, 'Group')
  );
  deleteGatewayGroup$ = createEffect(() =>
    this.remove(GatewayActions, 'Group')
  );

  loadGatewayAccountableFunction$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AccountableFunction')
  );
  addGatewayAccountableFunction$ = createEffect(() =>
    this.add(GatewayActions, 'AccountableFunction')
  );
  updateGatewayAccountableFunction$ = createEffect(() =>
    this.update(GatewayActions, 'AccountableFunction')
  );
  deleteGatewayAccountableFunction$ = createEffect(() =>
    this.remove(GatewayActions, 'AccountableFunction')
  );

  loadGatewayFunction$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Function')
  );
  addGatewayFunction$ = createEffect(() =>
    this.add(GatewayActions, 'Function')
  );
  updateGatewayFunction$ = createEffect(() =>
    this.update(GatewayActions, 'Function')
  );
  deleteGatewayFunction$ = createEffect(() =>
    this.remove(GatewayActions, 'Function')
  );

  loadGatewaySubGroup$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SubGroup')
  );
  addGatewaySubGroup$ = createEffect(() =>
    this.add(GatewayActions, 'SubGroup')
  );
  updateGatewaySubGroup$ = createEffect(() =>
    this.update(GatewayActions, 'SubGroup')
  );
  deleteGatewaySubGroup$ = createEffect(() =>
    this.remove(GatewayActions, 'SubGroup')
  );

  loadGatewaySubSubGroup$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SubSubGroup')
  );
  addGatewaySubSubGroup$ = createEffect(() =>
    this.add(GatewayActions, 'SubSubGroup')
  );
  updateGatewaySubSubGroup$ = createEffect(() =>
    this.update(GatewayActions, 'SubSubGroup')
  );
  deleteGatewaySubSubGroup$ = createEffect(() =>
    this.remove(GatewayActions, 'SubSubGroup')
  );

  loadGatewaySubSubGroupType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SubSubGroupType')
  );
  addGatewaySubSubGroupType$ = createEffect(() =>
    this.add(GatewayActions, 'SubSubGroupType')
  );
  updateGatewaySubSubGroupType$ = createEffect(() =>
    this.update(GatewayActions, 'SubSubGroupType')
  );
  deleteGatewaySubSubGroupType$ = createEffect(() =>
    this.remove(GatewayActions, 'SubSubGroupType')
  );

  loadGatewayOwnershipType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'OwnershipType')
  );
  addGatewayOwnershipType$ = createEffect(() =>
    this.add(GatewayActions, 'OwnershipType')
  );
  updateGatewayOwnershipType$ = createEffect(() =>
    this.update(GatewayActions, 'OwnershipType')
  );
  deleteGatewayOwnershipType$ = createEffect(() =>
    this.remove(GatewayActions, 'OwnershipType')
  );

  loadGatewayClaimType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ClaimType')
  );
  addGatewayClaimType$ = createEffect(() =>
    this.add(GatewayActions, 'ClaimType')
  );
  updateGatewayClaimType$ = createEffect(() =>
    this.update(GatewayActions, 'ClaimType')
  );
  deleteGatewayClaimType$ = createEffect(() =>
    this.remove(GatewayActions, 'ClaimType')
  );

  loadGatewayTerrain$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Terrain')
  );
  addGatewayTerrain$ = createEffect(() => this.add(GatewayActions, 'Terrain'));
  updateGatewayTerrain$ = createEffect(() =>
    this.update(GatewayActions, 'Terrain')
  );
  deleteGatewayTerrain$ = createEffect(() =>
    this.remove(GatewayActions, 'Terrain')
  );

  loadGatewayPaymentStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PaymentStatus')
  );
  addGatewayPaymentStatus$ = createEffect(() =>
    this.add(GatewayActions, 'PaymentStatus')
  );
  updateGatewayPaymentStatus$ = createEffect(() =>
    this.update(GatewayActions, 'PaymentStatus')
  );
  deleteGatewayPaymentStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'PaymentStatus')
  );

  loadGatewayCompany$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Company')
  );
  addGatewayCompany$ = createEffect(() => this.add(GatewayActions, 'Company'));
  updateGatewayCompany$ = createEffect(() =>
    this.update(GatewayActions, 'Company')
  );
  deleteGatewayCompany$ = createEffect(() =>
    this.remove(GatewayActions, 'Company')
  );

  loadGatewayCompanyFunction$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CompanyFunction')
  );
  addGatewayCompanyFunction$ = createEffect(() =>
    this.add(GatewayActions, 'CompanyFunction')
  );
  updateGatewayCompanyFunction$ = createEffect(() =>
    this.update(GatewayActions, 'CompanyFunction')
  );
  deleteGatewayCompanyFunction$ = createEffect(() =>
    this.remove(GatewayActions, 'CompanyFunction')
  );

  loadGatewayDivestmentStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DivestmentStatus')
  );
  addGatewayDivestmentStatus$ = createEffect(() =>
    this.add(GatewayActions, 'DivestmentStatus')
  );
  updateGatewayDivestmentStatus$ = createEffect(() =>
    this.update(GatewayActions, 'DivestmentStatus')
  );
  deleteGatewayDivestmentStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'DivestmentStatus')
  );

  loadGatewayEncroachmentType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'EncroachmentType')
  );
  addGatewayEncroachmentType$ = createEffect(() =>
    this.add(GatewayActions, 'EncroachmentType')
  );
  updateGatewayEncroachmentType$ = createEffect(() =>
    this.update(GatewayActions, 'EncroachmentType')
  );
  deleteGatewayEncroachmentType$ = createEffect(() =>
    this.remove(GatewayActions, 'EncroachmentType')
  );

  loadGatewayEncroachmentStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'EncroachmentStatus')
  );
  addGatewayEncroachmentStatus$ = createEffect(() =>
    this.add(GatewayActions, 'EncroachmentStatus')
  );
  updateGatewayEncroachmentStatus$ = createEffect(() =>
    this.update(GatewayActions, 'EncroachmentStatus')
  );
  deleteGatewayEncroachmentStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'EncroachmentStatus')
  );

  loadGatewayTitle = createEffect(() =>
    this.loadAllData(GatewayActions, 'Title')
  );
  addGatewayTitle$ = createEffect(() => this.add(GatewayActions, 'Title'));
  updateGatewayTitle$ = createEffect(() =>
    this.update(GatewayActions, 'Title')
  );
  deleteGatewayTitle$ = createEffect(() =>
    this.remove(GatewayActions, 'Title')
  );

  loadGatewayDocumentType = createEffect(() =>
    this.loadAllData(GatewayActions, 'DocumentType')
  );
  addGatewayDocumentType$ = createEffect(() =>
    this.add(GatewayActions, 'DocumentType')
  );
  updateGatewayDocumenttype$ = createEffect(() =>
    this.update(GatewayActions, 'DocumentType')
  );
  deleteGatewayDocumentType$ = createEffect(() =>
    this.remove(GatewayActions, 'DocumentType')
  );

  loadGatewayBank = createEffect(() =>
    this.loadAllData(GatewayActions, 'Bank')
  );
  addGatewayBank$ = createEffect(() => this.add(GatewayActions, 'Bank'));
  updateGatewayBank$ = createEffect(() => this.update(GatewayActions, 'Bank'));
  deleteGatewayBank$ = createEffect(() => this.remove(GatewayActions, 'Bank'));

  loadGatewayDivisionTbl = createEffect(() =>
    this.loadAllData(GatewayActions, 'DivisionTbl')
  );
  addGatewayDivisionTbl$ = createEffect(() =>
    this.add(GatewayActions, 'DivisionTbl')
  );
  updateGatewayDivisionTbl$ = createEffect(() =>
    this.update(GatewayActions, 'DivisionTbl')
  );
  deleteGatewayDivisionTbl$ = createEffect(() =>
    this.remove(GatewayActions, 'DivisionTbl')
  );

  loadGatewayContinent = createEffect(() =>
    this.loadAllData(GatewayActions, 'Continent')
  );
  addGatewayContinent$ = createEffect(() =>
    this.add(GatewayActions, 'Continent')
  );
  updateGatewayContinent$ = createEffect(() =>
    this.update(GatewayActions, 'Continent')
  );
  deleteGatewayContinent$ = createEffect(() =>
    this.remove(GatewayActions, 'Continent')
  );

  loadGatewayFiscalFileLocation = createEffect(() =>
    this.loadAllData(GatewayActions, 'FiscalFileLocation')
  );
  addGatewayFiscalFileLocation$ = createEffect(() =>
    this.add(GatewayActions, 'FiscalFileLocation')
  );
  updateGatewayFiscalFileLocation$ = createEffect(() =>
    this.update(GatewayActions, 'FiscalFileLocation')
  );
  deleteGatewayFiscalFileLocation$ = createEffect(() =>
    this.remove(GatewayActions, 'FiscalFileLocation')
  );

  loadGatewayGeoDatum = createEffect(() =>
    this.loadAllData(GatewayActions, 'GeoDatum')
  );
  addGatewayGeoDatum$ = createEffect(() =>
    this.add(GatewayActions, 'GeoDatum')
  );
  updateGatewayGeoDatum$ = createEffect(() =>
    this.update(GatewayActions, 'GeoDatum')
  );
  deleteGatewayGeoDatum$ = createEffect(() =>
    this.remove(GatewayActions, 'GeoDatum')
  );

  loadGatewayCountry = createEffect(() =>
    this.loadAllData(GatewayActions, 'Country')
  );
  addGatewayCountry$ = createEffect(() => this.add(GatewayActions, 'Country'));
  updateGatewayCountry$ = createEffect(() =>
    this.update(GatewayActions, 'Country')
  );
  deleteGatewayCountry$ = createEffect(() =>
    this.remove(GatewayActions, 'Country')
  );

  loadGatewayState = createEffect(() =>
    this.loadAllData(GatewayActions, 'State')
  );
  addGatewayState$ = createEffect(() => this.add(GatewayActions, 'State'));
  updateGatewayState$ = createEffect(() =>
    this.update(GatewayActions, 'State')
  );
  deleteGatewayState$ = createEffect(() =>
    this.remove(GatewayActions, 'State')
  );

  loadGatewayLga = createEffect(() => this.loadAllData(GatewayActions, 'Lga'));
  addGatewayLga$ = createEffect(() => this.add(GatewayActions, 'Lga'));
  updateGatewayLga$ = createEffect(() => this.update(GatewayActions, 'Lga'));
  deleteGatewayLga$ = createEffect(() => this.remove(GatewayActions, 'Lga'));

  loadGatewayCommunity = createEffect(() =>
    this.loadAllData(GatewayActions, 'Communities')
  );
  addGatewayCommunity$ = createEffect(() =>
    this.add(GatewayActions, 'Communities')
  );
  updateGatewayCommunity$ = createEffect(() =>
    this.update(GatewayActions, 'Communities')
  );
  deleteGatewayCommunity$ = createEffect(() =>
    this.remove(GatewayActions, 'Communities')
  );

  loadGatewayMeasure$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Measure')
  );
  addGatewayMeasure$ = createEffect(() => this.add(GatewayActions, 'Measure'));
  updateGatewayMeasure$ = createEffect(() =>
    this.update(GatewayActions, 'Measure')
  );
  deleteGatewayMeasure$ = createEffect(() =>
    this.remove(GatewayActions, 'Measure')
  );

  loadGatewayPurpose$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Purpose')
  );
  addGatewayPurpose$ = createEffect(() => this.add(GatewayActions, 'Purpose'));
  updateGatewayPurpose$ = createEffect(() =>
    this.update(GatewayActions, 'Purpose')
  );
  deleteGatewaPurpose$ = createEffect(() =>
    this.remove(GatewayActions, 'Purpose')
  );

  loadGatewayAcquisitionType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AcquisitionType')
  );
  addGatewayAcquisitionType$ = createEffect(() =>
    this.add(GatewayActions, 'AcquisitionType')
  );
  updateGatewayAcquisitionType$ = createEffect(() =>
    this.update(GatewayActions, 'AcquisitionType')
  );
  deleteGatewayAcquisitionType$ = createEffect(() =>
    this.remove(GatewayActions, 'AcquisitionType')
  );

  loadGatewayCurrency$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Currency')
  );
  addGatewayCurrency$ = createEffect(() =>
    this.add(GatewayActions, 'Currency')
  );
  updateGatewayCurrency$ = createEffect(() =>
    this.update(GatewayActions, 'Currency')
  );
  deleteGatewayCurrency$ = createEffect(() =>
    this.remove(GatewayActions, 'Currency')
  );

  loadGatewayBankingStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'BankingStatus')
  );
  addGatewayBankingStatus$ = createEffect(() =>
    this.add(GatewayActions, 'BankingStatus')
  );
  updateGatewayBankingStatus$ = createEffect(() =>
    this.update(GatewayActions, 'BankingStatus')
  );
  deleteGatewayBankingStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'BankingStatus')
  );

  loadGatewayLandUseStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LandUseStatus')
  );
  addGatewayLandUseStatus$ = createEffect(() =>
    this.add(GatewayActions, 'LandUseStatus')
  );
  updateGatewayLandUseStatus$ = createEffect(() =>
    this.update(GatewayActions, 'LandUseStatus')
  );
  deleteGatewayLandUseStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'LandUseStatus')
  );

  loadGatewayRightHeld$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RightHeld')
  );
  addGatewayRightHeld$ = createEffect(() =>
    this.add(GatewayActions, 'RightHeld')
  );
  updateGatewayRightHeld$ = createEffect(() =>
    this.update(GatewayActions, 'RightHeld')
  );
  deleteGatewayRightHeld$ = createEffect(() =>
    this.remove(GatewayActions, 'RightHeld')
  );

  loadGatewayCabinetShelfRef$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CabinetShelfRef')
  );
  addGatewayCabinetShelfRef$ = createEffect(() =>
    this.add(GatewayActions, 'CabinetShelfRef')
  );
  updateGatewayCabinetShelfRef$ = createEffect(() =>
    this.update(GatewayActions, 'CabinetShelfRef')
  );
  deleteGatewayCabinetShelfRef$ = createEffect(() =>
    this.remove(GatewayActions, 'CabinetShelfRef')
  );

  loadGatewayDocumentTypeCode$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DocumentTypeCode')
  );
  addGatewayDocumentTypeCode$ = createEffect(() =>
    this.add(GatewayActions, 'DocumentTypeCode')
  );
  updateGatewayDocumentTypeCode$ = createEffect(() =>
    this.update(GatewayActions, 'DocumentTypeCode')
  );
  deleteGatewayDocumentTypeCode$ = createEffect(() =>
    this.remove(GatewayActions, 'DocumentTypeCode')
  );

  loadGatewayRackTrayRef$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RackTrayRef')
  );
  addGatewayRackTrayRef$ = createEffect(() =>
    this.add(GatewayActions, 'RackTrayRef')
  );
  updateGatewayRackTrayRef$ = createEffect(() =>
    this.update(GatewayActions, 'RackTrayRef')
  );
  deleteGatewayRackTrayRef$ = createEffect(() =>
    this.remove(GatewayActions, 'RackTrayRef')
  );

  loadGatewaySecurityClassification$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SecurityClassification')
  );
  addGatewaySecurityClassification$ = createEffect(() =>
    this.add(GatewayActions, 'SecurityClassification')
  );
  updateGatewaySecurityClassification$ = createEffect(() =>
    this.update(GatewayActions, 'SecurityClassification')
  );
  deleteGatewaySecurityClassification$ = createEffect(() =>
    this.remove(GatewayActions, 'SecurityClassification')
  );

  loadGatewayCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Category')
  );
  addGatewayCategory$ = createEffect(() =>
    this.add(GatewayActions, 'Category')
  );
  updateGatewayCategory$ = createEffect(() =>
    this.update(GatewayActions, 'Category')
  );
  deleteGatewayCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'Category')
  );

  loadGatewayMeasuringFactor$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'MeasuringFactor')
  );
  addGatewayMeasuringFactor$ = createEffect(() =>
    this.add(GatewayActions, 'MeasuringFactor')
  );
  updateGatewayMeasuringFactor$ = createEffect(() =>
    this.update(GatewayActions, 'MeasuringFactor')
  );
  deleteGatewayMeasuringFactor$ = createEffect(() =>
    this.remove(GatewayActions, 'MeasuringFactor')
  );

  loadGatewayAppConfiguration$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AppConfiguration')
  );
  addGatewayAppConfiguration$ = createEffect(() =>
    this.add(GatewayActions, 'AppConfiguration')
  );
  updateGatewayAppConfiguration$ = createEffect(() =>
    this.update(GatewayActions, 'AppConfiguration')
  );
  deleteGatewayAppConfiguration$ = createEffect(() =>
    this.remove(GatewayActions, 'AppConfiguration')
  );

  loadGatewayPLStageActivity$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLStageActivity')
  );
  addGatewayPLStageActivity$ = createEffect(() =>
    this.add(GatewayActions, 'PLStageActivity')
  );
  updateGatewayPLStageActivity$ = createEffect(() =>
    this.update(GatewayActions, 'PLStageActivity')
  );
  deleteGatewayPLStageActivity$ = createEffect(() =>
    this.remove(GatewayActions, 'PLStageActivity')
  );

  loadGatewayPLStakeholderMatrix$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLStakeholderMatrix')
  );
  addGatewayPLStakeholderMatrix$ = createEffect(() =>
    this.add(GatewayActions, 'PLStakeholderMatrix')
  );
  updateGatewayPLStakeholderMatrix$ = createEffect(() =>
    this.update(GatewayActions, 'PLStakeholderMatrix')
  );
  deleteGatewayPLStakeholderMatrix$ = createEffect(() =>
    this.remove(GatewayActions, 'PLStakeholderMatrix')
  );

  loadGatewayPLStakeholderMatrixRaci$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLStakeholderMatrixRaci')
  );
  addGatewayPLStakeholderMatrixRaci$ = createEffect(() =>
    this.add(GatewayActions, 'PLStakeholderMatrixRaci')
  );
  updateGatewayPLStakeholderMatrixRaci$ = createEffect(() =>
    this.update(GatewayActions, 'PLStakeholderMatrixRaci')
  );
  deleteGatewayPLStakeholderMatrixRaci$ = createEffect(() =>
    this.remove(GatewayActions, 'PLStakeholderMatrixRaci')
  );

  loadGatewayPipelineLicenseCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineLicenseCategory')
  );
  addGatewayPipelineLicenseCategory$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineLicenseCategory')
  );
  updateGatewayPipelineLicenseCategory$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineLicenseCategory')
  );
  deleteGatewayPipelineLicenseCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineLicenseCategory')
  );

  loadGatewayPipelineLicenseStage$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineLicenseStage')
  );
  addGatewayPipelineLicenseStage$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineLicenseStage')
  );
  updateGatewayPipelineLicenseStage$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineLicenseStage')
  );
  deleteGatewayPipelineLicenseStage$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineLicenseStage')
  );

  loadGatewayPipelineLicenseStakeholder$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineLicenseStakeholder')
  );
  addGatewayPipelineLicenseStakeholder$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineLicenseStakeholder')
  );
  updateGatewayPipelineLicenseStakeholder$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineLicenseStakeholder')
  );
  deleteGatewayPipelineLicenseStakeholder$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineLicenseStakeholder')
  );
  loadGatewayStakeholderRep$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StakeholderRep')
  );
  searchGatewayStakeholderRep$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StakeholderRep')
  );

  addGatewayStakeholderRep$ = createEffect(() =>
    this.add(GatewayActions, 'StakeholderRep')
  );
  updateGatewayStakeholderRep$ = createEffect(() =>
    this.update(GatewayActions, 'StakeholderRep')
  );
  deleteGatewayStakeholderRep$ = createEffect(() =>
    this.remove(GatewayActions, 'StakeholderRep')
  );

  loadGatewayCorrespondenceTeam$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CorrespondenceTeam')
  );
  addGatewayCorrespondenceTeam$ = createEffect(() =>
    this.add(GatewayActions, 'CorrespondenceTeam')
  );
  updateGatewayCorrespondenceTeam$ = createEffect(() =>
    this.update(GatewayActions, 'CorrespondenceTeam')
  );
  deleteGatewayCorrespondenceTeam$ = createEffect(() =>
    this.remove(GatewayActions, 'CorrespondenceTeam')
  );
  loadGatewayCorrespondenceActionParty$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CorrespondenceActionParty')
  );
  addGatewayCorrespondenceActionParty$ = createEffect(() =>
    this.add(GatewayActions, 'CorrespondenceActionParty')
  );
  updateGatewayCorrespondenceActionParty$ = createEffect(() =>
    this.update(GatewayActions, 'CorrespondenceActionParty')
  );
  deleteGatewayCorrespondenceActionParty$ = createEffect(() =>
    this.remove(GatewayActions, 'CorrespondenceActionParty')
  );
  loadGatewayUserStaffDetail$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'UserStaffDetail')
  );
  addGatewayUserStaffDetail$ = createEffect(() =>
    this.add(GatewayActions, 'UserStaffDetail')
  );
  updateGatewayUserStaffDetail$ = createEffect(() =>
    this.update(GatewayActions, 'UserStaffDetail')
  );
  deleteGatewayUserStaffDetail$ = createEffect(() =>
    this.remove(GatewayActions, 'UserStaffDetail')
  );

  loadGatewayHierarchy$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Hierarchy')
  );

  addGatewayHierarchy$ = createEffect(() =>
    this.add(GatewayActions, 'Hierarchy')
  );

  updateGatewayHierarchy$ = createEffect(() =>
    this.update(GatewayActions, 'Hierarchy')
  );

  deleteGatewayHierarchy$ = createEffect(() =>
    this.remove(GatewayActions, 'Hierarchy')
  );

  // 	loadGatewayPipelineSection$ = createEffect(() =>
  // 	this.loadAllData(GatewayActions,"PipelineSection")
  // );
  // addGatewayPipelineSection$ = createEffect(() =>
  // 	this.add(GatewayActions,"PipelineSection")
  // );
  // updateGatewayPipelineSection$ = createEffect(() =>
  // 	this.update(GatewayActions,"PipelineSection")
  // );
  // deleteGatewayPipelineSection$ = createEffect(() =>
  // 	this.remove(GatewayActions,"PipelineSection")
  // );

  // loadGatewayAccountableFunctionLink2$ = createEffect(() =>
  // 	this.loadAllData(GatewayActions,"AccountableFunctionLink2")
  // );
  // addGatewayAccountableFunctionLink2$ = createEffect(() =>
  // 	this.add(GatewayActions,"AccountableFunctionLink2")
  // );
  // updateGatewayAccountableFunctionLink2$ = createEffect(() =>
  // 	this.update(GatewayActions,"AccountableFunctionLink2")
  // );
  // deleteGatewayAccountableFunctionLink2$ = createEffect(() =>
  // 	this.remove(GatewayActions,"AccountableFunctionLink2")
  // );

  // loadGatewayPipelineAssetLink2$ = createEffect(() =>
  // 	this.loadAllData(GatewayActions,"PipelineAssetLink2")
  // );
  // addGatewayPipelineAssetLink2$ = createEffect(() =>
  // 	this.add(GatewayActions,"PipelineAssetLink2")
  // );
  // updateGatewayPipelineAssetLink2$ = createEffect(() =>
  // 	this.update(GatewayActions,"PipelineAssetLink2")
  // );
  // deleteGatewayPipelineAssetLink2$ = createEffect(() =>
  // 	this.remove(GatewayActions,"PipelineAssetLink2")
  // );

  loadGatewayVerificationStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'VerificationStatus')
  );
  addGatewayVerificationStatus$ = createEffect(() =>
    this.add(GatewayActions, 'VerificationStatus')
  );
  updateGatewayVerificationStatus$ = createEffect(() =>
    this.update(GatewayActions, 'VerificationStatus')
  );
  deleteGatewayVerificationStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'VerificationStatus')
  );

  loadGatewayUom$ = createEffect(() => this.loadAllData(GatewayActions, 'Uom'));
  addGatewayUom$ = createEffect(() => this.add(GatewayActions, 'Uom'));
  updateGatewayUom$ = createEffect(() => this.update(GatewayActions, 'Uom'));
  deleteGatewayUom$ = createEffect(() => this.remove(GatewayActions, 'Uom'));

  loadGatewayPaymentType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PaymentType')
  );
  addGatewayPaymentType$ = createEffect(() =>
    this.add(GatewayActions, 'PaymentType')
  );
  updateGatewayPaymentType$ = createEffect(() =>
    this.update(GatewayActions, 'PaymentType')
  );
  deleteGatewayPaymentType$ = createEffect(() =>
    this.remove(GatewayActions, 'PaymentType')
  );
  loadGatewayPigibility$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Pigibility')
  );
  addGatewayPigibility$ = createEffect(() =>
    this.add(GatewayActions, 'Pigibility')
  );
  updateGatewayPigibility$ = createEffect(() =>
    this.update(GatewayActions, 'Pigibility')
  );
  deleteGatewayPigibility$ = createEffect(() =>
    this.remove(GatewayActions, 'Pigibility')
  );

  loadGatewayPipelineAssetLocation$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineAssetLocation')
  );
  addGatewayPipelineAssetLocation$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineAssetLocation')
  );
  updateGatewayPipelineAssetLocation$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineAssetLocation')
  );
  deleteGatewayPipelineAssetLocation$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineAssetLocation')
  );

  loadGatewayAssetStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AssetStatus')
  );
  addGatewayAssetStatus$ = createEffect(() =>
    this.add(GatewayActions, 'AssetStatus')
  );
  updateGatewayAssetStatus$ = createEffect(() =>
    this.update(GatewayActions, 'AssetStatus')
  );
  deleteGatewayAssetStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'AssetStatus')
  );

  loadGatewayDeliveryType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DeliveryType')
  );
  addGatewayDeliveryType$ = createEffect(() =>
    this.add(GatewayActions, 'DeliveryType')
  );
  updateGatewayDeliveryType$ = createEffect(() =>
    this.update(GatewayActions, 'DeliveryType')
  );
  deleteGatewayDeliveryType$ = createEffect(() =>
    this.remove(GatewayActions, 'DeliveryType')
  );

  loadGatewayLicenceStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LicenceStatus')
  );
  addGatewayLicenceStatus$ = createEffect(() =>
    this.add(GatewayActions, 'LicenceStatus')
  );
  updateGatewayLicenceStatus$ = createEffect(() =>
    this.update(GatewayActions, 'LicenceStatus')
  );
  deleteGatewayLicenceStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'LicenceStatus')
  );

  loadGatewayNetwork$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Network')
  );
  addGatewayNetwork$ = createEffect(() => this.add(GatewayActions, 'Network'));
  updateGatewayNetwork$ = createEffect(() =>
    this.update(GatewayActions, 'Network')
  );
  deleteGatewayNetwork$ = createEffect(() =>
    this.remove(GatewayActions, 'Network')
  );

  loadGatewayIPStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'IPStatus')
  );
  addGatewayIPStatus$ = createEffect(() =>
    this.add(GatewayActions, 'IPStatus')
  );
  updateGatewayIPStatus$ = createEffect(() =>
    this.update(GatewayActions, 'IPStatus')
  );
  deleteGatewayIPStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'IPStatus')
  );

  loadGatewayPipelineGrade$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineGrade')
  );
  addGatewayPipelineGrade$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineGrade')
  );
  updateGatewayPipelineGrade$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineGrade')
  );
  deleteGatewayPipelineGrade$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineGrade')
  );

  loadGatewayIdentityUser$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'IdentityUser')
  );

  updateGatewayIdentityUser$ = createEffect(() =>
    this.update(GatewayActions, 'IdentityUser')
  );

  loadGatewayPLActivityActionPerson$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLActivityActionPerson')
  );

  addGatewayPLActivityActionPerson$ = createEffect(() =>
    this.add(GatewayActions, 'PLActivityActionPerson')
  );
  updateGatewayPLActivityActionPerson$ = createEffect(() =>
    this.update(GatewayActions, 'PLActivityActionPerson')
  );

  deleteGatewayPLActivityActionPerson$ = createEffect(() =>
    this.remove(GatewayActions, 'PLActivityActionPerson')
  );

  loadGatewayActivityActionsPerson$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ActivityActionsPerson')
  );
  addGatewayActivityActionsPerson$ = createEffect(() =>
    this.add(GatewayActions, 'ActivityActionsPerson')
  );
  updateGatewayActivityActionsPerson$ = createEffect(() =>
    this.update(GatewayActions, 'ActivityActionsPerson')
  );
  deleteGatewayActivityActionsPerson$ = createEffect(() =>
    this.remove(GatewayActions, 'ActivityActionsPerson')
  );

  searchGatewayActivityActionsPerson$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'ActivityActionsPerson')
  );

  loadGatewayPipelineLicenseRequest$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineLicenseRequest')
  );
  addGatewayPipelineLicenseRequest$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineLicenseRequest')
  );
  updateGatewayPipelineLicenseRequest$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineLicenseRequest')
  );
  deleteGatewayPipelineLicenseRequest$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineLicenseRequest')
  );

  loadGatewayPipelineLicenseRequestStage$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineLicenseRequestStage')
  );
  addGatewayPipelineLicenseRequestStage$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineLicenseRequestStage')
  );
  updateGatewayPipelineLicenseRequestStage$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineLicenseRequestStage')
  );
  deleteGatewayPipelineLicenseRequestStage$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineLicenseRequestStage')
  );

  loadGatewayPLRStageActivity$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLRStageActivity')
  );
  addGatewayPLRStageActivity$ = createEffect(() =>
    this.add(GatewayActions, 'PLRStageActivity')
  );
  updateGatewayPLRStageActivity$ = createEffect(() =>
    this.update(GatewayActions, 'PLRStageActivity')
  );
  deleteGatewayPLRStageActivity$ = createEffect(() =>
    this.remove(GatewayActions, 'PLRStageActivity')
  );
  searchGatewayPLRStageActivity$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'PLRStageActivity')
  );

  loadGatewayPLRStakeholderMatrix$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLRStakeholderMatrix')
  );
  addGatewayPLRStakeholderMatrix$ = createEffect(() =>
    this.add(GatewayActions, 'PLRStakeholderMatrix')
  );
  updateGatewayPLRStakeholderMatrix$ = createEffect(() =>
    this.update(GatewayActions, 'PLRStakeholderMatrix')
  );
  deleteGatewayPLRStakeholderMatrix$ = createEffect(() =>
    this.remove(GatewayActions, 'PLRStakeholderMatrix')
  );

  loadGatewayPLRStakeholderMatrixRaci$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLRStakeholderMatrixRaci')
  );
  addGatewayPLRStakeholderMatrixRaci$ = createEffect(() =>
    this.add(GatewayActions, 'PLRStakeholderMatrixRaci')
  );
  updateGatewayPLRStakeholderMatrixRaci$ = createEffect(() =>
    this.update(GatewayActions, 'PLRStakeholderMatrixRaci')
  );
  deleteGatewayPLRStakeholderMatrixRaci$ = createEffect(() =>
    this.remove(GatewayActions, 'PLRStakeholderMatrixRaci')
  );

  loadGatewayEncroachmenAsset$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'EncroachmentAsset')
  );
  addGatewayEncroachmentAsset$ = createEffect(() =>
    this.add(GatewayActions, 'EncroachmentAsset')
  );
  updateGatewayEncroachmentAsset$ = createEffect(() =>
    this.update(GatewayActions, 'EncroachmentAsset')
  );
  deleteGatewayEncroachmentAsset$ = createEffect(() =>
    this.remove(GatewayActions, 'EncroachmentAsset')
  );

  loadGatewayPipelineEncroachment$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineEncroachment')
  );
  addGatewayPipelineEncroachment$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineEncroachment')
  );
  updateGatewayPipelineEncroachment$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineEncroachment')
  );
  deleteGatewayPipelineEncroachment$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineEncroachment')
  );

  loadGatewayEncroacher$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Encroacher')
  );
  addGatewayEncroacher$ = createEffect(() =>
    this.add(GatewayActions, 'Encroacher')
  );
  updateGatewayEncroacher$ = createEffect(() =>
    this.update(GatewayActions, 'Encroacher')
  );
  deleteGatewayEncroacher$ = createEffect(() =>
    this.remove(GatewayActions, 'Encroacher')
  );

  loadGatewayEncroachment$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'EncroachmentAction')
  );

  addGatewayEncroachment$ = createEffect(() =>
    this.add(GatewayActions, 'EncroachmentAction')
  );
  updateGatewayEncroachment$ = createEffect(() =>
    this.update(GatewayActions, 'EncroachmentAction')
  );
  deleteGatewayEncroachment$ = createEffect(() =>
    this.remove(GatewayActions, 'EncroachmentAction')
  );

  loadGatewayCounselType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CounselType')
  );
  addGatewayCounselType$ = createEffect(() =>
    this.add(GatewayActions, 'CounselType')
  );
  updateGatewayCounselType$ = createEffect(() =>
    this.update(GatewayActions, 'CounselType')
  );
  deleteGatewayCounselType$ = createEffect(() =>
    this.remove(GatewayActions, 'CounselType')
  );

  loadGatewaySuitCounsel$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SuitCounsel')
  );
  addGatewaySuitCounsel$ = createEffect(() =>
    this.add(GatewayActions, 'SuitCounsel')
  );
  updateGatewaySuitCounsel$ = createEffect(() =>
    this.update(GatewayActions, 'SuitCounsel')
  );
  deleteGatewaySuitCounsel$ = createEffect(() =>
    this.remove(GatewayActions, 'SuitCounsel')
  );
  searchGatewaySuitCounsel$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'SuitCounsel')
  );

  loadGatewayAdrPersonnel$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AdrPersonnel')
  );
  addGatewayAdrPersonnel$ = createEffect(() =>
    this.add(GatewayActions, 'AdrPersonnel')
  );
  updateGatewayAdrPersonnel$ = createEffect(() =>
    this.update(GatewayActions, 'AdrPersonnel')
  );
  deleteGatewayAdrPersonnel$ = createEffect(() =>
    this.remove(GatewayActions, 'AdrPersonnel')
  );
  searchGatewayAdrPersonnel$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'AdrPersonnel')
  );

  loadGatewayAdrAssets$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AdrAssets')
  );
  addGatewayAdrAssets$ = createEffect(() =>
    this.add(GatewayActions, 'AdrAssets')
  );
  updateGatewayAdrAssets$ = createEffect(() =>
    this.update(GatewayActions, 'AdrAssets')
  );
  deleteGatewayAdrAssets$ = createEffect(() =>
    this.remove(GatewayActions, 'AdrAssets')
  );

  searchGatewayAdrAssets$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'AdrAssets')
  );
  loadGatewayAdr$ = createEffect(() => this.loadAllData(GatewayActions, 'Adr'));
  addGatewayAdr$ = createEffect(() => this.add(GatewayActions, 'Adr'));
  updateGatewayAdr$ = createEffect(() => this.update(GatewayActions, 'Adr'));
  deleteGatewayAdr$ = createEffect(() => this.remove(GatewayActions, 'Adr'));

  searchGatewayAdr$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Adr')
  );

  clearGatewayAdr$ = createEffect(() =>
    this.loadClearState(GatewayActions, 'Adr')
  );

  loadGatewayPLRActivityActionHistory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PLRActivityActionHistory')
  );
  addGatewayPLRActivityActionHistory$ = createEffect(() =>
    this.add(GatewayActions, 'PLRActivityActionHistory')
  );
  updateGatewayPLRActivityActionHistory$ = createEffect(() =>
    this.update(GatewayActions, 'PLRActivityActionHistory')
  );
  deleteGatewayPLRActivityActionHistory$ = createEffect(() =>
    this.remove(GatewayActions, 'PLRActivityActionHistory')
  );

  searchGatewayPLRActivityActionHistory$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'PLRActivityActionHistory')
  );

  loadGatewayRequestType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RequestType')
  );
  addGatewayRequestType$ = createEffect(() =>
    this.add(GatewayActions, 'RequestType')
  );
  updateGatewayRequestType$ = createEffect(() =>
    this.update(GatewayActions, 'RequestType')
  );
  deleteGatewayRequestType$ = createEffect(() =>
    this.remove(GatewayActions, 'RequestType')
  );

  loadGatewayCorrespondenceCompany$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CorrespondenceCompany')
  );
  addGatewayCorrespondenceCompanye$ = createEffect(() =>
    this.add(GatewayActions, 'CorrespondenceCompany')
  );
  updateGatewayCorrespondenceCompany$ = createEffect(() =>
    this.update(GatewayActions, 'CorrespondenceCompany')
  );
  deleteGatewayCorrespondenceCompany$ = createEffect(() =>
    this.remove(GatewayActions, 'CorrespondenceCompany')
  );

  loadGatewaySex$ = createEffect(() => this.loadAllData(GatewayActions, 'Sex'));
  addGatewaySex$ = createEffect(() => this.add(GatewayActions, 'Sex'));
  updateGatewaySex$ = createEffect(() => this.update(GatewayActions, 'Sex'));
  deleteGatewaySex$ = createEffect(() => this.remove(GatewayActions, 'Sex'));

  loadGatewayLandPrefix$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LandPrefix')
  );
  addGatewayLandPrefixx$ = createEffect(() =>
    this.add(GatewayActions, 'LandPrefix')
  );
  updateGatewayLandPrefix$ = createEffect(() =>
    this.update(GatewayActions, 'LandPrefix')
  );
  deleteGatewayLandPrefix$ = createEffect(() =>
    this.remove(GatewayActions, 'LandPrefix')
  );

  loadGatewayTotalSumTable$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'TotalSumTable')
  );
  addGatewayTotalSumTable$ = createEffect(() =>
    this.add(GatewayActions, 'TotalSumTable')
  );
  updateGatewayTotalSumTable$ = createEffect(() =>
    this.update(GatewayActions, 'TotalSumTable')
  );
  deleteGatewayTotalSumTable$ = createEffect(() =>
    this.remove(GatewayActions, 'TotalSumTable')
  );

  loadGatewayLossOfUseItems$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LossOfUseItems')
  );
  addGatewayLossOfUseItems$ = createEffect(() =>
    this.add(GatewayActions, 'LossOfUseItems')
  );
  updateGatewayLossOfUseItems$ = createEffect(() =>
    this.update(GatewayActions, 'LossOfUseItems')
  );
  deleteGatewayLossOfUseItems$ = createEffect(() =>
    this.remove(GatewayActions, 'LossOfUseItems')
  );

  searchGatewayAddressBook$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'AddressBook')
  );

  loadGatewayAuditTray$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AuditTray')
  );
  addGatewayAuditTray$ = createEffect(() =>
    this.add(GatewayActions, 'AuditTray')
  );
  updateGatewayAuditTray$ = createEffect(() =>
    this.update(GatewayActions, 'AuditTray')
  );
  deleteGatewayAuditTray$ = createEffect(() =>
    this.remove(GatewayActions, 'AuditTray')
  );

  loadGatewayDamageAssessment$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DamageAssessment')
  );
  addGatewayDamageAssessment$ = createEffect(() =>
    this.add(GatewayActions, 'DamageAssessment')
  );
  updateGatewayDamageAssessment$ = createEffect(() =>
    this.update(GatewayActions, 'DamageAssessment')
  );
  searchGatewayDamageAssessment$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'DamageAssessment')
  );
  deleteGatewayDamageAssessment$ = createEffect(() =>
    this.remove(GatewayActions, 'DamageAssessment')
  );

  loadGatewayDamageAssessmentBreakDown$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DamageAssessmentBreakDown')
  );
  addGatewayDamageAssessmentBreakDown$ = createEffect(() =>
    this.add(GatewayActions, 'DamageAssessmentBreakDown')
  );
  updateGatewayDamageAssessmentBreakDown$ = createEffect(() =>
    this.update(GatewayActions, 'DamageAssessmentBreakDown')
  );
  searchGatewayDamageAssessmentBreakDown$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'DamageAssessmentBreakDown')
  );
  deleteGatewayDamageAssessment$BreakDown = createEffect(() =>
    this.remove(GatewayActions, 'DamageAssessmentBreakDown')
  );

  loadGatewayDamageAssessmentDraft$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DamageAssessmentDraft')
  );
  addGatewayDamageAssessmentDraft$ = createEffect(() =>
    this.add(GatewayActions, 'DamageAssessmentDraft')
  );
  updateGatewayDamageAssessmentDraft$ = createEffect(() =>
    this.update(GatewayActions, 'DamageAssessmentDraft')
  );
  searchGatewayDamagDamageAssessmentDraft$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'DamageAssessmentDraft')
  );
  deleteGatewayDamageAssessmentDraft$ = createEffect(() =>
    this.remove(GatewayActions, 'DamageAssessmentDraft')
  );

  loadGatewayDamageAssessmentBreakDownDraft$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DamageAssessmentBreakDownDraft')
  );
  addGatewayDamageAssessmentBreakDownDraft$ = createEffect(() =>
    this.add(GatewayActions, 'DamageAssessmentBreakDownDraft')
  );
  updateGatewayDamageAssessmentBreakDownDraft$ = createEffect(() =>
    this.update(GatewayActions, 'DamageAssessmentBreakDownDraft')
  );
  searchGatewayDamageAssessmentBreakDownDraft$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'DamageAssessmentBreakDownDraft')
  );
  deleteGatewayDamageAssessmentBreakDownDraft$ = createEffect(() =>
    this.remove(GatewayActions, 'DamageAssessmentBreakDownDraft')
  );
  //eGG
  loadGatewayYearOfSuit$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'YearOfSuit')
  );
  addGatewayYearOfSuit$ = createEffect(() =>
    this.add(GatewayActions, 'YearOfSuit')
  );
  updateGatewayYearOfSuit$ = createEffect(() =>
    this.update(GatewayActions, 'YearOfSuit')
  );
  deleteGatewayYearOfSuit$ = createEffect(() =>
    this.remove(GatewayActions, 'YearOfSuit')
  );

  loadGatewayDocumentCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DocumentCategory')
  );
  addGatewayDocumentCategory$ = createEffect(() =>
    this.add(GatewayActions, 'DocumentCategory')
  );
  updateGatewayDocumentCategory$ = createEffect(() =>
    this.update(GatewayActions, 'DocumentCategory')
  );
  deleteGatewayDocumentCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'DocumentCategory')
  );

  loadGatewayLessorRepType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LessorRepType')
  );
  addGatewayLessorRepType$ = createEffect(() =>
    this.add(GatewayActions, 'LessorRepType')
  );
  updateGatewayLessorRepType$ = createEffect(() =>
    this.update(GatewayActions, 'LessorRepType')
  );
  deleteGatewayLessorRepType$ = createEffect(() =>
    this.remove(GatewayActions, 'LessorRepType')
  );

  loadGatewayDamageStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'DamageStatus')
  );
  addGatewayDamageStatus$ = createEffect(() =>
    this.add(GatewayActions, 'DamageStatus')
  );
  updateGatewayDamageStatus$ = createEffect(() =>
    this.update(GatewayActions, 'DamageStatus')
  );
  deleteGatewayDamageStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'DamageStatus')
  );

  loadGatewayPole$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Pole')
  );
  addGatewayPole$ = createEffect(() => this.add(GatewayActions, 'Pole'));
  updateGatewayPole$ = createEffect(() => this.update(GatewayActions, 'Pole'));
  deleteGatewayPole$ = createEffect(() => this.remove(GatewayActions, 'Pole'));

  loadGatewayAccessRight$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AccessRight')
  );
  addGatewayAccessRight$ = createEffect(() =>
    this.add(GatewayActions, 'AccessRight')
  );
  updateGatewayAccessRight$ = createEffect(() =>
    this.update(GatewayActions, 'AccessRight')
  );
  deleteGatewayAccessRight$ = createEffect(() =>
    this.remove(GatewayActions, 'AccessRight')
  );

  loadGatewayCofOStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CofOStatus')
  );
  addGatewayCofOStatus$ = createEffect(() =>
    this.add(GatewayActions, 'CofOStatus')
  );
  updateGatewayCofOStatus$ = createEffect(() =>
    this.update(GatewayActions, 'CofOStatus')
  );
  deleteGatewayCofOStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'CofOStatus')
  );

  loadGatewayQuantum$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'QuantumOfImpact')
  );
  addGatewayQuantum$ = createEffect(() =>
    this.add(GatewayActions, 'QuantumOfImpact')
  );
  updateGatewayQuantum$ = createEffect(() =>
    this.update(GatewayActions, 'QuantumOfImpact')
  );
  deleteGatewayQuantum$ = createEffect(() =>
    this.remove(GatewayActions, 'QuantumOfImpact')
  );

  loadGatewaySubSubGroupTypeCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SubSubGroupTypeCategory')
  );
  addGatewaySubSubGroupTypeCategory$ = createEffect(() =>
    this.add(GatewayActions, 'SubSubGroupTypeCategory')
  );
  updateGatewaySubSubGroupTypeCategory$ = createEffect(() =>
    this.update(GatewayActions, 'SubSubGroupTypeCategory')
  );
  deleteGatewaySubSubGroupTypeCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'SubSubGroupTypeCategory')
  );

  loadGatewayIncidentQuantum$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'IncidentQuantumOfImpact')
  );
  addGatewayIncidentQuantum$ = createEffect(() =>
    this.add(GatewayActions, 'IncidentQuantumOfImpact')
  );
  updateGatewayIncidentQuantum$ = createEffect(() =>
    this.update(GatewayActions, 'IncidentQuantumOfImpact')
  );
  deleteGatewayIncidentQuantum$ = createEffect(() =>
    this.remove(GatewayActions, 'IncidentQuantumOfImpact')
  );

  loadGatewayIncidentSubCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'IncidentSubCategory')
  );
  addGatewayIncidentSubCategory$ = createEffect(() =>
    this.add(GatewayActions, 'IncidentSubCategory')
  );
  updateGatewayIncidentSubCategory$ = createEffect(() =>
    this.update(GatewayActions, 'IncidentSubCategory')
  );
  deleteGatewayIncidentSubCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'IncidentSubCategory')
  );

  loadGatewayRateStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RateStatus')
  );
  addGatewayRateStatus$ = createEffect(() =>
    this.add(GatewayActions, 'RateStatus')
  );
  updateGatewayRateStatus$ = createEffect(() =>
    this.update(GatewayActions, 'RateStatus')
  );
  deleteGatewayRateStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'RateStatus')
  );

  loadGatewayIncidentCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'IncidentCategory')
  );
  addGatewayIncidentCategory$ = createEffect(() =>
    this.add(GatewayActions, 'IncidentCategory')
  );
  updateGatewayIncidentCategory$ = createEffect(() =>
    this.update(GatewayActions, 'IncidentCategory')
  );
  deleteGatewayIncidentCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'IncidentCategory')
  );

  loadGatewayObligationPeriod$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ObligationPeriod')
  );
  addGatewayObligationPeriod$ = createEffect(() =>
    this.add(GatewayActions, 'ObligationPeriod')
  );
  updateGatewayObligationPeriod$ = createEffect(() =>
    this.update(GatewayActions, 'ObligationPeriod')
  );
  deleteGatewayObligationPeriod$ = createEffect(() =>
    this.remove(GatewayActions, 'ObligationPeriod')
  );

  loadGatewayRequestSubCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RequestSubCategory')
  );
  addGatewayRequestSubCategory$ = createEffect(() =>
    this.add(GatewayActions, 'RequestSubCategory')
  );
  updateGatewayRequestSubCategory$ = createEffect(() =>
    this.update(GatewayActions, 'RequestSubCategory')
  );
  deleteGatewayRequestSubCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'RequestSubCategory')
  );

  loadGatewayRequestCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RequestCategory')
  );
  addGatewayRequestCategory$ = createEffect(() =>
    this.add(GatewayActions, 'RequestCategory')
  );
  updateGatewayRequestCategory$ = createEffect(() =>
    this.update(GatewayActions, 'RequestCategory')
  );
  deleteGatewayRequestCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'RequestCategory')
  );

  loadGatewayActivityStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ActivityStatus')
  );
  addGatewayActivityStatus$ = createEffect(() =>
    this.add(GatewayActions, 'ActivityStatus')
  );
  updateGatewayActivityStatus$ = createEffect(() =>
    this.update(GatewayActions, 'ActivityStatus')
  );
  deleteGatewayActivityStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'ActivityStatus')
  );

  loadGatewayAddressType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AddressTypeTbl')
  );
  addGatewayAddressType$ = createEffect(() =>
    this.add(GatewayActions, 'AddressTypeTbl')
  );
  updateGatewayAddressType$ = createEffect(() =>
    this.update(GatewayActions, 'AddressTypeTbl')
  );
  deleteGatewayAddressType$ = createEffect(() =>
    this.remove(GatewayActions, 'AddressTypeTbl')
  );

  loadGatewayBatch = createEffect(() =>
    this.loadAllData(GatewayActions, 'Batch')
  );
  addGatewayBatch$ = createEffect(() => this.add(GatewayActions, 'Batch'));
  updateGatewayBatch$ = createEffect(() =>
    this.update(GatewayActions, 'Batch')
  );
  deleteGatewayBatch$ = createEffect(() =>
    this.remove(GatewayActions, 'Batch')
  );

  loadGatewayCleanUpStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CleanUpStatus')
  );
  addGatewayCleanUpStatus$ = createEffect(() =>
    this.add(GatewayActions, 'CleanUpStatus')
  );
  updateGatewayCleanUpStatus$ = createEffect(() =>
    this.update(GatewayActions, 'CleanUpStatus')
  );
  deleteGatewayCleanUpStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'CleanUpStatus')
  );

  loadGatewayDamageList = createEffect(() =>
    this.loadAllData(GatewayActions, 'DamageList')
  );
  addGatewayDamageList$ = createEffect(() =>
    this.add(GatewayActions, 'DamageList')
  );
  updateGatewayDamageList$ = createEffect(() =>
    this.update(GatewayActions, 'DamageList')
  );
  deleteGatewayDamageList$ = createEffect(() =>
    this.remove(GatewayActions, 'DamageList')
  );

  searchGatewayDamageList$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'DamageList')
  );

  loadGatewayLorp$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Lorp')
  );
  addGatewayLorp$ = createEffect(() => this.add(GatewayActions, 'Lorp'));
  updateGatewayLorp$ = createEffect(() => this.update(GatewayActions, 'Lorp'));
  deleteGatewayLorp$ = createEffect(() => this.remove(GatewayActions, 'Lorp'));

  loadGatewayExpirationStatus = createEffect(() =>
    this.loadAllData(GatewayActions, 'ExpirationStatus')
  );
  addGatewayExpirationStatus$ = createEffect(() =>
    this.add(GatewayActions, 'ExpirationStatus')
  );
  updateGatewayExpirationStatus$ = createEffect(() =>
    this.update(GatewayActions, 'ExpirationStatus')
  );
  deleteGatewayExpirationStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'ExpirationStatus')
  );

  loadGatewayFacilityAsset$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'FacilityAsset')
  );
  addGatewayFacilityAsset$ = createEffect(() =>
    this.add(GatewayActions, 'FacilityAsset')
  );
  updateGatewayFacilityAsset$ = createEffect(() =>
    this.update(GatewayActions, 'FacilityAsset')
  );
  deleteGatewayFacilityAsset$ = createEffect(() =>
    this.remove(GatewayActions, 'FacilityAsset')
  );

  loadGatewayIssuedCategory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'IssuedCategory')
  );
  addGatewayIssuedCategory$ = createEffect(() =>
    this.add(GatewayActions, 'IssuedCategory')
  );
  updateGatewayIssuedCategory$ = createEffect(() =>
    this.update(GatewayActions, 'IssuedCategory')
  );
  deleteGatewayIssuedCategory$ = createEffect(() =>
    this.remove(GatewayActions, 'IssuedCategory')
  );

  loadGatewayItemType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ItemType')
  );
  addGatewayItemType$ = createEffect(() =>
    this.add(GatewayActions, 'ItemType')
  );
  updateGatewayItemType$ = createEffect(() =>
    this.update(GatewayActions, 'ItemType')
  );
  deleteGatewayItemType$ = createEffect(() =>
    this.remove(GatewayActions, 'ItemType')
  );

  loadGatewayLeaseExpiration$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LeaseExpiration')
  );
  addGatewayLeaseExpiration$ = createEffect(() =>
    this.add(GatewayActions, 'LeaseExpiration')
  );
  updateGatewayLeaseExpiration$ = createEffect(() =>
    this.update(GatewayActions, 'LeaseExpiration')
  );
  deleteGatewayLeaseExpiration$ = createEffect(() =>
    this.remove(GatewayActions, 'LeaseExpiration')
  );

  loadGatewayPipelineTemplate$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineTemplate')
  );
  addGatewayPipelineTemplate$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineTemplate')
  );
  updateGatewayPipelineTemplate$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineTemplate')
  );
  deleteGatewayPipelineTemplate$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineTemplate')
  );

  //   loadGatewayLandTitle$ = createEffect(() =>
  // 	this.loadAllData(GatewayActions, "LandTitle")
  // 	);
  // 	addGatewayLandTitle$ = createEffect(() =>
  // 	this.add(GatewayActions, "LandTitle")
  // 	);
  // 	updateGatewayLandTitle$ = createEffect(() =>
  // 	this.update(GatewayActions, "LandTitle")
  // 	);
  // 	deleteGatewayLandTitle$ = createEffect(() =>
  // 	this.remove(GatewayActions, "LandTitle")
  // 	);
  loadGatewayPipelineDetails$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'PipelineDetails')
  );
  addGatewayPipelineDetails$ = createEffect(() =>
    this.add(GatewayActions, 'PipelineDetails')
  );
  updateGatewayPipelineDetails$ = createEffect(() =>
    this.update(GatewayActions, 'PipelineDetails')
  );
  deleteGatewayPipelineDetails$ = createEffect(() =>
    this.remove(GatewayActions, 'PipelineDetails')
  );

  loadGatewayStakeholderResponse$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StakeholderResponse')
  );
  addGatewayStakeholderResponse$ = createEffect(() =>
    this.add(GatewayActions, 'StakeholderResponse')
  );
  updateGatewayStakeholderResponse$ = createEffect(() =>
    this.update(GatewayActions, 'StakeholderResponse')
  );
  deleteGatewayStakeholderResponse$ = createEffect(() =>
    this.remove(GatewayActions, 'StakeholderResponse')
  );

  searchGatewayEncroachment$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'PipelineEncroachment')
  );
  searchGatewayEncroachmentAsset$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'EncroachmentAsset')
  );
  searchGatewayEncroachmentAction$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'EncroachmentAction')
  );
  searchGatewayEncroacher$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Encroacher')
  );
  searchGatewayResponse$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StakeholderResponse')
  );
  searchGatewayPipelineLicenseStakeholder$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'PipelineLicenseStakeholder')
  );
  searchGatewayPipelineLicenseRequest$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'PipelineLicenseRequest')
  );

  loadGatewayRole$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Role')
  );
  addGatewayRole$ = createEffect(() => this.add(GatewayActions, 'Role'));
  updateGatewayRole$ = createEffect(() => this.update(GatewayActions, 'Role'));
  deleteGatewayRole$ = createEffect(() => this.remove(GatewayActions, 'Role'));
  clearGatewayRole$ = createEffect(() =>
    this.loadClearState(GatewayActions, 'Role')
  );

  loadGatewayStaffRole$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StaffRoleNew')
  );
  addGatewayStaffRole$ = createEffect(() =>
    this.add(GatewayActions, 'StaffRoleNew')
  );
  updateGatewayStaffRole$ = createEffect(() =>
    this.update(GatewayActions, 'StaffRoleNew')
  );
  deleteGatewayStaffRole$ = createEffect(() =>
    this.remove(GatewayActions, 'StaffRoleNew')
  );
  searchGatewayStaffRole$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StaffRoleNew')
  );

  clearGatewayStaffRoleNew$ = createEffect(() =>
    this.loadClearState(GatewayActions, 'StaffRoleNew')
  );

  addGatewayBatchInsert$ = createEffect(() =>
    this.addBatch(GatewayActions, 'BatchData')
  );
  updateGatewayBatchUpdate$ = createEffect(() =>
    this.updateBatch(GatewayActions, 'BatchData')
  );

  loadGatewayLessors$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Lessors')
  );
  addGatewayLessor$ = createEffect(() => this.add(GatewayActions, 'Lessors'));
  updateGatewayLessor$ = createEffect(() =>
    this.update(GatewayActions, 'Lessors')
  );
  deleteGatewayLessor$ = createEffect(() =>
    this.remove(GatewayActions, 'Lessors')
  );

  loadGatewaySeismicClaimType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicClaimType')
  );
  addGatewaySeismicClaimType$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicClaimType')
  );
  updateGatewaySeismicClaimType$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicClaimType')
  );
  deleteGatewaySeismicClaimType$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicClaimType')
  );

  loadGatewaySeismicCurrency$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicCurrency')
  );
  addGatewaySeismicCurrency$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicCurrency')
  );
  updateGatewaySeismicCurrency$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicCurrency')
  );
  deleteGatewaySeismicCurrency$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicCurrency')
  );

  loadGatewaySeismicDivision$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicDivision')
  );
  addGatewaySeismicDivision$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicDivision')
  );
  updateGatewaySeismicDivision$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicDivision')
  );
  deleteGatewaySeismicDivision$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicDivision')
  );

  loadGatewaySeismicLineType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicLineType')
  );
  addGatewaySeismicLineType$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicLineType')
  );
  updateGatewaySeismicLineType$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicLineType')
  );
  deleteGatewaySeismicLineType$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicLineType')
  );

  loadGatewaySeismicOML$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicOML')
  );
  addGatewaySeismicOML$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicOML')
  );
  updateGatewaySeismicOML$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicOML')
  );
  deleteGatewaySeismicOML$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicOML')
  );

  loadGatewaySeismicProgram$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicProgram')
  );
  addGatewaySeismicProgram$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicProgram')
  );
  updateGatewaySeismicProgram$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicProgram')
  );
  deleteGatewaySeismicProgram$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicProgram')
  );

  loadGatewaySeismicSwathNo$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicSwathNo')
  );
  addGatewaySeismicSwathNo$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicSwathNo')
  );
  updateGatewaySeismicSwathNo$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicSwathNo')
  );
  deleteGatewaySeismicSwathNo$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicSwathNo')
  );

  loadGatewaySeismicParty$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicParty')
  );
  addGatewaySeismicParty$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicParty')
  );
  updateGatewaySeismicParty$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicParty')
  );
  deleteGatewaySeismicParty$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicParty')
  );

  loadGatewaySeismicSwathCalc$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicSwathCalc')
  );
  addGatewaySeismicSwathCalc$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicSwathCalc')
  );
  updateGatewaySeismicSwathCalc$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicSwathCalc')
  );
  deleteGatewaySeismicSwathCalc$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicSwathCalc')
  );

  loadGatewaySeismicDamageList$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicDamageList')
  );
  addGatewaySeismicDamageList$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicDamageList')
  );
  updateGatewaySeismicDamageList$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicDamageList')
  );
  deleteGatewaySeismicDamageList$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicDamageList')
  );

  loadGatewaySeismicCommunity$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicCommunity')
  );
  addGatewaySeismicCommunity$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicCommunity')
  );
  updateGatewaySeismicCommunity$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicCommunity')
  );
  deleteGatewaySeismicCommunity$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicCommunity')
  );

  loadGatewaySeismicCrew$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicCrew')
  );
  addGatewaySeismicCrew$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicCrew')
  );
  updateGatewaySeismicCrew$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicCrew')
  );
  deleteGatewaySeismicCrew$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicCrew')
  );

  loadGatewayApprovalLevel$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ApprovalLevel')
  );
  addGatewayApprovalLevel$ = createEffect(() =>
    this.add(GatewayActions, 'ApprovalLevel')
  );
  updateGatewayApprovalLevel$ = createEffect(() =>
    this.update(GatewayActions, 'ApprovalLevel')
  );
  deleteGatewayApprovalLevel$ = createEffect(() =>
    this.remove(GatewayActions, 'ApprovalLevel')
  );

  loadGatewaySupervisor$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Supervisor')
  );
  addGatewaySupervisor$ = createEffect(() =>
    this.add(GatewayActions, 'Supervisor')
  );
  updateGatewaySupervisor$ = createEffect(() =>
    this.update(GatewayActions, 'Supervisor')
  );
  deleteGatewaySupervisor$ = createEffect(() =>
    this.remove(GatewayActions, 'Supervisor')
  );

  loadGatewayNumberOfApproval$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'NumberOfApproval')
  );
  addGatewayNumberOfApproval$ = createEffect(() =>
    this.add(GatewayActions, 'NumberOfApproval')
  );
  updateGatewayNumberOfApproval$ = createEffect(() =>
    this.update(GatewayActions, 'NumberOfApproval')
  );
  deleteGatewayNumberOfApproval$ = createEffect(() =>
    this.remove(GatewayActions, 'NumberOfApproval')
  );

  loadGatewaySeisMicSwath$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeisMicSwath')
  );
  addGatewaySeisMicSwath$ = createEffect(() =>
    this.add(GatewayActions, 'SeisMicSwath')
  );
  updateGatewaySeisMicSwath$ = createEffect(() =>
    this.update(GatewayActions, 'SeisMicSwath')
  );
  deleteGatewaySeisMicSwath$ = createEffect(() =>
    this.remove(GatewayActions, 'SeisMicSwath')
  );

  loadGatewayProgram$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Program')
  );
  addGatewayProgram$ = createEffect(() => this.add(GatewayActions, 'Program'));
  updateGatewayProgram$ = createEffect(() =>
    this.update(GatewayActions, 'Program')
  );
  deleteGatewayProgram$ = createEffect(() =>
    this.remove(GatewayActions, 'Program')
  );

  loadGatewayOilMineralLease$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'OilMineralLease')
  );
  addGatewayOilMineralLease$ = createEffect(() =>
    this.add(GatewayActions, 'OilMineralLease')
  );
  updateGatewayOilMineralLease$ = createEffect(() =>
    this.update(GatewayActions, 'OilMineralLease')
  );
  deleteGatewayOilMineralLease$ = createEffect(() =>
    this.remove(GatewayActions, 'OilMineralLease')
  );

  loadGatewayProgramConfig$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ProgramConfig')
  );
  addGatewayProgramConfig$ = createEffect(() =>
    this.add(GatewayActions, 'ProgramConfig')
  );
  updateGatewayProgramConfig$ = createEffect(() =>
    this.update(GatewayActions, 'ProgramConfig')
  );
  deleteGatewayProgramConfig$ = createEffect(() =>
    this.remove(GatewayActions, 'ProgramConfig')
  );

  loadGatewayParty$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Party')
  );
  addGatewayParty$ = createEffect(() => this.add(GatewayActions, 'Party'));
  updateGatewayParty$ = createEffect(() =>
    this.update(GatewayActions, 'Party')
  );
  deleteGatewayParty$ = createEffect(() =>
    this.remove(GatewayActions, 'Party')
  );

  loadGatewayImpactedAsset$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ImpactedAsset')
  );
  addGatewayImpactedAsset$ = createEffect(() =>
    this.add(GatewayActions, 'ImpactedAsset')
  );
  updateGatewayImpactedAsset$ = createEffect(() =>
    this.update(GatewayActions, 'ImpactedAsset')
  );
  deleteGatewayImpactedAsset$ = createEffect(() =>
    this.remove(GatewayActions, 'ImpactedAsset')
  );

  loadGatewaySeismicClaimantRef$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicClaimantRef')
  );
  addGatewaySeismicClaimantRef$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicClaimantRef')
  );
  updateGatewaySeismicClaimantRef$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicClaimantRef')
  );
  deleteGatewaySeismicClaimantRef$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicClaimantRef')
  );
  loadGatewayAreaOfImpact$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'AreaOfImpact')
  );
  addGatewayAreaOfImpact$ = createEffect(() =>
    this.add(GatewayActions, 'AreaOfImpact')
  );
  updateGatewayAreaOfImpact$ = createEffect(() =>
    this.update(GatewayActions, 'AreaOfImpact')
  );
  deleteGatewayAreaOfImpact$ = createEffect(() =>
    this.remove(GatewayActions, 'AreaOfImpact')
  );

  loadGatewayVendor$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Vendor')
  );
  addGatewayVendor$ = createEffect(() => this.add(GatewayActions, 'Vendor'));
  updateGatewayVendor$ = createEffect(() =>
    this.update(GatewayActions, 'Vendor')
  );
  deleteGatewayVendor$ = createEffect(() =>
    this.remove(GatewayActions, 'Vendor')
  );

  searchGatewaySeismicApproval$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'SeismicApproval')
  );

  loadGatewayApplication$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Application')
  );
  addGatewayApplication$ = createEffect(() =>
    this.add(GatewayActions, 'Application')
  );
  updateGatewayApplication$ = createEffect(() =>
    this.update(GatewayActions, 'Application')
  );
  deleteGatewayApplication$ = createEffect(() =>
    this.remove(GatewayActions, 'Application')
  );
  searchGatewayApplication$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Application')
  );
  clearGatewayApplication$ = createEffect(() =>
    this.loadClearState(GatewayActions, 'Application')
  );

  loadGatewaySeismicContractor$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'SeismicContractor')
  );
  addGatewaySeismicContractor$ = createEffect(() =>
    this.add(GatewayActions, 'SeismicContractor')
  );
  updateGatewaySeismicContractor$ = createEffect(() =>
    this.update(GatewayActions, 'SeismicContractor')
  );
  deleteGatewaySeismicContractor$ = createEffect(() =>
    this.remove(GatewayActions, 'SeismicContractor')
  );

  loadGatewayOpllPtsHistory$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'OpllPtsHistory')
  );
  addGatewayOpllPtsHistory$ = createEffect(() =>
    this.add(GatewayActions, 'OpllPtsHistory')
  );
  updateGatewayOpllPtsHistory$ = createEffect(() =>
    this.update(GatewayActions, 'OpllPtsHistory')
  );
  deleteGatewayOpllPtsHistory$ = createEffect(() =>
    this.remove(GatewayActions, 'OpllPtsHistory')
  );

  loadGatewayStaffTransfer$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StaffTransfer')
  );
  addGatewayStaffTransfer$ = createEffect(() =>
    this.add(GatewayActions, 'StaffTransfer')
  );
  updateGatewayStaffTransfer$ = createEffect(() =>
    this.update(GatewayActions, 'StaffTransfer')
  );
  deleteGatewayStaffTransfer$ = createEffect(() =>
    this.remove(GatewayActions, 'StaffTransfer')
  );
  searchGatewayStaffTransfer$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StaffTransfer')
  );

  loadGatewayMeeting$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Meeting')
  );
  addGatewayMeeting$ = createEffect(() => this.add(GatewayActions, 'Meeting'));
  updateGatewayMeeting$ = createEffect(() =>
    this.update(GatewayActions, 'Meeting')
  );
  deleteGatewayMeeting$ = createEffect(() =>
    this.remove(GatewayActions, 'Meeting')
  );
  searchGatewayMeeting$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Meeting')
  );

  loadGatewayCertPermitRenewal$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'CertPermitRenewal')
  );
  addGatewayCertPermitRenewal$ = createEffect(() =>
    this.add(GatewayActions, 'CertPermitRenewal')
  );
  updateGatewayCertPermitRenewal$ = createEffect(() =>
    this.update(GatewayActions, 'CertPermitRenewal')
  );
  deleteGatewayCertPermitRenewal$ = createEffect(() =>
    this.remove(GatewayActions, 'CertPermitRenewal')
  );
  searchGatewayCertPermitRenewal$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'CertPermitRenewal')
  );

  loadGatewayCertnotificaionmapping$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Certnotificaionmapping')
  );
  addGatewayCertnotificaionmapping$ = createEffect(() =>
    this.add(GatewayActions, 'Certnotificaionmapping')
  );
  updateGatewayCertnotificaionmapping$ = createEffect(() =>
    this.update(GatewayActions, 'Certnotificaionmapping')
  );
  deleteGatewayCertnotificaionmapping$ = createEffect(() =>
    this.remove(GatewayActions, 'Certnotificaionmapping')
  );
  searchGatewayCertnotificaionmapping$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Certnotificaionmapping')
  );

  loadGatewayMemoType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'MemoType')
  );
  addGatewayMemoType$ = createEffect(() =>
    this.add(GatewayActions, 'MemoType')
  );
  updateGatewayMemoType$ = createEffect(() =>
    this.update(GatewayActions, 'MemoType')
  );
  deleteGatewayMemoType$ = createEffect(() =>
    this.remove(GatewayActions, 'MemoType')
  );
  searchGatewayMemoType$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'MemoType')
  );
  loadGatewayMemo$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Memo')
  );

  addGatewayMemo$ = createEffect(() => this.add(GatewayActions, 'Memo'));
  updateGatewayMemo$ = createEffect(() => this.update(GatewayActions, 'Memo'));
  deleteGatewayMemo$ = createEffect(() => this.remove(GatewayActions, 'Memo'));
  searchGatewayMemo$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Memo')
  );
  loadGatewayStaffQuery$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StaffQuery')
  );
  addGatewayStaffQuery$ = createEffect(() =>
    this.add(GatewayActions, 'StaffQuery')
  );
  updateGatewayStaffQuery$ = createEffect(() =>
    this.update(GatewayActions, 'StaffQuery')
  );
  deleteGatewayStaffQuery$ = createEffect(() =>
    this.remove(GatewayActions, 'StaffQuery')
  );
  searchGatewayStaffQuery$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StaffQuery')
  );

  loadGatewayStaffTermination$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StaffTermination')
  );
  addGatewayStaffTermination$ = createEffect(() =>
    this.add(GatewayActions, 'StaffTermination')
  );
  updateGatewayStaffTermination$ = createEffect(() =>
    this.update(GatewayActions, 'StaffTermination')
  );
  deleteGatewayStaffTermination$ = createEffect(() =>
    this.remove(GatewayActions, 'StaffTermination')
  );
  searchGatewayStaffTermination$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StaffTermination')
  );
  loadGatewayStaffInfos$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StaffInfos')
  );
  addGatewayStaffInfos$ = createEffect(() =>
    this.add(GatewayActions, 'StaffInfos')
  );
  updateGatewayStaffInfos$ = createEffect(() =>
    this.update(GatewayActions, 'StaffInfos')
  );
  deleteGatewayStaffInfos$ = createEffect(() =>
    this.remove(GatewayActions, 'StaffInfos')
  );
  searchGatewayStaffInfos$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StaffInfos')
  );

  loadGatewayDepartment$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Department')
  );
  addGatewayDepartment$ = createEffect(() =>
    this.add(GatewayActions, 'Department')
  );
  updateGatewayDepartment$ = createEffect(() =>
    this.update(GatewayActions, 'Department')
  );
  deleteGatewayDepartment$ = createEffect(() =>
    this.remove(GatewayActions, 'Department')
  );
  searchGatewayDepartment$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Department')
  );

  loadGatewayUnit$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Unit')
  );
  addGatewayUnit$ = createEffect(() => this.add(GatewayActions, 'Unit'));
  updateGatewayUnit$ = createEffect(() => this.update(GatewayActions, 'Unit'));
  deleteGatewayUnit$ = createEffect(() => this.remove(GatewayActions, 'Unit'));
  // searchGatewayUnit$ = createEffect(() =>
  //   this.genericFilter(GatewayActions, 'Unit')
  // );

  loadGatewayPosition$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Position')
  );
  addGatewayPosition$ = createEffect(() =>
    this.add(GatewayActions, 'Position')
  );
  updateGatewayPosition$ = createEffect(() =>
    this.update(GatewayActions, 'Position')
  );
  deleteGatewayPosition$ = createEffect(() =>
    this.remove(GatewayActions, 'Position')
  );
  searchGatewayPosition$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Position')
  );

  loadGatewayJobTitle$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'JobTitle')
  );
  addGatewayJobTitle$ = createEffect(() =>
    this.add(GatewayActions, 'JobTitle')
  );
  updateGatewayJobTitle$ = createEffect(() =>
    this.update(GatewayActions, 'JobTitle')
  );
  deleteGatewayJobTitle$ = createEffect(() =>
    this.remove(GatewayActions, 'JobTitle')
  );
  searchGatewayJobTitle$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'JobTitle')
  );

  loadGatewayGender$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Gender')
  );
  addGatewayGender$ = createEffect(() => this.add(GatewayActions, 'Gender'));
  updateGatewayGender$ = createEffect(() =>
    this.update(GatewayActions, 'Gender')
  );
  deleteGatewayGender$ = createEffect(() =>
    this.remove(GatewayActions, 'Gender')
  );
  searchGatewayGender$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Gender')
  );

  loadGatewayMaritalStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'MaritalStatus')
  );
  addGatewayMaritalStatus$ = createEffect(() =>
    this.add(GatewayActions, 'MaritalStatus')
  );
  updateGatewayMaritalStatus$ = createEffect(() =>
    this.update(GatewayActions, 'MaritalStatus')
  );
  deleteGatewayMaritalStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'MaritalStatus')
  );
  searchGatewayMaritalStatus$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'MaritalStatus')
  );

  loadGatewayEmploymentStatus$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'EmploymentStatus')
  );
  addGatewayEmploymentStatus$ = createEffect(() =>
    this.add(GatewayActions, 'EmploymentStatus')
  );
  updateGatewayEmploymentStatus$ = createEffect(() =>
    this.update(GatewayActions, 'EmploymentStatus')
  );
  deleteGatewayEmploymentStatus$ = createEffect(() =>
    this.remove(GatewayActions, 'EmploymentStatus')
  );
  searchGatewayEmploymentStatus$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'EmploymentStatus')
  );

  loadGatewayApprovalType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ApprovalType')
  );
  addGatewayApprovalType$ = createEffect(() =>
    this.add(GatewayActions, 'ApprovalType')
  );
  updateGatewayApprovalType$ = createEffect(() =>
    this.update(GatewayActions, 'ApprovalType')
  );
  deleteGatewayApprovalType$ = createEffect(() =>
    this.remove(GatewayActions, 'ApprovalType')
  );
  searchGatewayApprovalType$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'ApprovalType')
  );

  loadGatewayApprovalMapping$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ApprovalMapping')
  );
  addGatewayApprovalMapping$ = createEffect(() =>
    this.add(GatewayActions, 'ApprovalMapping')
  );
  updateGatewayApprovalMapping$ = createEffect(() =>
    this.update(GatewayActions, 'ApprovalMapping')
  );
  deleteGatewayApprovalMapping$ = createEffect(() =>
    this.remove(GatewayActions, 'ApprovalMapping')
  );
  searchGatewayApprovalMapping$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'ApprovalMapping')
  );

  loadGatewayGenericApprovalLevel$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'GenericApprovalLevel')
  );
  addGatewayGenericApprovalLevel$ = createEffect(() =>
    this.add(GatewayActions, 'GenericApprovalLevel')
  );
  updateGatewayGenericApprovalLevel$ = createEffect(() =>
    this.update(GatewayActions, 'GenericApprovalLevel')
  );
  deleteGatewayGenericApprovalLevel$ = createEffect(() =>
    this.remove(GatewayActions, 'GenericApprovalLevel')
  );
  searchGatewayGenericApprovalLevel$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'GenericApprovalLevel')
  );

  loadGatewayApprovalSetting$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ApprovalSetting')
  );
  addGatewayApprovalSetting$ = createEffect(() =>
    this.add(GatewayActions, 'ApprovalSetting')
  );
  updateGatewayApprovalSetting$ = createEffect(() =>
    this.update(GatewayActions, 'ApprovalSetting')
  );
  deleteGatewayApprovalSetting$ = createEffect(() =>
    this.remove(GatewayActions, 'ApprovalSetting')
  );
  searchGatewayApprovalSetting$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'ApprovalSetting')
  );

  loadGatewayApproval$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Approval')
  );
  addGatewayApproval$ = createEffect(() =>
    this.add(GatewayActions, 'Approval')
  );
  updateGatewayApproval$ = createEffect(() =>
    this.update(GatewayActions, 'Approval')
  );
  deleteGatewayApproval$ = createEffect(() =>
    this.remove(GatewayActions, 'Approval')
  );
  searchGatewayApproval$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Approval')
  );

  loadGatewayStaffInfo$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'StaffInfo')
  );
  addGatewayStaffInfo$ = createEffect(() =>
    this.add(GatewayActions, 'StaffInfo')
  );
  updateGatewayStaffInfo$ = createEffect(() =>
    this.update(GatewayActions, 'StaffInfo')
  );
  deleteGatewayStaffInfo$ = createEffect(() =>
    this.remove(GatewayActions, 'StaffInfo')
  );
  searchGatewayStaffInfo$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'StaffInfo')
  );

  loadGatewayLeaveRequest$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'LeaveRequest')
  );
  addGatewayLeaveRequest$ = createEffect(() =>
    this.add(GatewayActions, 'LeaveRequest')
  );
  updateGatewayLeaveRequest$ = createEffect(() =>
    this.update(GatewayActions, 'LeaveRequest')
  );
  deleteGatewayLeaveRequest$ = createEffect(() =>
    this.remove(GatewayActions, 'LeaveRequest')
  );
  searchGatewayLeaveRequest$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'LeaveRequest')
  );

  loadGatewayRequestBreakDowns$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'RequestBreakDowns')
  );
  addGatewayRequestBreakDowns$ = createEffect(() =>
    this.add(GatewayActions, 'RequestBreakDowns')
  );
  updateGatewayRequestBreakDowns$ = createEffect(() =>
    this.update(GatewayActions, 'RequestBreakDowns')
  );
  deleteGatewayRequestBreakDowns$ = createEffect(() =>
    this.remove(GatewayActions, 'RequestBreakDowns')
  );
  searchGatewayRequestBreakDowns$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'RequestBreakDowns')
  );

  loadGatewayStafftrainingplan$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Stafftrainingplan')
  );
  addGatewayStafftrainingplan$ = createEffect(() =>
    this.add(GatewayActions, 'Stafftrainingplan')
  );
  updateGatewayStafftrainingplan$ = createEffect(() =>
    this.update(GatewayActions, 'Stafftrainingplan')
  );
  deleteGatewayStafftrainingplan$ = createEffect(() =>
    this.remove(GatewayActions, 'Stafftrainingplan')
  );
  searchGatewayStafftrainingplan$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Stafftrainingplan')
  );

  loadGatewayqmsObjectiveType$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'QMSObjectiveType')
  );
  addGatewayqmsObjectiveType$ = createEffect(() =>
    this.add(GatewayActions, 'QMSObjectiveType')
  );
  updateGatewayqmsObjectiveType$ = createEffect(() =>
    this.update(GatewayActions, 'QMSObjectiveType')
  );
  deleteGatewayqmsObjectiveType$ = createEffect(() =>
    this.remove(GatewayActions, 'QMSObjectiveType')
  );
  searchGatewayqmsObjectiveType$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'QMSObjectiveType')
  );

  loadGatewayqmsObjectiveActivity$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'QMSObjectiveActivity')
  );
  addGatewayqmsObjectiveActivity$ = createEffect(() =>
    this.add(GatewayActions, 'QMSObjectiveActivity')
  );
  updateGatewayqmsObjectiveActivity$ = createEffect(() =>
    this.update(GatewayActions, 'QMSObjectiveActivity')
  );
  deleteGatewayqmsObjectiveActivity$ = createEffect(() =>
    this.remove(GatewayActions, 'QMSObjectiveActivity')
  );
  searchGatewayqmsObjectiveActivity$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'QMSObjectiveActivity')
  );

  loadGatewayqmsObjective$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'QMSObjective')
  );
  addGatewayqmsObjective$ = createEffect(() =>
    this.add(GatewayActions, 'QMSObjective')
  );
  updateGatewayqmsObjective$ = createEffect(() =>
    this.update(GatewayActions, 'QMSObjective')
  );
  deleteGatewayqmsObjective$ = createEffect(() =>
    this.remove(GatewayActions, 'QMSObjective')
  );
  searchGatewayqmsObjective$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'QMSObjective')
  );

  loadGatewayqmsObjectivePerformance$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'QMSObjectivePerformance')
  );
  addGatewayqmsObjectivePerformance$ = createEffect(() =>
    this.add(GatewayActions, 'QMSObjectivePerformance')
  );
  updateGatewayqmsObjectivePerformance$ = createEffect(() =>
    this.update(GatewayActions, 'QMSObjectivePerformance')
  );
  deleteGatewayqmsObjectivePerformance$ = createEffect(() =>
    this.remove(GatewayActions, 'QMSObjectivePerformance')
  );
  searchGatewayqmsObjectivePerformance$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'QMSObjectivePerformance')
  );

  loadGatewayRequisition$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'Requisition')
  );
  addGatewayRequisition$ = createEffect(() =>
    this.add(GatewayActions, 'Requisition')
  );
  updateGatewayRequisition$ = createEffect(() =>
    this.update(GatewayActions, 'Requisition')
  );
  deleteGatewayRequisition$ = createEffect(() =>
    this.remove(GatewayActions, 'Requisition')
  );
  searchGatewayRequisition$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'Requisition')
  );

  loadGatewayRQMSRespondents$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'QMSRespondents')
  );
  addGatewayQMSRespondents$ = createEffect(() =>
    this.add(GatewayActions, 'QMSRespondents')
  );
  updateGatewayQMSRespondents$ = createEffect(() =>
    this.update(GatewayActions, 'QMSRespondents')
  );
  deleteGatewayQMSRespondents$ = createEffect(() =>
    this.remove(GatewayActions, 'QMSRespondents')
  );
  searchGatewayQMSRespondents$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'QMSRespondents')
  );

  loadGatewayApprovalDelegate$ = createEffect(() =>
    this.loadAllData(GatewayActions, 'ApprovalDelegate')
  );
  addGatewayApprovalDelegate$ = createEffect(() =>
    this.add(GatewayActions, 'ApprovalDelegate')
  );
  updateGatewayApprovalDelegate$ = createEffect(() =>
    this.update(GatewayActions, 'ApprovalDelegate')
  );
  deleteGatewayApprovalDelegate$ = createEffect(() =>
    this.remove(GatewayActions, 'ApprovalDelegate')
  );
  searchGatewayApprovalDelegate$ = createEffect(() =>
    this.genericFilter(GatewayActions, 'ApprovalDelegate')
  );

  constructor(
    private actions$: Actions,
    private gatewayService: GatewayService,
    private router: Router
  ) {}
}
