// import { BaseModel } from './base';

import { BaseModel } from './base';

export class Biometric extends BaseModel {
  biometricsID: string;
  biometricsName: string;

  userId: string;
  passport: string;
  rightThumb: string;
  rightIndex: string;
  rightMiddle: string;
  rightFourthFinger: string;
  rightFifthFinger: string;
  leftThumb: string;
  leftIndex: string;
  leftMiddle: string;
  leftFourthFinger: string;
  leftFifthFinger: string;
  template: string;
  dateEnrolled: string;
  enrolledBy: string;
  enrolledById: string;
  deviceUsed: string;
  subscriberId: string;
  isEnrolled: boolean;
}
