// import { IsArray, IsDate, IsNotEmpty } from 'class-validator';
import { BaseModel } from './base';
import { UserDto } from './user';
export class BidMailReportDto extends BaseModel {
  subject: string;

  from: Date;

  to: Date;

  days: BidDayDto[];

  userId: UserDto | string;
}

export class BidDayDto {
  day: string;
  mails: BidMailDto[];
}

export class BidMailDto {
  sender: string;

  company: string;

  subject: string;

  matter: string;

  action: string;

  startTime: string;

  endTime: string;
}
