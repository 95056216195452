import { Leave } from './leave';
export class LeaveExtension {
  userId: string;
  extensionDates: string[];
  leavePlan: Leave | string;
  isUsed: boolean;
  createDate: Date;
  _id: string;
  numberOfDays: string;
}
