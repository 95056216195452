<div class="box green_box"></div>
<div class="box pink_box"></div>
<!-- <div class="blur_ball">
  <img src="assets/images/small.svg" class="top1" alt="" />
  <img src="assets/images/small.svg" class="bottom1" alt="" />
</div> -->
<div class="row" style="display: flex; flex-direction: column">
  <main class="main_signin" style="overflow: hidden; height: unset">
    <form [formGroup]="form" (ngSubmit)="login()" class="form">
      <div class="sign_in">
        <h2 class="title">Login</h2>

        <div class="contain">
          <div class="the_input">
            <label for="">Email</label>
            <input
              type="email"
              formControlName="username"
              class="default_input"
              [ngClass]="{ inactive_input: (authService.isLoading$ | async) }"
              [readonly]="authService.isLoading$ | async"
            />
          </div>
          <div class="the_input">
            <label for="">Password</label>
            <input
              type="password"
              formControlName="password"
              class="default_input"
              [ngClass]="{ inactive_input: (authService.isLoading$ | async) }"
              [readonly]="authService.isLoading$ | async"
            />
            <a
              style="display: block"
              class="forgot_password-btn"
              [routerLink]="['/auth/manage']"
              >Forgot password</a
            >
          </div>
        </div>

        <div
          *ngIf="!errorMessage"
          class="sign_in-submit"
          style="display: flex; justify-content: center"
        >
          <button
            type="submit"
            class="default_primary-button"
            [ngClass]="{ inactive_btn: authService.isLoading$ | async }"
            [disabled]="errorMessage || authService.isLoading$ | async"
          >
            Sign in
          </button>

          <div *ngIf="authService.isLoading$ | async">
            <mat-progress-spinner
              mode="indeterminate"
              diameter="30"
              strokeWidth="1"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>
      <br />
    </form>
  </main>

  <div *ngIf="errorMessage" style="width: fit-content">
    <div class="alert alert-danger larger" role="alert" style="font-size: 12pt">
      {{ errorMessage }}
    </div>
  </div>
</div>
<!-- 
  <div class="sign_in row container-fluid">
    <div class="left_background col-md-7"></div>
    <div class="right_form col-md-5">

        <div class="up_button">
          
            <button [routerLink]="['/']">Home <i class="ti-arrow-left"></i></button>
        </div>

        <div *ngIf="isSignIn">
            
          <h3 class="the_top-header">Sign in</h3>
          <div *ngIf="errorMessage">
            <div class="alert alert-danger" role="alert">
              {{errorMessage}}
            </div>
          </div>
          <form [formGroup]="form" (ngSubmit)="login()" class="form">
  
              <label for="">Username</label>
              <div class="input">
                  <i class="ti-user"></i>
                  <input type="text" formControlName="username" placeholder="Enter Username">
              </div>
  
              <label for="">Password</label>
              <div class="input">
                  <i class="ti-unlock"></i>
                  <input type="password" formControlName="password" placeholder="Enter password">
              </div>
  
              <p class="alternative">
                  <span>Don't have an account? <a  style=" color:green; text-decoration: none; cursor: pointer;" (click)="showSignUp()">Click here to
                          subscribe</a></span>
                  <span class="ml-5" style="text-align: right;"><a href="#"
                          style="color:green; text-decoration: none;">Forgot
                          password?</a></span>
              </p>
  
              <div class="login__button">
                  <input type="submit" value="Sign in" class="sign">
              </div>
          </form>
        </div>
      

        <div *ngIf="!isSignIn">

          <h3 class="the_top-header">Sign up</h3>
          <form [formGroup]="registerForm" (ngSubmit)="register()" class="subscribe_form">
  
              <label for="">Organization name</label>
              <div class="input">
                  <i class="ti-user"></i>
                  <input type="text" placeholder="Enter organization name" formControlName="subscriberName">
              </div>
  
              <label for="">Username</label>
              <div class="input">
                  <i class="ti-user"></i>
                  <input type="text" placeholder="Enter Username" formControlName="username">
              </div>
  
              <label for="">Password</label>
              <div class="input">
                  <i class="ti-unlock"></i>
                  <input type="password" placeholder="Enter password" formControlName="password">
              </div>
  
              <p class="alternative">Already have an account? <a  (click)="showSignIn()" style="cursor: pointer;">Click here to Sign in</a></p>
  
              <div class="login__button">
                  <input type="submit" value="Sign up" class="sign">
              </div>
          </form>
        </div>
    </div>


</div>
  -->
