import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  Gateway_FEATURE_KEY,
  Gateway_FEATURE_KEY_Group,
  Gateway_FEATURE_KEY_AccountableFunction,
  Gateway_FEATURE_KEY_Function,
  Gateway_FEATURE_KEY_SubGroup,
  Gateway_FEATURE_KEY_SubSubGroup,
  Gateway_FEATURE_KEY_SubSubGroupType,
  Gateway_FEATURE_KEY_OwnershipType,
  Gateway_FEATURE_KEY_ClaimType,
  Gateway_FEATURE_KEY_Terrain,
  Gateway_FEATURE_KEY_PaymentStatus,
  Gateway_FEATURE_KEY_Company,
  Gateway_FEATURE_KEY_CompanyFunction,
  Gateway_FEATURE_KEY_DivestmentStatus,
  Gateway_FEATURE_KEY_EncroachmentType,
  Gateway_FEATURE_KEY_EncroachmentStatus,
  Gateway_FEATURE_KEY_Title,
  Gateway_FEATURE_KEY_DocumentType,
  Gateway_FEATURE_KEY_Bank,
  Gateway_FEATURE_KEY_DivisionTbl,
  Gateway_FEATURE_KEY_Continent,
  Gateway_FEATURE_KEY_FiscalFileLocation,
  Gateway_FEATURE_KEY_GeoDatum,
  Gateway_FEATURE_KEY_Country,
  Gateway_FEATURE_KEY_State,
  Gateway_FEATURE_KEY_Lga,
  Gateway_FEATURE_KEY_Community,
  Gateway_FEATURE_KEY_Lessors,
  Gateway_FEATURE_KEY_Measure,
  Gateway_FEATURE_KEY_AddressBook,
  Gateway_FEATURE_KEY_SeismicContractor,
  Gateway_FEATURE_KEY_Purpose,
  Gateway_FEATURE_KEY_AcquisitionType,
  Gateway_FEATURE_KEY_BankingStatus,
  Gateway_FEATURE_KEY_Currency,
  Gateway_FEATURE_KEY_LandUseStatus,
  Gateway_FEATURE_KEY_RightHeld,
  Gateway_FEATURE_KEY_MeasuringFactor,
  Gateway_FEATURE_KEY_CabinetShelfRef,
  Gateway_FEATURE_KEY_DocumentTypeCode,
  Gateway_FEATURE_KEY_RackTrayRef,
  Gateway_FEATURE_KEY_SecurityClassification,
  Gateway_FEATURE_KEY_AppConfiguration,
  Gateway_FEATURE_KEY_SeismicApproval,
  Gateway_FEATURE_KEY_Category,
  Gateway_FEATURE_KEY_PLStageActivity,
  Gateway_FEATURE_KEY_PLStakeholderMatrix,
  Gateway_FEATURE_KEY_PLStakeholderMatrixRaci,
  Gateway_FEATURE_KEY_StakeholderRep,
  Gateway_FEATURE_KEY_PipelineLicenseCategory,
  Gateway_FEATURE_KEY_PipelineLicenseStage,
  Gateway_FEATURE_KEY_PipelineLicenseStakeholder,
  Gateway_FEATURE_KEY_Hierarchy,
  Gateway_FEATURE_KEY_PipelineAssetLocation,
  Gateway_FEATURE_KEY_VerificationStatus,
  Gateway_FEATURE_KEY_Uom,
  Gateway_FEATURE_KEY_PipelineSection,
  Gateway_FEATURE_KEY_AccountableFunctionLink2,
  Gateway_FEATURE_KEY_PipelineAssetLink2,
  Gateway_FEATURE_KEY_PaymentType,
  Gateway_FEATURE_KEY_Pigibility,
  Gateway_FEATURE_KEY_AssetStatus,
  Gateway_FEATURE_KEY_DeliveryType,
  Gateway_FEATURE_KEY_LicenceStatus,
  Gateway_FEATURE_KEY_Network,
  Gateway_FEATURE_KEY_IPStatus,
  Gateway_FEATURE_KEY_PipelineGrade,
  Gateway_FEATURE_KEY_ActivityActionsPerson,
  Gateway_FEATURE_KEY_PipelineLicenceRequest,
  Gateway_FEATURE_KEY_PipelineLicenceRequestStage,
  Gateway_FEATURE_KEY_PLRStageActivity,
  Gateway_FEATURE_KEY_PLRStakeholderMatrix,
  Gateway_FEATURE_KEY_PLRStakeholderMatrixRaci,
  Gateway_FEATURE_KEY_CorrespondenceActionParty,
  Gateway_FEATURE_KEY_CorrespondenceTeam,
  Gateway_FEATURE_KEY_UserStaffDetail,
  Gateway_FEATURE_KEY_PLActivityActionPerson,
  Gateway_FEATURE_KEY_IdentityUser,
  Gateway_FEATURE_KEY_EncroachmentAsset,
  Gateway_FEATURE_KEY_PipelineEncroachment,
  Gateway_FEATURE_KEY_Encroacher,
  Gateway_FEATURE_KEY_StakeholderResponse,
  Gateway_FEATURE_KEY_EncroachmentAction,
  Gateway_FEATURE_KEY_Adr,
  Gateway_FEATURE_KEY_CounselType,
  Gateway_FEATURE_KEY_SuitCounsel,
  Gateway_FEATURE_KEY_AdrPersonnel,
  Gateway_FEATURE_KEY_AdrAssets,
  Gateway_FEATURE_KEY_PLRActivityActionHistory,
  Gateway_FEATURE_KEY_SeismicClaimantRef,
  //blessed
  Gateway_FEATURE_KEY_StaffInfos,
  Gateway_FEATURE_KEY_Memo,
  Gateway_FEATURE_KEY_Meeting,
  Gateway_FEATURE_KEY_StaffTransfer,
  Gateway_FEATURE_KEY_StaffQuery,
  Gateway_FEATURE_KEY_StaffTermination,
  Gateway_FEATURE_KEY_MemoType,
  Gateway_FEATURE_KEY_CertPermitRenewal,
  Gateway_FEATURE_KEY_Certnotificaionmapping,
  Gateway_FEATURE_KEY_Department,
  Gateway_FEATURE_KEY_Unit,
  Gateway_FEATURE_KEY_Position,
  Gateway_FEATURE_KEY_Gender,
  Gateway_FEATURE_KEY_JobTitle,
  Gateway_FEATURE_KEY_MaritalStatus,
  Gateway_FEATURE_KEY_EmploymentStatus,
  Gateway_FEATURE_KEY_RequestType,
  Gateway_FEATURE_KEY_CorrespondenceCompany,
  Gateway_FEATURE_KEY_Sex,
  Gateway_FEATURE_KEY_LandPrefix,
  Gateway_FEATURE_KEY_TotalSumTable,
  Gateway_FEATURE_KEY_LossOfUseItems,
  Gateway_FEATURE_KEY_AuditTray,
  Gateway_FEATURE_KEY_DamageAssessment,
  Gateway_FEATURE_KEY_DamageAssessmentBreakDown,
  Gateway_FEATURE_KEY_DamageAssessmentDraft,
  Gateway_FEATURE_KEY_DamageAssessmentBreakDownDraft,
  Gateway_FEATURE_KEY_SeismicClaimType,
  Gateway_FEATURE_KEY_SeismicCurrency,
  Gateway_FEATURE_KEY_SeismicDivision,
  Gateway_FEATURE_KEY_SeismicLineType,
  Gateway_FEATURE_KEY_SeismicOML,
  Gateway_FEATURE_KEY_SeismicProgram,
  Gateway_FEATURE_KEY_SeismicParty,
  Gateway_FEATURE_KEY_SeismicSwathNo,
  Gateway_FEATURE_KEY_SeismicCrew,
  Gateway_FEATURE_KEY_SeismicCommunity,
  Gateway_FEATURE_KEY_SeismicDamageList,
  Gateway_FEATURE_KEY_SeismicSwathCalc,
  //eGG
  Gateway_FEATURE_KEY_YearOfSuit,
  Gateway_FEATURE_KEY_DocumentCategory,
  Gateway_FEATURE_KEY_LessorRepType,
  Gateway_FEATURE_KEY_DamageStatus,
  Gateway_FEATURE_KEY_Pole,
  Gateway_FEATURE_KEY_AccessRight,
  Gateway_FEATURE_KEY_CofOStatus,
  Gateway_FEATURE_KEY_Quantum,
  Gateway_FEATURE_KEY_SubSubGroupTypeCategory,
  Gateway_FEATURE_KEY_IncidentQuantum,
  Gateway_FEATURE_KEY_IncidentSubCategory,
  Gateway_FEATURE_KEY_IncidentCategory,
  Gateway_FEATURE_KEY_RateStatus,
  Gateway_FEATURE_KEY_RequestCategory,
  Gateway_FEATURE_KEY_RequestSubCategory,
  Gateway_FEATURE_KEY_ObligationPeriod,
  Gateway_FEATURE_KEY_CleanUpStatus,
  Gateway_FEATURE_KEY_DamageList,
  Gateway_FEATURE_KEY_AddressType,
  Gateway_FEATURE_KEY_ActivityStatus,
  Gateway_FEATURE_KEY_Batch,
  Gateway_FEATURE_KEY_Lorp,
  Gateway_FEATURE_KEY_ExpirationStatus,
  Gateway_FEATURE_KEY_FacilityAsset,
  Gateway_FEATURE_KEY_IssuedCategory,
  Gateway_FEATURE_KEY_PipelineTemplate,
  Gateway_FEATURE_KEY_ItemType,
  Gateway_FEATURE_KEY_LeaseExpiration,
  Gateway_FEATURE_KEY_PipelineDetails,
  Gateway_FEATURE_KEY_LandTitle,
  Gateway_FEATURE_KEY_Role,
  Gateway_FEATURE_KEY_Staffrole,
  Gateway_FEATURE_KEY_BatchData,
  Gateway_FEATURE_KEY_ApprovalLevel,
  Gateway_FEATURE_KEY_Supervisor,
  Gateway_FEATURE_KEY_NumberOfApproval,
  Gateway_FEATURE_KEY_SeisMicSwath,
  Gateway_FEATURE_KEY_Program,
  Gateway_FEATURE_KEY_OilMineralLease,
  Gateway_FEATURE_KEY_ProgramConfig,
  Gateway_FEATURE_KEY_Vendor,
  Gateway_FEATURE_KEY_Application,
  Gateway_FEATURE_KEY_ImpactedAsset,
  Gateway_FEATURE_KEY_AreaOfImpact,
  Gateway_FEATURE_KEY_Party,
  Gateway_FEATURE_KEY_OpllPtsHistory,
  Gateway_FEATURE_KEY_ApprovalType,
  Gateway_FEATURE_KEY_ApprovalMapping,
  Gateway_FEATURE_KEY_GenericApprovalLevel,
  Gateway_FEATURE_KEY_ApprovalSetting,
  Gateway_FEATURE_KEY_Approval,
  Gateway_FEATURE_KEY_StaffInfo,
  Gateway_FEATURE_KEY_LeaveRequest,
  Gateway_FEATURE_KEY_RequestBreakDowns,
  Gateway_FEATURE_KEY_Stafftrainingplan,
  Gateway_FEATURE_KEY_qmsObjectiveType,
  Gateway_FEATURE_KEY_qmsObjectiveactivity,
  Gateway_FEATURE_KEY_qmsObjective,
  Gateway_FEATURE_KEY_qmsObjectivePerformance,
  Gateway_FEATURE_KEY_Requisition,
  Gateway_FEATURE_KEY_QMSRespondents,
  Gateway_FEATURE_KEY_ApprovalDelegate,
  GatewayState,
  GatewayPartialState,
  GatewayAdapter,
  UploadStatus,
} from './gateway.reducer';

// Lookup the 'Stakeholder Reference' feature state managed by NgRx
export const getGatewayState = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY);

export const getGatewayStateImpactedAsset = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ImpactedAsset);

export const getGatewayStateParty = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Party);

export const getGatewayDamageAssessment = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DamageAssessment);

export const getGatewayStateDamageAssessmentBreakDown = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DamageAssessmentBreakDown);
export const getGatewayDamageAssessmentDraft = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DamageAssessmentDraft);

export const getGatewayStateDamageAssessmentBreakDownDraft =
  createFeatureSelector<GatewayPartialState, GatewayState>(
    Gateway_FEATURE_KEY_DamageAssessmentBreakDownDraft
  );
export const getGatewayStateSeismicApproval = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicApproval);
export const getGatewayStateGroup = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Group);

export const getGatewayStateAccountableFunction = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AccountableFunction);

export const getGatewayStateFunction = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Function);

export const getGatewayStateSubGroup = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SubGroup);

export const getGatewayStateSubSubGroup = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SubSubGroup);

export const getGatewayStateSubSubGroupType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SubSubGroupType);

export const getGatewayStateOwnershipType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_OwnershipType);

export const getGatewayStateClaimType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ClaimType);

export const getGatewayStateTerrain = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Terrain);
export const getGatewayStateSeismicClaimantRef = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicClaimantRef);
export const getGatewayStatePaymentStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PaymentStatus);

export const getGatewayStateCompany = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Company);
export const getGatewayStateStakeholderRep = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StakeholderRep);

export const getGatewayStateCompanyFunction = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CompanyFunction);

export const getGatewayStateDivestmentStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DivestmentStatus);

export const getGatewayStateEncroachmentType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_EncroachmentType);

export const getGatewayStateEncroachmentStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_EncroachmentStatus);

export const getGatewayStateTitle = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Title);

export const getGatewayStateDocumentType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DocumentType);

export const getGatewayStateBank = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Bank);

export const getGatewayStateDivisionTbl = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DivisionTbl);

export const getGatewayStateContinent = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Continent);

export const getGatewayStateFiscalFileLocation = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_FiscalFileLocation);

export const getGatewayStateGeoDatum = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_GeoDatum);

export const getGatewayStateCountry = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Country);

export const getGatewayStateState = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_State);

export const getGatewayStateLga = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Lga);

export const getGatewayStateCommunity = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Community);
export const getGatewayStateMeasure = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Measure);

export const getGatewayStatePurpose = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Purpose);

export const getGatewayStateAcquisitionType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AcquisitionType);

export const getGatewayStateCurrency = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Currency);

export const getGatewayStateBankingStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_BankingStatus);

export const getGatewayStateLandUseStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LandUseStatus);

export const getGatewayStateRightHeld = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RightHeld);

export const getGatewayStateCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Category);

export const getGatewayStateMeasuringFactor = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_MeasuringFactor);

export const getGatewayStateAppConfiguration = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AppConfiguration);

export const getGatewayStateCabinetShelfRef = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CabinetShelfRef);

export const getGatewayStateDocumentTypeCode = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DocumentTypeCode);

export const getGatewayStateRackTrayRef = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RackTrayRef);

export const getGatewayStateSecurityClassification = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SecurityClassification);

export const getGatewayStatePLStageActivity = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLStageActivity);

export const getGatewayStateStaffInfos = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StaffInfos);

export const getGatewayStateStaffTransfer = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StaffTransfer);

export const getGatewayStateStaffQuery = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StaffQuery);

export const getGatewayStateStaffTermination = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StaffTermination);

export const getGatewayStateDepartment = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Department);

export const getGatewayStateUnit = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Unit);

export const getGatewayStatePosition = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Position);

export const getGatewayStateGender = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Gender);

export const getGatewayStateJobTitle = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_JobTitle);

export const getGatewayStateEmploymentStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_EmploymentStatus);

export const getGatewayStateMaritalStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_MaritalStatus);

export const getGatewayStateMemoType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_MemoType);

export const getGatewayStateCertPermitRenewal = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CertPermitRenewal);

export const getGatewayStateCertnotificaionmapping = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Certnotificaionmapping);

export const getGatewayStateMemo = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Memo);

export const getGatewayStateMeeting = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Meeting);

export const getGatewayStatePLStakeholderMatrix = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLStakeholderMatrix);

export const getGatewayStatePLStakeholderMatrixRaci = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLStakeholderMatrixRaci);

export const getGatewayStatePipelineLicenseCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineLicenseCategory);

export const getGatewayStatePipelineLicenseStage = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineLicenseStage);

export const getGatewayStatePipelineLicenseStakeholder = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineLicenseStakeholder);

export const getGatewayStateHierarchy = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Hierarchy);

export const getGatewayStatePipelineAssetLocation = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineAssetLocation);

export const getGatewayStatePaymentType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PaymentType);

export const getGatewayStatePipelineSection = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineSection);

export const getGatewayStateAccountableFunctionLink2 = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AccountableFunctionLink2);

export const getGatewayStatePipelineAssetLink2 = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineAssetLink2);

export const getGatewayStateVerificationStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_VerificationStatus);

export const getGatewayStateUom = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Uom);

export const getGatewayStateAssetStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AssetStatus);

export const getGatewayStatePigibility = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Pigibility);

export const getGatewayStateDeliveryType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DeliveryType);

export const getGatewayStateLicenceStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LicenceStatus);

export const getGatewayStateNetwork = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Network);

export const getGatewayStatePipelineGrade = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineGrade);

export const getGatewayStateIPStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_IPStatus);

export const getGatewayStateActivityActionsPerson = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ActivityActionsPerson);

export const getGatewayStatePipelineLicenceRequest = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineLicenceRequest);

export const getGatewayStatePipelineLicenceRequestStage = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineLicenceRequestStage);

export const getGatewayStatePLRStageActivity = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLRStageActivity);

export const getGatewayStatePLRStakeholderMatrix = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLRStakeholderMatrix);

export const getGatewayStatePLRStakeholderMatrixRaci = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLRStakeholderMatrixRaci);
export const getGatewayStateCorrespondenceActionParty = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CorrespondenceActionParty);
export const getGatewayStateCorrespondenceTeam = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CorrespondenceTeam);
export const getGatewayStateUserStaffDetail = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_UserStaffDetail);
export const getGatewayStatePLActivityActionPerson = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLActivityActionPerson);

export const getGatewayStateIdentityUser = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_IdentityUser);

export const getGatewayStatePipelineDetails = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineDetails);

export const getGateWayStateEncroachmentAsset = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_EncroachmentAsset);

export const getGatewayStateEncroacher = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Encroacher);
export const getGatewayStakeholderResponse = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StakeholderResponse);
export const getGatewayStatePipelineEncroachment = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineEncroachment);

export const getGatewayStateEncroachmentAction = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_EncroachmentAction);

export const getGatewayStateAdr = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Adr);

export const getGatewayStateCounselType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CounselType);

export const getGatewayStatePLRActivityActionHistory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PLRActivityActionHistory);

export const getGatewayStateSuitCounsel = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SuitCounsel);

export const getGatewayStateAdrPersonnel = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AdrPersonnel);

export const getGatewayStateAdrAssets = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AdrAssets);

export const getGatewayStateRequestType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RequestType);

export const getGatewayStateCorrespondenceCompany = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CorrespondenceCompany);

export const getGatewayStateSex = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Sex);

export const getGatewayStateLandPrefix = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LandPrefix);

export const getGatewayStateTotalSumTable = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_TotalSumTable);
export const getGatewayStateLossOfUseItems = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LossOfUseItems);

export const getGatewayStateAuditTray = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AuditTray);

//eGG

export const getGatewayStateYearOfSuit = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_YearOfSuit);

export const getGatewayStateDocumentCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DocumentCategory);

export const getGatewayStateLessorRepType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LessorRepType);

export const getGatewayStateDamageStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DamageStatus);

export const getGatewayStatePole = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Pole);

export const getGatewayStateAccessRight = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AccessRight);

export const getGatewayStateCofOStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CofOStatus);

export const getGatewayStateQuantum = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Quantum);

export const getGatewayStateSubSubGroupTypeCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SubSubGroupTypeCategory);

export const getGatewayStateIncidentQuantum = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_IncidentQuantum);

export const getGatewayStateIncidentSubCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_IncidentSubCategory);

export const getGatewayStateIncidentCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_IncidentCategory);

export const getGatewayStateRateStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RateStatus);

export const getGatewayStateSeismicClaimType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicClaimType);

export const getGatewayStateSeismicCurrency = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicCurrency);

export const getGatewayStateSeismicDivision = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicDivision);

export const getGatewayStateSeismicLineType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicLineType);

export const getGatewayStateSeismicOML = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicOML);

export const getGatewayStateSeismicProgram = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicProgram);

export const getGatewayStateSeismicParty = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicParty);

export const getGatewayStateSeismicSwathNo = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicSwathNo);

export const getGatewayStateSeismicCrew = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicCrew);

export const getGatewayStateSeismicCommunity = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicCommunity);

export const getGatewayStateSeismicDamageList = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicDamageList);

export const getGatewayStateSeismicSwathCalc = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicSwathCalc);

export const getGatewayStateRequestCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RequestCategory);

export const getGatewayStateRequestSubCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RequestSubCategory);

export const getGatewayStateObligationPeriod = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ObligationPeriod);

export const getGatewayStateBatch = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Batch);

export const getGatewayStateCleanUpStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_CleanUpStatus);

export const getGatewayStateDamageList = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_DamageList);

export const getGatewayStateAddressType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AddressType);

export const getGatewayStateActivityStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ActivityStatus);

export const getGatewayStateLorp = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Lorp);

export const getGatewayStateExpirationStatus = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ExpirationStatus);

export const getGatewayStateFacilityAsset = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_FacilityAsset);

export const getGatewayStateIssuedCategory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_IssuedCategory);

export const getGatewayStateItemType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ItemType);

export const getGatewayStatePipelineTemplate = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_PipelineTemplate);

export const getGatewayStateLandTitle = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LandTitle);

export const getGatewayStateLeaseExpiration = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LeaseExpiration);

export const getGatewayStateRole = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Role);

export const getGatewayStateStaffRole = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Staffrole);

export const getGatewayStateBatchData = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_BatchData);

export const getGatewayStateLessors = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Lessors);

export const getGatewayStateAddressBook = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AddressBook);

export const getGatewayStateApprovalLevel = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ApprovalLevel);

export const getGatewayStateSupervisor = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Supervisor);

export const getGatewayStateNumberOfApproval = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_NumberOfApproval);

export const getGatewayStateSeisMicSwath = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeisMicSwath);

export const getGatewayStateProgram = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Program);

export const getGatewayStateOilMineralLease = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_OilMineralLease);

export const getGatewayStateProgramConfig = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ProgramConfig);

export const getGatewayStateVendor = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Vendor);

export const getGatewayStateAreaOfImpact = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_AreaOfImpact);

export const getGatewayStateApplication = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Application);

export const getGatewayStateSeismicContractor = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_SeismicContractor);

export const getGatewayStateOpllPtsHistory = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_OpllPtsHistory);

export const getGatewayStateApprovalType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ApprovalType);

export const getGatewayStateApprovalMapping = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ApprovalMapping);

export const getGatewayStateGenericApprovalLevel = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_GenericApprovalLevel);

export const getGatewayStateApprovalSetting = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ApprovalSetting);

export const getGatewayStateApproval = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Approval);

export const getGatewayStateLeaveRequest = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_LeaveRequest);

export const getGatewayStateRequestBreakDowns = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_RequestBreakDowns);

export const getGatewayStateStaffInfo = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_StaffInfo);

export const getGatewayStateStafftrainingplan = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Stafftrainingplan);

export const getGatewayStateRequisition = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_Requisition);

export const getGatewayQMSObjectiveType = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_qmsObjectiveType);

export const getGatewayQMSObjectiveActivity = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_qmsObjectiveactivity);

export const getGatewayQMSObjective = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_qmsObjective);

export const getGatewayQMSObjectivePerformance = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_qmsObjectivePerformance);

export const getGatewayQMSRespondents = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_QMSRespondents);

export const getGatewayApprovalDelegate = createFeatureSelector<
  GatewayPartialState,
  GatewayState
>(Gateway_FEATURE_KEY_ApprovalDelegate);

const { selectAll, selectEntities } = GatewayAdapter.getSelectors();

export function switchStateSlice(type): any {
  if (type === 'Group') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateGroup;
  }

  if (type === 'AccountableFunction') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAccountableFunction;
  }

  if (type === 'Function') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateFunction;
  }

  if (type === 'SubGroup') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateSubGroup;
  }

  if (type === 'SubSubGroup') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateSubSubGroup;
  }

  if (type === 'SubSubGroupType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateSubSubGroupType;
  }

  if (type === 'OwnershipType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateOwnershipType;
  }

  if (type === 'ClaimType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateClaimType;
  }
  if (type === 'StakeholderRep') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateStakeholderRep;
  }
  if (type === 'Terrain') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateTerrain;
  }

  if (type === 'PaymentStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePaymentStatus;
  }

  if (type === 'Company') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCompany;
  }

  if (type === 'CompanyFunction') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCompanyFunction;
  }

  if (type === 'DivestmentStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateDivestmentStatus;
  }

  if (type === 'StaffInfos') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateStaffInfos;
  }

  if (type === 'Meeting') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateMeeting;
  }

  if (type === 'MemoType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateMemoType;
  }

  if (type === 'CertPermitRenewal') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCertPermitRenewal;
  }
  if (type === 'Certnotificaionmapping') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCertnotificaionmapping;
  }

  if (type === 'Memo') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateMemo;
  }

  if (type === 'StaffTransfer') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateStaffTransfer;
  }

  if (type === 'StaffQuery') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateStaffQuery;
  }

  if (type === 'StaffTermination') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateStaffTermination;
  }

  if (type === 'Department') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateDepartment;
  }

  if (type === 'Unit') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateUnit;
  }

  if (type === 'Gender') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateGender;
  }

  if (type === 'Position') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePosition;
  }

  if (type === 'JobTitle') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateJobTitle;
  }

  if (type === 'MaritalStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateMaritalStatus;
  }

  if (type === 'EmploymentStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateEmploymentStatus;
  }

  if (type === 'EncroachmentType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateEncroachmentType;
  }

  if (type === 'EncroachmentStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateEncroachmentStatus;
  }
  if (type === 'Title') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateTitle;
  }

  if (type === 'DocumentType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateDocumentType;
  }

  if (type === 'Bank') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateBank;
  }
  if (type === 'DivisionTbl') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateDivisionTbl;
  }

  if (type === 'Continent') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateContinent;
  }

  if (type === 'FiscalFileLocation') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateFiscalFileLocation;
  }

  if (type === 'GeoDatum') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateGeoDatum;
  }

  if (type === 'Country') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCountry;
  }

  if (type === 'State') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateState;
  }

  if (type === 'Lga') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateLga;
  }
  if (type === 'Communities') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCommunity;
  }
  if (type === 'Measure') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateMeasure;
  }

  if (type === 'Purpose') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePurpose;
  }

  if (type === 'AcquisitionType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAcquisitionType;
  }

  if (type === 'Currency') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCurrency;
  }

  if (type === 'BankingStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateBankingStatus;
  }

  if (type === 'LandUseStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateLandUseStatus;
  }

  if (type === 'RightHeld') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateRightHeld;
  }

  if (type === 'Category') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCategory;
  }

  if (type === 'MeasuringFactor') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateMeasuringFactor;
  }

  if (type === 'AppConfiguration') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAppConfiguration;
  }

  if (type === 'CabinetShelfRef') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCabinetShelfRef;
  }

  if (type === 'DocumentTypeCode') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateDocumentTypeCode;
  }

  if (type === 'RackTrayRef') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateRackTrayRef;
  }

  if (type === 'SecurityClassification') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateSecurityClassification;
  }

  if (type === 'PLStageActivity') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLStageActivity;
  }
  if (type === 'PLStakeholderMatrix') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLStakeholderMatrix;
  }
  if (type === 'PLStakeholderMatrixRaci') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLStakeholderMatrixRaci;
  }

  if (type === 'PipelineLicenseCategory') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineLicenseCategory;
  }

  if (type === 'PipelineLicenseStage') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineLicenseStage;
  }

  if (type === 'PipelineLicenseStakeholder') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineLicenseStakeholder;
  }

  if (type === 'Hierarchy') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateHierarchy;
  }

  if (type === 'PipelineSection') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineSection;
  }

  if (type === 'AccountableFunctionLink2') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAccountableFunctionLink2;
  }

  if (type === 'PipelineAssetLink2') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineAssetLink2;
  }

  if (type === 'VerificationStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateVerificationStatus;
  }

  if (type === 'Uom') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateUom;
  }

  if (type === 'PaymentType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePaymentType;
  }

  if (type === 'PipelineAssetLocation') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineAssetLocation;
  }

  if (type === 'AssetStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAssetStatus;
  }

  if (type === 'DeliveryType') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateDeliveryType;
  }

  if (type === 'LicenceStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateLicenceStatus;
  }

  if (type === 'Network') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateNetwork;
  }

  if (type === 'IPStatus') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateIPStatus;
  }

  if (type === 'PipelineGrade') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineGrade;
  }

  if (type === 'Pigibility') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePigibility;
  }

  if (type === 'ActivityActionsPerson') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateActivityActionsPerson;
  }

  if (type === 'PipelineLicenseRequest') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineLicenceRequest;
  }

  if (type === 'PipelineLicenseRequestStage') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineLicenceRequestStage;
  }

  if (type === 'PLRStageActivity') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLRStageActivity;
  }

  if (type === 'PLRStakeholderMatrix') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLRStakeholderMatrix;
  }

  if (type === 'PLRStakeholderMatrixRaci') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLRStakeholderMatrixRaci;
  }

  if (type === 'CorrespondenceTeam') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCorrespondenceTeam;
  }
  if (type === 'CorrespondenceActionParty') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCorrespondenceActionParty;
  }
  if (type === 'SeismicClaimantRef') {
    console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicClaimantRef;
  }
  if (type === 'UserStaffDetail') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateUserStaffDetail;
  }

  if (type === 'PLActivityActionPerson') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGatewayStatePLActivityActionPerson;
  }

  if (type === 'IdentityUser') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGatewayStateIdentityUser;
  }

  if (type === 'EncroachmentAsset') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGateWayStateEncroachmentAsset;
  }

  if (type === 'PipelineEncroachment') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGatewayStatePipelineEncroachment;
  }
  if (type === 'Encroacher') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGatewayStateEncroacher;
  }
  if (type === 'StakeholderResponse') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGatewayStakeholderResponse;
  }
  if (type === 'EncroachmentAction') {
    // // //console.log('Type Passed in Selector Function', type);
    return getGatewayStateEncroachmentAction;
  }

  if (type === 'Adr') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAdr;
  }

  if (type === 'CounselType') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateCounselType;
  }
  if (type === 'SuitCounsel') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateSuitCounsel;
  }
  if (type === 'AdrPersonnel') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAdrPersonnel;
  }

  if (type === 'AdrAssets') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAdrAssets;
  }
  if (type === 'PLRStageActivity') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLRStageActivity;
  }
  if (type === 'ActivityActionsPerson') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateActivityActionsPerson;
  }
  if (type === 'PLRActivityActionHistory') {
    // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePLRActivityActionHistory;
  }

  if (type === 'RequestType') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateRequestType;
  }
  if (type === 'CorrespondenceCompany') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateCorrespondenceCompany;
  }

  if (type === 'Sex') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateSex;
  }
  if (type === 'LandPrefix') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLandPrefix;
  }
  if (type === 'TotalSumTable') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateTotalSumTable;
  }

  if (type === 'LossOfUseItems') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLossOfUseItems;
  }

  if (type === 'AuditTray') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateAuditTray;
  }
  //eGG
  if (type === 'YearOfSuit') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateYearOfSuit;
  }

  if (type === 'DocumentCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateDocumentCategory;
  }

  if (type === 'LessorRepType') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLessorRepType;
  }

  if (type === 'DamageStatus') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateDamageStatus;
  }

  if (type === 'Pole') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStatePole;
  }

  if (type === 'AccessRight') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateAccessRight;
  }

  if (type === 'CofOStatus') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateCofOStatus;
  }

  if (type === 'QuantumOfImpact') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateQuantum;
  }

  if (type === 'SubSubGroupTypeCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateSubSubGroupTypeCategory;
  }

  if (type === 'IncidentQuantumOfImpact') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateIncidentQuantum;
  }

  if (type === 'IncidentSubCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateIncidentSubCategory;
  }

  if (type === 'IncidentCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateIncidentCategory;
  }

  if (type === 'RateStatus') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateRateStatus;
  }

  if (type === 'RequestCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateRequestCategory;
  }

  if (type === 'RequestSubCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateRequestSubCategory;
  }

  if (type === 'ObligationPeriod') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateObligationPeriod;
  }

  if (type === 'ActivityStatus') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateActivityStatus;
  }

  if (type === 'Batch') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateBatch;
  }

  if (type === 'AddressTypeTbl') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateAddressType;
  }

  if (type === 'DamageList') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateDamageList;
  }

  if (type === 'CleanUpStatus') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateCleanUpStatus;
  }

  if (type === 'Lorp') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLorp;
  }

  if (type === 'ExpirationStatus') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateExpirationStatus;
  }

  if (type === 'FacilityAsset') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateFacilityAsset;
  }

  if (type === 'IssuedCategory') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateIssuedCategory;
  }

  if (type === 'ItemType') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateItemType;
  }

  if (type === 'LeaseExpiration') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLeaseExpiration;
  }

  if (type === 'SeismicClaimType') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicClaimType;
  }

  if (type === 'SeismicCurrency') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicCurrency;
  }

  if (type === 'SeismicDivision') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicDivision;
  }

  if (type === 'SeismicLineType') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicLineType;
  }

  if (type === 'SeismicOML') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicOML;
  }

  if (type === 'SeismicProgram') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicProgram;
  }

  if (type === 'SeismicParty') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicParty;
  }

  if (type === 'SeismicSwathNo') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicSwathNo;
  }

  if (type === 'SeismicCommunity') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicCommunity;
  }

  if (type === 'SeismicCrew') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicCrew;
  }

  if (type === 'SeismicDamageList') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicDamageList;
  }

  if (type === 'SeismicSwathCalc') {
    //console.log('Type Passed in Selector Function', type);
    return getGatewayStateSeismicSwathCalc;
  }

  if (type === 'LandTitle') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLandTitle;
  }

  if (type === 'PipelineTemplate') {
    // // //console.log("Type Passed in Selector Function", type);
    return getGatewayStatePipelineTemplate;
  }
  if (type === 'PipelineDetails') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStatePipelineDetails;
  }

  if (type === 'Role') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateRole;
  }
  if (type === 'SeismicApproval') {
    // // console.log('Type Passed in Selector Function',type )
    return getGatewayStateSeismicApproval;
  }
  if (type === 'StaffRoleNew') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateStaffRole;
  }

  if (type === 'BatchData') {
    // // //console.log('Type Passed in Selector Function',type )
    return getGatewayStateBatchData;
  }

  if (type === 'Lessors') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLessors;
  }

  if (type === 'AddressBook') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateAddressBook;
  }

  if (type === 'Supervisor') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateSupervisor;
  }

  if (type === 'NumberOfApproval') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateNumberOfApproval;
  }

  if (type === 'ApprovalLevel') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateApprovalLevel;
  }

  if (type === 'SeisMicSwath') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateSeisMicSwath;
  }

  if (type === 'Program') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateProgram;
  }

  if (type === 'OilMineralLease') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateOilMineralLease;
  }

  if (type === 'ProgramConfig') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateProgramConfig;
  }

  if (type === 'Vendor') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateVendor;
  }

  if (type === 'ImpactedAsset') {
    //console.log('Type Passed in Selector Function',type )
    return getGatewayStateImpactedAsset;
  }

  if (type === 'AreaOfImpact') {
    //console.log('Type Passed in Selector Function',type )
    return getGatewayStateAreaOfImpact;
  }
  if (type === 'DamageAssessment') {
    console.log('Type Passed in Selector Function', type);
    return getGatewayDamageAssessment;
  }
  if (type === 'DamageAssessmentBreakDown') {
    console.log('Type Passed in Selector Function', type);
    return getGatewayStateDamageAssessmentBreakDown;
  }

  if (type === 'DamageAssessmentDraft') {
    console.log('Type Passed in Selector Function', type);
    return getGatewayDamageAssessmentDraft;
  }
  if (type === 'DamageAssessmentBreakDownDraft') {
    console.log('Type Passed in Selector Function', type);
    return getGatewayStateDamageAssessmentBreakDownDraft;
  }

  if (type === 'Party') {
    //console.log('Type Passed in Selector Function',type )
    return getGatewayStateParty;
  }

  if (type === 'Application') {
    //console.log('Type Passed in Selector Function',type )
    return getGatewayStateApplication;
  }
  if (type === 'SeismicContractor') {
    //console.log('Type Passed in Selector Function',type )
    return getGatewayStateSeismicContractor;
  }

  if (type === 'OpllPtsHistory') {
    console.log('Type Passed in Selector Function', type);
    return getGatewayStateOpllPtsHistory;
  }

  if (type === 'ApprovalType') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateApprovalType;
  }
  if (type === 'ApprovalMapping') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateApprovalMapping;
  }
  if (type === 'GenericApprovalLevel') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateGenericApprovalLevel;
  }
  if (type === 'ApprovalSetting') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateApprovalSetting;
  }

  if (type === 'Approval') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateApproval;
  }
  if (type === 'StaffInfo') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateStaffInfo;
  }
  if (type === 'LeaveRequest') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateLeaveRequest;
  }
  if (type === 'RequestBreakDowns') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateRequestBreakDowns;
  }

  if (type === 'Stafftrainingplan') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayStateStafftrainingplan;
  }
  if (type === 'Requisition') {
    return getGatewayStateRequisition;
  }

  if (type === 'QMSObjectiveType') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayQMSObjectiveType;
  }

  if (type === 'QMSObjectiveActivity') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayQMSObjectiveActivity;
  }

  if (type === 'QMSObjective') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayQMSObjective;
  }

  if (type === 'QMSObjectivePerformance') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayQMSObjectivePerformance;
  }

  if (type === 'QMSRespondents') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayQMSRespondents;
  }

  if (type === 'ApprovalDelegate') {
    //console.log("Type Passed in Selector Function", type);
    return getGatewayApprovalDelegate;
  }
}

export const getAllGateway = (type) => {
  // // //console.log('Type Passed in Selector 2',type )

  return createSelector(switchStateSlice(type), (state: GatewayState) =>
    selectAll(state)
  );
};

export const getGatewayError = createSelector(
  getGatewayState,
  (state: GatewayState) => state.error
);

export const loadingGateway = (type) => {
  return createSelector(
    switchStateSlice(type),
    (state: GatewayState) => state.loaded
  );
};

export const genericFilterGateway = (type) => {
  // // //console.log('Type Passed in Selector 2',type)

  return createSelector(switchStateSlice(type), (state: GatewayState) =>
    selectAll(state)
  );
};

export const getGatewayEntities = createSelector(
  getGatewayState,
  (state: GatewayState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getGatewayState,
  (state: GatewayState) => state.selectedId
);

export const getSelected = createSelector(
  getGatewayEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

const getError = (state: GatewayState): string => state.error;

const getStarted = (state: GatewayState): boolean =>
  state.status === UploadStatus.Started;

const getRequested = (state: GatewayState): boolean =>
  state.status === UploadStatus.Requested;

const getReady = (state: GatewayState): boolean =>
  state.status === UploadStatus.Ready;

const getProgress = (state: GatewayState): number => state.progress;

const getInProgress = (state: GatewayState): boolean =>
  state.status === UploadStatus.Started && state.progress >= 0;

const getFailed = (state: GatewayState): boolean =>
  state.status === UploadStatus.Failed;

const getCompleted = (state: GatewayState): boolean =>
  state.status === UploadStatus.Completed;

export const selectUploadFileError: MemoizedSelector<object, string> =
  createSelector(getGatewayState, getError);

export const selectUploadFileReady: MemoizedSelector<object, boolean> =
  createSelector(getGatewayState, getReady);

export const selectUploadFileRequested: MemoizedSelector<object, boolean> =
  createSelector(getGatewayState, getRequested);

export const selectUploadFileStarted: MemoizedSelector<object, boolean> =
  createSelector(getGatewayState, getStarted);

export const selectUploadFileProgress: MemoizedSelector<object, number> =
  createSelector(getGatewayState, getProgress);

export const selectUploadFileInProgress: MemoizedSelector<object, boolean> =
  createSelector(getGatewayState, getInProgress);

export const selectUploadFileFailed: MemoizedSelector<object, boolean> =
  createSelector(getGatewayState, getFailed);

export const selectUploadFileCompleted: MemoizedSelector<object, boolean> =
  createSelector(getGatewayState, getCompleted);
