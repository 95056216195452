import { BaseModel } from './base';

export class PermitToWork extends BaseModel {
  Permitnumber: string;
  LocatiionOfWork: string;
  ContactName: string;
  ContactPhoneNumber: string;
  SummaryOfWork: string;
  SuspendedDuringCourse: string;
  EquipmentTobeWithdrawn: string;
  Supervision: string;
  SafetyWarning: string;
  StepTaken: string;
  SafetyMeasures: string;
  subscriberId: string;
  siteId: string;
  Status: string;
  StaffId: string;
  StaffName: string;
  createdDate: Date;
  approvalpersonId: string;
  approvedDate: Date;
  approvedTime: string;
  modificationService: string;
  safetyImplication: string;
  lockOff: string;
  safetySign: string;
  airMonitoring: string;
  hazardsassociated: string;
  location: string;
  safetyprecautions: string;
  fromDate: Date;
  fromTime: string;
  toDate: Date;
  toTime: string;
  completionDate: Date;
  completionTime: string;
  reinstatementDate: Date;
  reinstatementTime: string;
}
