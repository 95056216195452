import { BaseModel } from './base';

export class SlicklineItemCategory extends BaseModel {
  SlicklineItemCategoryName: string;
  SlicklineTypeId: string;
  SlicklineTypeName: string;
  SubscriberId: string;
  siteId: string;
  id: string;
}
