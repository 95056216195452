import { BaseModel } from './base';

export class RDIWeeklyReport extends BaseModel {
  fromDate?: Date;
  toDate: Date;
  clientsInfo: Array<ClientDetails>;
  overseasPurchase: Array<OverseasPurchaseDetails>;
  faultyEquipment: Array<FaultyEquipmentDetails>;
  expectedJobs: Array<ExpectedJobsDetial>;
  invoiceSent: Array<InvoiceSentDetail>;

  rdiUnitStaff: Array<rdiUnitStaffDetails>;
  SubscriberId: string;
  SiteId: string;
  dateCreated: Date;
  dateModified: Date;
}

export class rdiUnitStaffDetails {
  staff: string;
  days: [
    {
      day: string;
      activity: string;
    }
  ];
}

export class InvoiceSentDetail {
  client: string;
  jobDescriptions: string;
  USD: number;
  Naira: number;
  invoiceNumber: string;
}
export class ExpectedJobsDetial {
  client: string;
  description: string;
  status: string;
  clientRep: string;
  laserRep: string;
}
export class FaultyEquipmentDetails {
  equipment: string;
  descriptionOfFault: string;
  Recommendation: string;
}

export class OverseasPurchaseDetails {
  itemDescriptions: string;
  supplier: string;
  cost: number;
  payment: number;
  balance: number;
  status: string;
}

export class ClientDetails {
  client: string;
  sampleJobInfo: string;
  location: string;
  achievement: string;
  remarks: string;
}
