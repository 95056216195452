import { BaseModel } from './base';

export class ProcurementWeeklyReport extends BaseModel {
  fromDate: Date;
  toDate: Date;
  date: Date;
  logistics: Array<LogisticDetails>;
  foreignPurchasedEquipment: Array<ForeignPurchasedEquipmentDetails>;
  pvtLab: Array<PVTLabDetails>;
  rdiLab: Array<RDILabDetails>;
  environmentalLab: Array<EnvironmentalLabDetails>;
  slicklineLab: Array<SlicklineLabDetails>;
  admin: Array<AdminDetails>;
  actionBy: string;
  comments: string;
  reportedBy: string;
  SubscriberId: string;
  SiteId: string;
  dateCreated: Date;
  dateModified: Date;
}
export class LogisticDetails {
  activities: string;
  remarks: string;
}
export class ForeignPurchasedEquipmentDetails {
  activities: string;
  remarks: string;
}
export class PVTLabDetails {
  activities: string;
  remarks: string;
}
export class RDILabDetails {
  activities: string;
  remarks: string;
}
export class EnvironmentalLabDetails {
  activities: string;
  remarks: string;
}
export class SlicklineLabDetails {
  activities: string;
  remarks: string;
}
export class AdminDetails {
  activities: string;
  remarks: string;
}
