import { StaffInfo } from './staffinfo';

export class JobExecutionPlan {
  client: string;

  location: string;

  field: string;

  date: string;

  crew: [{ staffId: StaffInfo }];

  responsibilities: string[];

  dateCreated: string;

  dateModified: string;

  subscriberId: string;

  siteId: string;

  id: string;
}
export class Responsibilities {
  descriptionResponsibility: string;
  actionParty: string;
  sign: string;
  date: string;
  remark: string;
}
