import { BaseModel } from './base';

export class Meeting   extends BaseModel{
  meetingVenue: string;
  meetingId: string;
  subject: string;
  meetingChannel: string;
  startDate: Date;
  endDate: Date;
  departments:any[];
  permissionLevel:string;
  startTime: string;
  endTime: string;
  description: string;
  staffs: any[];
  reminderInDays: number;
  meetingType: string;
  meetingLink: string;
  status: string;
  host: any[];
  filePath: string;
  LogoPath: string;
  secretary: any[];
  meetingDay: string;
  meetingWeek: string;
  subscriberId: string;
  siteId: string;
  createdDate: Date;
  modifiedDate: Date;
  createdByName: string;
  createdById: string;
  modifiedByName: string;
  modifiedById: string;
  fileUrl: string;
  minutes: string;
  listId: string;
  listName: string;
}
