import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailtemp',
  templateUrl: './emailtemp.component.html',
  styleUrls: ['./emailtemp.component.scss'],
})
export class EmailtempComponent implements OnInit {
  subject = 'Job Application Update.';
  message = `Hello, we want to let you know that we have received your job 
    application in our compony for the position of a software engineer.
    Our HR department will reach out to you once they are done evaluating
     your requirement if you are a match for the role.`;
  constructor() {
    //
  }

  ngOnInit() {
    //
  }
}
