import { BaseModel } from './base';
import { SlicklineItem } from './slicklineitems';

export class PreventiveMaintenanceChecklist extends BaseModel {
  Date: Date;
  Equipment: string;
  LastMaintainedDate: Date;
  InspectedBy: string;
  PlateNo: string;
  Designation: string;
  DriversName: string;
  SerialNumber: string;
  SlicklineItem: SlicklineItem;
  SupervisedBy: Array<{
    SupervisorId: string;
    SupervisorName: string;
    Status: string;
    Remarks: string;
    Datesupervised: string;
  }>;
  ApprovedBy: Array<{
    ApprovalId: string;
    ApprovalName: string;
    Status: string;
    Remarks: string;
  }>;
  DateApproved: Date;
  DateSupervised: Date;
  Comments: string;
  LoginStaff: string;
  subscriberId: string;
  Type: string;
  siteId: string;
  id: string;
}
