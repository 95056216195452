 
    <div class="nav_header">
        <nav class="navigations">
            <div class="navigation_logo">MRsoftEBS</div>

            <div class="nav_list">
                <ul class="nav_links">
                    <li class="nav_link">Home</li>
                    <li class="nav_link">About</li>
                    <li class="nav_link">Partners</li>
                    <li class="nav_link">Link</li>
                    <li class="nav_link">Link</li>

                    <span class="links">
                        <li class="nav_link">Signup</li>
                        <li class="nav_link">Login</li>
                    </span>
                </ul>
            </div>

        </nav>

        <div class="caption">
            <h3 class="main_caption">
                <span>Lorem ipsum dolor sit</span>
                <span>amet consectetur adipisicing</span>
                <span>elit Quidem, dolorem?</span>
            </h3>
            <p class="secondary_caption">But I must explain to you how all this mistaken idea of denouncing</p>
        </div>

        <div class="the__action_btn">
            <button class="the_first-btn">Register</button>
            <button class="the_second-btn">Request Demo</button>
        </div>
        <div class="hero_img"></div>

        <div class="spans"></div>
    </div>



    <router-outlet></router-outlet> 
