import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMenuComponent implements OnInit {
  @Input() module$: Observable<{
    itemIcon: string;
    itemLink: string;
    itemName: string;
    moduleActions: Array<any>;
  }>;
  @Input() module;
  @Input() isClient;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.module$.subscribe((module) => {
      this.module = module;
      this.cd.detectChanges();
      console.log('Module in Sub: ', module);
    });
  }
}
