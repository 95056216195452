import { BaseModel } from './base';
import { Document } from './document';
export class PvtSample extends BaseModel {
  uniqueId: string;
  client: string;
  dateOfAnalysis: Date;
  field: string;
  well: string;
  sampleInformation: string;
  sampleNature: string;
  scopeOfWork: string;
  bottleNumber: string;
  samplingDate: Date;
  receivedBy: string;
  remarks: string;
  analysedBy: string;
  jobStatus: string;
  CCESheet: Array<any>;
  CellTestSheet: Array<any>;
  CVCSheet: Array<any>;
}

export class PvtJobJacket extends BaseModel {
  uniqueJobId: string;
  client: string;
  id: string;
  field: string;
  well: string;
  jobStatus: string;
  receivedBy: string;
  samples: PvtSample[];
}
