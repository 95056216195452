import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';

import { User, UserDto } from '@mrsoftebs/data';
import { GatewayFacade } from 'libs/gateway/src/lib/states/+gateway/gateway.facades';
import Swal from 'sweetalert2';

import { GatewayService } from 'libs/gateway/src/lib/services/gateway.service';
import { UserService } from 'libs/staffroles/src/lib/services/user.service';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
})
export class ManageComponent implements OnInit {
  public href = '';

  changePassowrdform: FormGroup;

  userId = '';
  Users: User[] = [];
  User: User;
  tokenIsValid = false;
  tokenIsExpire = false;
  passwordMissMatch = false;
  isForgotPassword = true;
  userEmail = '';
  password = '';
  confirmedPassword = '';
  token = '';
  submitted = false;
  forgotsubmitted = false;
  userUpdate = {
    id: '',
    Id: '',
    // UserName: '',
    // PhoneNo: '',

    // password: ,

    Email: '',
    // UserType: '',
    // SubscriberName: '',
    // FirstName: '',
    // LastName: '',
    // MiddleName: '',
    StaffName: '',
    // Logo: '',
    // VerifyStatus: '',
    // IsConfirmed: '',
    // UIN: '',
    // NewUser: '',
    // spouseName: '',
    // noOfChildren: 0,
    // address: '',
    // gender: '',
    // ocupation: '',
    // userRole: '',
    // slogan: '',
    // siteId: '',
    // siteName: '',
    // siteNumber: '',
    // levelCount: 0,
    // canAddSub: false,
    // siteGenericNumber: '',
    // systemAdminStatus: '',
    resetPasswordToken: '',
    resetPasswordExpires: new Date(),
    // ParentId: '',
  };

  form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
  });
  isProcessing = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private gatewayFacade: GatewayFacade,
    public gatewayService: GatewayService,
    public authService: AuthService,
    private _router: Router,
    private http: HttpClient,
    private msb: MatSnackBar,
    private userService: UserService
  ) {
    this.route.params.subscribe((params) => {
      console.log('Param ', params);

      if (params.id !== undefined) {
        this.isForgotPassword = false;
      }

      this.userId = params.id;
      this.token = params.token;
    });
  }

  ngOnInit() {
    this.changePassowrdform = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=.*[$@$!%*?&,./#])(?=[^A-Z]*[A-Z]).{8,30}$/
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.MustMatch('password', 'confirmPassword'),
      }
    );
    this.checkTokenValidity();
    // this.loadAllUsers();

    const location = window.location.href.split('#')[0];

    this.href = location;

    console.log(window.location.href, location);
  }

  checkTokenValidity() {
    // Get the user
    if (this.userId && this.token) {
      this.http
        .get<any>(environment.apiUrl + 'user/findone/' + this.userId)
        .subscribe((user) => {
          this.tokenIsValid = true;
          if (this.token === this.User.resetPasswordToken) {
            this.tokenIsValid = true;
          } else {
            this.tokenIsValid = false;
          }
          // console.log('User: ', user);

          const varDate = new Date(user.resetPasswordExpires); //dd-mm-YYYY
          const today = new Date();
          // console.log('Date: ', today, '     ......expire Date: ', varDate);
          if (varDate >= today) {
            this.tokenIsExpire = false;
            // console.log('Expired token: ', this.tokenIsExpire);
            // console.log('.isForgot Password: ', this.isForgotPassword);
            // console.log('token Is Valid: ', this.tokenIsValid);
          } else {
            this.tokenIsExpire = true;
            // console.log('  token Not Expired: ', this.tokenIsExpire);
            // console.log('  isForgot Password: ', this.isForgotPassword);
            // Show Message
          }
        });
    }
  }

  get controls() {
    return this.changePassowrdform.controls;
  }

  get forgotpass() {
    return this.form.controls;
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  loadAllUsers() {
    this.gatewayFacade.loadGateway('IdentityUser').subscribe((data) => {
      if (data.length > 0) {
        this.Users = data;

        const getUser = this.Users.find((n) => n.Id === this.userId);
        this.User = Object.assign({}, getUser);
        console.log('User on Load: ', this.User);
        if (this.User !== undefined) {
          //let
          this.userUpdate.Id = this.User.Id;

          this.userUpdate.Email = this.User.Email;
          // this.userUpdate.UserName = this.User.UserName;
          // this.userUpdate.PhoneNo = this.User.PhoneNo;
          // this.userUpdate.StaffName = this.User.StaffName;

          const varDate = new Date(this.User.resetPasswordExpires); //dd-mm-YYYY
          const today = new Date();

          if (varDate >= today) {
            this.tokenIsExpire = false;
          } else {
            this.tokenIsExpire = true;
            // Show Message
          }

          console.log('Gotten Date ', this.User);

          if (this.token === this.User.resetPasswordToken) {
            this.tokenIsValid = true;
          } else {
            this.tokenIsValid = false;
            // Show Message
          }

          console.log(
            'Token Prop ',
            this.token,
            this.tokenIsExpire,
            this.tokenIsValid
          );
        }

        console.log('IdentityUser ', data, getUser, this.userUpdate);
      }
    });
  }

  formInputChange() {
    this.submitted = false;
  }

  formForgotInputChange() {
    this.forgotsubmitted = false;
  }

  changePassword() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePassowrdform.invalid) {
      console.log('Not Valid :', this.changePassowrdform.value);
      return;
    }

    this.password = this.changePassowrdform.value.password;
    this.confirmedPassword = this.changePassowrdform.value.confirmPassword;

    if (this.password !== '') {
      console.log(
        'Password is not empty ',
        this.confirmedPassword,
        this.password
      );
      if (this.confirmedPassword === this.password) {
        // let password = "ThankYouJesus";

        console.log('Reaching Click ');

        this.gatewayService.isLoading$.next(true);
        this.userUpdate['password'] = this.password;
        this.userUpdate.id = this.userId;
        console.log('User for Update ', this.userUpdate);
        // this.gatewayFacade.update('IdentityUser', [this.userUpdate], []);
        this.userService.changePassword(this.userUpdate).subscribe((user) => {
          console.log('User: ', user);
          this.msb.open(
            user.message + ' You will be redirected to login page.',
            'close',
            { duration: 5000 }
          );
          setTimeout(() => {
            this._router.navigate(['auth/login']);
          }, 4000);
        });
        this.gatewayService.isLoading$.pipe(take(2)).subscribe((val) => {
          // console.log("Is Loading value ", val);
          if (val === false) {
            //this._router.navigate(['/auth/login'])
          }
        });
      } else {
        this.passwordMissMatch = true;
        // Show Message
      }
    } else {
      Swal.fire(
        'No Password!',
        'Please enter password to continue.',
        'warning'
      );
    }

    // hash password here and update with gateway
    // Always genearte a token and send with every password request

    // let confirmedPassword = aspnetidentity.hashPassword(password);
    // this.User.PasswordHash = confirmedPassword;
  }

  generateRandomString(length, randomString = '') {
    randomString += Math.random().toString(20).substr(2, length);
    if (randomString.length > length) return randomString.slice(0, length);
    return this.generateRandomString(length, randomString);
  }

  forgotPassword() {
    this.forgotsubmitted = true;
    this.isProcessing = true;
    // stop here if form is invalid

    console.log('Reaching Forgot Password', this.form.invalid);
    if (this.form.invalid) {
      return;
    }

    this.userEmail = this.form.value.username;

    console.log('Check Email ', this.userEmail, this.userUpdate.Email);

    // Get User in the

    if (this.userEmail !== '') {
      // let foundUser = this.Users.find((n) => n.Email === this.userEmail);
      console.log('useremail: ', this.userEmail);
      this.userService.findByEmail(this.userEmail).subscribe((user) => {
        console.log('Found user @Filter: ', user);
        if (user !== undefined) {
          const foundUser = user;
          this.userUpdate.Id = foundUser._id;

          this.userUpdate.Email = foundUser.Email;
          // this.userUpdate.UserName = foundUser.UserName;
          // this.userUpdate.PhoneNo = foundUser.PhoneNo;
          this.userUpdate.StaffName = foundUser.StaffName;

          const date = new Date();
          console.log('Date: ', date);
          date.setDate(date.getDate() + 2);
          console.log('Date 1: ', date);

          this.userUpdate.resetPasswordExpires = new Date(date);
          this.userUpdate.resetPasswordToken = this.generateRandomString(35);

          // Send Mail with Link

          const mailData = {
            fromEmail: '',
            toEmail: this.userEmail,
            subject: 'Manage Account',
            task: '',
            responsibility: '',
            pipelinename: '',
            pipelinerequestdesc: '',
            period: '',
            message: '',
            salutation: '',
            footer: '',
            url: '',
          };

          const salute = 'Dear ' + this.userUpdate.StaffName + ' ,';

          const conformButtonMsg = '';

          mailData.salutation = salute;

          mailData.message = `Click on the link below to change your password. ${
            this.href +
            '#/auth/manage/' +
            foundUser._id +
            '/' +
            this.userUpdate.resetPasswordToken
          }`;

          mailData.footer = '';

          // console.log('User update: ', this.userUpdate);

          this.userUpdate.resetPasswordToken;
          const linkURL =
            this.href +
            '#/auth/manage/' +
            foundUser._id +
            '/' +
            this.userUpdate.resetPasswordToken;
          mailData.url = linkURL;

          // console.log('Sending mail ', mailData);
          this.gatewayService
            .sendManageAccountMail(mailData)
            .subscribe((data) => {
              //
            });

          console.log('User for Update ', this.userUpdate);
          this.gatewayFacade.update('IdentityUser', [this.userUpdate], []);
          this.isProcessing = false;
        } else {
          this.isProcessing = false;
          Swal.fire(
            'Not Found!',
            'Sorry, the email supplied does not exist.',
            'warning'
          );
        }
      });
    } else {
      this.isProcessing = false;
      Swal.fire(
        'No Email!',
        'Please enter an email address to continue.',
        'warning'
      );
    }
  }

  login() {
    //
  }
}
