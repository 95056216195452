import { BaseModel } from './base';

export class   HrDocument extends BaseModel{
  documentId: string;

  documentName: string;

  documentTypeId: DocumentType;

  documentUrl: string;

  remarks: string;

  dateCreated: string;

  dateModified: string;
}
