import { BaseModel } from './base';

export class QMSActivity extends BaseModel {
  activityId: string;

  activityName: string;

  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
}
