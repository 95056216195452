import { BaseModel } from './base';

export class MaritalStatus extends BaseModel {
  maritalStatusId: string;

  maritalStatusName: string;

  subscriberId: string;

  siteId: string;
  id: string;
  _id?: string;
}
