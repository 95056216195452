import { BaseModel } from './base';

export class  FeedBack  extends BaseModel{
  companyName: string;

  companyRepresentative: string;

  companyRepPosition: string;

  message: string;

  dateCreated: string;

  dateModified: string;

  subscriberId: string;

  siteId: string;

  targetId: string;

  userId: string;
  id: string;
}
