import { PayCategoryType } from './paycategorytype';

import { BaseModel } from './base';

export class PayCategory extends BaseModel {
  payCategoryName: string;
  percentageOfBasicSalary: number;
  subscriberId: string;
  siteId: string;
  payCategoryType: PayCategoryType;
}
