import { User } from './user';

import { BaseModel } from './base';

export class JobOpenning extends BaseModel {
  title: string;
  description: string;
  location: string;
  openingDate: Date;
  closingDate: Date;
  numberOfApplicants: number;
  subscriberId: string;
  siteId: string;
  userId: User | string;
  id: string;
  _id: string;
}
