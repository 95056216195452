import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Credentials, User, UserDto } from '@mrsoftebs/data';
import { ActivatedRoute } from '@angular/router';
import { Guid } from 'guid-typescript';

import { AuthService } from '../services/auth.service';

import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../../apps/ebs/src/app/services/authentication.service';

@Component({
  selector: 'bc-login-form',
  templateUrl: './login-form.component.html',

  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        margin: 72px 0;
      }

      .mat-form-field {
        width: 100%;
        min-width: 300px;
      }

      mat-card-title,
      mat-card-content {
        display: flex;
        justify-content: center;
      }

      .loginError {
        padding: 16px;
        width: 300px;
        color: white;
        background-color: red;
      }

      .loginButtons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    `,
  ],
})
export class LoginFormComponent implements OnInit {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() errorMessage: string | null;

  @Output() submitted = new EventEmitter<Credentials>();
  @Output() registeredsubmitted = new EventEmitter<UserDto>();

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  registerForm: FormGroup;

  isSignIn = true;
  spin = true;
  disabled = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public authService: AuthService,
    private router: Router,
    public authServ: AuthenticationService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id === 'signup') {
        this.isSignIn = false;
      } else {
        this.isSignIn = true;
      }
    });
  }

  ngOnInit() {
    this.initRegisterForm();
    if (this.authServ.getUser() != null) {
      this.router.navigate(['/']);
    }
  }

  login() {
    if (this.form.valid) {
      //console.log("Reaching login In Form ", this.form.value);

      this.authService.isLoading$.next(true);
      this.submitted.emit(this.form.value);
    }
  }

  initRegisterForm() {
    const id = Guid.create().toString();

    const uin = Math.floor(10000000000000 + Math.random() * 90000000000000);
    const uinString = uin.toString();
    const subscriberId = Math.floor(Math.random() * 1000000000);
    const subscriberMainId = subscriberId.toString();
    this.registerForm = this.fb.group({
      id: [id],
      username: [''],
      phoneNo: ['080'],
      password: [''],
      email: [''],
      userType: ['Subscriber'],
      subscriberName: [''],
      firstName: [''],
      lastName: [''],
      middleName: [''],
      staffName: [''],
      logoURL: [''],
      verifyStatus: [''],
      isConfirmed: [''],
      UIN: [uinString],
      newUser: [''],
      spouseName: [''],
      noOfChildren: [0],
      address: [''],
      gender: [''],
      ocupation: [''],
      userRole: [''],
      slogan: [''],
      SubscriberId: [subscriberMainId],
      siteId: [id],
      siteName: [''],
      siteNumber: [''],
      levelCount: [0],
      canAddSub: [true],
      siteGenericNumber: [''],
      systemAdminStatus: [''],
      resetPasswordToken: [''],
      resetPasswordExpires: ['05/05/1987'],
      parentId: ['4646'],
    });
  }

  register() {
    console.log('User Creation');
    if (this.registerForm.valid) {
      // this.submitted.emit(this.form.value);
      //this.form.controls['staffName'].setValue('');
      this.registerForm.value.staffName = this.registerForm.value.subscriberName;
      this.registeredsubmitted.emit(this.registerForm.value);
    }

    // this.authServ.creatUser(this.registerForm.value).subscribe((data) => {
    //   console.log("Return Data ", data);
    // });
  }

  login2() {
    if (this.form.valid) {
      // this.submitted.emit(this.form.value);
    }

    //  this.authServ.login(this.form.value).subscribe((user) => {

    //   if (localStorage.getItem('loggedInUser')) {
    //     localStorage.removeItem('loggedInUser');
    //     localStorage.setItem('loggedInUser', JSON.stringify(user));
    //     this.router.navigate([ '/dashboard' ]);

    //   } else {
    //     localStorage.setItem('loggedInUser', JSON.stringify(user));
    //   }

    //  });
  }

  showSignUp() {
    this.isSignIn = false;
  }
  showSignIn() {
    this.isSignIn = true;
  }
}
