import { LeaveType } from './leavetype';
import { StaffInfo } from './staffinfo';
import { BaseModel } from './base';

export class LeaveHistory extends BaseModel {
  staffId: StaffInfo | string;

  startDate: string;

  endDate: string;

  leaveTypeId: LeaveType | string;

  noOfDays: string;

  dateApplied: string;

  approvalStatus: string;

  subscriberId: string;

  siteId: string;
  leaveRequestId: string;

  year: string;

  RequestorId: StaffInfo | string;
}
