import { LeaveRequest } from './leaverequest';

import { BaseModel } from './base';

export class LeaveApproval extends BaseModel {
  leaveId: LeaveRequest;

  senderId: string;

  senderName: string;

  sendDate: string;

  sendStatus: string;

  receiverId: string;

  receiverName: string;

  receivedDate: string;

  receivedStatus: string;

  senderRemarks: string;

  receiverRemarks: string;
}
