import { BaseModel } from './base';

export class LeaveConfiguration extends BaseModel {
  staffId: string;

  currentYear: string;

  currentYearLeave: string;

  leaveTaken: string;

  carryOverLeave: string;

  leaveBalance: string;

  additionalBalance: string;

  subscriberId: string;

  siteId: string;
}
