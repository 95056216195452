/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth.guard';
// import { HomepageComponent } from './components/homepage/homepage.component';
import { JobsNavComponent } from 'libs/recruitment/src/lib/components/jobs-nav/jobs-nav.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { LoginComponent } from './components/login/login.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { EmailtempComponent } from './components/emailtemp/emailtemp.component';
import { TableComponent } from './components/table/table.component';
import { UnAuthorizeComponent } from './components/un-authorize/un-authorize.component';
import { AuthGuardNoMenu } from './authNoMenu.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'login/:id', component: LoginComponent, pathMatch: 'full' },
  { path: 'email-temp', component: EmailtempComponent },
  { path: 'table', component: TableComponent },

  {
    path: 'auth',
    loadChildren: () => import('@mrsoftebs/auth').then((m) => m.AuthModule),
  },
  {
    path: 'role',
    loadChildren: () => import('@mrsoftebs/roles').then((m) => m.RoleModule),
  },

  {
    path: 'hr',
    component: TopNavComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'client',
    loadChildren: () => import('@mrsoftebs/client').then((p) => p.ClientModule),
  },

  // {
  //   path: 'applicant',
  //   loadChildren: () =>
  //     import('@mrsoftebs/applicant').then((p) => p.ApplicantModule),
  // },

  {
    path: 'feedback',
    loadChildren: () =>
      import('@mrsoftebs/feedback').then((m) => m.FeedbackModule),
  },

  {
    path: '',
    loadChildren: () => import('@mrsoftebs/applicant').then((p) => p.ApplicantModule),
  },

  {
    path: 'sub',
    component: TopNavComponent,
    children: [
      // {
      //   path: '',

      //   loadChildren: () =>
      //     import('@mrsoftebs/dashboard').then((m) => m.DashboardModule),
      //   canActivate: [AuthGuardNoMenu],
      // },

      {
        path: 'recruitmentadmin',

        loadChildren: () =>
          import('@mrsoftebs/recruitmentadmin').then(
            (m) => m.RecruitmentadminModule
          ),
        canActivate: [AuthGuardNoMenu],
      },

      {
        path: 'finalreport',
        loadChildren: () =>
          import('@mrsoftebs/finalreport').then((m) => m.FinalreportModule),
        canActivate: [AuthGuardNoMenu],
      },
    ],

    canActivate: [AuthGuardNoMenu],
  },
  {
    path: '',
    component: TopNavComponent,
    children: [
      {
        path: '',

        loadChildren: () =>
          import('@mrsoftebs/dashboard').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'policydocument',
        loadChildren: () =>
          import('@mrsoftebs/policy').then((m) => m.DocumentPolicyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'client',
        loadChildren: () =>
          import('@mrsoftebs/client').then((p) => p.ClientModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',

        loadChildren: () =>
          import('@mrsoftebs/profile-settings').then(
            (m) => m.ProfileSettingsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'pvt',
        loadChildren: () => import('@mrsoftebs/pvt').then((m) => m.PvtModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'leave',
        loadChildren: () =>
          import('@mrsoftebs/leave').then((m) => m.LeaveModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'delivery',
        loadChildren: () =>
          import('@mrsoftebs/delivery').then((m) => m.DeliveryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'feedback',
        loadChildren: () =>
          import('@mrsoftebs/feedback').then((m) => m.FeedbackModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'lims',
        loadChildren: () =>
          import('libs/environmental/src/index').then(
            (m) => m.EnvironmentalModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: 'logreporting',
        loadChildren: () =>
          import('@mrsoftebs/reportinglog').then((m) => m.ReportinglogModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'planmeeting',
        loadChildren: () =>
          import('@mrsoftebs/jobexecutionplan').then(
            (m) => m.JobexecutionplanModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'logreporting',
        loadChildren: () =>
          import('@mrsoftebs/reportinglog').then((m) => m.ReportinglogModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('@mrsoftebs/booking').then((m) => m.BookingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'weeklyreport',
        loadChildren: () =>
          import('@mrsoftebs/weeklyreport').then((m) => m.WeeklyreportModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'certpermitrenewal',

        loadChildren: () =>
          import('@mrsoftebs/certpermitrenewal').then(
            (m) => m.CertpermitrenewalModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'tender',

        loadChildren: () =>
          import('@mrsoftebs/tender').then((m) => m.TenderModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'requisition',

        loadChildren: () =>
          import('@mrsoftebs/requisition').then((m) => m.RequisitionModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'weeklyreport',
      //   loadChildren: () =>
      //     import('@mrsoftebs/weeklyreport').then((m) => m.WeeklyreportModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'qms',

        loadChildren: () => import('@mrsoftebs/qms').then((m) => m.QmsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'approval',

        loadChildren: () =>
          import('@mrsoftebs/approval').then((m) => m.ApprovalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'stock',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@mrsoftebs/inventory').then((m) => m.InventoryModule),
      },
      {
        path: 'staffrole',

        loadChildren: () =>
          import('@mrsoftebs/staffroles').then((m) => m.StaffRoleModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'staffrole',

        loadChildren: () =>
          import('@mrsoftebs/staffroles').then((m) => m.StaffRoleModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'customer',

        loadChildren: () =>
          import('@mrsoftebs/customer').then((m) => m.CustomerModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'contract',

        loadChildren: () =>
          import('@mrsoftebs/contract').then((m) => m.ContractInfoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'payroll',

        loadChildren: () =>
          import('@mrsoftebs/payroll').then((m) => m.PayRollModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'references',

        loadChildren: () =>
          import('@mrsoftebs/references').then((m) => m.ReferencesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'staff',

        loadChildren: () =>
          import('@mrsoftebs/staff').then((m) => m.StaffModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'travel',

        loadChildren: () =>
          import('@mrsoftebs/travel').then((m) => m.TravelModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'slickline',

        loadChildren: () =>
          import('@mrsoftebs/slickline').then((m) => m.SlicklineModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'disbursement',
        loadChildren: () =>
          import('@mrsoftebs/disbursement').then((m) => m.DisbursementModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'recruitment',

        loadChildren: () =>
          import('@mrsoftebs/recruitment').then((m) => m.RecruitmentModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'hse',

        loadChildren: () => import('@mrsoftebs/hse').then((m) => m.HseModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'onlinelearning',

        loadChildren: () =>
          import('@mrsoftebs/onlinelearning').then(
            (m) => m.OnlinelearningModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: 'meeting',

        loadChildren: () =>
          import('@mrsoftebs/meeting').then((m) => m.MeetingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'stafftraining',

        loadChildren: () =>
          import('@mrsoftebs/stafftraining').then((m) => m.StafftrainingModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'performance',

        loadChildren: () =>
          import('@mrsoftebs/performance').then((m) => m.PerformanceModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'rd',

        loadChildren: () => import('@mrsoftebs/rd').then((m) => m.RdModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'staffupate',
        loadChildren: () =>
          import('@mrsoftebs/staffupdate').then((m) => m.StaffUpdateModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'bookings',
        loadChildren: () =>
          import('@mrsoftebs/booking').then((m) => m.BookingModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'slickline',

        loadChildren: () =>
          import('@mrsoftebs/slickline').then((m) => m.SlicklineModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'stationaries',

        loadChildren: () =>
          import('@mrsoftebs/stationaries').then((m) => m.StationariesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'eventmanagement',

        loadChildren: () =>
          import('@mrsoftebs/eventmanagement').then(
            (m) => m.EventmanagementModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: 'inventory',

        loadChildren: () =>
          import('@mrsoftebs/inventory').then((m) => m.InventoryModule),
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },

  /** End of TopNav Children Routing */
  {
    path: 'account',
    component: TopNavComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@mrsoftebs/account').then((m) => m.AccountModule),
      },
    ],
  },

  {
    path: 'jobs',
    component: JobsNavComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@mrsoftebs/recruitment').then((m) => m.RecruitmentModule),
      },
    ],
  },
  { path: 'error/unauthorized', component: UnAuthorizeComponent },

  { path: '**', component: ErrorComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
