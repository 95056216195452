import { AcountBanks } from './accountbanks';
import { BaseModel } from './base';

export class ChequesClearedDetails extends BaseModel {
  id: string;
  nameoncheque: string;
  purpose: string;
  bankId: string;
  bankName: string;
  NGN: number;
  USD: number;
}
