import { BaseModel } from './base';

export class  Event  extends BaseModel{
   
    EventName: string;
    EventtypeId:string;
    
    EventtypeName:string;
    SubscriberId: string;

    SiteId: string;
    
    id: string;
}