import { BaseModel } from './base';

export class WeeklyReport extends BaseModel {
  reportCode: string;
  staffId: string;
  staffName: string;
  departmentId: string;
  departmentName: string;
  reportDate: Date;
  reportSubject: string;
  reportStatus: string;
  workdone: string;
  reportfile: string;
  subscriberId: string;
  siteId: string;
  dateCreated: Date;
  dateModified: Date;
  id: string;
}
