/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AngularMaterialModule } from '@mrsoftebs/material';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthEffects } from '../lib/effects';
import * as fromAuth from '../lib/reducers';
import { AuthFacade } from '../lib/facades/auth.facade';

import { AuthorizedGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { TokenInterceptor } from './token.interceptor';
import { ManageComponent } from './components/manage/manage.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { ApplicationContainerComponent } from './containers/application-container/application-container.component';
import { ApplicationComponent } from './components/application/application.component';
import { AddminApplicationComponent } from './components/addmin-application/addmin-application.component';
import { AddminApplicationContainerComponent } from './containers/addmin-application-container/addmin-application-container.component';
// import { SubscriberinfoComponent } from './components/subscriberinfo/subscriberinfo.component';
import {
  LoginFormComponent,
  LogoutConfirmationDialogComponent,
} from './components';
import { LoginPageComponent } from './containers';
import { SubscriberinfoComponent } from './components/subscriberinfo/subscriberinfo.component';
import { SiteTableComponent } from './components/site/siteTable/siteTable.component';
import { SiteComponent } from './components/site/site.component';
import { SiteContainerComponent } from './containers/siteContainer/siteContainer.component';
import { AuthGuard } from '../../../../apps/ebs/src/app/auth.guard';
import { SpinnersModule } from '@mrsoftebs/spinners';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

export const COMPONENTS = [
  LoginPageComponent,
  LoginFormComponent,
  LogoutConfirmationDialogComponent,
  ManageComponent,
  SubscribeComponent,
  ApplicationContainerComponent,
  ApplicationComponent,
  AddminApplicationComponent,
  AddminApplicationContainerComponent,
  SubscriberinfoComponent,
  SiteContainerComponent,
  SiteComponent,
  SiteTableComponent,
  ForgotPasswordComponent,
  ChangePasswordComponent,
];

export const authRoutes: Route[] = [
  { path: 'login', component: LoginPageComponent, data: { title: 'Login' } },

  {
    path: 'manage',
    component: ForgotPasswordComponent,
    data: { title: 'Manage' },
  },
  {
    path: 'login/:id',
    component: LoginPageComponent,
    data: { title: 'Login' },
  },
  {
    path: 'manage/:id/:token',
    component: ForgotPasswordComponent,
    data: { title: 'Manage' },
  },
  {
    path: 'site',
    canActivate: [AuthGuard],
    component: SiteContainerComponent,

    data: { title: 'Site' },
  },
  {
    path: 'subscribe',
    component: SubscribeComponent,
    data: { title: 'Subscribe' },
  },
  {
    path: 'subscriberinfo',
    component: SubscriberinfoComponent,
    data: { title: 'Subscribe' },
  },
  {
    path: 'application',
    component: ApplicationContainerComponent,
    data: { title: 'Application' },
  },
  {
    path: 'addminapplication',
    component: AddminApplicationContainerComponent,
    data: { title: 'Addmapplication' },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(authRoutes),
    HttpClientModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnersModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
    EffectsModule.forFeature([AuthEffects]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: COMPONENTS,
  entryComponents: [LogoutConfirmationDialogComponent],
  providers: [
    AuthFacade,
    AuthorizedGuard,
    AuthService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
