import { BaseModel } from './base';

export class QMSObjectiveType extends BaseModel {
  objectTypeId: string;

  objectTypeName: string;

  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  position: number;
}
