import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobOpenning, LoggedInUser } from '@mrsoftebs/data';
import { LogService } from 'apps/ebs/src/app/services/log.service';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { JobopeningService } from '../../services/jobopening.service';
import { SigninComponent } from '../signin/signin.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-jobs-nav',
  templateUrl: './jobs-nav.component.html',
  styleUrls: ['./jobs-nav.component.scss'],
})
export class JobsNavComponent implements OnInit {
  user: LoggedInUser;
  searchControl: FormControl = new FormControl();
  jobs: any;
  totalJobs = 0;
  currentPage = 0;
  limit = 4;
  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private logService: LogService,
    private accountService: AccountService,
    private jobService: JobopeningService
  ) {}

  ngOnInit() {
    this.user = this.accountService.getLoggedInUser();
    this.searchControl.valueChanges.subscribe((value) => {
      this.logService.log('Data: ', value);
      this.search(value);
    });
  }
  search(value) {
    this.jobService
      .search({ query: value, page: this.currentPage, limit: this.limit })
      .subscribe((data: { totlaJobs: number; jobs: JobOpenning[] }) => {
        this.jobs = data.jobs;
        this.totalJobs = data.totlaJobs;
        this.logService.log('found jobs: ', data);
      });
  }
  jobHome() {
    this._router.navigate(['/jobs/joblist']);
  }
  openJob(job) {
    // To open a selected job
    this.logService.log('Selected Job in nav: ', job);
    localStorage.setItem('selectedJob', job);
    this._router.navigate(['/jobs/detail', job._id]);
  }
  goToSearch(text: string) {
    // to open the job list searching for jobs with title similar to the text
    this.logService.log('Search Text: ', text);
    // this.searchControl.reset();
    // this.searchControl.setValue(text);
    // this.searchControl.updateValueAndValidity();
    // const splittext = text.split('Searching for ')[1];
    let joinedText = text.replace(' ', '_');
    this.logService.log('Search Text: ', joinedText);
    this._router.navigate(['jobs/search', joinedText]);
  }
  signin() {
    this.logService.log('Sign in clicked');
    this.dialog.open(SigninComponent, { data: { returnUrl: '/jobs/joblist' } });
  }
  signup() {
    this.dialog.open(SignupComponent, { data: { returnUrl: '/jobs/joblist' } });
  }

  signout() {
    this.logService.log('Logout clicked');
    this.accountService.logOut('/jobs/joblist');
    window.location.reload();
  }

  myApplications() {
    // Open the list of applications of an applicant
  }
}
