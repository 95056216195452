import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-un-authorize',
  templateUrl: './un-authorize.component.html',
  styleUrls: ['./un-authorize.component.scss'],
})
export class UnAuthorizeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      localStorage.removeItem('module');
      this.router.navigate(['/']);
    }, 5000);
  }
  goHome() {
    this.router.navigate(['/']);
  }
}
