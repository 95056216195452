import { createReducer, on, Action, ActionReducerMap } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as GatewayActions from './gateway.actions';

export const Gateway_FEATURE_KEY = 'gatewaygeneric';
export const Gateway_FEATURE_KEY_Group = 'gatewaygenericgroup';
export const Gateway_FEATURE_KEY_AccountableFunction =
  'gatewaygenericaccountablefunction';
export const Gateway_FEATURE_KEY_Function = 'gatewaygenericfunction';
export const Gateway_FEATURE_KEY_SubGroup = 'gatewaygenericsubgroup';
export const Gateway_FEATURE_KEY_SubSubGroup = 'gatewaygenericsubsubgroup';
export const Gateway_FEATURE_KEY_SubSubGroupType =
  'gatewaygenericsubsubgrouptype';
export const Gateway_FEATURE_KEY_OwnershipType = 'gatewaygenericownershiptype';
export const Gateway_FEATURE_KEY_ClaimType = 'gatewaygenericclaimtype';
export const Gateway_FEATURE_KEY_Terrain = 'gatewaygenericterrain';
export const Gateway_FEATURE_KEY_PaymentStatus = 'gatewaygenericpaymentstatus';
export const Gateway_FEATURE_KEY_Company = 'gatewaygenericcompany';
export const Gateway_FEATURE_KEY_CompanyFunction =
  'gatewaygenericcompanyfunction';
export const Gateway_FEATURE_KEY_DivestmentStatus =
  'gatewaygenericdivestmentstatus';
export const Gateway_FEATURE_KEY_EncroachmentType =
  'gatewaygenericencroachmenttype';
export const Gateway_FEATURE_KEY_EncroachmentStatus =
  'gatewaygenericencroachmentstatus';
export const Gateway_FEATURE_KEY_Title = 'gatewaygenerictitle';
export const Gateway_FEATURE_KEY_DocumentType = 'gatewaygenericdocumenttype';
export const Gateway_FEATURE_KEY_Bank = 'gatewaygenericbank';
export const Gateway_FEATURE_KEY_DivisionTbl = 'gatewaygenericdivisiontbl';
export const Gateway_FEATURE_KEY_Continent = 'gatewaygenericcontinent';
export const Gateway_FEATURE_KEY_FiscalFileLocation =
  'gatewaygenericfiscalfilelocation';
export const Gateway_FEATURE_KEY_GeoDatum = 'gatewaygenericgeodatum';
export const Gateway_FEATURE_KEY_Country = 'gatewaygenericcountry';
export const Gateway_FEATURE_KEY_State = 'gatewaygenericstate';
export const Gateway_FEATURE_KEY_Lga = 'gatewaygenericlga';
export const Gateway_FEATURE_KEY_Community = 'gatewaygenericcommunity';
export const Gateway_FEATURE_KEY_Measure = 'gatewaygenericmeasure';
export const Gateway_FEATURE_KEY_Memo = 'gatewaygenericmemo';
export const Gateway_FEATURE_KEY_Meeting = 'gatewaygenericmeeting';
export const Gateway_FEATURE_KEY_MemoType = 'gatewaygenericmemotype';
export const Gateway_FEATURE_KEY_CertPermitRenewal =
  'gatewaygenericcertpermitrenewal';
export const Gateway_FEATURE_KEY_Certnotificaionmapping =
  'gatewaygenericcertnotificaionmapping';
export const Gateway_FEATURE_KEY_StaffTransfer = 'gatewaygenericstafftransfer';
export const Gateway_FEATURE_KEY_StaffTermination =
  'gatewaygenericstafftermination';
export const Gateway_FEATURE_KEY_StaffQuery = 'gatewaygenericstaffquery';
export const Gateway_FEATURE_KEY_Department = 'gatewaygenericdepartment';
export const Gateway_FEATURE_KEY_Unit = 'gatewaygenericunit';
export const Gateway_FEATURE_KEY_Position = 'gatewaygenericposition';
export const Gateway_FEATURE_KEY_Gender = 'gatewaygenericgender';
export const Gateway_FEATURE_KEY_JobTitle = 'gatewaygenericjobtitle';
export const Gateway_FEATURE_KEY_MaritalStatus = 'gatewaygenericmaritalstatus';
export const Gateway_FEATURE_KEY_EmploymentStatus =
  'gatewaygenericemploymentstatus';
export const Gateway_FEATURE_KEY_StaffInfos = 'gatewaygenericstaffinfos';
export const Gateway_FEATURE_KEY_Purpose = 'gatewaygenericpurpose';
export const Gateway_FEATURE_KEY_AcquisitionType =
  'gatewaygenericacquisitiontype';
export const Gateway_FEATURE_KEY_Currency = 'gatewaygenericcurrency';
export const Gateway_FEATURE_KEY_BankingStatus = 'gatewaygenericbankingstatus';
export const Gateway_FEATURE_KEY_LandUseStatus = 'gatewaygenericlandusestatus';
export const Gateway_FEATURE_KEY_RightHeld = 'gatewaygenericrightheld';
export const Gateway_FEATURE_KEY_SeismicClaimantRef =
  'gatewaygenericseismicclaimantref';
export const Gateway_FEATURE_KEY_Category = 'gatewaygenericcategory';

export const Gateway_FEATURE_KEY_MeasuringFactor =
  'gatewaygenericmeasuringfactor';
export const Gateway_FEATURE_KEY_AppConfiguration =
  'gatewaygenericappconfiguration';
export const Gateway_FEATURE_KEY_CabinetShelfRef =
  'gatewaygenericcabinetshelfref';
export const Gateway_FEATURE_KEY_DocumentTypeCode =
  'gatewaygenericdocumenttypecode';
export const Gateway_FEATURE_KEY_RackTrayRef = 'gatewaygenericracktrayref';
export const Gateway_FEATURE_KEY_SecurityClassification =
  'gatewaygenericsecurityclassification';
export const Gateway_FEATURE_KEY_PLStageActivity =
  'gatewaygenericplstageactivity';
export const Gateway_FEATURE_KEY_PLStakeholderMatrix =
  'gatewaygenericplstakeholdermatrix';
export const Gateway_FEATURE_KEY_PLStakeholderMatrixRaci =
  'gatewaygenericplstakeholdermatrixraci';

export const Gateway_FEATURE_KEY_PipelineLicenseCategory =
  'gatewaygenericpipelinelicensecategory';
export const Gateway_FEATURE_KEY_PipelineLicenseStage =
  'gatewaygenericpipelinelicensestage';
export const Gateway_FEATURE_KEY_PipelineLicenseStakeholder =
  'gatewaygenericpipelinelicensestakeholder';
export const Gateway_FEATURE_KEY_Hierarchy = 'gatewaygenerichierarchy';

export const Gateway_FEATURE_KEY_PaymentType = 'gatewaygenericpaymenttype';
export const Gateway_FEATURE_KEY_PipelineAssetLocation =
  'gatewaygenericpipelineassetlocation';
export const Gateway_FEATURE_KEY_StakeholderResponse =
  'gatewayStakeholderResponse';
export const Gateway_FEATURE_KEY_PipelineSection =
  'gatewaygenericpipelinesection';
export const Gateway_FEATURE_KEY_AccountableFunctionLink2 =
  'gatewaygenericaccountablefunctionlink2';
export const Gateway_FEATURE_KEY_PipelineAssetLink2 =
  'gatewaygenericpipelineassetlink2';
export const Gateway_FEATURE_KEY_VerificationStatus =
  'gatewaygenericverificationstatus';
export const Gateway_FEATURE_KEY_Uom = 'gatewaygenericuom';

export const Gateway_FEATURE_KEY_AssetStatus = 'gatewaygenericassetstatus';
export const Gateway_FEATURE_KEY_DeliveryType = 'gatewaygenericdeliverytype';
export const Gateway_FEATURE_KEY_LicenceStatus = 'gatewaygenericlicencestatus';
export const Gateway_FEATURE_KEY_Network = 'gatewaygenericnetwork';
export const Gateway_FEATURE_KEY_IPStatus = 'gatewaygenericipstatus';
export const Gateway_FEATURE_KEY_PipelineGrade = 'gatewaygenericpipelinegrade';
export const Gateway_FEATURE_KEY_Pigibility = 'gatewaygenericpigibility';
export const Gateway_FEATURE_KEY_StakeholderRep =
  'gatewaygenericstakeholderrep';
export const Gateway_FEATURE_KEY_ActivityActionsPerson =
  'gatewaygenericactivityactionsperson';
export const Gateway_FEATURE_KEY_PipelineLicenceRequest =
  'gatewaygenericpipelinelicencerequest';
export const Gateway_FEATURE_KEY_PipelineLicenceRequestStage =
  'gatewaygenericpipelinelicencerequeststage';
export const Gateway_FEATURE_KEY_PLRStageActivity =
  'gatewaygenericplrstageactivity';
export const Gateway_FEATURE_KEY_PLRStakeholderMatrix =
  'gatewaygenericplrstakeholdermatrix';
export const Gateway_FEATURE_KEY_PLRStakeholderMatrixRaci =
  'gatewaygenericplrstakeholdermatrixraci';
export const Gateway_FEATURE_KEY_UserStaffDetail =
  'gatewaygenericuserstaffdetail';
export const Gateway_FEATURE_KEY_CorrespondenceTeam =
  'gatewaygenericcorrespondenceteam';
export const Gateway_FEATURE_KEY_CorrespondenceActionParty =
  'gatewaygenericcorrespondenceactionParty';
export const Gateway_FEATURE_KEY_PLActivityActionPerson =
  'gatewaygenericplactivityactionperson';
export const Gateway_FEATURE_KEY_IdentityUser = 'gatewaygenericidentityuser';
export const Gateway_FEATURE_KEY_AddressBook = 'gatewaygenericaddressbook';
export const Gateway_FEATURE_KEY_PipelineEncroachment =
  'getgatewayPipelineEncroachment';
export const Gateway_FEATURE_KEY_EncroachmentAsset = 'EncroachmentassetState';
export const Gateway_FEATURE_KEY_EncroachmentAction = 'EncroachmentActionState';
export const Gateway_FEATURE_KEY_Encroacher = 'EncroacherState';
export const Gateway_FEATURE_KEY_Adr = 'gatewaygenericadr';
export const Gateway_FEATURE_KEY_Lorp = 'gatewaygenericlorp';
export const Gateway_FEATURE_KEY_YearOfSuit = 'gatewaygenericyearofsuit';
export const Gateway_FEATURE_KEY_CounselType = 'gatewaygenericcounseltype';
export const Gateway_FEATURE_KEY_SuitCounsel = 'gatewaygenericsuitcounsel';
export const Gateway_FEATURE_KEY_AdrPersonnel = 'gatewaygenericadrpersonnel';
export const Gateway_FEATURE_KEY_AdrAssets = 'gatewaygenericadrassets';
export const Gateway_FEATURE_KEY_PLRActivityActionHistory =
  'gatewaygenericplractivityactionhistory';
export const Gateway_FEATURE_KEY_PipelineDetails =
  'gatewaygenericpipelinedetails';

export const Gateway_FEATURE_KEY_RequestType = 'gatewaygenericrequesttype';
export const Gateway_FEATURE_KEY_CorrespondenceCompany =
  'gatewaygenericcorrespondencecompany';

export const Gateway_FEATURE_KEY_Sex = 'gatewaygenericsex';
export const Gateway_FEATURE_KEY_LandPrefix = 'gatewaygenericlandprefix';
export const Gateway_FEATURE_KEY_TotalSumTable = 'gatewaygenerictotalsumtable';
export const Gateway_FEATURE_KEY_LossOfUseItems =
  'gatewaygenericlossofuseitems';

export const Gateway_FEATURE_KEY_AuditTray = 'gatewaygenericaudittray';
export const Gateway_FEATURE_KEY_DamageAssessmentBreakDown =
  'gatewaygenericdamageassessmentbreakdown';
export const Gateway_FEATURE_KEY_DamageAssessment =
  'gatewaygenericdamageassessment';
export const Gateway_FEATURE_KEY_DamageAssessmentBreakDownDraft =
  'gatewaygenericdamageassessmentbreakdowndraft';
export const Gateway_FEATURE_KEY_DamageAssessmentDraft =
  'gatewaygenericdamageassessmentdraft';
export const Gateway_FEATURE_KEY_SeismicApproval =
  'gatewaygenericseismicapproval';
//eGG
export const Gateway_FEATURE_KEY_DocumentCategory =
  'gatewaygenericdocumentcategory';
export const Gateway_FEATURE_KEY_DamageStatus = 'gatewaygenericdamagestatus';
export const Gateway_FEATURE_KEY_LessorRepType = 'gatewaygenericlessorreptype';
export const Gateway_FEATURE_KEY_Pole = 'gatewaygenericpole';
export const Gateway_FEATURE_KEY_AccessRight = 'gatewaygenericaccessright';
export const Gateway_FEATURE_KEY_CofOStatus = 'gatewaygenericcofostatus';
export const Gateway_FEATURE_KEY_Quantum = 'gatewaygenericquantum';
export const Gateway_FEATURE_KEY_RateStatus = 'gatewaygenericratestatus';
export const Gateway_FEATURE_KEY_SubSubGroupTypeCategory =
  'gatewaygenericsubsubgrouptypecategory';
export const Gateway_FEATURE_KEY_IncidentQuantum =
  'gatewaygenericincidentquantum';
export const Gateway_FEATURE_KEY_IncidentSubCategory =
  'gatewaygenericincidentsubcategory';
export const Gateway_FEATURE_KEY_IncidentCategory =
  'gatewaygenericincidentcategory';
export const Gateway_FEATURE_KEY_ObligationPeriod =
  'gatewaygenericobligationperiod';
export const Gateway_FEATURE_KEY_RequestSubCategory =
  'gatewaygenericrequestsubcategory';
export const Gateway_FEATURE_KEY_RequestCategory =
  'gatewaygenericrequestcategory';

export const Gateway_FEATURE_KEY_CleanUpStatus = 'gatewaygenericcleanupstatus';
export const Gateway_FEATURE_KEY_DamageList = 'gatewaygenericdamagelist';
export const Gateway_FEATURE_KEY_AddressType = 'gatewaygenericaddresstype';
export const Gateway_FEATURE_KEY_ActivityStatus =
  'gatewaygenericactivitystatus';
export const Gateway_FEATURE_KEY_Batch = 'gatewaygenericbatch';

export const Gateway_FEATURE_KEY_ExpirationStatus =
  'gatewaygenericexpirationstatus';
export const Gateway_FEATURE_KEY_FacilityAsset = 'gatewaygenericfacilityasset';
export const Gateway_FEATURE_KEY_IssuedCategory =
  'gatewaygenericissuedcategory';
export const Gateway_FEATURE_KEY_ItemType = 'gatewaygenericitemtype';
export const Gateway_FEATURE_KEY_LeaseExpiration =
  'gatewaygenericleaseexpiration';
export const Gateway_FEATURE_KEY_PipelineTemplate =
  'gatewaygenericpiplinetemplate';
export const Gateway_FEATURE_KEY_LandTitle = 'gatewaygenericlandtitle';
export const Gateway_FEATURE_KEY_SeismicClaimType =
  'gatewaygenericseismicclaimtype';
export const Gateway_FEATURE_KEY_SeismicCurrency =
  'gatewaygenericseismiccurrency';
export const Gateway_FEATURE_KEY_SeismicDivision =
  'gatewaygenericseismicdivision';
export const Gateway_FEATURE_KEY_SeismicLineType =
  'gatewaygenericseismiclinetype';
export const Gateway_FEATURE_KEY_SeismicOML = 'gatewaygenericseismicoml';
export const Gateway_FEATURE_KEY_SeismicProgram =
  'gatewaygenericseismicprogram';
export const Gateway_FEATURE_KEY_SeismicParty = 'gatewaygenericseismicparty';
export const Gateway_FEATURE_KEY_SeismicSwathNo =
  'gatewaygenericseismicswathno';
export const Gateway_FEATURE_KEY_SeismicCrew = 'gatewaygenericseismiccrew';
export const Gateway_FEATURE_KEY_SeismicCommunity =
  'gatewaygenericseismiccommunity';
export const Gateway_FEATURE_KEY_SeismicDamageList =
  'gatewaygenericseismicdamagelist';
export const Gateway_FEATURE_KEY_SeismicSwathCalc =
  'gatewaygenericseismicswathcalc';

export const Gateway_FEATURE_KEY_Role = 'gatewayrole';
export const Gateway_FEATURE_KEY_Staffrole = 'gatewaystaffrole';
export const Gateway_FEATURE_KEY_BatchData = 'gatewaybatchdata';

export const Gateway_FEATURE_KEY_Lessors = 'gatewaygenericlessors';

export const Gateway_FEATURE_KEY_ApprovalLevel = 'gatewaygenericapprovallevel';
export const Gateway_FEATURE_KEY_Supervisor = 'gatewaygenericsupervisor';
export const Gateway_FEATURE_KEY_NumberOfApproval =
  'gatewaygenericnumberofapproval';
export const Gateway_FEATURE_KEY_SeisMicSwath = 'gatewaygenericseismicswath';
export const Gateway_FEATURE_KEY_Program = 'gatewaygenericprogram';
export const Gateway_FEATURE_KEY_OilMineralLease =
  'gatewaygenericoilminerallease';
export const Gateway_FEATURE_KEY_ProgramConfig = 'gatewaygenericprogramconfig';
export const Gateway_FEATURE_KEY_Vendor = 'gatewaygenericvendor';
export const Gateway_FEATURE_KEY_AreaOfImpact = 'gatewaygenericareaofimpact';
export const Gateway_FEATURE_KEY_ImpactedAsset = 'gatewaygenericimpactedasset';
export const Gateway_FEATURE_KEY_Party = 'gatewaygenericparty';
export const Gateway_FEATURE_KEY_Application = 'gatewaygenericapplication';
export const Gateway_FEATURE_KEY_SeismicContractor =
  'gatewaygenericseismiccontractor';
export const Gateway_FEATURE_KEY_OpllPtsHistory =
  'gatewaygenericopllptshistory';
export const Gateway_FEATURE_KEY_ApprovalType = 'gatewaygenericapprovaltype';
export const Gateway_FEATURE_KEY_GenericApprovalLevel =
  'gatewaygenericgenericapprovallevel';
export const Gateway_FEATURE_KEY_ApprovalMapping =
  'gatewaygenericapprovalmapping';
export const Gateway_FEATURE_KEY_ApprovalSetting =
  'gatewaygenericapprovalsetting';
export const Gateway_FEATURE_KEY_Approval = 'gatewaygenericapproval';
export const Gateway_FEATURE_KEY_StaffInfo = 'gatewaygenericstaffinfo';
export const Gateway_FEATURE_KEY_RequestBreakDowns =
  'gatewaygenericsrequestbreakdown';
export const Gateway_FEATURE_KEY_LeaveRequest = 'gatewaygenericleaverequest';
export const Gateway_FEATURE_KEY_Requisition = 'gatewaygenericrequisition';
export const Gateway_FEATURE_KEY_Stafftrainingplan =
  'gatewaygenericstafftrainingplan';
export const Gateway_FEATURE_KEY_qmsObjectiveType =
  'gatewaygenericqmsobjectivetype';
export const Gateway_FEATURE_KEY_qmsObjectiveactivity =
  'gatewaygenericqmsobjectiveactivity';
export const Gateway_FEATURE_KEY_qmsObjective = 'gatewaygenericqmsobjective';
export const Gateway_FEATURE_KEY_qmsObjectivePerformance =
  'gatewaygenericqmsobjectiveperformance';

export const Gateway_FEATURE_KEY_QMSRespondents =
  'gatewaygenericQMSRespondents';

export const Gateway_FEATURE_KEY_ApprovalDelegate =
  'gatewaygenericApprovalDelegate';

export function Gateway_FeatureKey(key: string) {
  return key;
}

export enum UploadStatus {
  Ready = 'Ready',
  Requested = 'Requested',
  Started = 'Started',
  Failed = 'Failed',
  Completed = 'Completed',
}

export interface GatewayState extends EntityState<any> {
  selectedId?: string | number; // which Model Type record has been selected
  loaded: boolean; // has the Model list been loaded
  error?: string | null; // last none error (if any)
  status: UploadStatus;
  progress: number | null;
}

export interface GatewayPartialState {
  readonly [Gateway_FEATURE_KEY]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Group]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AccountableFunction]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Function]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SubGroup]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SubSubGroup]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SubSubGroupType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_OwnershipType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ClaimType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Terrain]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PaymentStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Company]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CompanyFunction]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DivestmentStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_EncroachmentStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Title]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DocumentType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Bank]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DivisionTbl]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Continent]: GatewayState;
  readonly [Gateway_FEATURE_KEY_FiscalFileLocation]: GatewayState;
  readonly [Gateway_FEATURE_KEY_GeoDatum]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Country]: GatewayState;
  readonly [Gateway_FEATURE_KEY_State]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Lga]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Community]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AddressBook]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Measure]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Purpose]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AcquisitionType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Currency]: GatewayState;
  readonly [Gateway_FEATURE_KEY_BankingStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LandUseStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_RightHeld]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Category]: GatewayState;
  readonly [Gateway_FEATURE_KEY_MeasuringFactor]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicClaimantRef]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AppConfiguration]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CabinetShelfRef]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DocumentTypeCode]: GatewayState;
  readonly [Gateway_FEATURE_KEY_RackTrayRef]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SecurityClassification]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLStageActivity]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLStakeholderMatrix]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLStakeholderMatrixRaci]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Memo]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Meeting]: GatewayState;
  readonly [Gateway_FEATURE_KEY_MemoType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CertPermitRenewal]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Certnotificaionmapping]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StaffTransfer]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StaffTermination]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StaffQuery]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StaffInfos]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Department]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Unit]: GatewayState;

  readonly [Gateway_FEATURE_KEY_Position]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Gender]: GatewayState;
  readonly [Gateway_FEATURE_KEY_JobTitle]: GatewayState;
  readonly [Gateway_FEATURE_KEY_MaritalStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_EmploymentStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineLicenseCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineLicenseStage]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineLicenseStakeholder]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Hierarchy]: GatewayState;

  readonly [Gateway_FEATURE_KEY_PaymentType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineSection]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AccountableFunctionLink2]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineAssetLink2]: GatewayState;
  readonly [Gateway_FEATURE_KEY_VerificationStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Uom]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineAssetLocation]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StakeholderResponse]: GatewayState;

  readonly [Gateway_FEATURE_KEY_AssetStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DeliveryType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_IPStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineGrade]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Pigibility]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StakeholderRep]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ActivityActionsPerson]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineLicenceRequest]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineLicenceRequestStage]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLRStageActivity]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLRStakeholderMatrix]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLRStakeholderMatrixRaci]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CorrespondenceActionParty]: GatewayState;
  readonly [Gateway_FEATURE_KEY_UserStaffDetail]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLActivityActionPerson]: GatewayState;
  readonly [Gateway_FEATURE_KEY_EncroachmentAsset]: GatewayState;

  readonly [Gateway_FEATURE_KEY_PipelineEncroachment]: GatewayState;
  readonly [Gateway_FEATURE_KEY_EncroachmentAction]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Encroacher]: GatewayState;

  readonly [Gateway_FEATURE_KEY_Adr]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Lorp]: GatewayState;
  readonly [Gateway_FEATURE_KEY_YearOfSuit]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CounselType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SuitCounsel]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AdrPersonnel]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AdrAssets]: GatewayState;
  readonly [Gateway_FEATURE_KEY_EncroachmentType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLRStageActivity]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ActivityActionsPerson]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineDetails]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineAssetLocation]: GatewayState;

  readonly [Gateway_FEATURE_KEY_RequestType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CorrespondenceCompany]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CorrespondenceTeam]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Sex]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LandPrefix]: GatewayState;
  readonly [Gateway_FEATURE_KEY_TotalSumTable]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LossOfUseItems]: GatewayState;
  readonly [Gateway_FEATURE_KEY_IdentityUser]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AuditTray]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DamageAssessmentBreakDown]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DamageAssessment]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DamageAssessmentBreakDownDraft]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DamageAssessmentDraft]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicApproval]: GatewayState;

  //eGG
  readonly [Gateway_FEATURE_KEY_DocumentCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LicenceStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LessorRepType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DamageStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Pole]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AccessRight]: GatewayState;
  readonly [Gateway_FEATURE_KEY_CofOStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Quantum]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SubSubGroupTypeCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_IncidentQuantum]: GatewayState;
  readonly [Gateway_FEATURE_KEY_IncidentSubCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_IncidentCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_RateStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_RequestCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_RequestSubCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ObligationPeriod]: GatewayState;

  readonly [Gateway_FEATURE_KEY_CleanUpStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_DamageList]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AddressType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ActivityStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Batch]: GatewayState;

  readonly [Gateway_FEATURE_KEY_IssuedCategory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ItemType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Network]: GatewayState;
  readonly [Gateway_FEATURE_KEY_FacilityAsset]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ExpirationStatus]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LeaseExpiration]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PLRActivityActionHistory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_PipelineTemplate]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LandTitle]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Role]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Staffrole]: GatewayState;
  readonly [Gateway_FEATURE_KEY_BatchData]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Lessors]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicClaimType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicCurrency]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicDivision]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicLineType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicOML]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicProgram]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicParty]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicSwathNo]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicCrew]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicCommunity]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicDamageList]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicSwathCalc]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ApprovalLevel]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Supervisor]: GatewayState;
  readonly [Gateway_FEATURE_KEY_NumberOfApproval]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeisMicSwath]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Program]: GatewayState;
  readonly [Gateway_FEATURE_KEY_OilMineralLease]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ProgramConfig]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Vendor]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ImpactedAsset]: GatewayState;
  readonly [Gateway_FEATURE_KEY_AreaOfImpact]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Party]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Application]: GatewayState;
  readonly [Gateway_FEATURE_KEY_SeismicContractor]: GatewayState;
  readonly [Gateway_FEATURE_KEY_OpllPtsHistory]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ApprovalType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_GenericApprovalLevel]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ApprovalMapping]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ApprovalSetting]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Approval]: GatewayState;
  readonly [Gateway_FEATURE_KEY_StaffInfo]: GatewayState;
  readonly [Gateway_FEATURE_KEY_RequestBreakDowns]: GatewayState;
  readonly [Gateway_FEATURE_KEY_LeaveRequest]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Stafftrainingplan]: GatewayState;
  readonly [Gateway_FEATURE_KEY_qmsObjectiveType]: GatewayState;
  readonly [Gateway_FEATURE_KEY_qmsObjectiveactivity]: GatewayState;
  readonly [Gateway_FEATURE_KEY_qmsObjective]: GatewayState;
  readonly [Gateway_FEATURE_KEY_qmsObjectivePerformance]: GatewayState;
  readonly [Gateway_FEATURE_KEY_Requisition]: GatewayState;
  readonly [Gateway_FEATURE_KEY_QMSRespondents]: GatewayState;
  readonly [Gateway_FEATURE_KEY_ApprovalDelegate]: GatewayState;
}

export const GatewayAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (gateway) => gateway._id,
  sortComparer: false,
});

export const initialState: GatewayState = GatewayAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  error: null,
  selectedId: null,
  status: UploadStatus.Ready,
  progress: null,
});

export const gatewayReducer = (type: string) => {
  return createReducer(
    initialState,
    on(GatewayActions.loadGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.loadGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.addMany(returnedData, { ...state, loaded: false })
    ),

    on(GatewayActions.loadGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.addGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.addGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.addMany(returnedData, { ...state, loaded: false })
    ),
    on(GatewayActions.addGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.updateGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.updateGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.upsertMany(returnedData, { ...state, loaded: false })
    ),
    on(GatewayActions.updateGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.genericFilterGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(
      GatewayActions.genericFilterGatewaySuccess(type),
      (state, { returnedData }) =>
        GatewayAdapter.setAll(returnedData, { ...state, loaded: false })
    ),

    on(
      GatewayActions.genericFilterGatewayFailure(type),
      (state, { error }) => ({
        ...state,
        loaded: false,
        error,
      })
    ),

    on(GatewayActions.removeGateway(type), (state, inputtype) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.removeGatewaySuccess(type), (state, { ids }) =>
      GatewayAdapter.removeMany(ids, { ...state, loaded: false })
    ),
    on(GatewayActions.removeGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.loadGatewayClearState(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(
      GatewayActions.loadGatewayClearStateSuccess(type),
      (state, { returnedData }) =>
        GatewayAdapter.setAll(returnedData, { ...state, loaded: false })
    )
  );
};

// const stakeholderReducer =

export function reducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Country')(state, action);
}

export function groupreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Group')(state, action);
}

export function accountablefunctionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AccountableFunction')(state, action);
}

export function functionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Function')(state, action);
}

export function subgroupreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SubGroup')(state, action);
}
export function subsubgroupreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SubSubGroup')(state, action);
}

export function subsubgrouptypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SubSubGroupType')(state, action);
}

export function memoreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Memo')(state, action);
}

export function meetingreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Meeting')(state, action);
}

export function memotypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('MemoType')(state, action);
}

export function certpermitrenewalreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CertPermitRenewal')(state, action);
}

export function certnotificaionmappingreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Certnotificaionmapping')(state, action);
}

export function stafftransferreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StaffTransfer')(state, action);
}

export function staffqueryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StaffQuery')(state, action);
}

export function staffterminationreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StaffTermination')(state, action);
}

export function staffinfosreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StaffInfos')(state, action);
}

export function departmentreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Department')(state, action);
}
export function unitreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Unit')(state, action);
}

export function positionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Position')(state, action);
}

export function genderreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Gender')(state, action);
}

export function jobtitlereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('JobTitle')(state, action);
}

export function employmentstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('EmploymentStatus')(state, action);
}

export function maritalstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('MaritalStatus')(state, action);
}

export function ownershiptypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('OwnershipType')(state, action);
}

export function claimtypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ClaimType')(state, action);
}

export function terrainreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Terrain')(state, action);
}

export function paymentstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PaymentStatus')(state, action);
}

export function companyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Company')(state, action);
}

export function companyfunctionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CompanyFunction')(state, action);
}

export function divestmentstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DivestmentStatus')(state, action);
}

export function encroachmenttypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('EncroachmentType')(state, action);
}

export function encroachmentstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('EncroachmentStatus')(state, action);
}

export function titlereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Title')(state, action);
}
export function documenttypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DocumentType')(state, action);
}

export function bankreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Bank')(state, action);
}
export function divisiontblreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DivisionTbl')(state, action);
}
export function continentreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Continent')(state, action);
}
export function fiscalfilelocatonreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('FiscalFileLocation')(state, action);
}
export function geodatumreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('GeoDatum')(state, action);
}
export function countryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Country')(state, action);
}
export function statereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('State')(state, action);
}
export function lgareducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Lga')(state, action);
}
export function communityreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Communities')(state, action);
}
export function measurereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Measure')(state, action);
}

export function purposereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Purpose')(state, action);
}

export function acquisitiontypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AcquisitionType')(state, action);
}

export function currencyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Currency')(state, action);
}

export function bankingstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('BankingStatus')(state, action);
}

export function landusestatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LandUseStatus')(state, action);
}

export function rightheldreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RightHeld')(state, action);
}
export function categoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Category')(state, action);
}

export function measuringfactorreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('MeasuringFactor')(state, action);
}

export function appconfigurationreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AppConfiguration')(state, action);
}

export function cabinetshelfrefreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CabinetShelfRef')(state, action);
}

export function documenttypecodereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DocumentTypeCode')(state, action);
}

export function racktrayrefreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RackTrayRef')(state, action);
}

export function securityclassificationreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SecurityClassification')(state, action);
}

export function plstageactivityreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLStageActivity')(state, action);
}

export function plstakeholdermatrixreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLStakeholderMatrix')(state, action);
}

export function plstakeholdermatrixracireducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLStakeholderMatrixRaci')(state, action);
}

export function pipelinelicensecategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineLicenseCategory')(state, action);
}

export function pipelinelicensestagereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineLicenseStage')(state, action);
}

export function pipelinelicensestakeholderreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineLicenseStakeholder')(state, action);
}
export function stakeholderrepreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StakeholderRep')(state, action);
}
export function hierarchyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Hierarchy')(state, action);
}

export function pipelineassetlocationreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineAssetLocation')(state, action);
}

export function pipelinesectionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineSection')(state, action);
}
export function accountablefunctionlink2reducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AccountableFunctionLink2')(state, action);
}
export function pipelineassetlink2reducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineAssetLink2')(state, action);
}
export function verificationstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('VerificationStatus')(state, action);
}
export function uomreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Uom')(state, action);
}
export function paymenttypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PaymentType')(state, action);
}

export function assetstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AssetStatus')(state, action);
}
export function deliverytypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DeliveryType')(state, action);
}
export function licencestatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LicenceStatus')(state, action);
}
export function networkreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Network')(state, action);
}
export function ipstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('IPStatus')(state, action);
}
export function pipelinegradereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineGrade')(state, action);
}
export function pigibilityreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Pigibility')(state, action);
}

export function activityactionspersonreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ActivityActionsPerson')(state, action);
}

export function pipelinelicenserequestreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineLicenseRequest')(state, action);
}

export function pipelinelicenserequeststagereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineLicenseRequestStage')(state, action);
}

export function plrstageactivityreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLRStageActivity')(state, action);
}
export function plrstakeholdermatrixreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLRStakeholderMatrix')(state, action);
}

export function plrstakeholdermatrixracireducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLRStakeholderMatrixRaci')(state, action);
}

export function correspondenceactionpartyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CorrespondenceActionParty')(state, action);
}

export function correspondenceteamreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CorrespondenceTeam')(state, action);
}
export function userstaffdetailreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('UserStaffDetail')(state, action);
}
export function plactivityactionpersonreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLActivityActionPerson')(state, action);
}
export function identityuserreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('IdentityUser')(state, action);
}
export function adrreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Adr')(state, action);
}
export function lorpreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Lorp')(state, action);
}

export function yearofsuitreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('YearOfSuit')(state, action);
}

export function counseltypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CounselType')(state, action);
}
export function suitcounselreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SuitCounsel')(state, action);
}

export function adrpersonnelreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AdrPersonnel')(state, action);
}

export function adrassetsreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AdrAssets')(state, action);
}

export function landtitlereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LandTitle')(state, action);
}

export function plractivityactionhistoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PLRActivityActionHistory')(state, action);
}

export function pipelinedetailsreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineDetails')(state, action);
}
export function encroachmentAssetreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('EncroachmentAsset')(state, action);
}

export function addressbookreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AddressBook')(state, action);
}

export function pipelineEncroachmentreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineEncroachment')(state, action);
}

export function encroacherreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Encroacher')(state, action);
}
export function stakeholderResponserreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StakeholderResponse')(state, action);
}
export function encroachmentActionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('EncroachmentAction')(state, action);
}

export function requesttypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RequestType')(state, action);
}
export function correspondencecompanyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CorrespondenceCompany')(state, action);
}

export function sexreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Sex')(state, action);
}
export function landprefixreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LandPrefix')(state, action);
}
export function totalsumtablereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('TotalSumTable')(state, action);
}
export function lossofuseitemsreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LossOfUseItems')(state, action);
}

export function audittrayreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AuditTray')(state, action);
}

export function damageassessmentbreakdownreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DamageAssessmentBreakDown')(state, action);
}
export function damageassessmentreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DamageAssessment')(state, action);
}

export function damageassessmentbreakdowndraftreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DamageAssessmentBreakDownDraft')(state, action);
}
export function damageassessmentdraftreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DamageAssessmentDraft')(state, action);
}
export function seismicapprovalreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicApproval')(state, action);
}

//eGG

export function documentcategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DocumentCategory')(state, action);
}

export function lessorreptypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LessorRepType')(state, action);
}

export function damagestatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DamageStatus')(state, action);
}

export function polereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Pole')(state, action);
}

export function accessrightreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AccessRight')(state, action);
}

export function cofostatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CofOStatus')(state, action);
}

export function quantumreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('QuantumOfImpact')(state, action);
}

export function subsubgrouptypecategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SubSubGroupTypeCategory')(state, action);
}

export function incidentquantumreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('IncidentQuantumOfImpact')(state, action);
}

export function incidentsubcategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('IncidentSubCategory')(state, action);
}

export function incidentcategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('IncidentCategory')(state, action);
}
export function seismicclaimantrefreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicClaimantRef')(state, action);
}
export function ratestatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RateStatus')(state, action);
}

export function requestcategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RequestCategory')(state, action);
}

export function requestsubcategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RequestSubCategory')(state, action);
}

export function obligationperiodreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ObligationPeriod')(state, action);
}

export function seismicclaimtypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicClaimType')(state, action);
}

export function seismiccurrencyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicCurrency')(state, action);
}

export function seismicdivisionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicDivision')(state, action);
}

export function seismiclinetypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicLineType')(state, action);
}

export function seismicomlreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicOML')(state, action);
}

export function seismicprogramreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicProgram')(state, action);
}

export function seismicpartyreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicParty')(state, action);
}

export function seismicswathnoreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicSwathNo')(state, action);
}

export function seismiccrewreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicCrew')(state, action);
}

export function seismiccommunityreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicCommunity')(state, action);
}

export function seismicdamagelistreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicDamageList')(state, action);
}

export function seismicswathcalcreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicSwathCalc')(state, action);
}

export function batchreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Batch')(state, action);
}

export function cleanupstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('CleanUpStatus')(state, action);
}

export function damagelistreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('DamageList')(state, action);
}

export function addresstypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AddressTypeTbl')(state, action);
}

export function activitystatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ActivityStatus')(state, action);
}

export function issuedcategoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('IssuedCategory')(state, action);
}
export function itemtypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ItemType')(state, action);
}

export function facilityassetreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('FacilityAsset')(state, action);
}
export function expirationstatusreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ExpirationStatus')(state, action);
}

export function leaseexpirationreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LeaseExpiration')(state, action);
}

export function pipelinetemplatereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('PipelineTemplate')(state, action);
}

export function rolereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Role')(state, action);
}

export function staffrolereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StaffRoleNew')(state, action);
}

export function batchdatareducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('BatchData')(state, action);
}

export function lessorsreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Lessors')(state, action);
}

export function approvallevelreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ApprovalLevel')(state, action);
}

export function supervisorreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Supervisor')(state, action);
}
export function numberofapprovalreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('NumberOfApproval')(state, action);
}

export function seismicswathreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeisMicSwath')(state, action);
}

export function programreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Program')(state, action);
}

export function oilmineralleasereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('OilMineralLease')(state, action);
}

export function programconfigreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ProgramConfig')(state, action);
}

export function impactedassetreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ImpactedAsset')(state, action);
}

export function areaofimpactreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('AreaOfImpact')(state, action);
}

export function partyreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Party')(state, action);
}
export function vendorreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Vendor')(state, action);
}

export function applicationreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Application')(state, action);
}
export function seismiccontractorreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('SeismicContractor')(state, action);
}

export function opllptshistoryreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('OpllPtsHistory')(state, action);
}

export function approvaltypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ApprovalType')(state, action);
}

export function genericapprovallevelreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('GenericApprovalLevel')(state, action);
}

export function approvalmappingreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ApprovalMapping')(state, action);
}

export function approvalsettingreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ApprovalSetting')(state, action);
}

export function approvalreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Approval')(state, action);
}

export function staffinforeducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('StaffInfo')(state, action);
}

export function requestbreakdownsreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('RequestBreakDowns')(state, action);
}

export function leaverequestreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('LeaveRequest')(state, action);
}

export function stafftrainingplanreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Stafftrainingplan')(state, action);
}

export function qmsobjectivetypereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('QMSObjectiveType')(state, action);
}

export function qmsobjectiveactivityreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('QMSObjectiveActivity')(state, action);
}

export function qmsobjectivereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('QMSObjective')(state, action);
}

export function qmsobjectiveperformancereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('QMSObjectivePerformance')(state, action);
}

export function requisitionreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('Requisition')(state, action);
}

export function qmsrespondentsreducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('QMSRespondents')(state, action);
}

export function approvalDelegatereducer(
  state: GatewayState | undefined,
  action: Action
) {
  return gatewayReducer('ApprovalDelegate')(state, action);
}
