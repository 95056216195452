import { BaseModel } from './base';

export class Bookings extends BaseModel {
  visitorsName: [{ fullName: string }];

  passport: string;
  passportUrl: string;

  badgeNo: string;

  personVisiting: string;
  personVisitingId: string;

  departmentVisiting: string;

  approvalStatus: boolean;

  company: string;

  badgeIssuerName: string;

  appointmentTime: string;
  timeIn: string;
  signOut: boolean;

  timeOut: string;
  signIn: boolean;

  phoneNo: string;

  purposeOfVisit: string;

  dateOfVisit: string;

  dateCreated: string;

  dateModified: string;

  subscriberId: string;

  siteId: string;
  id: string;
}
