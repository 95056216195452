<body class="inventory-body">
  <div class="container scale-up-center center ">
    <div class="row">
      <div class="col"></div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div id="card">
          <div class="m-4 ">
            <form [formGroup]="loginForm" class="form">
              <div id="card-title" class="center">
                <img [src]="subscriber.LogoPath"
                  style="min-width: 100px; max-width: 300px; margin-bottom: 6px; height: auto; max-height: 100px;" />
                <h3> <em class="caps">E</em>nterprise <em class="capsm">B</em>usiness <em class="capsm">S</em>olution
                </h3>


                <div class="underline-title"></div>
              </div>

              <div class="input-group col-lg-12 col-md-12 col-sm-12 mt-2">
                <mat-form-field class="input">
                  <mat-label>Email Address</mat-label>
                  <input matInput formControlName="email" placeholder="Email Address " required autofocus />
                  <mat-error>Username is required.</mat-error>
                </mat-form-field>
                <!-- <span *ngIf="
                    loginForm.controls['email'].invalid &&
                    (loginForm.controls['email'].dirty ||
                      loginForm.controls['email'].touched)
                  " class="text-danger border-top border-danger col-md-12 d-block  mx-auto">
                  <span *ngIf="loginForm.controls['email'].errors.required" class="col-md-8 d-block  mx-auto">
                    Username is required.
                  </span>
                </span> -->
                <!-- <div class="form-border mt-3"></div> -->
              </div>

              <div class="input-group col-lg-12 mt-3">
                <mat-form-field>
                  <mat-label>Password</mat-label>
                  <input matInput type="password" class="form-content " formControlName="password"
                    placeholder="Password " required />
                  <mat-error> Password is required.</mat-error>
                </mat-form-field>

                <!-- <span *ngIf="
                    loginForm.controls['password'].invalid &&
                    (loginForm.controls['password'].dirty ||
                      loginForm.controls['password'].touched)
                  " class="text-danger border-top border-danger col-lg-12 col-md-12 d-block  mx-auto">
                  <span *ngIf="loginForm.controls['passwor'].errors.required" class="col-md-8 d-block  mx-auto">
                    Password is required.
                  </span>
                </span> -->
                <!-- <div class="form-border mt-3"></div> -->
                <a style="margin-left: auto;margin-right: auto;" routerLink="/auth/manage">Forgot
                  Password?</a>
                <br />

              </div>
              <div class="form-group mt-4 ">
                <div class="row">
                  <button [disabled]="showProgress" type="submit" class="center" id="submit-btn" (click)="onSubmit()">
                    <span *ngIf="!showProgress"><i class="fa fa-sign-in"></i> Login</span>
                    <mat-spinner class="center" diameter="45" strokeWidth="3" color="warn" mode="indeterminate"
                      *ngIf="showProgress"></mat-spinner>
                  </button>
                </div>
                <div class=" mt-3">
                  <div class="logo" style="margin-bottom: 0px;">
                    <img src="assets/images/MrsoftLogo.png" class="logo">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</body>