<div class="container">

    <div [formGroup]="applicationForm">
        <!-- <pre>{{applicationForm.value | json}}</pre> -->
        <button mat-raised-button (click)="onsubmit(applicationForm.value)" class="float-right m-4">Submit</button>
        <button mat-button (click)="navigate()" class="float-right m-4">Return to Dashboard</button>
        <div>
            <h4>
                Total Subscrition Amount: <span> {{calTotal().totalAmount}} </span>,
                Total Subscription: <span> {{calTotal().totalSub}}</span>,
                Total Amount Paid: <span> {{calTotal().totalAmountPaid}}</span>,
                Yet to be Paid: <span> {{calTotal().notPaid}}</span>,
                Negotiated: <span> {{calTotal().negotiated}}</span>
            </h4>
        </div>
        <div formArrayName="Roles" class="row">



            <mat-card style="width: 30%; height: 200px; display: inline-block;"
                *ngFor="let role of applicationForm.get('Roles')['controls']; index as i" [formGroupName]="i"
                class="m-2">
                <mat-card-header>


                    <mat-card-subtitle>{{role.value.ModuleName}}<mat-checkbox class="ml-5" formControlName="isChecked"
                            (click)="unSubscibe(i, role.value)"></mat-checkbox>
                    </mat-card-subtitle>
                </mat-card-header>
                <p>Payment Status: {{role.value.PaymentStatus}}</p>
                <p>Approval Status: {{role.value.ApprovalStatus}}</p>
                <p>Subscription Amount:
                    <span *ngIf="control(i).value.Unlimited !== 'Unlimited'">
                        {{calcAmount(role.value.AmountPerUser, role.value.NoOfUsers, role.value.Duration, i )}}
                    </span>
                    <span *ngIf="control(i).value.Unlimited === 'Unlimited'">
                        Negotiated
                    </span>
                </p>
                <div *ngIf="role.value.ApprovalStatus !=='Paid'">
                    <div *ngIf="control(i).value.isChecked">
                        <span *ngIf="control(i).value.Unlimited !== 'Unlimited'">
                            <label for="">Number of user</label>
                            <input type="number" formControlName="NoOfUsers" class="m-2" placeholder="number of users">
                        </span>

                        <mat-select placeholder="Duration" name="item" formControlName="Duration"
                            *ngIf="control(i).value.Unlimited !== 'Unlimited'">
                            <mat-option *ngFor="let item of duratons" [value]="item.name"
                                (click)="selectSub(item.name, i)">
                                {{item.value}}
                            </mat-option>
                        </mat-select>

                        <mat-select placeholder="Unlimited" name="item" formControlName="Duration"
                            *ngIf="control(i).value.Unlimited === 'Unlimited'">
                            <mat-option *ngFor="let item of duratons" [value]="item.name"
                                (click)="selectSub(item.name, i)">
                                {{item.value}}
                            </mat-option>
                        </mat-select>

                    </div>
                </div>

                <mat-card-content>
                    <p>{{role.value.MenuDesc}}</p>
                </mat-card-content>
            </mat-card>


        </div>
    </div>
</div>