import { BaseModel } from './base';
import { StaffInfo } from './staffinfo';

export class SalarySchedule extends BaseModel {
  staffId: StaffInfo;
  Name: string;
  BasicSalary: number;
  Leave: number;
  Bonus: number;
  TotalAdditions: number;
  Grossearning: number;
  Totalearning: number;
  Pension: number;
  LifeInsurance: number;
  Cra: number; //(20 % of TE)+200000
  FreePay: number;
  TaxableIncome: number; //	0.07 * 300,000	0.11*300,000	0.15* 500,000	0.19*50000	0.21*1,600,000	0.24*3,200,000
  TotalTaxPayable: number;
  MonthlyTaxPayable: number;
  CreatedOn: Date;
  UpdatedOn: Date;
}
