import { BaseModel } from './base';

export class PVTReportDto extends BaseModel {
  fromDate: Date;
  toData: Date;
  challenges: string;
  currentJobs: PVTCurrentJob[];
  expectedJobs: PVTExpectedJob[];
  fieldServices: PVTFieldService[];
  invoices: PVTInvoice[];
  faultyEquipments: PVTFaultyEquipment[];
  localPurchaseExpenses: PVTlocalPurchaseExpense[];
  overseasPurchases: PVTOverseasPurchase[];
}

export class PVTCurrentJob {
  client: string;
  job: string;
  workUpdate: string;
  clientFocalPoint: string;
  lesarFocalPoint: string;
}

export class PVTExpectedJob {
  client: string;
  job: string;
  clientFocalPoint: string;
  lesarFocalPoint: string;
}

export class PVTFieldService {
  client: string;
  location: string;
  description: string;
  status: string;
  personel: string;
}

export class PVTInvoice {
  client: string;
  analysis: string;
  invoiceNumber: number;
  amount: number;
}

export class PVTFaultyEquipment {
  equipment: string;
  challenge: string;
  actionTaken: string;
}

export class PVTlocalPurchaseExpense {
  date: Date;
  item: string;
  amount: number;
}

export class PVTOverseasPurchase {
  description: string;
  oem: string;
  dueDate: Date;
  amount: number;
  payment: number;
  remarks: string;
}
