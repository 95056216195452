import { BaseModel } from './base';

export class ContainerType extends BaseModel {
  title: string;
  timestamp: Date;
  department: string;
  subscriberId: string;
  userId: string;
}

export class Samples extends BaseModel {
  point: string;
  sampleId: string;
  containerType: ContainerType | string;
  matrix: string;
  preservative: string;
  PH: string;
  DO: string;
  TDS: string;
  EC: string;
  turb: string;
  temp: string;
  dateOfAnalysis: Date;
  samplingDate: Date;
  subscriberId: string;
  userId: string;
  coordinate: string;
}

export class ChainofCustody extends BaseModel {
  containerType: ContainerType;
  jobId: string;
  field: string;
  reportReceivers: Array<{ email: string; name: string }>;
  invoiceReceiver: Array<{ email: string; name: string }>;
  samples: Samples[];
  subscriberId: string;
  userId: string;
  dateCreated: Date;
  client: string;
  finalReport: Document;
  samplerId: string; // name of the staff that collected the sample
  time: string; // time the sample was collected
  type: string;
  loggedInPerson: string;
}
