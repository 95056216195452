<div class="error ">
    <img src="assets/404.png" style="width: 150px; height: auto;" />
    <div class="row">

        <h1>Page Not Found</h1>
        <span class="pull-right large">
            <!-- <mat-icon (click)="close()"></mat-icon> -->
        </span>
    </div>
    <div class="row">
        <h4>It seems you are trying to visit a page that does no longer exist</h4>
        <br /><br />
        <button mat-button (click)="back()">Back to dashboard</button>
    </div>
</div>