import { BaseModel } from './base';
import { Sample } from './sample';

export class Airquality extends BaseModel {
  Client: string;
  Date: Date;
  Location: string;
  Samples: Sample[];
  SamplerName: string;
  ClientReps: string;
  SubscriberId: string;

  SiteId: string;

  id: string;
}
