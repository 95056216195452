import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from 'apps/ebs/src/app/services/log.service';
import { AccountService } from 'libs/account/src/lib/services/account.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  showProgress = false;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private logService: LogService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(MatDialogRef) private dialogRef: MatDialogRef<SignupComponent>
  ) {}

  ngOnInit() {
    console.log('Data: ', this.data);
    this.signupForm = this.fb.group({
      email: ['', Validators.required],
      phone: ['', Validators.required],
      password: [''],
      Email: [''],
      UserType: [''],
    });
  }

  onSubmit() {
    this.logService.log('Signup Form: ', this.data);
    this.signupForm.patchValue({
      UserType: this.data.type,
      Email: this.signupForm.get('email').value,
    });
    // if (this.type.includes('subscriber')) {
    this.accountService.signUp(this.signupForm.value).subscribe((data) => {
      if (data) {
        this.accountService.signin(this.signupForm.value, this.data.returnUrl);
        this.dialogRef.close();
        // window.location.reload();
      }
    });
    // }
  }
}
