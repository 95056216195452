import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';
import { Payload, LoggedInUser, UserDto } from '@mrsoftebs/data';
import { TokenService } from 'apps/ebs/src/app/services/token.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GatewayService } from 'libs/gateway/src/lib/services/gateway.service';
const apiUrl = environment.apiUrl;
const route = 'user/';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    public gatewayService: GatewayService
  ) {}

  findUserById(userId) {
    return this.http.get(apiUrl + route + 'findone/' + userId);
  }
  getLogo(subscriberId) {
    return this.http.get<any>(apiUrl + route + 'getlogo/' + subscriberId);
  }
  signUp(form) {
    return this.http.post(apiUrl + route + 'signup', form);
  }

  signin(form, returnUrl?) {
    let isSuccessful = false;
    this.http.post<Payload>(apiUrl + route + 'signin/', form).subscribe(
      (data) => {
        isSuccessful = true;
        console.log('signin data: ', data);
        this.tokenService.setLoggedIn(true);
        this.saveUser(data.access_token);
        this.setToken(data.access_token);
        if (returnUrl) {
          console.log('Return url: ', returnUrl),
            this.router.navigate([`${returnUrl}`]);
        } else {
          this.tokenService.setLoggedIn(false);
          this.router.navigateByUrl(`/`);
        }
      },
      (err) => {
        isSuccessful = false;
      }
    );
    return isSuccessful;
  }
  dialogSignin(form) {
    return this.http.post<Payload>(apiUrl + route + 'signin/', form);
  }
  getAllSites(parentId: string): Observable<any[]> {
    return this.http.get<any[]>(apiUrl + route + '/site?id=' + parentId);
  }

  saveUser(encodedUser: string) {
    const user = window.atob(encodedUser.split('.')[1]);
    const meta = window.atob(encodedUser.split('.')[0]);
    console.log('User @Account: ', user);
    //   const requestmeassage =
    //   'Please, Login into the ESB Portal with respect to ' +
    //   ' ' +
    //   '-' +
    //   plainText +
    //   '.' +
    //   ' ' +
    //   'Navigate to the Memo Module for more details ';
    // this.sendMeetingNoticeByMail(
    //   user['email'],
    //   this.memoForm.value.subject,
    //   StaffName,
    //   requestmeassage
    // );
    // console.log("meta: ", meta);

    localStorage.setItem('EBSloggedInUser', user);
  }
  getLoggedInUser(): LoggedInUser {
    const user: LoggedInUser = JSON.parse(
      localStorage.getItem('EBSloggedInUser')
    );
    return user;
  }

  setToken(token) {
    localStorage.setItem('EBSJwtToken', token);
  }

  logOut(returnUrl?) {
    localStorage.removeItem('EBSloggedInUser');
    localStorage.removeItem('EBSwtToken');
    this.tokenService.setLoggedIn(false);
    this.router.navigate([returnUrl ? returnUrl : '/home']);
    console.clear();
  }

  updateUser(data: any) {
    return this.http.post(apiUrl + route + 'updateuser', data);
  }

  getAllRoles(): Observable<any[]> {
    return this.http.get<any[]>(apiUrl + route + 'roles');
  }

  getAllStaffRoles(staffId: string): Observable<any[]> {
    return this.http.get<any[]>(apiUrl + route + 'staffrole?id=' + staffId);
  }

  getAllUsersBySubscribers(subscriberId: string): Observable<any[]> {
    return this.http.get<any[]>(apiUrl + route + 'allusers?id=' + subscriberId);
  }

  createRoles(data: any) {
    return this.http.post(apiUrl + route + 'createroles', data);
  }

  createStaffRoles(data: any) {
    return this.http.post(apiUrl + route + 'createstaffroles', data);
  }
  sendMeetingNoticeByMail(toEmail, subject, staffName, message) {
    const mailData = {
      fromEmail: '',
      toEmail: toEmail,
      subject: subject,
      task: '',
      responsibility: '',
      pipelinename: '',
      pipelinerequestdesc: '',
      period: '',
      message: '',
      salutation: '',
      footer: '',
      url: '',
      damagedEntries: '',
    };

    const salute = 'Dear ' + staffName + ' ,';

    const conformButtonMsg = '';

    mailData.salutation = salute;

    mailData.message = message;

    mailData.footer = '';

    //let linkURL = this.href + '#/' ;
    mailData.url = '';

    console.log('Sending mail ', mailData);
    this.gatewayService.sendSeismicNoticeMail(mailData).subscribe((data) => {});
  }
}
