import { BaseModel } from './base';

export class GenericApprovalLevel extends BaseModel {
  genapprovallevelName: string;
  levelPriority: number;
  SubscriberId: string;
  SiteId: string;
  dateCreated: string;
  dateModified: string;
}
