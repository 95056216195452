// import { Schema } from 'mongoose';
// import { buildSchema } from '@typegoose/typegoose';

export abstract class BaseModel {
  createdDate?: Date; // provided by timestamps
  createdBy: string;
  updatedBy: string;
  updatedDate?: Date; // provided by timestamps
  id?: string; // is actually model._id getter
  modifiedBy?: string;
  SubscriberId: string;
  SiteId: string;
  static get modelName(): string {
    return this.name;
  }
}
