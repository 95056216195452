import { BaseModel } from './base';

export class RelationshipType extends BaseModel {
  relationshipTypeId?: string;

  relationshipTypeName: string;

  subscriberId: string;

  siteId;
  string;

  dateCreated: string;

  dateModified: string;
  id: string;
}
