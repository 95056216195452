
<cradua-application
  [applications]="applications$ | async"
  [roles]="roles$ | async"
  [staffRoles]="staffRole$ | async"
  (submitEvent)="onSubmit($event)"
  (emitAssignedRole)="onEmitAssignedRole($event)"
  (emitDeleteRole)="onEmitDeleteRole($event)"
  (deleteapplicationEvent)="onDeleteapplication($event)"
  *ngIf="
  (isLoading$ | async) === false &&
  (isLoadingStaffRole$ | async) === false"

>
</cradua-application>

<mrsoftebs-list-spinner *ngIf="
(isLoading$ | async) === true ||
(isLoadingStaffRole$ | async) === true

">
</mrsoftebs-list-spinner>

