import { BaseModel } from "./base";
export class ApplicantDocumentDto extends BaseModel {


  applicationId: string;
  applicationNo: string;
  certificateTypeId: string;
  certificateTypeName: string;
  docPath: string;
  docName: string;
  institutionAttended: string;
  startDate: Date;
  graduationDate: Date;
  classOfDegreeId: string;
  classOfDegreeName: string;

  positionId: string;
  categoryId: string;

  departmentId: string;
  facultyId: string;
  positionName: string;
  categoryName: string;
  facultyName: string;
  departmentName: string;

  dateCreated: Date;
  dateUpdated: Date;
  modifyBy: string;
  title: string;
  FileType: string;
  documentClass: string;
  documentNo: string;

  startYear: string;
  endYear: string;


}
