import { Owner } from './owner';
import { Task } from './task';
import { Document } from './document';
import { BaseModel } from './base';

export class Project extends BaseModel {
  projectName: string;

  description: string;

  picture: Document;

  projectDates: string;

  startDate: string;

  endDate: string;

  updatedAt: string;

  responsiblePerson: Owner[];

  projectOwners: [];

  cost: number;

  taskId: string;

  feature: {
    isPublic: boolean;
    isChat: boolean;
    isComment: boolean;
    isCalendar: boolean;
  };

  meetingTime: string;

  supportDoc: Document[];

  percentageCompletion?: number;
  tasks: Task[];

  departmentId: string;

  subscriberId: string;

  siteId: string;

  projectTypeId: string;

  period: string;
  id: string;
}
