import { BaseModel } from "./base";

export class QMSObjectivePerformance extends BaseModel{
    
    objectivePerformanceId: string;
    
    objectiveId: string;
    
    activityId: string;
    
    activityName: string;
    
    planTargetExpected: string;
    
    indicator: string;
    
    actualPercentage: number;
    
    actualPercentageScore: number;
    
    supervisorPercentage: number;
    
    managementPercentage: number;
    
    remarks: string;
    
    docNo: string;
    
    issuanceNo: string;
    
    RevNo: string;
    
    dateCreated: Date;

    
    issueCreated: Date;

    
    revDate: Date;
    
    dateModified: Date;
    
    subscriberId: string;
    
    siteId: string;
    id: string;

    objYear: string;

    janName: string;
    janValue: number;
    janUpdated: Date;
    febName: string;
    febValue: number;
    febUpdated: Date;
    marName: string;
    marValue: number;
    marUpdated: Date;
    aprilName: string;
    aprilValue: number;
    aprilUpdated: Date;
    mayName: string;
    mayValue: number;
    mayUpdated: Date;
    juneName: string;
    juneValue: string;
    juneUpdated: Date;
    julyName: string;
    julyValue: number;
    julyUpdated: Date;
    augName: string;
    augValue: number;
    augUpdated: Date;
    sepName: string;
    sepValue: number;
    sepUpdated: Date;
    octName: string;
    octValue: number;
    octUpdated: Date;
    novName: string;
    novValue: number;
    novUpdated: Date;
    decName: string;
    decValue: number;
    decUpdated: Date;
    totalValue: number;

    month: string;
    monthName: string;
    performancefeedbacks: any[];

    departmentId: string;
    departmentName: string;

}