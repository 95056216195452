import { BaseModel } from './base';

export class StaffTransfer extends BaseModel {
  resumptionDate: Date;
  dateTransferred: Date;
  departmentId: string;
  staffs: any[];
  previousDepartment: string;
  transferLetter: string;
  copiedStaff: any[];
  transfferedStaff: any[];
  subscriberId: string;
  siteId: string;
  createdbyId: string;
  createdbyName: string;
  modifiedBy: string;
  dateCreated: string;
  dateModified: string;
  memoNumber: string;
}
