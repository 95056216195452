import { BaseModel } from "./base";

export class StaffStatus extends BaseModel{
    staffStatusId?: string;
  
    staffStatusName: string;
  
    subscriberId: string;
  
    siteId:   string;
  
    dateCreated: string;
  
    dateModified: string;
    id: string;
  }
  