import { StaffInfo } from './staffinfo';
import { Trainingtype } from './trainingtype';
import { Trainingcategory } from './trainingcategory';
import { BaseModel } from './base';

export class Stafftrainingplan extends BaseModel {
  trainingTitle: string;

  staffId: StaffInfo;

  trainingLocation: string;

  fromDate: Date;

  toDate: Date;

  year: string;

  coordinatorId: StaffInfo;

  trainingStatus: string;

  remarks: string;

  trainingTypeId: Trainingtype;

  trainingCategoryId: Trainingcategory;

  organizationtrainingconducted: string;

  subscriberId: string;

  siteId: string;

  createdpersonId: string;

  dateCreated: Date;
  trainingReason: string;
  trainingcost: string;
  fileName: string;
  filePath: string;
}
