import { BaseModel } from './base';

export class ContractorData extends BaseModel {
  id: string;
  contractorId?: string;
  contractorName: string;
  contractorPhone: string;
  contractorEmail: string;
  contractorWebsite: string;
  contractorAddress: string;
  reps: [
    {
      name: string;
      email: string;
      position: string;
      phoneNumber: string;
      contractorId: string;
      SubscriberId: string;
      SiteId: string;
    }
  ];
  subscriberId: string;
  siteId: string;
  dateCreated: Date;
  dateModified: Date;

  _id: string;
}
