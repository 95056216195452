import { ItemRequest } from './itemsrequest';
import { RequestQuery } from './requestquery';
export class Requisition extends RequestQuery {
  requisitionId?: string;
  requisitorId: string;
  requestorName: string;
  requestDate: Date;
  amount: number;
  requesttypeId: string;
  requesttype: string;
  client: string;
  code: string;
  contract: string;
  purpose: string;
  requestStatus: string;
  amountGiven: number;
  amountSpent: number;
  amountReimbursed: number;
  purposeTrans: string;
  collectionDate: Date;
  amountCollected: number;
  amountSpentTotal: number;
  totalBalance: number;
  subscriberId: string;
  siteId: string;
  dateCreated: Date;
  dateModified: Date;
  id: string;
  documentUrl: string[];
  items: Array<ItemRequest>;
  isDisbursed: boolean;
  disbursedDate: Date;
  disbursedById: string;
  disbursedByName: string;
  isPosted: boolean;
  postedDate: Date;
  postedById: string;
  postedByName: string;

  
  SiteId: string;
  SubscriberId: string;

  isDisbursedQuery: boolean;
  disbursedQueryDate: Date;
  disbursedQueryById: string;
  disbursedQueryByName: string;

  hasBeenDisbursedQuery: boolean;
}
