import { AcountBanks } from './accountbanks';
import { BaseModel } from './base';

export class ChequesIssuedDetails extends BaseModel {
  id: string;
  nameOfCheque: string;
  purpose: string;
  reference: string;
  BankId: string;
  BankName: string;
  NGN: number;
}
