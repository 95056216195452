import { BaseModel } from './base';

export class ProjectType extends BaseModel {
  projectTypeName: string;

  subscriberId: string;

  siteId: string;
  id: string;
}
