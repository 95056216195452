<mat-card class=" m-4 center">
  <!-- <mat-progress-bar mode="buffer" *ngIf="showProgress"></mat-progress-bar> -->
  <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->

  <img src="assets/images/MrsoftLogo.png" class="mb-1" style="margin-top: -10px" />
  <mat-progress-bar mode="buffer" *ngIf="showProgress"></mat-progress-bar>
  <h3>Sign up for Organization Account</h3>
  <form [formGroup]="signupForm" class="form">
    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input matInput type="email" placeholder="Enter Email Address" formControlName="email" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input matInput placeholder="Enter Phone Number" formControlName="phone">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Organization Name</mat-label>
      <input matInput placeholder="Enter Your organization Name" formControlName="subscriberName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Organization Short Name</mat-label>
      <input matInput placeholder="Enter Your organization Short Name" formControlName="subscriberShortName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="Enter Password" formControlName="password">
    </mat-form-field>
    <br /><br />
    <button mat-raised-button color="primary" (click)="onSignUp()">Sign up</button>

    <button mat-button class="right" (click)="cancel()">Cancel</button>
    <!-- <button class="btn" (click)="onSubmit()">Signin</button> -->
    <br /><br />
    <!-- <p> <button class="link-button" mat-button (click)="toggleLogin()">Back to Sign in</button></p> -->
  </form>
</mat-card>