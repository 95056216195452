import { BaseModel } from './base';

export class   Country extends BaseModel{
  CountryName: string;
  CountryCode: string;
  DateCreated: string;
  DateModified: string;
  SubscriberId: string;
  SiteId: string;
  id: string;
  _id: string;
}
