import { createReducer, on } from '@ngrx/store';
import { AuthApiActions, AuthActions } from '../actions';

import { UserRO } from '@mrsoftebs/data';

export const statusFeatureKey = 'status';

export interface State {
  user: UserRO | null;
}

export const initialState: State = {
  user: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthApiActions.loginSuccess, (state, { user }) => ({ ...state, user })),
 
  on(AuthActions.logout, () => initialState)
);

export const getUser = (state: State) => state.user;