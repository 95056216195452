import { Ref } from '@typegoose/typegoose';
import { BaseModel } from './base';
import { StaffInfo } from './staffinfo';
import { StatusCondition } from './status-enum';

export class Travel extends BaseModel {
  authorizedBy: string;
  bookingDate: Date;
  travelPerson: string;
  takeOffLocation: string;
  purpose: string;
  destination: string;
  // noOfPassengers: number;
  proposedDate: Date;
  proposedTime: string;
  dateExpected: Date;
  timeExpected: string;
  vehicleType: string;
  vehicleNo: string;
  numberOfDays: string;
  nonStaff: boolean;
  nonStaffEntries: any[];
  takeOffTime: string;
  airline: string;
  transportCompany: string;
  landingTime: string;
  allowances: [];
  bookingBy: string;
  flightStatus: string;
  flightPnr: string;
  driverName: string;
  // odometerOut: string;
  // odometerIn: string;
  grandTotal: number;

  overnightStay: boolean;
  validLicense: boolean;
  nightDriving: boolean;
  waiverApprov: boolean;
  shocReq: boolean;
  shocProv: boolean;
  weekVehicleCheck: boolean;
  vehicleCheck: boolean;
  restStop: boolean;

  subscriberId: string;
  siteId: string;
  status: StatusCondition;
  meansOfTravel: string;
  _id: string;
  travelRefNo: string;
  ApprovalTrackingNo: string;
  userId: string;
  queryResponse: string;
  completedDate: Date;
}
