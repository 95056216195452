import { BaseModel } from "./base";

export class EnvironmentalReport extends BaseModel {
  ReceiverId: string;
  ReceiverName: string
  SenderId: string;
  SenderName: string;
  FromDate: string;
  ToDate: string;
  Clients: [];
  OverseaPurchase: [];
  PendingEquipment: [];
  FaultyEquipment: [];
  ExpectedJobs: [];
  InvoiceSent: [];
  EnvironmentalUnit: [];
  id: string;
}
