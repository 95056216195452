import { BaseModel } from './base';

export class Fdback extends BaseModel {
  clientName: string;
  address: string;
  date: Date;
  serviceDescription: string;
  laserRep: string;
  scaleOfPerformance: string;
  timeSchdle: string;
  analysisReport: string;
  staffPerformance: string;
  priceCharge: string;
  recommend: string;
  waysToImprove1: string;
  waysToImprove2: string;
  waysToImprove3: string;
  complaints: string;
  promptResponse: string;
  otherComments: string;
  clientRep: string;
  designation: string;
  _id: string;
}
