import { BaseModel } from './base';

export class PerformanceEvaluationQuestions extends BaseModel {
  evaluationQxns: string;

  subscriberId: string;

  siteId: string;

  DateCreated: string;

  DateModified: string;

  id: string;

  _id: string;
}
