import { StaffInfo } from './staffinfo';

import { BaseModel } from './base';

export class Loan extends BaseModel {
  staffId: StaffInfo;
  requestDate: Date;
  approvedOrCancelDate: Date;
  requestAmount: number;
  approvedAmount: number;
  approvedBy: StaffInfo[];
  durationOfRepaymentInMonths: number;
  repaymentHistory: [{ repayedAmount: number; repaymentDate: Date }];
  totalAmountRepayed: number;
  approvalStatus: string;
  repaymentStatus: string;
  userId: string;
}
