import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/ebs/src/environments/environment';

@Component({
  selector: 'mrsoftebs-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  // emailControl = new FormControl('', [Validators.email, Validators.required]);
  isProccessing = false;
  emailForm: FormGroup;
  userId: string;
  token: string;
  isForgotPassword = true;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private msb: MatSnackBar
  ) {}

  ngOnInit() {
    this.emailForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
    });

    this.activatedRoute.paramMap.subscribe((param) => {
      this.userId = param.get('id');
      this.token = param.get('token');
      if (this.token && this.userId) {
        this.isForgotPassword = false;
      }

      console.log('index...', this.userId, this.token);
    });
  }

  forgotPassword() {
    this.isProccessing = true;
    if (this.emailForm.valid) {
      console.log('index3...', this.emailForm.valid);
      this.http
        .post(environment.apiUrl + 'user/forgotpassword', this.emailForm.value)
        .subscribe(
          (result) => {
            console.log('result: ', result);
            this.isProccessing = false;
            if (result) {
              this.msb.open(
                'Password reset link has been sent to your email address',
                'X',
                { duration: 4000 }
              );
            }
          },
          (error) => {
            console.log('error: ', error);
            this.isProccessing = false;
            this.msb.open('Sorry, your password reset request failed', 'X', {
              duration: 4000,
            });
          }
        );
    }
  }
}
