import { BaseModel } from './base';

export class CorrectiveMaintenanceLog extends BaseModel {
  equipmentName: string;
  date: string;
  techomer: string;
  descriptionOfWork: string;
  locationOfMaintenance: string;
  maintainedBy: string;
  supervisedBy: string;
  dateCreated: string;
  dateModified: string;
  subscriberId: string;
  siteId: string;
  id: string;
}
