import { BaseModel } from "./base";

export class HseSubToolsDto extends BaseModel {
  criteriaId: string;
  criteriaName: string;
toolId: string;
toolName: string;
  subscriberId: string;

  siteId: string;


}
