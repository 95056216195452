import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
})
export class AppsComponent implements OnInit {
  modules: Array<{
    moduleName: string;
    moduleIcon: string;
    moduleLink: string;
  }> = [
    {
      moduleName: 'Account',
      moduleIcon: 'assets/images/side_nav/admin-settings-male.png',
      moduleLink: '/auth/manage',
    },
    {
      moduleName: 'Access Control',
      moduleIcon: 'assets/images/side_nav/admin-settings-male.png',
      moduleLink: '/staffrole',
    },
    {
      moduleName: 'References',
      moduleIcon: 'assets/images/side_nav/admin-settings-male.png',
      moduleLink: '/references',
    },
    {
      moduleName: 'HR',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/hr',
    },
    {
      moduleName: 'Booking',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/recruitment/joblist',
    },
    {
      moduleName: 'PVT',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/hr/',
    },
    {
      moduleName: 'Procurement',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/recruitment/joblist',
    },
    {
      moduleName: 'HSE',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/staff/',
    },
    {
      moduleName: 'I.T Process',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/recruitment/joblist',
    },
    {
      moduleName: 'Laboratory',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/hr/',
    },
    {
      moduleName: 'Tendered Materials',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/recruitment/joblist',
    },
    {
      moduleName: 'Stock/Inventory',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/staffinfo/',
    },
    {
      moduleName: 'Logistics',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/recruitment/joblist',
    },
    {
      moduleName: 'Learning',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/hr/',
    },
    {
      moduleName: 'Library',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/recruitment/library',
    },
    {
      moduleName: 'Manage Modules',
      moduleIcon:
        'assets/images/side_nav/collaboration-clipart-managment-5.png',
      moduleLink: '/auth/application',
    },
    {
      moduleName: 'Update Company Data',
      moduleIcon:
        'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
      moduleLink: '/auth/subscriberinfo',
    },
    // {
    //   moduleName: 'HR',
    //   moduleIcon:
    //     'assets/images/side_nav/collaboration-clipart-managment-5.png',
    //   moduleLink: '/hr/',
    // },
    // {
    //   moduleName: 'Booking',
    //   moduleIcon:
    //     'assets/images/side_nav/337-3376285_search-recruitment-icon.png',
    //   moduleLink: '/recruitment/joblist',
    // },
  ];
  staffRoles: Array<any> = JSON.parse(localStorage.getItem('userRoles'));
  user = JSON.parse(localStorage.getItem('EBSloggedInUser'));
  constructor(private router: Router) {}

  ngOnInit() {}
  navigate(module) {
    console.log('Module click: ', module);
    localStorage.setItem('module', JSON.stringify(module));
    this.router.navigate([module.moduleLink]);
  }
  canAccessAction(link: string) {
    const canaccess = this.staffRoles.find((l) =>
      l.link.toString().trim().includes(link.trim())
    );
    if (canaccess || this.user.userType.includes('Subscriber')) {
      return true;
    } else {
      return false;
    }
  }
}
