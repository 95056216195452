// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: 'http://mrsoft-01:9988/api/',
  // socketUrl: 'http://mrsoft-01:9988/',

  apiUrl: 'http://localhost:7788/api/',
  socketUrl: 'http://localhost:7788/',

  // apiUrl: 'http://mrsoft-01:5788/api/',
  // socketUrl: 'http://mrsoft-01:5788/',

  //apiUrl: 'https://apimrsoftebsdemo.mrsoftebs.com/api/',
  // apiUrl: 'https://apimrsoftebsdemo.mrsoftebs.com/api/',
  // socketUrl: 'https://apidemo.mrsoftebs.com/',
  // apiUrl: 'https://apidemo.mrsoftebs.com/api/',
  // socketUrl: 'https://laserapi.mrsoftebs.com/api/',
  // apiUrl: 'https://laserapi.mrsoftebs.com/api/',
  // apiUrl: 'https://api.mrsoftebs.com/api/',
  // socketUrl: 'https://careerapi.mrsoftebs.com/api/',
  // apiUrl: 'https://careerapi.mrsoftebs.com/api/',
};

/*
 */
