

import { BaseModel } from './base';

export class FinalReportVetters extends BaseModel
{


    comment: string;


    jobIdReportNo: string;


    finalReportAutoNo: string;


    dateReported: string;


    senderId: string;


    senderName: string;


    dateSent: string;


    senderComment: string;
    senderStatus: string;


    vettingOfficerId: string;


    vettingOfficerName: string;


    vettingOfficerCommentDate: string;
    vettingOfficerStatus: string;


    dateCreated: string;

    dateModified: string;

    subscriberId: string;

    siteId: string;


    userId: string;
    id: string;
}
