<div class="box green_box"></div>
<div class="box pink_box"></div>
<div class="blur_ball">
  <img src="assets/images/small.svg" class="top1" alt="" />
  <img src="assets/images/small.svg" class="bottom1" alt="" />
</div>

<main class="reference_body" style="text-align: center; margin-left: auto; margin-right: auto; margin-top: 20vh;">

  <div *ngIf="isForgotPassword">
    <form [formGroup]="form" (ngSubmit)="forgotPassword()" class="form">
      <div class="forgot_password m-4">
        <h2 class="">Forgot Password</h2>
        <div class="contain">
          <div class="the_input">

            <mat-form-field style="width: 400px; ">
              <mat-label for="">Email</mat-label>
              <input type="email" formControlName="username" matInput placeholder="Enter Email" />
            </mat-form-field>
            <div *ngIf="forgotsubmitted && forgotpass.username.errors">
              <div *ngIf="forgotpass.username.errors.required" style="color: red; padding-top: 3px;">Email is required
              </div>
              <div *ngIf="forgotpass.username.errors.email" style="color: red; padding-top: 3px;">Email must be a valid
                email address</div>
            </div>
          </div>
          <h4 style="color: red">An Email will be sent to you after submission, check your mail to continue.</h4>

        </div>
        <br />
        <div class="submit">
          <button [disabled]="isProcessing" type="submit" class="default_primary-button">Submit</button>
        </div>
        <mat-spinner mode="indeterminate" diameter='30' strokeWidth='1' *ngIf="isProcessing"></mat-spinner>
      </div>


    </form>
  </div>

  <div *ngIf="!isForgotPassword && (tokenIsExpire || !tokenIsValid)">

    <div style="color: red; font-size: 16px;">
      Invalid link, please check your mail
    </div>

  </div>

  <div *ngIf="!isForgotPassword && !tokenIsExpire && tokenIsValid">
    <form [formGroup]="changePassowrdform" (ngSubmit)="changePassword()" class="form">

      <div class="main_signin">
        <div class="forgot_password">
          <h2 class="title">Change Password</h2>
          <div class="contain">
            <div class="the_input">
              <label for=""> New Password</label>
              <input type="password" formControlName="password" class="default_input" placeholder="Enter new password"
                (input)="formInputChange()" [ngClass]="{ 'inactive_input': (gatewayService.isLoading$ | async) }"
                [readonly]="(gatewayService.isLoading$ | async)" />
              <div *ngIf="controls.password.errors">
                <span class="error"
                  *ngIf="controls.password.touched && controls.password.errors && controls.password.errors.pattern">
                  Password must be at least 8 characters long
                </span>
                <span class="error"
                  *ngIf="controls.password.touched && controls.password.errors && controls.password.errors.pattern">
                  and must be a combination of lowercase, uppercase,</span>
                <span class="error"
                  *ngIf="controls.password.touched && controls.password.errors && controls.password.errors.pattern">
                  number and
                  special character</span>
              </div>
            </div>

            <div class="the_input">
              <label for="">Confirm Password</label>
              <input type="password" formControlName="confirmPassword" class="default_input"
                placeholder="Confirm password" (input)="formInputChange()"
                [ngClass]="{ 'inactive_input': (gatewayService.isLoading$ | async) }"
                [readonly]="(gatewayService.isLoading$ | async)" />
              <div *ngIf="controls.confirmPassword.errors">
                <div *ngIf="controls.confirmPassword.touched && controls.confirmPassword.errors.required" class="error">
                  Confirm
                  password is
                  required</div>
                <div *ngIf="controls.confirmPassword.touched && controls.confirmPassword.errors.mustMatch"
                  class="error">Passwords
                  must
                  match
                </div>
              </div>

            </div>

          </div>



          <div class="sign_in-submit">
            <button type="submit" class="default_primary-button"
              [ngClass]="{ 'inactive_btn': (gatewayService.isLoading$ | async) }"
              [disabled]="(gatewayService.isLoading$ | async)">
              Submit
            </button>

            <div *ngIf="(gatewayService.isLoading$ | async)">
              <mat-progress-spinner mode="indeterminate" diameter='30' strokeWidth='1'></mat-progress-spinner>
            </div>

          </div>
        </div>
      </div>

    </form>
  </div>



</main>