import { Subscriber } from './subscriber';

// export interface Subsriber {
//   _id: string;
//   subscriberName: string;
//   subsriberShortName: string;
//   createdAt?: Date;
//   upatedAt?: Date;
// }

import { BaseModel } from './base';

export class LoggedInUser extends BaseModel {
  email: string;
  subscriber: Subscriber;
  // subscriberId: string;
  SubscriberId: string;
  subscriberName: string;
  subscriberShortName: string;
  StaffName: string;
  siteId: string;
  userId: string;
  passportUrl: string;
  iat: number;
  siteGenericNumber: number;
  levelCount: any;
  userType: string;

  departmentId: string;
  departmentName: string;

  // selectCountry: any
  canAddSub: boolean;
}
