import { BaseModel } from './base';

export class CertPermitRenewalHistory  extends BaseModel{
    certType:string;
    certName:string;
    description:string;
    expirationDate:string;
    notifyIn:number;
    notifyByDays:number;
    noOfNotification:string;
    createdDate:Date;
    createdById:string;
    creayedByName:string;
    modifiedDate:Date;
    modifiedById:string;
    modifiedByName:string;
    certPermitNo: number;
}