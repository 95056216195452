import { ActionReducerMap } from "@ngrx/store";


import * as Auth from 'libs/auth/src/lib/reducers/auth.reducer';
import * as User from 'libs/auth/src/lib/reducers/user.reducer';
import * as LoginPage from 'libs/auth/src/lib/reducers/login-page.reducer';

export interface AppState {
    Auth: Auth.State;
	LoginPage: LoginPage.State;
	user: User.UserState;

}

export const reducers: ActionReducerMap<AppState> = {
    Auth: Auth.reducer,
    LoginPage: LoginPage.reducer,
    user: User.reducer,
};