import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'apps/ebs/src/environments/environment';
import { UserService } from 'libs/staffroles/src/lib/services/user.service';

@Component({
  selector: 'mrsoftebs-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  isProccessing = false;
  passwordForm: FormGroup;
  @Input() userId: string;
  @Input() token: string;
  tokenIsValid: boolean;
  tokenIsExpire: boolean;
  hasData = false;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private msb: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.fb.group(
      {
        id: [''],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=\D*\d)(?=[^a-z]*[a-z])(?=.*[$@$!%*?&,./#])(?=[^A-Z]*[A-Z]).{8,30}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: this.MustMatch('password', 'confirmPassword'),
      }
    );
    this.verifyUserAndToken();
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  verifyUserAndToken() {
    // Get the user
    if (this.userId && this.token) {
      this.http
        .get<any>(
          environment.apiUrl +
            'user/findone/' +
            this.userId +
            '?token=' +
            this.token
        )
        .subscribe((user) => {
          console.log('user: ', user);
          this.hasData = true;
          if (user) {
            this.tokenIsValid = true;
            this.passwordForm.patchValue({ id: this.userId });
            const varDate = new Date(user.resetPasswordExpires); //dd-mm-YYYY
            const today = new Date();
            if (varDate >= today) {
              this.tokenIsExpire = false;
            } else {
              this.tokenIsExpire = true;
            }
          }
        });
    }
  }

  changePassword() {
    this.isProccessing = true;
    this.userService.changePassword(this.passwordForm.value).subscribe(
      (result) => {
        console.log('result: ', result);
        this.isProccessing = false;
        if (result) {
          this.msb.open(
            'Your password reset was successful. Hold on while we redirect you to login page',
            'X',
            { duration: 5000 }
          );
          this.router.navigate(['/auth/login']);
        }
      },
      (error) => {
        if (error) {
          this.msb.open('Your password reset was NOT successful.', 'X', {
            duration: 5000,
          });
          this.isProccessing = false;
        }
      }
    );
  }
}
