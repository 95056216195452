import { BaseModel } from './base';

export class  CertNotificationMapping extends BaseModel{
    staffInfo: string;
    staffInfoId:string
    staffName:string;
    staffEmail:string;
    dateCreated:Date;
    dateModified:Date;
    createdByName:string;
    createdById:string;
    modifiedByName:string;
    modifiedById:string;
    subscriberId:string;
    siteId:string;

}