import { BaseModel } from './base';
import { Level } from './level';
import { PayCategory } from './paycategory';

export class Step extends BaseModel {
  stepName: string;

  description: string;

  basicSalary: number;

  subscriberId: string;

  siteId: string;

  levelId: Level;

  payCategories: Array<{ paycatId: PayCategory }>;
  _id: string;
}
