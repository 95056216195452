import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { Notification } from '@mrsoftebs/data';

const apiUrl = environment.apiUrl;
const route = 'notifier/';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private socket: Socket,
    private http: HttpClient) {}
  updateStatus(data) {
    return this.http.post(apiUrl + route + 'update', data);
  }
  createNotify(data: Notification) {
    this.http.post<Notification>(apiUrl + route, data).subscribe((result) => {
      console.log('Notification Result: ', result);
      // Notify all stake holders
      this.notify(result);
    });
  }
  notify(data: Notification) {
    this.socket.emit('notify', data);
  }
  getNotify() {
    return this.socket.fromEvent('notify');
  }
  getMyNotifications() {
    return this.http.get<Notification[]>(apiUrl + route);
  }
}
