import { HrDocument } from './hrdocument';

import { BaseModel } from './base';

export class LeaveRequest extends BaseModel {
  userId: string;
  staffName: string;
  startDate: string;

  endDate: string;

  dateApplied: string;

  leaveReason: string;

  supportDoc: HrDocument[];

  leaveStatus: string;

  currentLeaveBal: string;
  replies?: [
    {
      userId: string;
      reason: string;
      status: string;
      position: string;
    }
  ];
  noOfDays: number;

  approvalStatus: string;

  subscriberId: string;
  unitId: string;
  siteId: string;
  year: string;
  leaveType: string;
  requestorId: string;
  reasonForCancelOrApprove: string;
  approvalTrackingNo: string;
}
