import { environment } from 'apps/ebs/src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { Credentials, UserRO, User, UserDto } from '@mrsoftebs/data';

import {
  catchError,
  mapTo,
  tap,
  shareReplay,
  concatMap,
  finalize,
} from 'rxjs/operators';
import { config } from './../config';
import { Tokens } from '../models/tokens';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;

  url = environment.apiUrl;
  accountURL = environment.apiUrl;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingSite$ = this.subject$.asObservable();

  constructor(private http: HttpClient) {}

  getCountry(): Observable<UserDto[]> {
    return this.http.get<UserDto[]>(this.url + 'country');
  }

  createCountry(data: UserDto[]): Observable<UserDto[]> {
    // console.log('this is service ', data)
    return this.http.post<UserDto[]>(this.url + 'country', data);
  }
  getSingleCountry(id: string): Observable<any> {
    return this.http.get(this.url + 'country/' + id);
  }

  updateCountry(data: UserDto[]): Observable<UserDto[]> {
    return this.http.post<UserDto[]>(this.url + 'country/update', data);
  }

  getAllUsersBySubscriberId(): Observable<User[]> {
    return this.http.get<User[]>(this.url + 'user/subscriber/', {});
  }

  deleteCountry(ids: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: ids,
    };

    let params = new HttpParams();
    params = params.append('country', ids.join(', '));
    return this.http.delete(this.url + 'country', httpOptions);
  }

  login(data: Credentials): Observable<UserRO> {
    /**
     * Simulate a failed login to display the error
     * message for the login form.
     */
    // if (username !== 'test' && username !== 'ngrx') {
    //   return throwError('Invalid username or password');
    // }

    // return of({ id: '1233', username: 'User', phoneNo: '363633'  });

    //console.log(' Data From Fomr ', data);

    //return this.http.post<UserRO>(this.accountURL + 'user/login', data);
    return this.http.post<UserRO>(this.accountURL + 'user/signin', data);
  }

  creatUser(user: UserDto[]): Observable<UserDto[]> {
    /**
     * Simulate a failed login to display the error
     * message for the login form.
     */
    // if (username !== 'test' && username !== 'ngrx') {
    //   return throwError('Invalid username or password');
    // }

    // return of({ id: '1233', username: 'User', phoneNo: '363633'  });

    console.log(' User for register ', this.accountURL, user);

    return this.http.post<UserDto[]>(this.accountURL + 'user/register', user);
  }

  // logout() {
  //   return of(true);
  // }
  logout() {
    return this.http
      .post<any>(`${config.apiUrl}/logout`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError((error) => {
          alert(error.error);
          return of(false);
        })
      );
  }

  // Site module endpoints

  getAllSiteBySubscriberId(id): Observable<User[]> {
    return this.http
      .post<User[]>(this.url + 'user/site/', { id })
      .pipe(shareReplay());
  }

  deleteSiteId(id): Observable<any> {
    return this.http
      .delete(this.url + 'user/deleteone/' + id)
      .pipe(shareReplay());
  }

  creatSite(user: any[]): Observable<UserDto[]> {
    return this.http
      .post<UserDto[]>(this.accountURL + 'user/create-site', user)
      .pipe(shareReplay());
  }

  updateSite(user: UserDto): Observable<UserDto> {
    return this.http
      .post<UserDto>(this.accountURL + 'user/update-site', user)
      .pipe(shareReplay());
  }

  showSpinner() {
    return this.subject$.next(true);
  }

  hideSpinner() {
    return this.subject$.next(false);
  }

  showLoaderUntilComplement<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => this.showSpinner()),
      concatMap(() => obs$),
      finalize(() => this.hideSpinner())
    );
  }

  // end of site endpoint

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http
      .post<any>(`${config.apiUrl}/refresh`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap((tokens: Tokens) => {
          this.storeJwtToken(tokens.jwt);
        })
      );
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
