import { BaseModel } from './base';
import { MaintenanceCategory } from './maintenancecategory';

export class RecordsBook extends BaseModel {
  maintenancecategoryId: MaintenanceCategory;
  capturedate: string;
  activity: string;
  actionparty: string;
  actionpartysignature: string;
  unityheadsignature: string;
  remark: string;
  type: string;
  time: string;
  openingpessure: string;
  subpressure: string;
  leakcheck: string;
  equipment: string;
  calibratedgaugename: string;
  calibratedgaugerate: string;
  calibratedgaugemaximumreading: string;
  calibratedby: string;
  assistedby: string;
  calibratorreading: string;
  calibratorgaugereading: string;
  errorPercentage: number;
  datecreated: Date;
  createdPersonId: string;
  subscriberId: string;
  siteId: string;
}
