import { BaseModel } from "./base";

export class RequestType extends BaseModel {
  requestTypeId?: string;

  requestTypeName: string;

  subscriberId: string;

  siteId: string;

  dateCreated: string;

  dateModified: string;
  id: string;
}
