import { ApprovalType } from './approvaltype';

import { BaseModel } from './base';

export class ApprovalSetting extends BaseModel {
  approvaltypeName: string;
  ApprovalTypeId: string;
  NoOfApprovals: number;
  minNoApprovals: number;
  dateCreated: string;
  dateModified: string;
  SubscriberId: string;
  SiteId: string;
}
