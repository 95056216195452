import { BaseModel } from './base';

export class ApplicationDegree extends BaseModel{

  degreeId: string;

  degreeName: string;

  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  _id: string;
}
