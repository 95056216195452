import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { TokenService } from './services/token.service';
import { Role } from '@mrsoftebs/data';
import { ErrorComponent } from './components/top-nav/error/error.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UnAuthorizeComponent } from './components/un-authorize/un-authorize.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  globalRoute = [
    '/',
    '/leave/leavereport',
    '/staff/staffupdate',
    'leave/leaverequest',
  ];
  userRoles: Role[] = JSON.parse(localStorage.getItem('userRoles'));
  exist: Role;
  url = '';
  constructor(
    private auth: AuthenticationService,
    private myRoute: Router,
    private tokenServ: TokenService,
    private dialog: MatDialog
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.url = state.url;
    if (this.url.charAt(0) != '/') {
      this.url = '/'.concat(this.url);
    }
    if (this.url.charAt(this.url.length - 1) != '/') {
      this.url = this.url.concat('/');
    }

    const userRoles: Role[] = JSON.parse(localStorage.getItem('userRoles'));
    // this.formatRoute(state);
    // console.log('userRole; ', userRoles);
    const found = userRoles?.find((role) => {
      // console.log('role; ', role);
      if (role?.link?.charAt(0) != '/') {
        role.link = '/'.concat(role.link);
      }
      if (role?.link?.charAt(role.link.length - 1) != '/') {
        role.link = role.link.concat('/');
      }
      // console.log('role; ', role);
      return role?.link.includes(state.url);
    });
    console.log('found: ', found, ' state route: ', state.url);
    // if (this.tokenServ.getloggedInUser()) {
    //   return true;
    // }
    // return false;
    if (this.tokenServ.getloggedInUser()) {
      if (this.globalRoute.find((route) => route.includes(state.url))) {
        return true;
      } else if (found) {
        console.log('route is valid 3: ', true);
        return true;
      } else {
        this.myRoute.navigate(['/error/unauthorized']);
        console.log('route is valid 4: ', false);
        return false;
      }
    } else {
      const dialogRef = this.dialog.open(ErrorComponent, {
        backdropClass: 'true',
        data: {
          message: `You can not access this page without been logged in. We're redirecting you to login page`,
        },
      });
      setTimeout(() => {
        this.myRoute.navigate(['/auth/login']);
        dialogRef.close();
      }, 5000);
      return false;
    }
  }

  // formatRoute(state) {
  //   if (this.userRoles && this.userRoles?.length) {
  //     for (const role of this.userRoles) {
  //       if (role?.link?.charAt(role.link.length - 1) !== '/') {
  //         role?.link?.concat('/');
  //       }
  //       if (role.link.charAt(0) == '/' && state.url.charAt(0) !== '/') {
  //         state.url += '/' + state.url;
  //       }
  //       const roleFirstLetter = role?.link?.charAt(0);
  //       const stateFirstLetter = state.url.charAt(0);
  //       if (
  //         roleFirstLetter === '/' &&
  //         (stateFirstLetter === '/' || stateFirstLetter === '')
  //       ) {
  //         return role.link === state.url;
  //       }
  //       if (roleFirstLetter === '/' && stateFirstLetter !== '/') {
  //         role.link = role.link.substring(1);
  //       }
  //       if (roleFirstLetter !== '/' && stateFirstLetter === '/') {
  //         state.url = state.url.substring(1);
  //         console.log('url: ', state.url);
  //       }
  //       console.log('stateUrl: ', state.url);
  //       // console.log('Url 1: ', state.url, 'role link: ', role?.link);
  //       if (state.url === role.link) {
  //         this.exist = role;
  //         break;
  //       }
  //     }
  //   }
  //   console.log('stateUrl 1: ', state.url);
  // }
}
