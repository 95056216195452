import { BaseModel } from "./base";

export class HseToolsDto extends BaseModel {
  toolId: string;
  toolName: string;
  subscriberId: string;

  siteId: string;


}
