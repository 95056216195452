import { BaseModel } from './base';

export class   ContractStatus extends BaseModel{
  contractStatusId: string;
  contractStatusName: string;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
}
