import { BaseModel } from './base';

export class MaintenanceCategory extends BaseModel {
  maintenancacategoryName: string;
  SubscriberId: string;

  SiteId: string;

  id: string;
}
