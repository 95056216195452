import { BaseModel } from './base';

export class  EmploymentStatus  extends BaseModel{
    employmentStatusId?: string;
    employmentStatusName: string;
    subscriberId: string;
    siteId: string;
    dateCreated: string;
    dateModified: string;
    id: string;
  }
  