import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardNavComponent } from './dashboard-nav/dashboard-nav.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularMaterialModule } from '@mrsoftebs/material';
import { RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { NgxSpinnerModule } from 'ngx-spinner';
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    NgxSpinnerModule,
    AngularMaterialModule,
  ],
  declarations: [DashboardNavComponent, HomepageComponent],
  exports: [DashboardNavComponent],
})
export class DashboardNavModule {}
