import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { ErrorService } from './models/error/error.service';

@Component({
  selector: 'mrsoftebs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  error: any;

  error$: Observable<any[]>;
}
