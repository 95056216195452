<div class="container">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let subscriber of filteredSubscriber">
            <mat-expansion-panel-header>
                {{subscriber.sub.StaffName}}
            </mat-expansion-panel-header>
            <div>
                <div>
                    <p>
                        Total Subscrition Amount: <span> {{calTotal(subscriber.subscriptions).totalAmount}} </span>,
                        Total Subscription: <span> {{calTotal(subscriber.subscriptions).totalSub}}</span>,
                        Total Amount Paid: <span> {{calTotal(subscriber.subscriptions).totalAmountPaid}}</span>,
                        Yet to be Paid: <span> {{calTotal(subscriber.subscriptions).notPaid}}</span>,
                        Negotiated: <span> {{calTotal(subscriber.subscriptions).negotiated}}</span>
                        <button mat-icon-button (click)="approveAll(subscriber.subscriptions)" title="Approve all">
                            <mat-icon class="text-success">check</mat-icon>
                        </button>

                    </p>
                </div>
                <div>



                    <mat-card style="width: 30%; height: 200px; display: inline-block;"
                        *ngFor="let role of subscriber.subscriptions; index as i" class="m-2 float-left">
                        <mat-card-header>


                            <mat-card-subtitle>{{role.ModuleName}}
                                <button mat-icon-button (click)="activateModule(role)">
                                    <mat-icon *ngIf="role.ApprovalStatus === 'Pending'" class="text-danger"
                                        title="Approve">cancel</mat-icon>
                                    <mat-icon *ngIf="role.ApprovalStatus === 'Paid'" class="text-success"
                                        title="Disapprove">check</mat-icon>
                                </button>
                            </mat-card-subtitle>
                        </mat-card-header>
                        <p>Payment Status: {{role.PaymentStatus}}</p>
                        <p>Approval Status: {{role.ApprovalStatus}}</p>
                        <p>Subscription Amount:
                            <span *ngIf="role.Unlimited !== 'Unlimited'">
                                {{role.AmountPaid}}
                            </span>
                            <span *ngIf="role.Unlimited === 'Unlimited'">
                                Negotiated
                            </span>
                            <span *ngIf="role.Unlimited !== 'Unlimited'">
                                Duration: {{role.Duration}} month<span *ngIf="Duration > 1">'s</span>
                            </span>
                        </p>
                        <mat-card-content>
                            <p>{{role.Description}}</p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>