import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { TokenService } from 'apps/ebs/src/app/services/token.service';
import { LogService } from 'apps/ebs/src/app/services/log.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  showProgress = false;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private tokenService: TokenService,
    private dialogRef: MatDialogRef<SigninComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.logService.log('Data: ', this.data);
    this.signinForm = this.fb.group({
      email: [''],
      password: [''],
      rememberMe: [''],
    });
  }
  onSubmit() {
    this.showProgress = true;
    console.log('Form value: ', this.signinForm.value);
    this.accountService.dialogSignin(this.signinForm.value).subscribe(
      (data) => {
        // console.log('signin data: ', data);
        this.tokenService.setLoggedIn(true);
        this.accountService.saveUser(data.access_token);
        this.accountService.setToken(data.access_token);
        // this.dialogRef.close('Closing dialog. ...');
        this.router.navigateByUrl(`${this.data.returnUrl}`);
        window.location.reload();
      },
      (err) => {
        this.showProgress = false;
      }
    );
    // this.dialogRef.close();
  }
}
