import { BaseModel } from './base';
import { Samples } from './chainofcustody';

export class ClientAnalysisRequest extends BaseModel {
  date: Date;
  receivedBy: string;
  time: string;
  expectedTAT: string;
  jobId: string;
  clientAddress: string;
  client: string;
  sampleType: string;
  noOfSamples: number;
  dateSampled: Date;
  sampledBy: string;
  sampleSource: string;
  nameOfClientRep: string;
  phoneNo: string;
  note: string;
  signature: string;
  laserRepName: string;
  laserRepSignature: string;
  anticipatedUse: string;
  sendResultBy: string;
  docNo: string;
  issuanceNo: string;
  logo: string;
  samples: Samples[];
  _id?: string;
}
