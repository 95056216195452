import { BaseModel } from './base';

export class DisbursementNotice extends BaseModel {


  RequestNo: string;
  RequestAmount: number;
  RequestDescription: string;
  Requestor: string;
  RequestDate: Date;
  RequestStatus: string;
  SenderId: string;
  SenderName: string;
  SenderDate: Date;
  ReceiverId: string;
  ReceiverName: string;
  IsSeen: boolean;
  Responded: boolean;
  RespondedDate: Date;
  Message: string;
  RequestType: string;
  RequisitionId: string;

}
