import { BaseModel } from './base';

export class BloodGroup extends BaseModel {
  bloodGroupId: string;
  bloodGroupName: string;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
  _id: string;
}
