import { props, createAction } from '@ngrx/store';
import { UserRO , UserDto} from 'libs/data/src/lib';


export const loginSuccess = createAction(
  '[Auth/API] Login Success',
  props<{ user: UserRO }>()
);

export const loginFailure = createAction(
  '[Auth/API] Login Failure',
  props<{ error: any }>()
);

export const addUserSuccess = createAction(
  '[Auth/API] Register Success',
  props<{ user: UserDto[] }>()
);

export const addUserFailure = createAction(
  '[Auth/API] Register Failure',
  props<{ error: any }>()
);

export const updateUserSuccess = createAction(
  '[Auth/API] Update User Success',
  props<{ user: UserDto[] }>()
);

export const updateUserFailure = createAction(
  '[Auth/API] Update User Failure',
  props<{ error: any }>()
);

export const removeUserSuccess = createAction(
  '[Auth/API] Remove User Success',
  props<{ ids: string[] }>()
);

export const removeUserFailure = createAction(
  '[Auth/API] Remove User Failure',
  props<{ error: any }>()
);


export const loadUserSuccess = createAction(
  '[Auth/API] Load User Success',
  props<{ user: UserDto[] }>()
);

export const loadUserFailure = createAction(
  '[Auth/API] Load User Failure',
  props<{ error: any }>()
);

export const loginRedirect = createAction('[Auth/API] Login Redirect');