import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ListSpinnerComponent } from "./components/list-spinner/list-spinner.component";

@NgModule({
  imports: [CommonModule, NgxSkeletonLoaderModule],
  declarations: [
    ListSpinnerComponent,
  ],
  exports: [
    ListSpinnerComponent,
  ],
})
export class SpinnersModule {}
