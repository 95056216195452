import { environment } from 'apps/ebs/src/environments/environment';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IsString } from 'class-validator';
import { NgxIndexedDBService } from 'ngx-indexed-db';

// import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class GatewayService {
  url = environment.apiUrl;
  encryptSecretKey: string = '!data1998@#0007653@&^%(!gen4keypass';
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isHome$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService
  ) {}

  getAll(type: string): Observable<any[]> {
    //console.log("Reaching Get All ", type);
    // const httpOptions = {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    //     type: type
    // };

    // let params = new HttpParams();
    // params = params.append('type', type);

    // //console.log("Http Options ", httpOptions);

    return this.http.get<any[]>(this.url + 'ebsgates?type=' + type);
  }

  createData(type: string, data: any[]): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    console.log('Service Adding ');
    return this.http.post<any[]>(
      this.url + 'ebsgates/createdata?type=' + type,
      data
    );
  }

  addOffLineData(
    type: string,
    data: any,
    transactionno: string,
    transdate: Date
  ) {
    return this.dbService.add('tbl', {
      // name: this.encryptData(data),
      batchNo: transactionno,
      transdate: transdate,
      transtype: type,
    });
  }

  getOfflineData(type: string) {
    return this.dbService.getAllByIndex(
      'tbl',
      'transtype',
      IDBKeyRange.only(type)
    );
  }

  decryptAllData(data: any[]): any[] {
    let decryptedData = [];

    if (data.length > 0) {
      for (let dat of data) {
        // decryptedData.push(this.decryptData(dat["name"]));
      }
    }

    return decryptedData;
  }
  addOneFriend() {
    this.dbService
      .add('people', {
        //   name: this.encryptData({
        //   name: `Nsisong Nuka`,
        //   email: `nukstel@yahoo.com`,
        // }),
        email: `nukstel@yahoo.com`,
        transdate: new Date(),
      })
      .subscribe((key) => {
        //console.log('key: ', key);
      });

    // Update

    // this.dbService
    // .update('people', {
    //   id: 1,
    //   email: 'luke@skywalker.com',
    //   name: 'Luke Skywalker',
    // })
    // .subscribe((storeData) => {
    //   //console.log('storeData: ', storeData);
    // });

    // this.dbService.getByKey('people', 1).subscribe((people) => {
    //   //console.log(people);
    // });

    // this.dbService.getAll('people').subscribe((peoples) => {
    //   //console.log(peoples);
    // });

    // QueryParameter

    // this.dbService.getByIndex('people', 'name', 'Dave').subscribe((people) => {
    //   //console.log(people);
    // });

    // Count
    // this.dbService.count('people').subscribe((peopleCount) => {
    //   //console.log(peopleCount);
    // });

    // Delete

    // this.dbService.delete('people', 3).subscribe((allPeople) => {
    //   //console.log('all people:', allPeople);
    // });
  }

  // encryptData(data) {

  //   try {
  //     return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
  //   } catch (e) {
  //     //console.log(e);
  //   }
  // }

  // decryptData(data) {

  //   try {
  //     const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
  //     if (bytes.toString()) {
  //       return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     }
  //     return data;
  //   } catch (e) {
  //     //console.log(e);
  //   }
  // }

  getapprovalbyapprovaltype(
    type: string,
    staffId: string,
    take: number,
    skip: number,
    status: string
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.url +
        'ebsgates/getapprovalbyapprovaltype?type=' +
        type +
        '&&staffId=' +
        staffId +
        '&&take=' +
        take +
        '&&skip=' +
        skip +
        '&&status=' +
        status,
      []
    );
  }

  approvaldetailsbystatus(
    type: string,
    staffId: string,
    take: number,
    skip: number,
    status: string,
    approvalType: string,
    searchText: string,
    fromDate: string,
    toDate: string
  ): Observable<any[]> {
    return this.http.post<any[]>(
      this.url +
        'ebsgates/approvaldetailsbystatus?type=' +
        type +
        '&&staffId=' +
        staffId +
        '&&take=' +
        take +
        '&&skip=' +
        skip +
        '&&status=' +
        status +
        '&&approvalType=' +
        approvalType +
        '&&searchText=' +
        searchText +
        '&&fromDate=' +
        fromDate +
        '&&toDate=' +
        toDate,
      []
    );
  }

  createDataBatch(data: any[]): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<any[]>(this.url + 'ebsgates/createdatabatch', data);
  }

  public BatchDataCreateUpdateWithFile(
    files: File[],
    data: any[]
  ): Observable<HttpEvent<{}>> {
    const formData = new FormData();

    console.log('File Length ', files.length);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // formData.append(files[i].name, files[i]);

        if (files[i] !== undefined) {
          formData.append('file', files[i], files[i].name);
        }
      }
    }

    // formData.append('file', file, file.name);
    formData.append('Body', JSON.stringify(data));

    //console.log("Reaching Service for Upload", file);

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${this.url + 'ebsgates/createdatabatchWithFiles'}`,
      formData,
      options
    );

    //console.log("Http Request ", req);
    return this.http.request(req);
  }
  getSingleData(type: string, id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);
    return this.http.get(this.url + 'ebsgates?type=' + type + id, httpOptions);
  }

  updateDataWithIds(
    type: string,
    data: any,
    status: string
  ): Observable<any[]> {
    //console.log("Reaching Update function ", type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    //let newDataStruct = { data: data, deletedData: deletedData };

    return this.http.post<any[]>(
      this.url + 'ebsgates/updatewithids?type=' + type + '&&status=' + status,
      data
    );
  }

  updateData(type: string, data: any[], deletedData: any[]): Observable<any[]> {
    //console.log("Reaching Update function ", type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    let newDataStruct = { data: data, deletedData: deletedData };

    return this.http.post<any[]>(
      this.url + 'ebsgates/update?type=' + type,
      newDataStruct
    );
  }

  updateDataBatch(data: any[], deletedData: any[]): Observable<any[]> {
    //console.log("Reaching Update function ", data);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    let newDataStruct = { data: data, deletedData: deletedData };

    return this.http.post<any[]>(this.url + 'ebsgates/batchupdate', data);
  }

  deleteData2(type: string, ids: any) {
    console.log('Reaching App Delete Service', ids);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: ids,
    };

    let params = new HttpParams();
    params = params.append('stakeholderRef', ids.join(', '));

    return this.http.delete(this.url + 'ebsgates?type=' + type, httpOptions);
  }

  deleteData(type: string, ids: any): Observable<any[]> {
    //console.log("Reaching Update function ", data);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    // let newDataStruct = { data: data, deletedData: deletedData}

    return this.http.post<any[]>(
      this.url + 'ebsgates/deleteDataRefPost?type=' + type,
      ids
    );
  }

  public uploadFile(
    file: File,
    data: any[],
    type: string
  ): Observable<HttpEvent<{}>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('Body', JSON.stringify(data));

    //console.log("Reaching Service for Upload", file);

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${this.url + 'ebsgates/createdocument?type=' + type}`,
      formData,
      options
    );

    //console.log("Http Request ", req);
    return this.http.request(req);

    //   const formData2 = new FormData();
    // formData2.append('file', file);

    // return  this.http.post<any>(this.url  + 'epimsgates/createdocument?type=' + type, formData, {
    //   reportProgress: true,
    //   observe: 'events'
    // });

    //   this.http.get<any>(this.url  + 'epimsgates?type=Document').subscribe((data) => {
    //     //console.log("Data from Document Table ", data);
    //   })

    //     return this.http.post<any>(this.url  + 'epimsgates/createdocument?type=' + type, data, {
    //       reportProgress: true,
    //       observe: 'events'
    //     })
  }


  public CreateUpdateFinalReportWithFile(
    file: File,
    data: any[],
    type: string
  ): Observable<HttpEvent<{}>> {
    const formData = new FormData();

    if(file !== undefined){

      formData.append('file', file, file.name);
    }

    formData.append('Body', JSON.stringify(data));

    console.log('Reaching Service With File Upload ', this.url);

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${this.url + 'ebsgates/createFinalReportDocument?type=' + type}`,
      formData,
      options
    );


    // const req = new HttpRequest(
    //   'POST',
    //   `${this.url + 'ebsgates/createfinalreportdocumentNew?type=' + type}`,
    //   formData,
    //   options
    // );

    console.log('Http Request ', req);

    return this.http.request(req);


  }

  genericFilter(
    type: string,
    entryType: string,
    searchField: any
  ): Observable<any[]> {
    console.log('Reaching genericFilter function ', type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    const newDataStruct = { entryType: entryType, searchField: searchField };

    return this.http.post<any[]>(
      this.url + 'ebsgates/search?type=' + type,
      newDataStruct
    );
  }

  filterMemo(
    type: string,
    take: number,
    skip: number,
    status: string,
    searchText,
    toDate: string,
    fromDate: string
  ): Observable<any[]> {
    console.log('Filter memo ', type, skip, take, searchText, status);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    return this.http.post<any[]>(
      this.url +
        'ebsgates/searchMemo?type=' +
        type +
        '&&skip=' +
        skip +
        '&&take=' +
        take +
        '&&status=' +
        status +
        '&&searchText=' +
        searchText +
        '&&toDate=' +
        toDate +
        '&&fromDate=' +
        fromDate,
      []
    );
  }
  filterMeetingByStaff(
    type: string,
    take: number,
    skip: number,
    status: string,
    searchText: string,
    toDate: string,
    fromDate: string,
    staffId: string,
    subscriberId: string,
    siteId: string
  ): Observable<any[]> {
    console.log(
      'Filter meeting ',
      staffId,
      type,
      skip,
      take,
      searchText,
      status
    );
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    return this.http.post<any[]>(
      this.url +
        'ebsgates/searchMeetingByStaff?type=' +
        type +
        '&&skip=' +
        skip +
        '&&take=' +
        take +
        '&&status=' +
        status +
        '&&searchText=' +
        searchText +
        '&&toDate=' +
        toDate +
        '&&fromDate=' +
        fromDate +
        '&&staffId=' +
        staffId +
        '&&subscriberId=' +
        subscriberId +
        '&&siteId=' +
        siteId,
      []
    );
  }

  filterMemoByStaff(
    type: string,
    take: number,
    skip: number,
    status: string,
    searchText,
    toDate: string,
    fromDate: string,
    staffId: string
  ): Observable<any[]> {
    console.log('Filter memo ', type, skip, take, searchText, status);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    return this.http.post<any[]>(
      this.url +
        'ebsgates/searchMemoByStaff?type=' +
        type +
        '&&skip=' +
        skip +
        '&&take=' +
        take +
        '&&status=' +
        status +
        '&&searchText=' +
        searchText +
        '&&toDate=' +
        toDate +
        '&&fromDate=' +
        fromDate +
        '&&staffId=' +
        staffId,
      []
    );
  }

  requisitiondetail(
    type: string,
    datafields: any,
    take: number,
    skip: number
  ): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    return this.http.post<any[]>(
      this.url +
        'ebsgates/requisitiondetails?type=' +
        type +
        '&&skip=' +
        skip +
        '&&take=' +
        take,
      datafields
    );
  }

  disbursementNoticeResponse(type: string, datafields: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    return this.http.post<any[]>(
      this.url + 'ebsgates/disbursementNoticeResponse?type=' + type,
      datafields
    );
  }

  requisitionsummary(type: string, datafields: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    console.log('Data passed in the service ', datafields);

    return this.http.post<any>(
      this.url + 'ebsgates/requisitionsummary?type=' + type,
      datafields
    );
  }

  disbursementNotice(type: string, datafields: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    //   let params = new HttpParams();
    //   params = params.append('type', type);

    console.log('Data passed in the service ', datafields);

    return this.http.post<any>(
      this.url + 'ebsgates/disbursementNotice?type=' + type,
      datafields
    );
  }

  sendMail(): Observable<any[]> {
    //console.log("Reaching Mail Sending in Gateway Service");
    return this.http.get<any>(this.url + 'mailer');
  }

  sendPipelineLicenceMail(data: any): Observable<any> {
    //console.log("Reaching Send Pipeline Mail ");
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(
      this.url + 'mailer/sendpipelinelicencemail',
      data
    );
  }

  sendManageAccountMail(data: any): Observable<any> {
    //console.log("Reaching Send Pipeline Mail ");
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(this.url + 'mailer/manageaccountmail', data);
  }

  sendSeismicNoticeMail(data: any): Observable<any> {
    //console.log("Reaching Send Pipeline Mail ");
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(this.url + 'mailer/seismicnoticeemail', data);
  }
  recruitmentnotification(data: any): Observable<any> {
    //console.log("Reaching Send Pipeline Mail ");
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(this.url + 'mailer/recruitmentnotification', data);
  }

  getAllGeneric(type: string, dataObject: any): Observable<any[]> {
    console.log('Reaching Get All Generic ', type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any[]>(
      this.url + 'ebsgates/getallgeneric?type=' + type,
      dataObject
    );
  }


  recruitmentAdminStat(type: string, dataObject: any): Observable<any[]> {
    console.log('Reaching Get All Generic ', type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any[]>(
      this.url + 'ebsgates/recruitmentAdminStat?type=' + type,
      dataObject
    );
  }


  finalReportData(type: string,userId: string, dataObject: any): Observable<any[]> {
    console.log('Reaching Get All Generic ', type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any[]>(
      this.url + 'ebsgates/finalReportData?type=' + type + '&&userId='+ userId,
      dataObject
    );
  }





  getAllGenericApplicant(type: string, dataObject: any): Observable<any[]> {
    console.log('Reaching Get All Generic ', type);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any[]>(
      this.url + 'ebsgates/getallgenericapplicant?type=' + type,
      dataObject
    );
  }
}
