import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { AuthFacade } from '@mrsoftebs/auth/';
import { LoggedInUser, User } from '@mrsoftebs/data';
import { AuthenticationService } from 'apps/ebs/src/app/services/authentication.service';
import { GatewayService } from 'libs/gateway/src/lib/services/gateway.service';
import { GatewayFacade } from 'libs/gateway/src/lib/states/+gateway/gateway.facades';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-subscriberinfo',
  templateUrl: './subscriberinfo.component.html',
  styleUrls: ['./subscriberinfo.component.scss'],
})
export class SubscriberinfoComponent implements OnInit {
  user: LoggedInUser;
  subscriber: User;
  href: string = '';
  registerForm: FormGroup;
  file: File;
  selectedFileName: string = '';
  fileSize: string = '';
  fileType: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,

    private gatewayFacade: GatewayFacade,
    // private authFade: AuthFacade,
    public authServ: AuthenticationService,
    public gatewayService: GatewayService,
    public authService: AuthService
  ) {
    let user: any = JSON.parse(localStorage.getItem('EBSloggedInUser'));
    //this.user = this.authServ.getUser();
    this.user = user;
    console.log('Logged In User ', this.authServ.getUser());
  }

  ngOnInit() {
    this.initRegisterForm();

    //this.loadData();
  }

  initRegisterForm() {
    this.registerForm = this.fb.group({
      Id: this.user?.userId ? this.user.userId : [''],
      UserName: this.user?.email ? this.user.email : [''],
      PhoneNo: ['080'],
      // password: [''],
      Email: this.user?.email ? this.user.email : [''],
      UserType: ['Subscriber'],
      SubscriberName: this.user?.subscriberName
        ? this.user?.subscriberName
        : [''],
      // FirstName: [''],
      // LastName: [''],
      // middleName: [''],
      // StaffName: [''],
      logoURL: [''],
      // verifyStatus: [''],
      // isConfirmed: [''],
      // UIN: [''],
      // newUser: [''],
      // spouseName: [''],
      // noOfChildren: [0],
      // address: [''],
      // gender: [''],
      // ocupation: [''],
      // userRole: [''],
      // slogan: [''],
      // SubscriberId: [''],
      // SiteId: [''],
      // siteName: [''],
      // siteNumber: [''],
      // levelCount: [0],
      // canAddSub: [true],
      // siteGenericNumber: [''],
      // systemAdminStatus: [''],
      // resetPasswordToken: [''],
      // resetPasswordExpires: [''],
      // parentId: [''],
      _id: this.user?.userId ? this.user.userId : [''],
    });

    this.loadData();
  }

  getAllData() {
    this.gatewayFacade.loadGateway('IdentityUser').subscribe((data) => {
      console.log('Data for All ', data);
    });
  }

  loadData() {
    this.gatewayFacade
      .genericFilterGateway('IdentityUser', '_id', this.user.userId)
      .subscribe((data) => {
        console.log('Data Gotten Subscriber ', data);

        //Checck if Record is more than one
        // Check if user type is Subscriber

        if (data.length == 1) {
          if (data[0].UserType === 'Subscriber') {
            this.subscriber = data[0];
            this.registerForm.patchValue(data[0]);
          }
        }
      });
  }

  onSubmit() {
    //this.authService.isLoading$.next(true);

    this.registerForm.value.StaffName = this.registerForm.value.SubscriberName;
    this.registerForm.value.Email = this.registerForm.value.UserName;
    let newAdd = [];
    console.log('User Identity', this.registerForm.value);
    newAdd.push(this.registerForm.value);

    //   this.gatewayService.sendManageAccountMail(mailData).subscribe((data) => {

    //  });

    this.registerForm.reset;

    //this.authFade.addNewUser(newAdd);

    if (this.file !== undefined) {
      this.gatewayService
        .uploadFile(this.file, newAdd, 'IdentityUser')
        .subscribe((data) => {
          console.log('Data from Server with Files ', data);
          // this.loadDocuments();
        });
    } else {
      // Update without File
    }
  }

  uploadFile(event: any) {
    const files: FileList = event.target.files;
    const file = files.item(0);

    this.file = file;

    let document: Document[] = [];

    this.selectedFileName = file.name;
    this.fileSize = Math.floor(file.size / 1000).toString() + ' KB';
    //this.fileType = file.type.split('.')[1];
    this.fileType = file.type;

    // this.documentForm.value.FileName.setValue = file.name;
    // this.documentForm.get('FileName').setValue(
    //   file.name.split('.')[0]
    // );

    // let doc = new DocumentDto();

    // doc.DocumentTitle = "Testing Document Upload";
    // doc.DocumentDescription = "New Document posting for development";

    // document.push(doc);

    console.log('Selected File ', file);

    // clear the input form
    event.srcElement.value = null;
  }
}
