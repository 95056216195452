import { BaseModel } from './base';

export class  LeaveApprovalPerson  extends BaseModel {
  leaveTypeId: string;

  subscriberId: string;

  siteId: string;

  approvalpersonId: string;

  leavelevelId: string;

  levelpriority: string;
}
