import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { RoleDto } from '@mrsoftebs/data';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAuth from '../reducers';

@Injectable({
  providedIn: 'any',
})
export class AuthorizedGuard implements CanActivate {

  isAuthorized = false;
  constructor(private store: Store<fromAuth.State>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      const appInStorage: RoleDto[] = JSON.parse(localStorage.getItem('Link'));
              if(appInStorage && state.url) {
                const found = appInStorage.find(role => role.link && role.link.toLowerCase() === state.url.toLowerCase());
                if(!found && state.url !== '/dashboard') {
                  this.router.navigate(["/dashboard"]);
                  return false;
                } else {
                  return  true;
                }
              }

  }

}
