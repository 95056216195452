import { BaseModel } from "./base";

export class StationariesItemHistory extends BaseModel{
  ItemId: string;
  ItemName: string;
  QtyLeft: number;
  QtyLastAdded: number;
  PreviousQty: number;
  DateModified: string;
  ModifiedCost: number;
  PreviousCost: number;
  CreatedPersonId: string;
  SubscriberId: string;
  SiteId: string;
}
