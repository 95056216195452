import { DBConfig } from 'ngx-indexed-db';
 
export const dbConfig: DBConfig  = {
  name: 'edblockdat',
  version: 1,
  objectStoresMeta: [{
    store: 'tbl',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
      { name: 'batchNo', keypath: 'batchNo', options: { unique: false } },
      { name: 'transdate', keypath: 'transdate', options: { unique: false } },
      { name: 'transtype', keypath: 'transtype', options: { unique: false } }
    ]
  }]
};