import { BaseModel } from './base';

export class SampleType extends BaseModel {
  TypeName: string;

  SubscriberId: string;
  SiteId: string;
  id: string;
  _id: string;
}
