import { BaseModel } from './base';

export class Document extends BaseModel {
  name: string;
  url: string;
  path: string;
  type: string;
  moduletype: string;
  subscriberid: string;
  siteId: string;
}
