import { BaseModel } from './base';

export class PayRollSetting extends BaseModel {
  dueDate: number;
  notifyManager: boolean;
  autoGenerate: boolean;
  subscriberId: string;
  siteId: string;
  status: string;
}
