import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { Company } from '../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  @Output()
  selectedCompany: EventEmitter<Company> = new EventEmitter<Company>();
  @Output() loggedInUser: EventEmitter<User> = new EventEmitter<User>();
  @Output()
  isAuthenticated: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private myRoute: Router) {}
  sendToken(token: string) {
    localStorage.setItem('loggedInUser', token);
  }

  getCompany(): Company {
    // console.log("Reaching getting Company " , localStorage.getItem("Company"));
    let comp: Company = JSON.parse(localStorage.getItem('Company'));
    this.selectedCompany.emit(comp);

    //  console.log("Now Emitting");
    return comp;
  }

  getToken() {
    // this.localStorage.getItem('loggedInUser').subscribe((user) => {
    //   if (user != null) {
    //     //user.firstName;
    //   }
    // }, () => {});
    return localStorage.getItem('loggedInUser');
  }
  isLoggednIn() {
    return this.getToken() !== null;
  }
  logout() {
    localStorage.removeItem('loggedInUser');
    //localStorage.removeItem('Company');
    //this.getUser();

    this.myRoute.navigate(['/login']);
  }

  getUser(): any {
    let user: any = JSON.parse(localStorage.getItem('EBSloggedInUser'));
    console.log('User gotten ', user, localStorage.getItem('EBSloggedInUser'));

    if (user) {
      //console.log("User gotten ", user.user );
      this.loggedInUser.emit(user);
      this.isAuthenticated.emit(true);
      return user;
    } else {
      this.isAuthenticated.emit(false);
    }

    //console.log("User Gotten ", user);
    return user;
  }

  canAccessModule(modName: string, act: string): boolean {
    console.log('Access control...');
    let user: User = JSON.parse(localStorage.getItem('loggedInUser'));

    if (user) {
      console.log('Can Access of Service ', user.menuItems);

      if (user.menuItems !== undefined) {
        let userModule = user.menuItems;
        let mod = userModule.find((n) => n.moduleName === modName);
        if (mod !== undefined) {
          let activity = mod.moduleActivities.find(
            (n) => n.activityValue === act
          );

          if (activity !== undefined) {
            if (activity.accessRight === 'NoAccess') {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }

    return false;
  }
}
