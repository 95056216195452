import { Overtime } from './overtime';
import { PayCategory } from './paycategory';
import { StaffInfo } from './staffinfo';

import { BaseModel } from './base';

export class PaySlip extends BaseModel {
  staffId: StaffInfo;
  paySlipMonth: number;
  dateGenerated: Date;
  netPay: number;
  grossPay: number;
  payCategories: Array<PayCategory>;
  generatedBy: string;
  paymentStatus: string;
  totalOvertimes: number;
  overtimes: Overtime[];
  subscriberId: string;
  siteId: string;
  datePaid: Date;
  paySlipYear: number;
  allAllowances: number;
  allDeductions: number;
  tax: number;
  loanDeductions: number;
}
