import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { TokenService } from './services/token.service';
import { Role } from '@mrsoftebs/data';
import { ErrorComponent } from './components/top-nav/error/error.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UnAuthorizeComponent } from './components/un-authorize/un-authorize.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardNoMenu implements CanActivate {
  globalRoute = [
    '/apps',
    '/leave/leavereport',
    '/staff/staffupdate',
    'leave/leaverequest',
  ];
  userRoles: Role[] = JSON.parse(localStorage.getItem('userRoles'));
  exist: Role;
  url = '';
  constructor(
    private auth: AuthenticationService,
    private myRoute: Router,
    private tokenServ: TokenService,
    private dialog: MatDialog
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this.tokenServ.getloggedInUser()) {
     return true
    } else {
      this.myRoute.navigate(["auth/login"]);
          return false;
    }




  }

  // formatRoute(state) {
  //   if (this.userRoles && this.userRoles?.length) {
  //     for (const role of this.userRoles) {
  //       if (role?.link?.charAt(role.link.length - 1) !== '/') {
  //         role?.link?.concat('/');
  //       }
  //       if (role.link.charAt(0) == '/' && state.url.charAt(0) !== '/') {
  //         state.url += '/' + state.url;
  //       }
  //       const roleFirstLetter = role?.link?.charAt(0);
  //       const stateFirstLetter = state.url.charAt(0);
  //       if (
  //         roleFirstLetter === '/' &&
  //         (stateFirstLetter === '/' || stateFirstLetter === '')
  //       ) {
  //         return role.link === state.url;
  //       }
  //       if (roleFirstLetter === '/' && stateFirstLetter !== '/') {
  //         role.link = role.link.substring(1);
  //       }
  //       if (roleFirstLetter !== '/' && stateFirstLetter === '/') {
  //         state.url = state.url.substring(1);
  //         console.log('url: ', state.url);
  //       }
  //       console.log('stateUrl: ', state.url);
  //       // console.log('Url 1: ', state.url, 'role link: ', role?.link);
  //       if (state.url === role.link) {
  //         this.exist = role;
  //         break;
  //       }
  //     }
  //   }
  //   console.log('stateUrl 1: ', state.url);
  // }
}
