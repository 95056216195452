import { BaseModel } from './base';

export class  Receiver  extends BaseModel{
  userId: string;
  email: string;
  dateReceived?: Date;
  dateRead?: Date;
  readStatus?: string;
}

export class Notification extends BaseModel{
  sender: string;
  receivers: Array<Receiver>;
  message: string;
  dateCreated: Date;
  notificationType: string;
  url?: string;
  permissionLevel?: string;
  selectedDepartments?: [{ DepartmentId: string; DepartmentName: string }];
}
