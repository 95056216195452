import { HrDocument } from './hrdocument';
import { LeaveCategory } from './leavecategory';
import { StaffInfo } from './staffinfo';
import { Unit } from './unit';

export class Leave {
  name: string;
  leaveReason: string;
  supportDoc: HrDocument[];
  leavePlan: Leave;
  leaveRequest: Leave | string;
  leaveDays: Date[];
  unitInfo: Unit | string;
  approvalTrackingNo: string;
  staffInfo: StaffInfo | string;
  year: number;
  daysPerYear: number;
  daysPerMonth: number;
  userId: string;
  requestor: StaffInfo | string;
  subscriberId: string;
  siteId: string;
  dateApplied: Date;
  payment: boolean;
  leaveStatus: string;
  isRescheduled?: boolean;
  rescheduledDates: Date[];
  numberOfleaveTaken?: number;
  isExtraLeave: boolean;
  replies?: [
    {
      userId: string;
      reason: string;
      status: string;
      position: string;
    }
  ];

  approvalStatus: string;
  leaveType: string;
  leaveCategory: LeaveCategory;
}
