import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showProgress = false;
  subscriber: any;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tokenService: TokenService // private dialogRef: MatDialogRef<SigninComponent>
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((data) => {
      console.log('routeId: ', data.get('id'));
      this.accountService.getLogo(data.get('id')).subscribe((data) => {
        console.log('Logo: ', data);
        localStorage.setItem('EBSLogo', data.LogoPath);
        this.subscriber = data;
      });
    });
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [''],
    });
  }
  getSubscriber() {
    // this.accountService.
  }
  onSubmit() {
    this.showProgress = true;
    console.log('Form value: ', this.loginForm.value);
    this.accountService.dialogSignin(this.loginForm.value).subscribe(
      (data) => {
        // console.log('signin data: ', data);
        this.tokenService.setLoggedIn(true);
        this.accountService.saveUser(data.access_token);
        this.accountService.setToken(data.access_token);
        // this.dialogRef.close('Closing dialog. ...');
        // this.router.navigateByUrl(`/hr`);
       
        this. router. navigate(['/app-dashboard']);
      },
      (err) => {
        this.showProgress = false;
      }
    );
    // this.dialogRef.close();
  }
}
