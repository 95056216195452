import { BaseModel } from './base';

export class SampleRecordBook extends BaseModel {
  datereceived: Date;
  jobId: string;
  fieldwell: string;
  sampleinformation: string;
  workresearchdescription: string;
  samplenature: string;
  samplingdepth: string;
  samplingdate: Date;
  recieveddate: Date;
  remarks: string;
  type: string;
  recievedbyyesno: string;
  jobstatus: string;
  datecreated: Date;
  createdPersonId: string;
  subscriberId: string;
  siteId: string;
}
