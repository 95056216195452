import { AcountBanks } from './accountbanks';
import { BaseModel } from './base';
import { Currency } from './store-references';

export class BankBalancesDetails extends BaseModel {
  id: string;
  BankId: string;
  BankName: string;
  CurrencyId: string;
  CurrencyName: string;
  OpeningBal: number;
  Additions: number;
  Deductions: number;
  ClosingBal: number;
  BalAs: number;
}
