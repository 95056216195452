import { BaseModel } from './base';

export class RefData extends BaseModel {
  id: string;
  itemId: string;
  itemName: string;
  itemCode: string;
  itemEmail: string;
  itemPhone: string;
  itemAddress: string;
  itemWebsite: string;
  groupId: string;
  subGroupId: string;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  type: string;
  _id: string;
}
