import { BaseModel } from './base';

export class WireTest extends BaseModel {
  DateOfTest: Date;
  UnityType: string;
  UnityNumber: string;
  SpoolInDate: Date;
  TypeOfWireId: string;
  TypeOfWireName: string;
  WireSizeId: string;
  WireSizeName: string;
  TypeOfTest: string;
  NumberOfWraps: string;
  NumberOfTwist: string;
  TypeOfFracture: string;
  CommentByTester: string;
  NameOfTester: string;
  NameOfWitness: string;
  ApprovedBy: string;
  ApprovalStatus: string;
}
