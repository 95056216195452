import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '@mrsoftebs/data';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notificationviewer',
  templateUrl: './notificationviewer.component.html',
  styleUrls: ['./notificationviewer.component.scss'],
})
export class NotificationviewerComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public notice: Notification,
    private dialogRef: MatDialogRef<NotificationviewerComponent>,
    private notifier: NotificationService
  ) {}

  ngOnInit() {
    console.log('Notice: ', this.notice);
    this.update();
  }
  closeDialog() {
    this.dialogRef.close(this.notice);
  }
  update() {
    this.notifier
      .updateStatus({
        id: this.notice['_id'],
        dateReceived: new Date(),
        dateRead: new Date(),
        readStatus: 'Read',
      })
      .subscribe((result) => {
        console.log('Update result: ', result);
      });
  }
}
