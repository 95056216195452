import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TokenService {
  constructor(private router: Router) {}

  logout() {
    localStorage.removeItem('EBSloggedInUser');
    localStorage.removeItem('EBSJwtToken');
    localStorage.removeItem('isLoggedIn');
    localStorage.clear();
    this.router.navigate(['/home']);
  }
  private loggedIn = new BehaviorSubject<boolean>(false);

  isLoggedIn(value?: boolean): Observable<boolean> {
    if (value != null && value != undefined) {
      this.loggedIn.next(value);
    } else {
      if (this.getToken()) {
        this.loggedIn.next(true);
      } else {
        this.loggedIn.next(false);
      }
    }
    return this.loggedIn.asObservable(); // {2
  }
  getToken() {
    return localStorage.getItem('EBSJwtToken');
  }
  setToken(token: string) {
    localStorage.setItem('EBSJwtToken', token);
  }
  getloggedInUser() {
    return localStorage.getItem('EBSloggedInUser');
  }
  setLoggedIn(value: boolean) {
    this.loggedIn.next(value);
  }
}
