import { BaseModel } from './base';

export class State extends BaseModel {
  CountryId: string;

  StateName: string;

  StateCode: string;

  DateCreated: string;

  DateModified: string;
  id: string;
  _id: string;
}
