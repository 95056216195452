import { JobOpenning } from './jobopening';
import { Document } from './document';

import { BaseModel } from './base';

export class JobApplication extends BaseModel {
  firstName: string;
  lastName: string;
  otherNames: string;
  email: string;
  phone: string;
  jobId: JobOpenning;
  passportUrl: Document;
  resumeUrl: Document;
  credentials?: Document[];
  status: string;
  dateApplied: Date;
  subscriberId: string;
  siteId: string;
}
