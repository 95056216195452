import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.css'],
})
export class HtmlViewComponent implements OnInit {
  @Input() value;
  constructor() {}

  ngOnInit() {
    // console.log("Html Value: ", this.value);
  }
}
