import { prop } from '@typegoose/typegoose';
import { IsString } from 'class-validator';
import { BaseModel } from './base';

export class ApplicationPosition extends BaseModel{

  positionId: string;

  positionName: string;

 qualification: string;

  categoryId: string;
  salaryScale: string;
  departmentId: string;
  categoryName: string;
  departmentName: string;
  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  _id: string;
}
