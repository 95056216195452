import { BaseModel } from './base';
import { Unit } from './unit';

export class IncidentDto extends BaseModel {
  date: Date;

  from: string;

  incidentType: string;

  startDate: string;

  unit: Unit | string;

  to: string;

  location: string;

  icds: IcdDto[];

  kpis: KpiDto[];

  invoices: InvoiceDto[];

  mobs: MobDto[];
}

export class IcdDto {
  date: Date;
  time: string;
  location: string;
  description: string;
  otherComplain: string;
}
export class InvoiceDto {
  performedBy: string;
  remark: string;
  resultOutcome: string;
  workActivityId: string;
}

export class KpiDto {
  fromDate: Date;
  location: string;
  toDate: Date;

  performances: PerformanceKpiDtoDto[];
}

export class PerformanceKpiDtoDto {
  kp1: string;
  kp2: number;
  kp3: number;
  kp4: number;
  kp5: number;
  kp6: number;
  kp7: number;
  total: number;
}

export class MobDto {
  client: string;
  dateOfReturn: Date;
  mbDate: Date;
  name: string;
  noOfPersonnel: string;
  unit: string;
}
