
  
<cradua-addmin-application
[applications]="applications$ | async"
[subscribers]="subscribers$ | async" 
(submitEvent)="onSubmit($event)"
*ngIf="(isLoading$ | async ) === false || (isLoadingSubscriber$ | async) === false"
>
</cradua-addmin-application>

<mrsoftapps-epims-reference-spinner

*ngIf="(isLoading$ | async) === true  && (isLoadingSubscriber$ | async) === true"
>
</mrsoftapps-epims-reference-spinner>

