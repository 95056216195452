import { Component, OnInit } from '@angular/core';
import { ApplicationDto, User } from '@mrsoftebs/data';
import { GatewayFacade } from 'libs/gateway/src/lib/states/+gateway/gateway.facades';
import { Observable } from 'rxjs';

@Component({
  selector: 'cradua-addmin-application-container',
  templateUrl: './addmin-application-container.component.html',
  styleUrls: ['./addmin-application-container.component.scss'],
})
export class AddminApplicationContainerComponent implements OnInit {
  applications$: Observable<ApplicationDto[]>;
  subscribers$: Observable<User[]>;
  isLoadingSubscriber$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  constructor(private gatewayFacade: GatewayFacade) {}

  ngOnInit(): void {
    this.isLoading$ = this.gatewayFacade.isLoadingGateway('Application');
    this.applications$ = this.gatewayFacade.loadGateway('Application');
    // this.isLoadingSubscriber$ = this.gatewayFacade.isLoadingGateway('User');
    this.subscribers$ = this.gatewayFacade.loadGateway('IdentityUser');
  }

  onSubmit(roles) {
    this.gatewayFacade.update('Application', roles, ['id']);
  }
}
