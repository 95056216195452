import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY, UserState, UserPartialState, userAdapter } from '.././reducers/user.reducer';

// Lookup the 'student record' feature state managed by NgRx
export const getUserState = createFeatureSelector<UserPartialState, UserState>(USER_FEATURE_KEY);

const { selectAll, selectEntities } = userAdapter.getSelectors();

export const getUserLoaded = createSelector(getUserState, (state: UserState) => state.loaded);

export const getUserError = createSelector(getUserState, (state: UserState) => state.error);

export const loadingUser = createSelector(getUserState, (state: UserState) => state.loaded);

export const getAllUsers = createSelector(getUserState, (state: UserState) => {
	return selectAll(state);
});

export const getUserEntities = createSelector(getUserState, (state: UserState) => selectEntities(state));

export const getSelectedId = createSelector(getUserState, (state: UserState) => state.selectedUserId);

export const getSelected = createSelector(
	getUserEntities,
	getSelectedId,
	(entities, selectedId) => selectedId && entities[selectedId]
);
