import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '@mrsoftebs/data';
import { Guid } from 'guid-typescript';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteComponent implements OnInit {
  @Input() sites: User;
  @Output() emitSaveSite: EventEmitter<User> = new EventEmitter<User>();
  @Output() emitDeleteEvent: EventEmitter<string> = new EventEmitter<string>();

  showForm = false;
  user: any = JSON.parse(localStorage.getItem('EBSloggedInUser'));
  siteForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createSiteForm();
  }

  onEmitAddEvent() {
    this.showForm = !this.showForm;
  }

  onEmitSiteUpdate(value) {
    this.siteForm.patchValue({ ...value });
    this.showForm = true;
  }

  onSubmit(value: User) {
    if (this.siteForm.invalid) {
      return;
    }
    this.emitSaveSite.emit(value);
    this.siteForm.reset();
  }

  onEmitDeleteEvent(value: string) {
    this.emitDeleteEvent.emit(value);
  }

  createSiteForm() {
    const id = Guid.create().toString();
    const uin = Math.floor(10000000000000 + Math.random() * 90000000000000);
    const uinString = uin.toString();

    const siteGenericNo = Math.floor(Math.random() * 10000000).toString();
    const resetToken = this.generateRandomString(35);
    this.siteForm = this.fb.group({
      Id: [id],
      UserName: [''],
      PhoneNo: ['080'],
      password: [''],
      Email: [''],
      UserType: ['Site'],
      SubscriberName: [''],
      FirstName: [''],
      LastName: [''],
      middleName: [''],
      StaffName: [''],
      logoURL: [''],
      verifyStatus: [''],
      isConfirmed: [''],
      UIN: [uinString],
      newUser: [''],
      spouseName: [''],
      noOfChildren: [0],
      address: [''],
      gender: [''],
      ocupation: [''],
      userRole: [''],
      slogan: [''],
      SubscriberId: [this.user?.subscriberId],
      SiteId: [
        this.user?.siteId != null ? this.user?.siteId : this.user.SubscriberId,
      ],
      siteName: [''],
      siteNumber: [''],
      levelCount: [0],
      canAddSub: [false],
      siteGenericNumber: [siteGenericNo],
      systemAdminStatus: [''],
      resetPasswordToken: [resetToken],
      resetPasswordExpires: ['05/05/1987'],
      ParentId: [this.user?.userType === 'Subscriber' ? '' : this.user?.userId],
      _id: [''],
    });
  }

  generateRandomString(length, randomString = '') {
    randomString += Math.random().toString(20).substr(2, length);
    if (randomString.length > length) return randomString.slice(0, length);
    return this.generateRandomString(length, randomString);
  }
}
