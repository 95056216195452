import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@mrsoftebs/data';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-site-table',
  templateUrl: './siteTable.component.html',
  styleUrls: ['./siteTable.component.scss'],
})
export class SiteTableComponent {
  @Input() sites: User;
  @Output() emitAddEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitDeleteEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitSiteUpdate: EventEmitter<User> = new EventEmitter<User>();
}
