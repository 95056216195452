import { UserDto } from '@mrsoftebs/data';
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { AuthApiActions, LoginPageActions } from '../actions';

export const USER_FEATURE_KEY = 'user';

export interface UserState extends EntityState<UserDto> {
	selectedUserId?: string | number; // which User record has been selected
	loaded: boolean; // has the User list been loaded
	error?: string | null; // last none error (if any)
}

export interface UserPartialState {
	readonly [USER_FEATURE_KEY]: UserState;
}

export const userAdapter: EntityAdapter<UserDto> = createEntityAdapter<UserDto>({
	selectId: (record) => record.id,
	sortComparer: false
});

export const initialState: UserState = userAdapter.getInitialState({
	// set initial required properties
	loaded: false,
	error: null,
	selectedUserId: null
});

const userReducer = createReducer(
  initialState,
  	on(LoginPageActions.LoadUsersBySubscriber, (state) => ({
		...state,
		loaded: true,
		error: null
	})),
	on(LoginPageActions.LoadUsersBySubscriberSuccess, (state, { user }) =>
		userAdapter.addMany(user, { ...state, loaded: false })
	),
	on(LoginPageActions.LoadUsersBySubscriberFailure, (state, { error }) => ({
		...state,
		loaded: false,
		error
	})),
	on(LoginPageActions.loadUser, (state) => ({
		...state,
		loaded: true,
		error: null
	})),
	on(AuthApiActions.loadUserSuccess, (state, { user }) => userAdapter.addMany(user, { ...state, loaded: false })),
	on(AuthApiActions.loadUserFailure, (state, { error }) => ({
		...state,
		loaded: false,
		error
	})),
	on(LoginPageActions.addUser, (state) => ({
		...state,
		loaded: true,
		error: null
	})),
	on(AuthApiActions.addUserSuccess, (state, { user }) => userAdapter.addMany(user, { ...state, loaded: false })),
	on(AuthApiActions.addUserFailure, (state, { error }) => ({
		...state,
		loaded: false,
		error
	})),

	on(LoginPageActions.updateUser, (state) => ({
		...state,
		loaded: true,
		error: null
	})),
	on(AuthApiActions.updateUserSuccess, (state, { user }) =>
		userAdapter.upsertMany(user, { ...state, loaded: false })
	),
	on(AuthApiActions.updateUserFailure, (state, { error }) => ({
		...state,
		loaded: false,
		error
	})),
	on(LoginPageActions.removeUser, (state) => ({
		...state,
		loaded: true,
		error: null
	})),
	on(AuthApiActions.removeUserSuccess, (state, { ids }) => userAdapter.removeMany(ids, { ...state, loaded: false })),
	on(AuthApiActions.removeUserFailure, (state, { error }) => ({
		...state,
		loaded: false,
		error
	}))
);

export function reducer(state: UserState | undefined, action: Action) {
	return userReducer(state, action);
}
