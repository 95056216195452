import { BaseModel } from './base';

export class Subscriber extends BaseModel {
  //  subscriberId: string;
  subscriberName: string;
  siteId: string;
  subscriberShortName: string;
  createdAt?: Date;
  updatedAt?: Date;
  email: string;
  phone: string;
  siteUrl?: string;
  logo?: string;
}
