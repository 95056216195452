import { prop } from '@typegoose/typegoose';
import { IsString, IsEmail } from 'class-validator';
import { Route } from './route';
import { Role } from './roles';
import { Service } from './service';

import { BaseModel } from './base';

export class Application extends BaseModel {
  RoleId: string;

  ModuleName: string;
  link: string;
  Description: string;

  NoOfUsers: number;

  PaymentStatus: string;

  Duration: number;

  Unlimited: string;

  ApprovalStatus: string;

  StartDate: Date;

  EndDate: Date;

  AmountPaid: number;
  SubscriberId: string;

  highestQualificationId: string;
  highestQualificationName: string;
  classOfFirstDegreeId: string;
  classOfFirstDegreeName: string;
  responseToQuestionnaire: string;
  hasRespondedToSmarttest: boolean;
  dateRespondedToSmartTest: Date;
}

export class ApplicationDto {
  link: string;
  RoleId: string;

  ModuleName: string;

  Description: string;

  NoOfUsers: number;

  PaymentStatus: string;

  Duration: number;

  Unlimited: string;

  ApprovalStatus: string;

  StartDate: Date;

  EndDate: Date;

  AmountPaid: number;
  SubscriberId: string;
  ModifiedById: string;
  SiteId: string;
  dateCreated: Date;
  dateUpdated: Date;
  id: string;
  createdBy: any;


  highestQualificationId: string;
  highestQualificationName: string;
  classOfFirstDegreeId: string;
  classOfFirstDegreeName: string;
  responseToQuestionnaire: string;
  hasRespondedToSmarttest: boolean;
  dateRespondedToSmartTest: Date;
}
