import { BaseModel } from './base';

export class ContractInfoHistory extends BaseModel {
  contractTitle: string;
  contractDescription: string;
  startDate: string;
  endDate: string;
  contractRefNo: string;
  contractStatusId: string;
  contractTypeId: string;
  contractMode: string;
  contractorsId: string;
  contractAmount: number;
  departmentId: string;
  departmentName: string;
  contractStatusName: string;
  contractName: string;
  expirationDays: number;
  amountInvoiced: number;
  contractBalance: number;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
}
