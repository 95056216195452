import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Guid } from 'guid-typescript';

import Swal from 'sweetalert2';
import { GatewayFacade } from './../../../../../gateway/src/lib/states/+gateway/gateway.facades';
import { AuthFacade } from './../../../../../auth/src/lib/facades/auth.facade';

import { GatewayService } from 'libs/gateway/src/lib/services/gateway.service';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../../../../apps/ebs/src/app/services/authentication.service';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  href = '';
  registerForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private gatewayFacade: GatewayFacade,
    private authFade: AuthFacade,
    private gatewayService: GatewayService,
    private router: Router,
    public authServ: AuthenticationService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.initRegisterForm();
    const location = window.location.href.split('#')[0];

    this.href = location;

    // if(this.authServ.getUser() != null){
    //   this.router.navigate(['/dashboard']);
    // }
  }

  initRegisterForm() {
    const id = Math.floor(Math.random() * 1000000000);

    const uin = Math.floor(10000000000000 + Math.random() * 90000000000000);
    const uinString = uin.toString();
    const subscriberId = Math.floor(Math.random() * 1000000000);
    const subscriberMainId = subscriberId.toString();
    const siteGenericNo = Math.floor(Math.random() * 10000000).toString();
    const resetToken = this.generateRandomString(35);
    this.registerForm = this.fb.group({
      Id: [id],
      UserName: ['', [Validators.required, Validators.email]],
      PhoneNo: ['080'],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&./><,])[A-Za-zd$@$!%*?&].{8,}'
          ),
        ],
      ],
      Email: [''],
      UserType: ['Subscriber'],
      SubscriberName: ['', Validators.required],
      FirstName: [''],
      LastName: [''],
      middleName: [''],
      StaffName: [''],
      logoURL: [''],
      verifyStatus: [''],
      isConfirmed: [''],
      UIN: [uinString],
      newUser: [''],
      spouseName: [''],
      noOfChildren: [0],
      address: [''],
      gender: [''],
      ocupation: [''],
      userRole: [''],
      slogan: [''],
      SubscriberId: [subscriberMainId],
      SiteId: [id],
      siteName: [''],
      siteUrl: [''],
      siteNumber: [''],
      levelCount: [0],
      canAddSub: [true],
      siteGenericNumber: [siteGenericNo],
      systemAdminStatus: [''],
      resetPasswordToken: [resetToken],
      resetPasswordExpires: ['05/05/1987'],
      parentId: [''],
    });
  }
  get controls() {
    return this.registerForm.controls;
  }
  generateRandomString(length, randomString = '') {
    randomString += Math.random().toString(20).substr(2, length);
    if (randomString.length > length) return randomString.slice(0, length);
    return this.generateRandomString(length, randomString);
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.authService.isLoading$.next(true);

      this.registerForm.value.StaffName = this.registerForm.value.SubscriberName;
      this.registerForm.value.Email = this.registerForm.value.UserName;
      const newAdd = [];
      console.log('User Identity', this.registerForm.value);
      newAdd.push(this.registerForm.value);

      const mailData = {
        fromEmail: '',
        toEmail: this.registerForm.value.Email,
        subject: 'Manage Account',
        task: '',
        responsibility: '',
        pipelinename: '',
        pipelinerequestdesc: '',
        period: '',
        message: '',
        salutation: '',
        footer: '',
        url: '',
      };

      const salute = 'Dear ' + this.registerForm.value.StaffName + ' ,';

      const conformButtonMsg = '';

      mailData.salutation = salute;

      mailData.message =
        'Click on the link below to change your password or copy the link and paste on the browser';

      mailData.footer = '';

      const linkURL =
        this.href +
        '#/auth/manage/' +
        this.registerForm.value.Id +
        '/' +
        this.registerForm.value.resetPasswordToken;
      mailData.url = linkURL;

      console.log('Sending mail ', mailData, this.registerForm.value.Id);
      //   this.gatewayService.sendManageAccountMail(mailData).subscribe((data) => {

      //  });

      this.registerForm.reset;

      this.authFade.addNewUser(newAdd);

      this.authService.isLoading$.pipe(take(3)).subscribe((val) => {
        console.log('Is Loading value Pending ', val);
        if (val === false) {
          this.gatewayService
            .sendManageAccountMail(mailData)
            .subscribe((data) => {});
        }
      });
    } else {
      console.log('Form: ', this.registerForm);
    }
  }
}
