<div class="error-container" style="">
  <div class="title-container">
    <h1 class="title"><u>401 Unauthorized</u></h1>
  </div>

  <div class="message-container center">
    <!-- <img src="assets/images/access-control.jpg" width="200px" /> -->
    <h4 class="message">
      You lack necessary authorization to access the requested resources.
    </h4>
    <h5 class="message">We are redirecting you to home</h5>
  </div>
  <div align="center">
    <button mat-button (click)="goHome()">Go Home</button>
  </div>
  <!-- <mat-card-footer> Footer </mat-card-footer> -->
</div>
