import { BaseModel } from "./base";

export class ItReport extends BaseModel {
  ItReport: It[];
  id: string;
}

export class It {
  Item: string;
  Description: string;
  Remark: string;
}
