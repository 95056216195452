import { BaseModel } from './base';
import { Currency } from './store-references';

export class AcountBanks extends BaseModel {
  BankId: string;
  BankName: string;
  USD: number;
  NGN: number;
  ETransfer: number;
}
