import { BaseModel } from './base';

export class DigitalLearning extends BaseModel {
  subject: string;
  descriptionofincident: string;
  immediateactiontaken: string;
  immediatecauses: string;
  rootcauses: string;
  recommendedpreventionaction: string;
  targetaudience: string;
  datecreated: Date;
  createdPersonId: string;
  subscriberId: string;
  siteId: string;
}
