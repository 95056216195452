import { BaseModel } from "./base";

export class ApplicantSmartTestDto extends BaseModel {
    
    _id: string;
  fullname: string;
  referenceNumber: string;
  qualificationId: string;
  qualificationName: string;
  positionId: string;
  positionName: string;
  categoryId: string;
  categoryName: string;
  applicationId: string;
  departmentId: string;
  departmentName: string;
  facultyId: string;
  facultyName: string;
  degreeId: string;
  degreeName: string;
  questions: string;
  answers: string;
  dateCreated: Date;
  dateUpdated: Date;
  modifiedBy: string;
  
}
