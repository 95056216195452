
<app-site
[sites]="site"
(emitSaveSite)="onSaveSite($event)"
(emitDeleteEvent)="onEmitDeleteEvent($event)"
*ngIf="(authService.isLoadingSite$ | async) === false"
></app-site>


<mrsoftebs-list-spinner

*ngIf="(authService.isLoadingSite$ | async) === true"
>
</mrsoftebs-list-spinner>

