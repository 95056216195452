import { BaseModel } from './base';

export class PayRoll extends BaseModel {
  month: number;
  day: number;
  year: number;
  staffs: number;
  amount: number;
  paymentStatus: string;
  adjustmentStatus: string;
  subscriberId: string;
  siteId: string;
  dateCreated: Date;
}
