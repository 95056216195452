import { BaseModel } from './base';

export class  DocumentType  extends BaseModel{
  documentTypeId?: string;

  documentTypeName: string;

  subscriberId: string;

  siteId;
  string;

  dateCreated: string;

  dateModified: string;
  id: string;
  _id: string;
}
