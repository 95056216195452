import { User } from '@mrsoftebs/data';
import { createAction, props } from '@ngrx/store';

import { Credentials, UserDto } from 'libs/data/src/lib';

export const login = createAction('[Login Page] Login', props<{ credentials: Credentials }>());

export const addUser = createAction('[User Page] Add User', props<{ user: UserDto[] }>());

export const updateUser = createAction('[User Page] Update User', props<{ user: UserDto }>());

export const removeUser = createAction('[User Page] Remove User', props<{ ids: string[] }>());

export const loadUser = createAction('[User Page] Load User', props<{ user: UserDto[] }>());

export const LoadUsersBySubscriber = createAction('[Student] Load Users By Subscriber');

export const LoadUsersBySubscriberSuccess = createAction(
	'[Student] Load Users By Subscriber Success',
	props<{ user: User[] }>()
);

export const LoadUsersBySubscriberFailure = createAction(
	'[Student] Load Users By Subscriber Failure',
	props<{ error: any }>()
);
