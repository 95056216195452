import { BaseModel } from './base';

export class ActionStatus extends BaseModel {
  actionStatusId: string;
  actionStatusName: string;
  color: string;
  SubscriberId: string;
  SiteId: string;
  Status: string;
  id: string;
}