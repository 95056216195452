import { BaseModel } from './base';

export class WireSize extends BaseModel {
  WireSizeName: string;
  WireTypeId: string;
  WireTypeName: string;
  SubscriberId: string;

  SiteId: string;

  id: string;
}
