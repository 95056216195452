<div class="form">
  <div class="box green_box"></div>
  <div class="box pink_box"></div>
  <div
    [formGroup]="passwordForm"
    class="p-4 forgot-password card-diagonal-rounded"
    *ngIf="tokenIsValid"
  >
    <h4 class="p-4">Change Password</h4>
    <mat-form-field class="input" appearance="outline">
      <mat-label>Password</mat-label>
      <input
        autocomplete="false"
        matInput
        formControlName="password"
        type="password"
        required
        placeholder="Enter your password"
      />
      <mat-icon matSuffix>password</mat-icon>
    </mat-form-field>

    <samll
      class="error"
      *ngIf="
        passwordForm.controls.password.touched &&
        passwordForm.controls.password.errors?.pattern
      "
      >Password must be at least 8 characters long.</samll
    ><samll
      class="error"
      *ngIf="
        passwordForm.controls.password.touched &&
        passwordForm.controls.password.errors?.pattern
      "
    >
      Must contain a lowercase, uppercase,</samll
    ><samll
      class="error mb-4"
      *ngIf="
        passwordForm.controls.password.touched &&
        passwordForm.controls.password.errors?.pattern
      "
    >
      number and a special character</samll
    >
    <samll
      class="error mb-4"
      *ngIf="
        passwordForm.controls.password.touched &&
        passwordForm.controls.password.errors?.required
      "
      >You must enter password</samll
    >

    <mat-form-field class="input" appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input
        autocomplete="false"
        matInput
        formControlName="confirmPassword"
        type="password"
        required
        placeholder="Confirm password"
      />
      <mat-icon matSuffix>password</mat-icon>
    </mat-form-field>

    <samll
      class="error mb-4"
      *ngIf="
        passwordForm.controls.confirmPassword.touched &&
        passwordForm.controls.confirmPassword.errors?.mustMatch
      "
      >Password and confirm password must match</samll
    >
    <samll
      class="error mb-4"
      *ngIf="
        passwordForm.controls.confirmPassword.touched &&
        passwordForm.controls.confirmPassword.errors?.required
      "
      >You must enter confirm password</samll
    >

    <div class="sign_in-submit">
      <button
        (click)="changePassword()"
        type="submit"
        class="default_primary-button"
        [ngClass]="{
          inactive_btn: isProccessing || passwordForm.errors
        }"
        [disabled]="isProccessing || passwordForm.errors"
      >
        Submit
        <div *ngIf="isProccessing">
          <mat-progress-spinner
            color="warn"
            mode="indeterminate"
            diameter="30"
            strokeWidth="5"
          ></mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
  <h3 class="error large" *ngIf="!tokenIsValid && hasData">
    Your reset token is either invalid or has expired
  </h3>
  <h3 class="text-info large" *ngIf="!hasData">
    Hold on! We are verifying your reset password token
  </h3>
</div>
