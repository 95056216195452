import { BaseModel } from './base';
import { Owner } from './owner';
export class Activity extends BaseModel {
  activityOwners: Owner[];
  title: string;
  activityDescription: string;
  percentageCompletion: string;
  startDate: string;
  endDate: string;
  isCompleted: boolean;
  priority: string;
  taskId: string;
}
