/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationDto, LoggedInUser, RoleDto, User } from '@mrsoftebs/data';

import { AuthenticationService } from 'apps/ebs/src/app/services/authentication.service';
import { MenuService } from 'apps/ebs/src/app/services/menu.service';
import Swal from 'sweetalert2';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cradua-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnChanges {
  @Input() roles: RoleDto[];
  @Input() staffRoles: any[];
  @Input() applications: ApplicationDto[];
  @Output() submitEvent = new EventEmitter();
  @Output() emitAssignedRole = new EventEmitter();
  @Output() emitDeleteRole = new EventEmitter();

  @Output() deleteapplicationEvent = new EventEmitter();
  applicationForm: FormGroup;
  deletedData = [];
  user: LoggedInUser;
  subscriberId;
  mainRoles: RoleDto[];
  assignedRole: RoleDto[] = [];
  deleteRole = [];

  constructor(
    private fb: FormBuilder,
    private cdref: ChangeDetectorRef,
    public authServ: AuthenticationService,
    private router: Router // private menuService: MenuService
  ) {
    const user: any = JSON.parse(localStorage.getItem('EBSloggedInUser'));
    //this.user = this.authServ.getUser();
    this.user = user;

    // console.log('Logged In User ', this.authServ.getUser());
    // if(this.user == null)
    // {
    //   this.router.navigate(['/auth/login'])
    // }else{
    //   if(this.user.UserType !== "Subscriber")
    //   {
    //     this.router.navigate(['/dashboard'])
    //   }
    // }
  }
  duratons = [
    { name: 'Unlimited', value: 'Unlimited' },
    { name: 12, value: 'Yearly' },
    { name: 1, value: 'Monthly' },
  ];

  navigate() {
    this.router.navigate(['/']);
  }
  ngOnChanges(): void {
    this.applicationForm = this.fb.group({
      Roles: this.fb.array([]),
    });
    this.subscriberId = this.user.SubscriberId
      ? this.user.SubscriberId
      : this.user.SubscriberId;
    if (this.applications && this.applications.length) {
      if (localStorage.getItem('Application')) {
        localStorage.removeItem('Application');
        localStorage.setItem('Application', JSON.stringify(this.applications));
      } else {
        localStorage.setItem('Application', JSON.stringify(this.applications));
      }
    }

    this.mainRoles = this.roles;
    // console.log('roles', this.roles);
    if (this.roles?.length) {
      this.roles = this.roles.filter((role) => !role.parentId);

      // console.log('application', this.applications);
      this.roles.forEach((data) => {
        const app = this.checkExistingApp(data);

        if (app) {
          this.setApp(app);
        } else {
          this.addModule(data);
        }
      });
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  addModule(data) {
    const control = this.applicationForm.controls.Roles as FormArray;
    control.insert(
      0,
      this.fb.group({
        AmountPerUser: data.AmountPerUser,
        isChecked: [false],
        ModuleName: data.name,
        Description: data.MenuDesc,
        NoOfUsers: [0],
        PaymentStatus: ['Pending'],
        Duration: [0],
        ApprovalStatus: ['Pending'],
        Unlimited: [''],
        AmountPaid: [0],
        RoleId: data.menuId,
        SubscriberId: this.subscriberId,
        SiteId: [''],
        createdBy: 'N/A',
        id: null,
      })
    );
  }

  setApp(data) {
    const control = this.applicationForm.controls.Roles as FormArray;
    control.insert(
      0,
      this.fb.group({
        ...data,
        isChecked: [true],
      })
    );
  }

  selectSub(data, i: number) {
    if (data === 'Unlimited') {
      this.control(i).patchValue({
        Unlimited: data,
        Duration: 0,
        AmountPaid: 0,
        NoOfUsers: 0,
      });
    } else {
      this.control(i).patchValue({ Duration: data, Unlimited: '' });
    }
  }

  calcAmount(amount, numberOfUser, duration, i) {
    this.control(i).patchValue({
      AmountPaid: amount * numberOfUser * duration,
    });
    return amount * numberOfUser * duration;
  }

  control(i: number) {
    return (this.applicationForm.controls.Roles as FormArray).at(i);
  }

  checkExistingApp(app) {
    const data = this.applications.find(
      (ap) =>
        ap.RoleId === app.menuId && +ap.SubscriberId === +this.subscriberId
    );

    let newApp;
    if (data) {
      newApp = {
        AmountPerUser: app.AmountPerUser,
        isChecked: true,
        ModuleName: data.ModuleName,
        Description: data.Description,
        NoOfUsers: data.NoOfUsers,
        PaymentStatus: data.PaymentStatus,
        Duration: data.Duration,
        ApprovalStatus: data.ApprovalStatus,
        Unlimited: data.Unlimited,
        AmountPaid: data.AmountPaid,
        RoleId: data.RoleId,
        SubscriberId: data.SubscriberId,
        SiteId: data.SiteId,
        createdBy: data.createdBy,
        id: data.id,
      };
    }
    return newApp;
  }

  calTotal() {
    const money = {
      totalAmount: 0,
      totalAmountPaid: 0,
      notPaid: 0,
      totalSub: 0,
      negotiated: 0,
    };
    this.applicationForm.value.Roles.map((a) => {
      money.totalAmount += a.AmountPaid;
      if (a.isChecked) {
        money.totalSub += 1;
      }

      if (a.PaymentStatus === 'Paid') {
        money.totalAmountPaid += a.AmountPaid;
      } else {
        money.notPaid += a.AmountPaid;
      }

      if (a.Unlimited === 'Unlimited') {
        money.negotiated += 1;
      }
    });
    return money;
  }

  unSubscibe(index, value) {
    // console.log('subscribe ', value, this.mainRoles)

    if (value.id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Unsubscribing to this app you wont be able to access it again!',

        showCancelButton: true,
        confirmButtonText: 'Yes, unsubscribe!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.deletedData.push(value.id);
          Swal.fire(
            'Unsubscribed!',
            'You have successfully unsubscribed.',
            'success'
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.control(index).patchValue({ isChecked: true });
          Swal.fire('Cancelled', 'Your subscription retained :)', 'error');
        }
      });
    }
  }

  transformRole(selectedRole: string) {
    const grandChildren = [];

    const parentRole = this.mainRoles.find(
      (parentRole) =>
        parentRole &&
        parentRole.menuId === selectedRole &&
        parentRole.parentId === ''
    );

    if (parentRole) {
      const children = this.mainRoles
        .filter((child) => child.parentId === parentRole.menuId)
        .map((item) => {
          return {
            ...item,
            id: undefined,
            StaffId: this.user.userId,
          };
        });

      if (children.length) {
        for (const child of children) {
          const foundGrandChild = this.mainRoles.find(
            (grand) => grand.parentId === child.menuId
          );
          if (foundGrandChild) {
            const grandChild = {
              ...foundGrandChild,
              id: undefined,
              StaffId: this.user.userId,
            };
            grandChildren.push(grandChild);
          }
        }
      }
      const newParentRole = {
        ...parentRole,
        id: undefined,
        StaffId: this.user.userId,
      };

      this.assignedRole.push(newParentRole, ...children, ...grandChildren);
    }
  }

  transformReference(parentRole: RoleDto) {
    const grandChildren = [];

    const children = this.mainRoles
      .filter((child) => child.parentId === parentRole.menuId)
      .map((item) => {
        return {
          ...item,
          id: undefined,
          StaffId: this.user.userId,
        };
      });

    if (children.length) {
      for (const child of children) {
        const foundGrandChild = this.mainRoles.find(
          (grand) => grand.parentId === child.menuId
        );
        if (foundGrandChild) {
          const grandChild = {
            ...foundGrandChild,
            id: undefined,
            StaffId: this.user.userId,
          };
          grandChildren.push(grandChild);
        }
      }
    }
    const newParentRole = {
      ...parentRole,
      id: undefined,
      StaffId: this.user.userId,
    };

    this.assignedRole.push(newParentRole, ...children, ...grandChildren);
  }

  onsubmit(data) {
    for (const role of data['Roles']) {
      // console.log('found parent role ', role);
      if (role.isChecked && role.id === null) {
        this.transformRole(role.RoleId);
      } else if (role.isChecked) {
        this.assignedRole.push(role);
      } else if (!role.isChecked && role.id != null) {
        const foundStaffRole = this.staffRoles.find(
          (item) => item.menuId === role.RoleId
        );
        this.deleteRole.push(foundStaffRole?._id);
      }
    }

    const appInStorage = localStorage.getItem('Application');
    if (!appInStorage) {
      const foundParentRoles = this.mainRoles.filter(
        (roleparent) =>
          roleparent &&
          (roleparent.name.toLowerCase() === 'staff' ||
            roleparent.name.toLowerCase() === 'settings')
      );

      for (const foundParentRole of foundParentRoles) {
        this.transformReference(foundParentRole);
      }
    }

    // console.log('found parent role ', this.deleteRole, this.staffRoles);

    this.submitEvent.emit(data);
    // console.log('tobedeleted: ', this.deleteapplicationEvent);
    if (this.deleteRole?.length) {
      this.emitDeleteRole.emit(this.deleteRole);
    }
    if (this.deletedData.length > 0) {
      this.deleteapplicationEvent.emit(this.deletedData);
    }

    this.emitAssignedRole.emit(this.assignedRole);

    this.assignedRole = [];

    // this.router.navigate(['/dashboard']);
  }
}
