import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Credentials, UserDto, User } from '@mrsoftebs/data';
import * as fromAuth from '../reducers';
import { LoginPageActions } from '../actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const api = environment.apiUrl;

@Component({
  selector: 'bc-login-page',
  template: `
    <bc-login-form
      (submitted)="onSubmit($event)"
      (registeredsubmitted)="onRegisterSubmit($event)"
      [pending]="pending$ | async"
      [errorMessage]="message | async"
    >
    </bc-login-form>
  `,
  styles: [],
})
export class LoginPageComponent implements OnInit {
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  error$ = this.store.pipe(select(fromAuth.getLoginPageError));
  user$ = this.store.select(fromAuth.getUser);
  message: BehaviorSubject<string> = new BehaviorSubject('');
  networkInterval: NodeJS.Timeout;

  constructor(
    private http: HttpClient,
    private msb: MatSnackBar,
    private store: Store<fromAuth.State>
  ) {}

  ngOnInit() {
    // this.networkInterval = setInterval(() => this.checkNetwork(), 5000);
  }
  checkNetwork() {
    this.http.head(api).subscribe(
      (result) => {
        // console.log('result: ', result);
        this.message.next(null);
      },
      (err) => {
        // console.log('Header Error: ', err);
        // this.msb.open(
        //   `It seems you don't have active internet connection. Check your internet connection and try again`,
        //   'X',
        //   { duration: 500 }
        // );
        this.message.next(
          `It seems you don't have active internet connection. Check your internet connection and try again`
        );
      }
    );
  }
  onSubmit(credentials: Credentials) {
    //console.log("Reaching login In ", credentials);

    //return;
    this.store.dispatch(LoginPageActions.login({ credentials }));
    //this.addNewUser();
  }

  onRegisterSubmit(userData: UserDto) {
    console.log('Adding User ', userData);

    const user: UserDto[] = [];
    //let userNew: UserDto = new UserDto();

    user.push(userData);
    this.store.dispatch(LoginPageActions.addUser({ user }));
    //this.addNewUser();
  }

  addNewUser() {
    const user: UserDto[] = [];
    const userNew: UserDto = new UserDto();

    userNew.Email = 'nukstel@gmail.com';
    userNew.UserName = 'nukstel@gmail.com';
    userNew.PhoneNo = '838939399394';
    userNew.password = 'Welcome';
    userNew.FirstName = 'Nuka';
    userNew.LastName = 'Adooh';
    userNew.ParentId = '5667373';

    user.push(userNew);
    this.store.dispatch(LoginPageActions.addUser({ user }));
  }
  ngOnDestroy(): void {
    clearInterval(this.networkInterval);
  }
  //this.userFacade.addNewUser(newRecord);
}
