import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { catchError, retry, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';
import { LogService } from '../services/log.service';
import { AccountService } from 'libs/account/src/lib/services/account.service';

// import { ToastsManager } from 'ng2-toastr';
// import { tokenService } from '../services/token.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private accountService: AccountService,
    private msb: MatSnackBar,
    private router: Router,
    private logService: LogService,
    private _loading: LoadingService // private toastr: ToastsManager
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // show a spinner
    this._loading.setLoading(true, request.url);
    // add authorization header with jwt token if available
    const token = this.tokenService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        // this.logService.log('Request type: ', event);
        if (event instanceof HttpResponse && event.status === 201) {
          // this.msb.open('Request succeeded.', 'X', { duration: 3000 });
        }

        this._loading.setLoading(false, request.url);
        // this.spinner.hide();
      }),
      retry(2),
      catchError((error: HttpErrorResponse) => {
        // if (error.status !== 401) {
        // 401 handled in auth.interceptor
        // this.errorService.addError(error);

        // this.logService.log('Error: ', error);
        // if (error.status === 0) {
        //   this.msb.open(
        //     `Sorry, it seems you don't have active internet connection, Check Your internet connection and Try again please`,
        //     'X',
        //     { duration: 5000 }
        //   );
        // } else
        if (error.status === 401) {
          // Log The user out

          this.msb.open(
            `You are not Authorized to perform the
                    requested operation. Try login with a priviledged account`,
            'X',
            { duration: 7000 }
          );
          this.accountService.logOut();
          // this.router.navigate(['/home']);
        } else if (error.status === 500) {
          this.msb.open(
            error.error.message
              ? error.error.message
              : `Sorry! Server Error occured. Try Again`,
            'X',
            {
              duration: 10000,
            }
          );
        } else {
          // this.msb.open(error.message, 'X', { duration: 7000 });
        }
        // this.spinner.hide();

        this._loading.setLoading(false, request.url);
        return throwError(error);
      })
    );
  }
}
