<mat-card class="center">
  <img src="assets/images/MrsoftLogo.png" class="mb-2" />
  <mat-progress-bar mode="buffer" *ngIf="showProgress"></mat-progress-bar>

  <h3>Sign in to your Account</h3>
  <form [formGroup]="signinForm" class="center form">
    <mat-form-field>
      <mat-label>Username</mat-label>
      <input matInput placeholder="Enter Username or Email Address" formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input type="password" matInput placeholder="Enter Password" formControlName="password">
    </mat-form-field>
    <br />
    <a class=" m-2" routerLink="/auth/manage">Forgot Password?</a>
    <br />

    <button mat-raised-button color="primary" class="center m-2" (click)="onSubmit()">Sign In</button>
    <!-- <br /><br /> -->
    <!-- <p>No Account? <button class="link-button" mat-button (click)="toggleLogin()">Sign up</button></p> -->
  </form>
</mat-card>