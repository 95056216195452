

import { BaseModel } from './base';

export class FinalReportFindings extends BaseModel
{


    observations: string;

    pageNumber: string;

    jobIdReportNo: string;


    finalReportAutoNo: string;


    dateReported: string;


    vettingOfficerId: string;


    vettingOfficerName: string;


    dateCreated: string;

    dateModified: string;

    subscriberId: string;

    siteId: string;


    userId: string;
    id: string;
}
