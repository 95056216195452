import { BaseModel } from './base';

export class Grade extends BaseModel {
  gradeName: string;

  description: string;

  subscriberId: string;

  siteId: string;
  _id: string;
}
