import { BaseModel } from './base';

export class Department extends BaseModel {
  departmentCode: string;
  departmentName: string;
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
  _id: string;
}
