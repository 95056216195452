import { BaseModel } from './base';

export class Unit extends BaseModel {
  departmentId: string;
  unitName: string;

  DateCreated: string;

  DateModified: string;

  subscriberId: string;
  unitId: Unit;

  siteId: string;
  id: string;
  _id: string;
}
