import { BaseModel } from './base';

export class CommentCriterial  extends BaseModel {
  postion: string;
  level: number;
  dateCreated: string;
  dateModified: string;
  siteId: string;
  subscriberId: string;
  id: string;
}
