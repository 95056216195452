import { BaseModel } from './base';

export class PolicyDocumentDto extends BaseModel {
  id: string;
  _id: string;
  documentName: string;
  documentId: string;
  description: string;
  documentCategoryId: string;
  docType: string;
  url: string;
  dateCreated: Date;
  dateUpdated: string;
  status: string;
}

export class FilterDocByDate {
  fromDate: Date;
  toDate: Date;
}
