import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleDto } from '@mrsoftebs/data';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}
  getMenu() {
    return this.http.get<RoleDto[]>('/assets/data/menu.json');
  }
}
