<mat-card class="center" style="height: 400px; width: fit-content;">
  <mat-progress-bar mode="buffer" *ngIf="showProgress"></mat-progress-bar>

  <h3 class="center m-4">SIGN UP</h3>

  <form [formGroup]="signupForm" class="center form">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput placeholder="Enter Email Address" formControlName="email" id="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input matInput placeholder="Enter Phone Number" formControlName="phone" id="phone">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input type="password" matInput placeholder="Enter Password" formControlName="password" id="password">
    </mat-form-field>
    <br />
    <button mat-raised-button color="primary" class="center" (click)="onSubmit()">Sign Up</button>
    <br />
    <img style="float: right;" src="assets/newlogo.png" class="mt-2" />

    <!-- <br /><br /> -->
    <!-- <p>No Account? <button class="link-button" mat-button (click)="toggleLogin()">Sign up</button></p> -->
  </form>
</mat-card>