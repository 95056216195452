import { BaseModel } from './base';

export class  AppraisalRole extends BaseModel{
  departmentId: string;
  staffId: string;
  staffName: string;
  position: string;
  level: number;
  dateCreated: string;
  dateModified: string;
  siteId: string;
  subscriberId: string;
  id: string;
  _id: string;
}
