import { BaseModel } from "./base";

export class User extends BaseModel{
  UserName: string;
  password: string;
  PhoneNo: string;
  ParentId: string;
  Email: string;
  email: string;
  UserType: string;
  userRole: string;
  SubscriberName: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  StaffName: string;
  name: string;
  lastLogin: number;
  idleScreenMaxTime: number;
  Logo: string;
  LogoPath: string;
  VerifyStatus: string;
  IsConfirmed: string;
  UIN: string;
  NewUser: string;
  spouseName: string;
  noOfChildren: number;
  address: string;
  gender: string;
  ocupation: string;
  slogan: string;
  siteId: string;
  siteName: string;
  siteNumber: string;
  levelCount: number;
  canAddSub: boolean;
  siteGenericNumber: string;
  systemAdminStatus: string;
  resetPasswordToken: string;
  resetPasswordExpires?: Date;
  Id: string;
  SubscriberId: string;
  socketId?: string;
  
  departmentId: string;
  departmentName: string;
}

export class UserDto {
  UserName: string;
  password: string;
  PhoneNo: string;
  ParentId: string;
  Email: string;
  UserType: string;
  userRole: string;
  SubscriberName: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  StaffName: string;
  Logo: string;
  VerifyStatus: string;
  IsConfirmed: string;
  UIN: string;
  NewUser: string;
  spouseName: string;
  noOfChildren: number;
  address: string;
  gender: string;
  ocupation: string;
  slogan: string;
  siteId: string;
  siteName: string;
  siteNumber: string;
  levelCount: number;
  canAddSub: boolean;
  siteGenericNumber: string;
  systemAdminStatus: string;
  resetPasswordToken: string;
  resetPasswordExpires?: Date;
  Id: string;
  id?: string;
  socketId?: string;
  departmentId: string;
  departmentName: string;
}

export class UserRO {
  id: string;
  username: string;
  phoneNo: string;
  token?: string;
  email: string;
  userType: string;
  subscriberName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  StaffName: string;
  logoURL: string;
  verifyStatus: string;
  isConfirmed: string;
  UIN: string;
  newUser: string;
  spouseName: string;
  noOfChildren: number;
  address: string;
  gender: string;
  ocupation: string;
  slogan: string;
  siteId: string;
  siteName: string;
  siteNumber: string;
  levelCount: number;
  canAddSub: boolean;
  siteGenericNumber: string;
  systemAdminStatus: string;
  resetPasswordToken: string;
  resetPasswordExpires?: Date;
  parentId: string;
  userRole: string;
  departmentId: string;
  departmentName: string;
}

export class Credentials {
  username: string;
  password: string;
  UIN: string;
  email: string;
}
