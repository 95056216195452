import { BaseModel } from './base';

export class Chat extends BaseModel {
  replyId?: string;
  description: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  targetId: string;
  subscriberId: string;
  siteId: string;
}
