import { Ref } from '@typegoose/typegoose';
import { BaseModel } from './base';
import { VendorTable } from './vendor';

export class TenderMaterial extends BaseModel {
  // vendorId: Ref<VendorTable>;
  vendorName: string;
  tenderDescription: string;
  tenderPerson: string;
  deptName: string;
  tenderDate: string;
  dueDate: string;
  bidSource: string;
  // tenderAmount: string;
  bidTypeName: string;
  comments: string;
  tenderStatus: string;
  receivedDate: string;
  dateCreated: Date;
  subscriberId: string;
  siteId: string;
  _id: string;
}
