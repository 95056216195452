import { Ref } from '@typegoose/typegoose';
import { StaffInfo, User } from '.';

import { BaseModel } from './base';

export class OpeningPressure extends BaseModel {
  client: string;
  jobNo: string;
  field: string;
  well: string;
  date: Date;
  sampleId: string;
  checks: Array<{
    CID: string;
    TVD: string;
    OpeningPressure_PSIG: string;
    BHP_PSI_BHT: string;
    OverPressure_PSI: string;
    SAND: string;
    LABCondition: string;
  }>;
  operators: Array<{
    userId: Ref<User>;
    staffId: Ref<StaffInfo>;
    staffName: string;
  }>;
  clients: Array<{
    reps: [{ repName: string; date: Date }];
    name: string;
  }>;
}
