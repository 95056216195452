import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApplicationDto, UserDto, User } from '@mrsoftebs/data';

import { AuthenticationService } from 'apps/ebs/src/app/services/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'cradua-addmin-application',
  templateUrl: './addmin-application.component.html',
  styleUrls: ['./addmin-application.component.scss'],
})
export class AddminApplicationComponent implements OnInit {
  @Input() applications: ApplicationDto[];
  @Input() subscribers: User[];
  @Output() submitEvent = new EventEmitter();
  activatedModules = [];
  filteredSubscriber = [];
  status = FormControl;
  user: User;
  constructor(
    private fb: FormBuilder,
    public authServ: AuthenticationService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.user = this.authServ.getUser();

    this.subscribers = this.subscribers.filter(
      (sub) => sub.UserType === 'Subscriber'
    );
    this.subscribers.map((sub) => {
      const subscriber = {
        sub,
        subscriptions: this.applications.filter(
          (myapp) => +myapp.SubscriberId === +sub.SubscriberId
        ),
      };
      this.filteredSubscriber.push(subscriber);
    });
  }

  calcAmount(amount, numberOfUser, duration) {
    return amount * numberOfUser * duration;
  }

  calTotal(applications) {
    let money = {
      totalAmount: 0,
      totalAmountPaid: 0,
      notPaid: 0,
      totalSub: 0,
      negotiated: 0,
    };

    applications.map((a) => {
      money.totalAmount += a.AmountPaid;
      money.totalSub += 1;
      if (a.PaymentStatus === 'Paid') {
        money.totalAmountPaid += a.AmountPaid;
      } else {
        money.notPaid += a.AmountPaid;
      }

      if (a.Unlimited === 'Unlimited') {
        money.negotiated += 1;
      }
    });
    return money;
  }

  activateModule(data: ApplicationDto, status?: boolean) {
    const newData = new Date();
    let day = newData.getDate();
    let mnth = newData.getMonth();
    let yr = newData.getFullYear();
    let approve = '';
    let message = '';
    let app = {
      AmountPaid: data.AmountPaid,
      ApprovalStatus: data.ApprovalStatus,
      Description: data.Description,
      Duration: data.Duration,
      EndDate: data.EndDate,
      ModifiedById: data.ModifiedById,
      ModuleName: data.ModuleName,
      NoOfUsers: data.NoOfUsers,
      PaymentStatus: data.PaymentStatus,
      RoleId: data.RoleId,
      SiteId: data.SiteId,
      StartDate: data.StartDate,
      SubscriberId: data.SubscriberId,
      Unlimited: data.Unlimited,
      dateCreated: data.dateCreated,
      dateUpdated: data.dateUpdated,
      id: data.id,
    };

    if (app.PaymentStatus === 'Pending') {
      approve = 'approved';
      message = 'this subscriber will access to this paid module!';
    } else {
      approve = 'disaproved';
      message =
        'this subscriber will be disable from having access to this module!';
    }

    if (app.ApprovalStatus === 'Pending') {
      app.ApprovalStatus = 'Paid';
      app.PaymentStatus = 'Paid';
      if (app.Unlimited !== 'Unlimited') {
        app.StartDate = newData;
        if (app.Duration === 1) {
          app.EndDate = new Date(yr, mnth + 1, day);
        } else {
          app.EndDate = new Date(yr, mnth + 12, day);
        }
      }
    } else {
      app.ApprovalStatus = 'Pending';
      app.PaymentStatus = 'Pending';
    }

    if (status) {
      this.submitEvent.emit([app]);
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: message,

        showCancelButton: true,
        confirmButtonText: `Yes, ${approve}!`,
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          this.submitEvent.emit([app]);
          Swal.fire('Approve!', `You have successfully ${approve}.`, 'success');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', `Your ${approve} retained :)`, 'error');
        }
      });
    }
  }

  approveAll(applications) {
    if (applications.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text:
          'approving all will eneble subscriber have access to all paid module!',

        showCancelButton: true,
        confirmButtonText: 'Yes, approve!',
        cancelButtonText: 'No, keep it',
      }).then((result) => {
        if (result.value) {
          applications.map((a) => {
            if (a.PaymentStatus === 'Pending') {
              this.activateModule(a, true);
            }
          });
          Swal.fire(
            'Approve!',
            'You have successfully approved all.',
            'success'
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Cancelled', 'Your approval retained :)', 'error');
        }
      });
    }
  }

  checkStatus(status) {
    return status == 'Paid' ? true : false;
  }
  onSubmit(data) {
    this.submitEvent.emit(data);
  }
}
