import { BaseModel } from './base';

export class Stationaries extends BaseModel {
  ItemName: string;

  QtyLeft: number;

  QtyLastAdded: number;

  DateCreated: Date;
  Cost: number;
  DateModified: Date;
  createdPersonId: string;
  SubscriberId: string;
  _id: string;
  SiteId: string;
}
