import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '@mrsoftebs/data';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public notice: Notification,
    private dialogRef: MatDialogRef<NotifierComponent>
  ) // private notifier: NotificationService
  {}

  ngOnInit() {}
  readNow() {
    this.dialogRef.close(this.notice);
  }
  readLater() {
    this.dialogRef.close();
  }
}
