import { ChainofCustody } from './chainofcustody';
import { StaffInfo } from './staffinfo';

import { BaseModel } from './base';

export class EnvJobSchedule extends BaseModel {
  chainOfCustody: ChainofCustody;
  jobRequirement: [{ title: string; status: string; dateCreated: Date }];
  jobSchedule: [
    { parameter: string; staffs: [{ staff: StaffInfo }]; dateCreated: Date }
  ];
  dateCreated: Date;
  analysisRequestId: string;
}
