import { BaseModel } from './base';

export class RefereeDetails extends BaseModel {
  refereeId?: string;

  name: string;

  phoneNumber: string;

  homeAddress: string;

  emailAddress: string;

  subscriberid: string;

  siteId: string;

  dateCreated: string;

  dateModified: string;
  _id: string;
}
