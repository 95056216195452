import { BaseModel } from './base';

export class Bankdetails extends BaseModel {
  accountName: string;
  bankId: string;
  bankBranchId: string;
  accountNo: string;
  bankSorteCode: string;
  bvn: string;
  status: string;
  dateCreated: Date;
  dateModified: Date;
  id: string;
}
