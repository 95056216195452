
import { BaseModel } from './base';

export class ApplicationFaculty extends BaseModel{

  facultyId: string;

  facultyName: string;

  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
  _id: string;
}
