<div class="box green_box"></div>
<div class="box pink_box"></div>
<div class="blur_ball">
  <img src="assets/images/small.svg" class="top1" alt="" />
  <img src="assets/images/small.svg" class="bottom1" alt="" />
</div>

<main class="main_signin">



  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="form">
    <div class="sign_in">
      <h2 class="title">Sign up</h2>
      <div class="contain">
        <div class="the_input">
          <label for="">Organization name <span class="red">*</span></label>
          <input type="text" placeholder="Enter organization name" formControlName="SubscriberName"
            class="default_input" [ngClass]="{ 'inactive_input': (authService.isLoading$ | async) }"
            [readonly]="(authService.isLoading$ | async)" />
          <span class="error"
            *ngIf="(controls.SubscriberName.touched || controls.SubscriberName.dirty) && controls.SubscriberName.errors && controls.SubscriberName.errors.required">SubscriberName
            is required</span>
        </div>
        <div class="the_input">
          <label for="">Email <span class="red">*</span> </label>
          <input type="email" placeholder="Enter email" formControlName="UserName" class="default_input"
            [ngClass]="{ 'inactive_input': (authService.isLoading$ | async) }"
            [readonly]="(authService.isLoading$ | async)" />
          <span class="error"
            *ngIf="(controls.UserName.touched || controls.UserName.dirty) && controls.UserName.errors && controls.UserName.errors.required">UserName
            is
            required</span>
          <span class="error"
            *ngIf="(controls.UserName.touched || controls.UserName.dirty) && controls.UserName.errors && controls.UserName.errors.email">Must
            be a valid email
          </span>
        </div>
        <div class="the_input">
          <label for="">Password <span class="red">*</span></label>
          <input required type="password" formControlName="password" class="default_input"
            [ngClass]="{ 'inactive_input': (authService.isLoading$ | async) }"
            [readonly]="(authService.isLoading$ | async)" />
          <span class="error"
            *ngIf="(controls.password.touched || controls.password.dirty) && controls.password.errors && controls.password.errors.required">Password
            is
            required</span>
        </div>
        <p class="error"
          *ngIf="(controls.password.touched || controls.password.dirty) && controls.password.errors && controls.password.errors.pattern">
          Password must be at least 8 characters long
        </p>
        <p class="error"
          *ngIf="(controls.password.touched || controls.password.dirty) && controls.password.errors && controls.password.errors.pattern">
          and must be a combination of lowercase, uppercase,</p>
        <p class="error"
          *ngIf="controls.password.touched && controls.password.errors && controls.password.errors.pattern">
          number and
          special character</p>
      </div>
      <p class="alternative">Already have an account? <a [routerLink]="['/auth/login']" style="cursor: pointer;">Click
          here to Sign in</a></p>
      <div class="submit">
        <button type="submit_button" class="default_primary-button"
          [ngClass]="{ 'inactive_btn': (authService.isLoading$ | async) }"
          [disabled]="(authService.isLoading$ | async)">
          Sign Up
        </button>
        <!-- <div class="submit">
          <button class="submit_button" (click)="onSubmit()">Submit</button>
        </div> -->
        <div *ngIf="(authService.isLoading$ | async)">
          <mat-progress-spinner mode="indeterminate" diameter='30' strokeWidth='1'></mat-progress-spinner>
        </div>

      </div>




    </div>
  </form>

</main>




<!-- 
  <div class="sign_in row container-fluid">
    <div class="left_background col-md-7"></div>
    <div class="right_form col-md-5">

        <div class="up_button">
          
            <button [routerLink]="['/']">Home <i class="ti-arrow-left"></i></button>
        </div>

        <div *ngIf="isSignIn">
            
          <h3 class="the_top-header">Sign in</h3>
          <div *ngIf="errorMessage">
            <div class="alert alert-danger" role="alert">
              {{errorMessage}}
            </div>
          </div>
          <form [formGroup]="form" (ngSubmit)="login()" class="form">
  
              <label for="">Username</label>
              <div class="input">
                  <i class="ti-user"></i>
                  <input type="text" formControlName="username" placeholder="Enter Username">
              </div>
  
              <label for="">Password</label>
              <div class="input">
                  <i class="ti-unlock"></i>
                  <input type="password" formControlName="password" placeholder="Enter password">
              </div>
  
              <p class="alternative">
                  <span>Don't have an account? <a  style=" color:green; text-decoration: none; cursor: pointer;" (click)="showSignUp()">Click here to
                          subscribe</a></span>
                  <span class="ml-5" style="text-align: right;"><a href="#"
                          style="color:green; text-decoration: none;">Forgot
                          password?</a></span>
              </p>
  
              <div class="login__button">
                  <input type="submit" value="Sign in" class="sign">
              </div>
          </form>
        </div>
      

        <div *ngIf="!isSignIn">

          <h3 class="the_top-header">Sign up</h3>
          <form [formGroup]="registerForm" (ngSubmit)="register()" class="subscribe_form">
  
              <label for="">Organization name</label>
              <div class="input">
                  <i class="ti-user"></i>
                  <input type="text" placeholder="Enter organization name" formControlName="subscriberName">
              </div>
  
              <label for="">Username</label>
              <div class="input">
                  <i class="ti-user"></i>
                  <input type="text" placeholder="Enter Username" formControlName="username">
              </div>
  
              <label for="">Password</label>
              <div class="input">
                  <i class="ti-unlock"></i>
                  <input type="password" placeholder="Enter password" formControlName="password">
              </div>
  
              <p class="alternative">Already have an account? <a  (click)="showSignIn()" style="cursor: pointer;">Click here to Sign in</a></p>
  
              <div class="login__button">
                  <input type="submit" value="Sign up" class="sign">
              </div>
          </form>
        </div>
    </div>


</div>
  -->