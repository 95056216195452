import { BaseModel } from './base';
import { RelationshipType } from './relationshiptype';

export class Relations extends BaseModel {
  relationId?: string;
  relationName: string;
  // staffId: StaffInfo;
  relationshipTypeId: RelationshipType;
  dob: Date;
  address: string;
  phoneNo: string;
  emailAddress: string;
  dateCreated: Date;
  dateModified: Date;
  dependantPassport: string;
}
