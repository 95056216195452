import { StaffInfo } from './staffinfo';
import { LeaveType } from './leavetype';

import { BaseModel } from './base';

export class Leaveplan extends BaseModel {
  staffId: StaffInfo | string;
  leavetypeId: LeaveType[];
  noOfDays: string;
  leaveBalance: number;
  year: string;
  subscriberId: string;
  siteId: string;
  createdPersonId: string;
  totalLeaveTaken: number;
  totalExtraLeaveTaken: number;
}
