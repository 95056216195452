import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@mrsoftebs/schemas';
import { environment } from '../../environments/environment';

const url = environment.apiUrl;
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}
  findAll(): Observable<User[]> {
    return this.http.get<User[]>(url + 'user/listusers');
  }
  createUser(user: { name: string }) {
    return this.http.post(url + 'user/createuser', user);
  }
  updateUser(user) {
    return this.http.post(url + 'user/updateuser', user);
  }
}
