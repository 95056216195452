/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserDto } from '@mrsoftebs/data';
import { GatewayService } from 'libs/gateway/src/lib/services/gateway.service';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-siteContainer',
  templateUrl: './siteContainer.component.html',
  styleUrls: ['./siteContainer.component.scss'],
})
export class SiteContainerComponent implements OnInit, OnDestroy {
  isLoadingSite$: Observable<boolean>;
  site: UserDto[] = [];
  user: any = JSON.parse(localStorage.getItem('EBSloggedInUser'));
  href;
  subscription: Subject<boolean> = new Subject<boolean>();

  constructor(
    public authService: AuthService,
    private gatewayService: GatewayService
  ) {
    this.href = window.location.href.split('#')[0];
  }

  ngOnInit() {
    this.getAllSite();
  }

  getAllSite() {
    const sites = this.authService.getAllSiteBySubscriberId(
      this.user?.subscriberId
    );
    this.authService
      .showLoaderUntilComplement(sites)
      .pipe(takeUntil(this.subscription))
      .subscribe((data) => {
        this.site = data;
      });
  }

  onSaveSite(value: any) {
    if (!value?._id) {
      this.onCreateNewSite(value);
    } else {
      this.onUpdateSite(value);
    }
  }

  onCreateNewSite(value) {
    const mailData = {
      fromEmail: '',
      toEmail: value.Email,
      subject: 'Manage Account',
      task: '',
      responsibility: '',
      pipelinename: '',
      pipelinerequestdesc: '',
      period: '',
      message: '',
      salutation: '',
      footer: '',
      url: '',
    };

    const salute = 'Dear ' + value.StaffName + ' ,';

    mailData.salutation = salute;

    mailData.message = 'Click on the link below to change your password';

    mailData.footer = '';

    const linkURL =
      this.href + '#/auth/manage/' + value.Id + '/' + value.resetPasswordToken;
    mailData.url = linkURL;

    const site$ = this.authService.creatSite([{ ...value, _id: undefined }]);

    this.authService
      .showLoaderUntilComplement(site$)
      .pipe(
        map((item) => item),
        mergeMap((value) =>
          this.gatewayService
            .sendManageAccountMail(mailData)
            .pipe(withLatestFrom([value]))
        )
      )
      .pipe(takeUntil(this.subscription))
      .subscribe(() => {
        this.getAllSite();
      });
  }

  onUpdateSite(value) {
    const site$ = this.authService.updateSite(value);
    this.authService
      .showLoaderUntilComplement(site$)
      .pipe(takeUntil(this.subscription))
      .subscribe(() => {
        this.getAllSite();
      });
  }

  onEmitDeleteEvent(value) {
    Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to retrieve back the file once is deleted`,

      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        const deleteSite$ = this.authService.deleteSiteId(value);
        this.authService
          .showLoaderUntilComplement(deleteSite$)
          .pipe(takeUntil(this.subscription))
          .subscribe(() => {
            this.site = this.site.filter((item) => item['_id'] !== value);
          });
        Swal.fire('Deleted!', 'Deleted successfully.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file retained :)', 'error');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.next(true);
    this.subscription.complete();
  }
}
