// import { LoginService } from './../registration/services/login.service';
import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { delay } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
// import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
// import { NgxSpinnerService } from 'ngx-spinner';
import {
  NavigationCancel,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { TokenService } from 'apps/ebs/src/app/services/token.service';
import { LoggedInUser } from '@mrsoftebs/data';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { LoadingService } from 'apps/ebs/src/app/shared/loading.service';
import { ErrorService } from 'apps/ebs/src/app/models/error/error.service';

@Component({
  selector: 'dashboardnav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
})
export class DashboardNavComponent implements OnInit {
  loading: boolean;
  loggedInUser: LoggedInUser;
  isLoggedIn;
  // = JSON.parse(localStorage.getItem('isLoggedIn'));

  constructor(
    // private spinner: NgxSpinnerService,
    private tokenService: TokenService,
    private accountService: AccountService,
    mediaObserver: MediaObserver,
    //  private _loadingBar: NgxSpinnerService,
    // tslint:disable-next-line: variable-name
    private _router: Router // private loginservice: LoginService, // private spinner: NgxSpinnerService,
  ) // private subscriberservice: SubscriberService,
  // private _auth: AuthService
  {
    //  this.tokenService.isLoggedIn().subscribe(isLoggedIn =>{this.isLoggedIn =isLoggedIn});
    this.loggedInUser = this.accountService.getLoggedInUser();
    // tslint:disable-next-line: deprecation
  }
  SubscriberId = '';
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  showprofile = false;
  // overlap = false;
  watcher: Subscription;
  activeMediaQuery = '';
  title = 'app';
  collapseTwo = false;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  //  @ViewChild('sideNav',{static: true}) sidenav: MatSidenav = new MatSidenav();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose = new EventEmitter<any>();
  payload: any;

  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'my-custom-class',
    // listBackgroundColor: `#141E30`,
    fontColor: `rgb(170, 170, 170)`,
    // backgroundColor: `#141E30`,
    selectedListFontColor: `white`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false,
  };

  logout(data?) {
    this.tokenService.logout();
  }
  toggle() {
    this.sidenav.toggle();
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      // this._loadingBar.show();
    }
    if (event instanceof NavigationEnd) {
      // this._loadingBar.show();
    }
    if (event instanceof NavigationCancel) {
      // this._loadingBar.hide();
    }
    if (event instanceof NavigationError) {
      // this._loadingBar.hide();
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.listenToLoading();
    setTimeout(() => {
      // this.spinner.hide();
    }, 2000);
    // this.getSubscriber();
    console.log('Dashboard initialized...');
    if (!this.loggedInUser) {
      // this._router.navigate(['/']);
      this._router.navigate(['/app-dashboard']);
    }
    // this.errorService.error$.subscribe((error) => {
    //   console.log('Error: ', error);
    //   this.error = error;
    // });

    console.log('Logged In User ', this.loggedInUser);
  }

  //   // notification dialog
  notificatonDialog(event: any) {
    //   const dialogRef = this.dialog.open(NotificationComponent, {
    //     data: event,
    // height: '1000',
    // width: '500'
    //   } );
    // // closed the dialog method
    //   dialogRef.afterClosed().subscribe(result => {
    //     });
  }

  messageDialog(event: any) {
    //   const dialogRef = this.dialog.open(MessageComponent, {
    //     data: event,
    // height: '1001',
    // width: '501'
    //   } );
    // // closed the dialog method
    //   dialogRef.afterClosed().subscribe(result => {
    //     });
  }
  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    // this._loading.loadingSub
    //   .pipe(delay(5)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
    //   .subscribe((loading) => {
    //     this.loading = loading;
    //   });
  }

  setting() {}
  profile() {}
}
