<div class="form" *ngIf="isForgotPassword">
  <div class="box green_box"></div>
  <div class="box pink_box"></div>
  <div
    [formGroup]="emailForm"
    class="p-4 forgot-password card-diagonal-rounded"
  >
    <h4 class="p-4">Forgot Password</h4>
    <mat-form-field class="input" appearance="outline">
      <mat-label>Email Address</mat-label>
      <input
        matInput
        formControlName="email"
        type="email"
        required
        placeholder="Enter your email"
      />
      <mat-icon matSuffix>email</mat-icon>
      <!-- <mat-hint>Enter your email</mat-hint> -->
    </mat-form-field>

    <span
      class="error mb-4"
      *ngIf="
        emailForm.controls.email.touched &&
        emailForm.controls.email.errors?.email
      "
      >Not a valid email address</span
    >
    <span
      class="error mb-4"
      *ngIf="
        emailForm.controls.email.touched &&
        emailForm.controls.email.errors?.required
      "
      >You must enter email address</span
    >

    <div class="sign_in-submit">
      <button
        (click)="forgotPassword()"
        type="submit"
        class="default_primary-button"
        [ngClass]="{
          inactive_btn: isProccessing || emailForm.errors
        }"
        [disabled]="isProccessing || emailForm.errors"
      >
        Submit
        <div *ngIf="isProccessing">
          <mat-progress-spinner
            mode="indeterminate"
            color="warn"
            diameter="30"
            strokeWidth="5"
          ></mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
</div>

<mrsoftebs-change-password
  *ngIf="!isForgotPassword"
  [userId]="userId"
  [token]="token"
></mrsoftebs-change-password>
