import { Step } from './step';
import { Grade } from './grade';
import { Level } from './level';
import { Relations } from './relations';
import { WorkExperience } from './workexperience';
import { Country } from './country';
import { User } from './user';
import { RelationshipType } from './relationshiptype';
import { Bankdetails } from './bankdetails';
import { Title } from './title';
import { PayCategory } from './paycategory';
import { MaritalStatus } from './maritalstatus';
import { StaffEducationalHistory } from './staffeducationalhistory';
import { HrDocument } from './hrdocument';
import { BloodGroup } from './bloodgroup';
import { State } from './state';
import { LGA } from './lga';
import { BaseModel } from './base';
import { Document } from './document';
import { RefereeDetails } from './refereedetails';

export class StaffInfo extends BaseModel {
  staffId?: string;
  surname: string;
  firstname: string;
  middlename: string;
  fullname: string;
  gender: string;
  countryId: string;
  state: string;
  lga: string;
  dob: string;
  address: string;
  email: string;
  phoneNo: string;
  jobTitleId: string;
  positionId: string;
  employmentDate: Date;
  terminationDate: Date;
  hobbies: Array<string>;
  idCardNo: string;
  hometown: string;
  maritalStatusId: string;
  bloodGroup: string;
  passport: Document | string;
  passportUrl: string | Document;
  relations: Array<Relations>;
  documents: Array<HrDocument>;
  workExperiences: Array<WorkExperience>;
  educationalHistorys: Array<StaffEducationalHistory>;
  departmentId: string;
  basicSalary: number;
  payCategories?: PayCategory[];
  dateCreated: Date;
  dateModified: Date;
  type: string;
  subscriberId: string;
  siteId: string;
  titleId: Title;
  gradeId: Grade;
  levelId: Level;
  stepId: Step;
  nextofkinSurname: string;
  nextofkinFirstname: string;
  nextofkinPhone: string;
  nextofkinEmail: string;
  nextofkinAddress: string;
  relationshipTypeId: string;
  bankDetails: Array<Bankdetails>;
  status: string;
  userId: User | string;
  _id: string;
  planType: string;
  coverType: string;
  hospitalChoice: string;
  unitId: string;
  departmentName: string;
  SubscriberName: string;
  hasPension: string;
  approvalLimit: number;
  archived:boolean;
  isActive: boolean;
  refereeDetails: Array<RefereeDetails>;
}
