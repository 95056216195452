import {Department} from './department';
import {StaffInfo} from './staffinfo';

import { BaseModel } from './base';

export class   Memo extends BaseModel{
    message: string;
    departments:any[];
    staffsTopCopy:any[];
    permissionLevel:string;
    createdbyId:string;
    createdbyName:string;
    memoDesc:string;
    readBy:any[];
    dateModified: Date;
    dateCreated: Date;
    subject: string[];
    read:any [];
    staffs:any[];
    status: string;
    subscriberId:string;
    filePath:string;
    memoTypeId:string;
    memoTypeName:string;
    memoNumber:string;
    departmentId:string;
    resumptionDate:Date;
    terminationDate:Date;

}