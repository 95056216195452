

export class RequestQuery{
 isQueried: boolean;
    queryDate: Date;
    queryReasons: string;
    queryById: string;
    queryByName: string;
    queryAllFlags: string;
    queryByEmail: string;

    queryResponse: string;
}
