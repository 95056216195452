import { BaseModel } from './base';

export class VendorTable extends BaseModel {
  organisation: string;
  address: string;
  country: string;
  fullname: string;
  state: string;
  contactInfo: [];
  bankInfo: [];
  subscriberId: string;
  siteId: string;
  _id: string;
}
