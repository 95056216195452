import { BaseModel } from './base';

export class Role extends BaseModel {
  name: string;

  link: string;

  menuId: string;

  parentId: string;

  createdAt: Date;

  updatedAt: Date;

  selected: boolean;

  AmountPerUser?: number;

  SubscriberId: string;

  SubscriptionUserId?: string;

  SiteId: string;

  ModifiedById?: string;

  CreatedById?: string;

  dateUpdated?: Date;

  dateCreated?: Date;

  isMajor?: boolean;

  MenuDesc?: string;

  Version?: string;

  activities?: string;
  activitiesTransform?: [];

  iconPath?: string;
}

export class RoleParent {
  role: Role;
  children: RoleChild[];
}

export class RoleChild {
  role: Role;
  grandchildren: RoleGrandChild[];
}

export class RoleGrandChild {
  role: Role;
}

export class RoleDto extends Role {}
