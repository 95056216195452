import { BaseModel } from './base';
import { StaffInfo } from './staffinfo';

export class ReportVetting extends BaseModel {
  client: string;
  field: string;
  jobIdReportNo: string;
  typeOfAnalysis: string;
  jobCurrentStatus: string;
  dataAnalysts: Array<any>;
  reportFindings: Array<{
    observRecomendation: string;
    pageNo: string;
  }>;
  vettingOfficers: Array<{
    staff: StaffInfo | string;
    signature: string;
    date: Date;
  }>;
  SubscriberId: string;
}
