import { BaseModel } from './base';

export class SampleParameter extends BaseModel {
  SampleParameterName: string;
  SampletypeId: string;

  SampletypeName: string;
  SubscriberId: string;

  SiteId: string;

  id: string;
}
