import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { SigninComponent } from '../signin/signin.component';
import { SubscribeComponent } from '../subscribe/subscribe.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  slideItem;
  slide = [
    {
      src: 'assets/images/white-laptop-1181406.jpg',
      title: 'Enterprise Business Solution',
      description: 'All in one system for managing your business',
    },
    {
      src: 'assets/images/interview-tips.jpg',
      title: 'Recruitment Management',
      description:
        'Post job adverts, see candidates information and send invite with just a few clicks',
    },
    {
      src: 'assets/images/5-phases-project-management-lifecycle-696x360-1.jpg',
      title: 'Project Management',
      description:
        'Manage and monitor the progress of all your projects from anywhere',
    },
  ];
  repeater: NodeJS.Timeout;
  constructor(
    private dialog: MatDialog,
    private tokenService: TokenService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.tokenService.getloggedInUser()) {
      this.router.navigate(['/hr']);
    }
    this.slideItem = this.slide[0];
    this.repeater = setInterval((time) => {
      this.nextSlide(this.slideItem);
    }, 8000);
  }

  nextSlide(current) {
    const currentIndex = this.slide.findIndex((a) => a == current);
    if (currentIndex == this.slide.length - 1) {
      this.slideItem = this.slide[0];
    } else {
      this.slideItem = this.slide[currentIndex + 1];
    }
  }
  PreviousSlide(current) {
    const currentIndex = this.slide.findIndex((a) => a == current);
    if (currentIndex == 0) {
      this.slideItem = this.slide[this.slide.length - 1];
    } else {
      this.slideItem = this.slide[currentIndex - 1];
    }
  }
  showLogin() {
    this.router.navigate(['auth/login']);
    // this.dialog.open(SigninComponent, {
    //   width: '450px',
    //   height: '700px',
    //   position: { left: '35%', right: '35%', top: '10%', bottom: 'auto' },
    // });
  }
  showSubscribe() {
    this.router.navigate(['/auth/subscribe']);
    // console.log('Subscribe button');
    // this.dialog
    //   .open(SubscribeComponent, {
    //     width: '450px',
    //     height: '700px',
    //     position: { left: '35%', right: '35%', top: '30px', bottom: 'auto' },
    //   })
    //   .afterClosed()
    //   .subscribe((data) => {
    //     console.log('Data after close of dialog: ', data);
    //   });
  }
  ngOnDestroy() {
    clearInterval(this.repeater);
  }
}
