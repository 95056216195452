<nav class="navigation">
  <div class="logo">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon (click)="toggle()">menu</mat-icon>
    </button>
    <span>
      <h5 *ngIf="loggedInUser && loggedInUser.subscriber.subscriberName.length > 25">
        {{loggedInUser.subscriber.subscriberShortName}}</h5>
      <h5 *ngIf="loggedInUser && loggedInUser.subscriber.subscriberName.length <= 25">
        {{loggedInUser.subscriber.subscriberName}}</h5>
    </span>
  </div>

  <!-- <div class="search">
          <input type="search" name="search" id="nav_search" placeholder="Search...">
          <span class="search_icon"><img src="/images/top_nav/Magnifiction.svg" alt=""></span>
      </div> -->
  <div class="right_elements">
    <div class="user_greeting">
      <img src="assets/images/top_nav/user.jpg" class="nav_img-icon" alt="" height="30px"
        style="border-radius: 30px; width:30px;">
      <p class="greetings">

        <span class="name" *ngIf="loggedInUser">

          <button mat-button [matMenuTriggerFor]="menu">{{loggedInUser.email}}
            <span><img src="assets/images/top_nav/more-alt.svg" height="15px" style="margin-left: 5px;" alt=""></span>
          </button>
        </span>
      </p>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="profile()">
          <mat-icon>perm_identity</mat-icon> Profile
        </button>
        <button mat-menu-item (click)="setting()">
          <mat-icon aria-hidden="false" aria-label="{2:label} icon">settings</mat-icon> Setting
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon aria-hidden="false" aria-label="{2:label} icon">logout</mat-icon> Log out
        </button>

      </mat-menu>
    </div>
  </div>
</nav>
<main class="reference_main">
  <div class="pink_box"></div>
  <div class="green_box"></div>

  <div class="main">
    <div class="hold_center">
      <div class="the_menu">
        <div class="menu_item" (click)="goTo('dashboard')">
          <img src="assets/images/side_nav/admin-settings-male.png" height="60%" alt="" />
          <h2 class="title">Admin</h2>
        </div>
        <div class="menu_item" (click)="goTo('staff/staffinfo')">
          <img src="assets/images/side_nav/collaboration-clipart-managment-5.png" height="60%" alt="" />
          <h2 class="title">Employee Management</h2>
        </div>
        <div class="menu_item" (click)="goTo('leave')">
          <img src="assets/images/side_nav/leave.png" height="60%" alt="" />
          <h2 class="title">Leave Management</h2>
        </div>
        <div class="menu_item" (click)="goTo('stafftraining')">
          <img src="assets/images/side_nav/1436664.png" height="60%" alt="" />
          <h2 class="title">Staff Training</h2>
        </div>
        <div class="menu_item" (click)="goTo('time')">
          <img src="assets/images/side_nav/time_management_improve.jpg" height="60%" alt="" />
          <h2 class="title">Time Management</h2>
        </div>
        <div class="menu_item" (click)="goTo('travel')">
          <img src="assets/images/side_nav/b05e6a6f4645493b58ca26c6c2a3000b.png" height="60%" alt="" />
          <h2 class="title">Travel Management</h2>
        </div>
        <div class="menu_item" (click)="goTo('performance')">
          <img src="assets/images/side_nav/performance-icon-png-11.png" height="60%" alt="" />
          <h2 class="title">Performance</h2>
        </div>
        <div class="menu_item" (click)="goTo('payroll')">
          <img src="assets/images/side_nav/payroll.png" height="60%" alt="" />
          <h2 class="title">Payroll</h2>
        </div>
        <div class="menu_item">
          <img src="assets/images/side_nav/337-3376285_search-recruitment-icon.png" height="60%" alt="" />
          <h2 class="title">Recruitment</h2>
        </div>
        <div class="menu_item" (click)="goTo('references')">
          <img src="assets/images/side_nav/305-3054768_file-cabinet-png-transparent-image-file-cabinet-icon.png"
            height="60%" alt="" />
          <h2 class="title">References</h2>
        </div>
      </div>
    </div>
  </div>
</main>