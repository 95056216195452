import { AcountBanks } from './accountbanks';
import { BankBalancesDetails } from './bankbalancesdetails';
import { BaseModel } from './base';
import { ChequesClearedDetails } from './chequescleareddetails';
import { ChequesIssuedDetails } from './chequesIssueddetails';
import { ExpectedPaymentsdetails } from './expectedpaymentsdetails';
import { ImprestExpensesTypeDetails } from './imprestexpensestype';
import { ImprestTypeDetails } from './impresttypedetail';
import { InvoicePreparedDetails } from './invoiceprepareddetails';
import { PaymentReceivedDetails } from './paymentreceiveddetails';
import { summaryOfImprestExpensesDetails } from './summaryofimprestexpensesdetails';

export class AccountWeeklyReport extends BaseModel {
  receiverId?: string;
  receiverName: string;
  senderId?: string;
  senderName: string;
  subject: string;
  sentDate: Date;
  fromDate: Date;
  toDate: Date;
  invoicePrepared: Array<InvoicePreparedDetails>;
  expectedPayments: Array<ExpectedPaymentsdetails>;
  paymentReceived: Array<PaymentReceivedDetails>;
  bankBalances: Array<BankBalancesDetails>;

  imprestType: Array<ImprestTypeDetails>;
  imprestExpensesType: Array<ImprestExpensesTypeDetails>;
  summaryOfImprestExpenses: Array<summaryOfImprestExpensesDetails>;
  detailsofImprest: Array<DetailsOfImprest>;
  refundBreakdown: Array<RefundBreakdown>;
  refund: Array<Refund>;

  chequesCleared: Array<ChequesClearedDetails>;
  chequesIssued: Array<ChequesIssuedDetails>;
  accountantName: string;
  accountantId: string;
  SubscriberId: string;
  SiteId: string;
  dateCreated: Date;
  dateModified: Date;
}

export class DetailsOfImprest {
  Date: Date;
  Description: string;
  Amount: number;
}
export class RefundBreakdown {
  Date: Date;
  Description: string;
  Amount: number;
}
export class Refund {
  Date: Date;
  Description: string;
  Amount: number;
}
