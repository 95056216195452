import { BaseModel } from './base';

export class SlicklineWeeklyReport extends BaseModel {
  activitiesAccomplished: Array<activitiesAccomplishedDetails>;
  otherAssignedActivities: string;
  currentField: Array<currentFieldDetails>;
  additionalComment: string;
  actionBy: string;
  comment: string;
  reportBy: string;
  date: Date;
  receiverName: string;
  senderId: string;
  senderName: string;
  periodEnding: Date;
}
export class activitiesAccomplishedDetails {
  title: string;
  fromDate: string;
  toDate: string;
  activity: string;
}
export class currentFieldDetails {
  asDate: Date;
  client: string;
  description: string;
  value: number;
  invoiceNo: string;
}
