import { BaseModel } from './base';
import { ActionComment } from '@mrsoftebs/data';
export class EventActionDto extends BaseModel {
  EventId: string;
  EventName: string;
  ActionName: string;
  EventDate: Date;
  ActionDesc: string;
  DatePosted: string;
  OwnerId: string;
  OwnerName: string;
  OwnerEmail: string;
  ActionParties: [
    { ActionPartyId: string; ActionPartyName: string; ActionPartyEmail: string }
  ];
  DueDate: Date;
  StatusId: string;
  StatusName: string;
  PriorityId: string;
  PriorityName: string;
  Progress: number;
  Comments: ActionComment[];
  Files: [];
}
