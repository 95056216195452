<header class="home_header">
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light px-5 pt-0 pb-0">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img src="assets/images/logo.png" alt="" /> <span class="d-block"
          style="color: rgb(102, 102, 102); font-size: 1vw; font-weight: 500">Enterprise Business Solution</span></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <button mat-button class="nav-link active" aria-current="page" href="#" (click)="showLogin()">Login</button>
          </li>
          <li class="nav-item">
            <button mat-button class="nav-link" href="#" (click)="showSubscribe()">Subscribe</button>
          </li>
          <!-- <li class="nav-item">
            <button mat-button class="nav-link active" aria-current="page" href="#"
             (click)="showLogin()">Change Password</button>
          </li> -->
          <!-- <li class="nav-item">
              <a class="nav-link" href="#">Pricing</a>
            </li> -->
        </ul>
      </div>
    </div>
  </nav>

  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
        aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
        aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
        aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img [src]="slideItem.src" class="d-block carousel_img w-100" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5>{{slideItem.title}}</h5>
          <br />
          <p class="m-4">{{slideItem.description}} </p>
        </div>
      </div>
      <!-- <div class="carousel-item">
        <img [src]="slideItem.src" class="d-block w-100 carousel_img" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5>{{slideItem.title}} </h5>
          <p>{{slideItem.description}} </p>
        </div>
      </div>
      <div class="carousel-item">
        <img [src]="slideItem.src" class="d-block w-100 carousel_img" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5>{{slideItem.title}} </h5>
          <p>{{slideItem.description}} </p>
        </div>
      </div> -->
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden" (click)="PreviousSlide(slideItem)">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden" (click)="nextSlide(slideItem)">Next</span>
    </button>
  </div>
</header>