import { BaseModel } from './base';

export class CustomerData extends BaseModel {
  companyName: string;
  email: string;
  address: string;
  reps: [
    {
      name: string;
      email: string;
      position: string;
      gender: string;
      customerId: string;
      SubscriberId: string;
      SiteId: string;
    }
  ];
  dateCreated: Date;
  dateModified: Date;
  subscriberId: string;
  siteId: string;
  id: string;
  _id: string;
}
