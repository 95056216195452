import { Component, OnInit, ViewChild } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LoggedInUser } from '@mrsoftebs/data';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { ErrorService } from '../../models/error/error.service';
import { TokenService } from '../../services/token.service';
import { LoadingService } from '../../shared/loading.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  loading: boolean;
  loggedInUser: LoggedInUser;
  isLoggedIn;
  watcher: any;
  activeMediaQuery: string;
  opened: boolean;
  over: string;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  // = JSON.parse(localStorage.getItem('isLoggedIn'));

  constructor(
    // private spinner: NgxSpinnerService,
    private tokenService: TokenService,
    private accountService: AccountService,
    mediaObserver: MediaObserver,
    private _router: Router,

    public dialog: MatDialog
  ) {
    this.loggedInUser = this.accountService.getLoggedInUser();

    // this.watcher = mediaObserver.asObservable() .subscribe((change: MediaChange) => {
    //   this.activeMediaQuery = change
    //     ? `'${change.mqAlias}' = (${change.mediaQuery})`
    //     : '';
    //   if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
    //     this.opened = false;
    //     this.over = 'over';

    //     this._router.events.subscribe((event: any) => {
    //       this.navigationInterceptor(event);
    //     });
    //   } else {
    //     this.opened = true;
    //     this.over = 'side';
    //   }
    // });
  }
  navigationInterceptor(event: Event) {
    throw new Error('Method not implemented.');
  }
  logout(data?) {
    this.tokenService.logout();
  }
  toggle() {
    this.sidenav.toggle();
  }
  goTo(route) {
    this._router.navigate([route]);
  }
  profile() {
    // console.log()
  }
  setting() {
    // console.log()
  }
  ngOnInit() {
    // console.log()
  }
}
