import { BaseModel } from './base';

export class StationariesHistory extends BaseModel {
  ItemId: string;
  ItemName: string;
  NameOfCollector: string;
  StaffId: string;
  DepartmentName: string;
  DepartmentId: string;
  QtyRequested: number;

  DateCollected: Date;
  Status: string;
  ApprovalPersonId: string;
  SubscriberId: string;

  SiteId: string;
}
