import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  error$ = new BehaviorSubject(null);

  constructor() {}

  getError(): Observable<any> {
    return new BehaviorSubject(this.error$);
  }
  setError(error) {
    this.error$ = new BehaviorSubject(error);
    this.error$.next(error);
  }
}
