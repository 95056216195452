import { JobApplication } from './jobapplication';
import { JobOpenning } from './jobopening';

import { BaseModel } from './base';

export class JobMessage extends BaseModel {
  message: string;
  jobId: JobOpenning;
  id: string;
  applicationId: JobApplication;
  applicantEmail: string;
}
