import { BaseModel } from './base';

export class Position extends BaseModel {
  positionName: string;
  unitId: string;
  jobTitleId: string;
  deptId: string;

  DateCreated: string;

  DateModified: string;

  subscriberId: string;

  siteId: string;
  id: string;
  _id: string;
}
