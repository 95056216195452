import { BaseModel } from "./base";

export class QualificationType extends BaseModel{
  qualificationTypeId: string;

  qualificationTypeName: string;

  dateCreated: Date;

  dateModified: Date;

  subscriberId: string;

  siteId: string;
  id: string;
}
